import React,{ Component } from 'react';
import { StyleSheet, Text, View, Platform, Dimensions, ImageBackground, Image } from 'react-native';
import OnlineStatus from '../common/OnlineStatus';
import Constants from '../../constants/Constants';

const width = Dimensions.get('window').width;
const percent = Math.ceil(width*15/100);
const cardWidth = width-percent;
const thumbnailHeight = cardWidth+20;
const screenWidth = width;
let delImgHeight = 60;
if(Platform.OS!=='web') delImgHeight = 65;
const isWeb = Platform.OS === 'web';

export class Card extends Component {
  constructor(props) {
    super(props);
    this.imgHeight = thumbnailHeight-delImgHeight;
  }

  componentDidMount(){
    const { swipeCardHeight } = this.props;
    if(swipeCardHeight!=undefined) this.imgHeight = swipeCardHeight-delImgHeight;
  }

  render() {
    let onlineId = 0;
    if(this.props.online!=undefined){
      onlineId = this.props.online?.id;
    }
    
    let income = this.props?.income;
    if(Constants.NOTSETTEXT.indexOf(income)!=-1) income = '';
    if(!isWeb) income = '';

    let message = this.props?.message;
    if(message) message = message.replaceAll("\n","");
    let showMessageBalloon = false;
    if(this.props?.messageType==2){
      showMessageBalloon = true;
    }

    return (    
      <View style={styles.card}>
        <View style={{width:'100%',height: this.imgHeight}}>
          <ImageBackground resizeMode={'cover'} 
            style={[styles.thumbnail]} 
            imageStyle={{borderTopLeftRadius:25, borderTopRightRadius:25}}
            source={{uri: this.props?.mainImageUrl}}
          >
          {this.props?.newFlag==1 && <View style={styles.newUser}><Text style={styles.newUserText}>NEW</Text></View>}
          </ImageBackground>
        </View>
        {this.props?.yellowcardFlg==1 && (
        <View style={{position:'absolute', top:10, right: 10, width:'100%', elevation:1, zIndex:1, justifyContent:'flex-end', alignItems:'flex-end'}}>
          <Image resizeMode={'cover'} style={{width:56, height:53}} source={require('../../assets/images/icon-iine-yellow.png')}/>  
        </View>
        )}
        {showMessageBalloon===true && (
        <View style={{position:'absolute',left:0, bottom:55, width:'100%', elevation:1, zIndex:1, justifyContent:'center', alignItems:'center'}}> 
          <View style={{width:'95%'}}>
            <ImageBackground source={require('../../assets/images/direct-message-bg.png')} resizeMode="stretch" style={{width:'100%',height:screenWidth<375? 68:81}}>
              <View style={{position:'absolute', top:0, left:10, paddingLeft:8, paddingRight:8, height:screenWidth<375? 20:24, backgroundColor:'#fff', borderColor:'#5bc6c8', borderWidth:2, borderRadius:999}}>
                <Text style={{fontSize:screenWidth<375? 9:11, fontWeight: 'bold', lineHeight:screenWidth<375? 16:20, color: '#5bc6c8'}}>ダイレクトメッセージが届いています！</Text>
              </View>
              <View style={{padding:10, paddingTop:screenWidth<375? 18:24}}>
              <Text numberOfLines={2} style={{fontSize:screenWidth<375? 12:14, fontWeight: 'normal', lineHeight:screenWidth<375? 18:20, color: '#fff'}}>{message}</Text>
              </View>
            </ImageBackground>
          </View>
        </View>
        )}

        <View style={styles.profileUser}>
          <View style={{paddingTop: 5,paddingLeft:10}}>
            <View style={styles.descItem}>
              <OnlineStatus online={onlineId} />
              <Text style={styles.textName}>{this.props?.name} {this.props?.age} {this.props?.currentPref} {income}</Text>
            </View>
            <View style={{width: cardWidth-20, paddingLeft:10}}>
              <Text numberOfLines={1}>{showMessageBalloon===true?'いいねを受信しました':message}</Text>
            </View>
          </View>
        </View>
      </View>
    )
  }
}

export class NoMoreCards extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View style={styles.noMoreCards}>
        <Text>No more cards</Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    borderRadius: 25,
    borderColor: '#cccccc',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    elevation: 1,
    width: cardWidth,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 1,
    ...Platform.select({
      ios:{
        height:'100%',
        marginTop: 0
      },
      android:{
        height:'100%',
        marginTop: 0
      },
      web:{
        height:'100%',
        marginTop: 0,
      }
    })
  },
  thumbnail: {
    width:'100%',
    height:'100%',
  },
  nowStatus: {
    marginRight:4,
    fontSize:8,
    color:'#aeaeae',
  },
  greenStatus: {
    color:'#24eb25',
  },
  orangeStatus: {
    color:'#ebba24',
  },
  redStatus: {
    color:'#eb7524',
  },
  profileInside:{
    flex:1,
    position:'absolute',
    left:0,
    bottom:0,
    padding:10,
    width:'100%',
    zIndex:10,
    elevation:10,
  },
  profileUser: {
    justifyContent:'flex-start',
    alignItems:'flex-start',
    width:'100%',
    height:60,
    backgroundColor:'#ffffff',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25
  },
  descItem: {
    width:'100%',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'flex-start',
  },
  textName: {
    fontSize:16,
    fontWeight:'bold',
    color:'#484848',
    textShadowOffset:{width: 1,height:1},
    textShadowRadius:1,
  },
  textDesc: {
    fontSize:14,
    fontWeight:'normal',
    lineHeight:24,
    color:"#fff",
    textShadowColor:'rgba(0,0,0,0.7)',
    textShadowOffset:{width: 1,height:1},
    textShadowRadius:1,
  },
  noMoreCards: {
    flex:1,
    justifyContent:'center',
    alignItems:'center',
  },
  royal: {
    position: 'absolute',
    right: 10,
    bottom: 50,
  },
  royal_icon: {
    width: 45,
    height: 45,
    marginLeft:4,
    marginBottom: 4,
  },
  warning: {
    position: 'absolute',
    right: 10,
    bottom: 50,
  },
  warning_icon: {
    width: 45,
    height: 41,
    marginLeft:4,
    marginBottom: 4,
  },  
  newUser: {
    backgroundColor:'rgba(255, 141, 99, 0.8)', 
    opacity:0.8, 
    position:'absolute', 
    left:0, 
    top:76, 
    width:75, 
    height:36, 
    justifyContent:'center',
    textAlign:'center',
    borderTopRightRadius:24,
    borderBottomRightRadius:24,
  },
  newUserText: {
    color:"#ffffff",
    fontSize:16,    
    fontWeight:"bold",
    lineHeight:36,
    textTransform:"uppercase",
    textAlign:'center'
  },
  arrowIcon: {
    alignItems: 'flex-end',
    paddingRight: 5
  },
  arrowRight:{
    marginLeft:10,
    fontSize:24,
  },
  moreContainer:{
    flexDirection:'row',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    height:24
  },
  moreView:{
    flex:1,
    alignSelf:'stretch', 
    alignItems:'flex-start',
    justifyContent:'center',
    top:Platform.OS === 'web' ? 2:0,
  },
  photoProfile: {
    flex:1,
    flexDirection:'row',
    justifyContent:'flex-end',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  icPhotoProfile: {
    marginRight:4,
    fontSize:14,
    color:'#ffffff',
  },
  numPhotoProfile: {
    color:'#ffffff',
    fontSize:14,
    lineHeight:19,
    letterSpacing:-.4,
  }
});