import React, {memo} from 'react';
import { View, Image, StyleSheet } from 'react-native';
//import UserColumn from '../../components/data/UserColumn';
import UserColumnNew from '../data/UserColumnNew';

const RenderItemSearchList = memo(
  ({item, index, numColumns, onPress, itemLayoutHeight, isMale, isRecommend}) => {
    return (
      <View style={[{flex:1/numColumns, height: itemLayoutHeight, borderRadius:10, alignItems: 'center', justifyContent: 'center', marginLeft:2, marginRight:2, marginBottom:20}]}>           
        <UserColumnNew index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend}/>
        <NewUser newFlag={item?.newFlag}/>
      </View>
    )
},
  (prevProps, nextProps) => {
    return prevProps.item?.mainImageUrl+prevProps.index.toString() === nextProps.item?.mainImageUrl+nextProps.index.toString();
  },
);

const NewUser = memo(
  ({newFlag}) => {
    if(newFlag==1){
      return (
        <Image style={styles.newUser} source={require('../../assets/images/badge-new.png')}/>
      )
    }
    
    return <></>
  },
  (prevProps, nextProps) => {
    return prevProps.newFlag === nextProps.newFlag;
  },
)

const styles = StyleSheet.create({
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
  newUser:{
    position:'absolute', 
    left:0, 
    top:0,
    width:50,
    height:50,
  },
  newUserImg: {
    width:50,
    height:50,
  }
});

export default RenderItemSearchList;