import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import ApiConfig from '../../constants/ApiConfig';

/**
 * API: 03_API仕様/レスポンス仕様書/22_おすすめ.xlsx
 */
export default class Recommend22Api extends ApiPappy{
  //1.1.1.おすすめリスト取得
  static async getList(from,to,showError=0){

    console.log('Recommend22Api.getList',from,to);
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Suggest/Get/List/",
        {
          ageFrom:from,
          ageTo:to
        },
        true
      );
      if(__DEV__) console.log('/Suggest/Get/List/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.SuggestGetList));
            console.log('/Suggest/Get/List/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1. スキップ登録
  static async postSkip(value, showError=0){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Suggest/Post/Skip/",
        {targetMemberId: value},
        true
      );
      if(__DEV__) console.log('/Suggest/Post/Skip/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Suggest/Post/Skip/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}