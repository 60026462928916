import React, { Component } from "react";
import { View } from "react-native";
import { Portal } from 'react-native-paper';
import PortalStyle from '../../styles/PortalStyles';
import OverlayManager from "./OverlayManager";
import ActivityIndicator from "./ActivityIndicator";

export default class Overlay extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      show: false
    }
    this.transparent = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      OverlayManager.register(this);
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    OverlayManager.unregister();
  }

  show = (transparent=false) => {
    if(this._isMounted){
      if(transparent!=undefined) this.transparent = transparent;
      this.setState({show: true});
    }
  }

  hide = () => {
    if(this._isMounted){
      this.setState({show: false});
      this.transparent = false;
    }
  }

  render(){
    if(this.state.show){
      return (
        <Portal style={PortalStyle.portal}>
          {this.transparent===true?
          <View style={[PortalStyle.portalContainer,{backgroundColor:'rgba(255,255,255,0)'}]} />
          :
          <View style={[PortalStyle.portalContainer,{backgroundColor:'rgba(255,255,255,1)'}]}>
            <ActivityIndicator/>
          </View>
          }
        </Portal>
      );
    }
    
    return null;
  }
}
