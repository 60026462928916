import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Dimensions, ImageBackground, Platform  } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import * as RootNavigation from '../../navigation/RootNavigation';
import FireBaseCommon from '../../utils/FireBaseCommon';
import CommonFunction from '../../utils/CommonFunction';
import Storage from '../../utils/StorageHelper2';

const windowWidth = Dimensions.get('window').width;

export default class RequestNofityPermissionPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: false,
    }
    this.hideAction = null;
    this.popup1 = React.createRef();
    this.popup2 = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = async()=>{
    const isShowedPopup = await Storage.getData('isShowedRequestNofityPermissionPopup');
    const isPushRequest = window.Notification.permission === "default";
    if(isPushRequest){
      if(!isShowedPopup){
        Storage.storeData('isShowedRequestNofityPermissionPopup', true);
        this.setState({ show: true});
      }
      
    }else{
      await FireBaseCommon.checkNotification();
    }
  }

  hide = async()=>{
    this.setState({ show: false, error: false});
    
  }

  error = async()=>{
    this.setState({ error: true});
  }

  requestPermission = ()=>{
    this.hide();
    if(Platform.OS === 'web' && CommonFunction.isAndroidUA()){
      window.Notification.requestPermission().then(async(permission) => {
        if (permission === 'granted') {
          const { requestForToken } = require('../../firebase/firebase');
          const token = await requestForToken();
          if(token)
            FireBaseCommon.setupWebDeviceToken();
        }else if(permission === 'denied'){
          this.error();
        }
      });
    } else{
      this.hide();
    }
    // FireBaseCommon.requestPermission();
  }

  render() {
    const popupWidth = windowWidth * 0.77;
    const newHeight = popupWidth * 950 / 951;

    if(this.state.error){
      return(
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12,}}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#484848', lineHeight:24,}}>通知をONにするには</Text>
              <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>端末の[設定]アプリから[通知]＞[アプリの設定]＞すべてのアプリから[Chrome]を選択＞ウェブサイトの[pappy.jp]をオンにして下さい。{"\n"}※シークレットブラウザではプッシュ通知機能はご利用いただけません。</Text>
              <TouchableOpacity style={[styles.btnCancel, {marginTop:20,}]} onPress={()=>{ 
                this.hide();
              }}>
                <Text style={styles.txtBtnCancel}>閉じる</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Portal>
      )
    }

    if(!this.state.show) return <></>;

    return (
      <View>
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} />
            <View style={styles.container}>
              <ImageBackground resizeMode={'cover'} 
                style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center', marginTop:10}} 
                source={require("../../assets/images/notif_popup.png")}
              ></ImageBackground>
              <TouchableOpacity style={styles.btnBlue} onPress={this.requestPermission}>
                <Text style={styles.txtBtnBlue}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Portal>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    alignContent:'center', 
    paddingTop: 5, 
    width:'80%', 
    backgroundColor:'#fefefe', 
    borderRadius: 12,
  },
  btnBlue:{
    paddingLeft:8,
    paddingRight:8,
    marginTop:10,
    marginBottom:10,
    width:'50%',
    height:35,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'50%',
    height:30,
    alignSelf:'center', 
    backgroundColor:'#c3c3c3',
    alignItems:"center",
    justifyContent:"center",
    borderRadius:999,
    letterSpacing:2,
    marginRight:5
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  btnArea: {
    paddingTop:5,
    paddingBottom:5,
    paddingRight:27,
    paddingLeft:27,
  },
});