import React, { Component } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UserInfoScreen from '../../screens/UserInfoScreen';
import RecommendIineUserInfoScreen from '../../screens/RecommendIineUserInfoScreen';
import ChatRoomScreen from '../../screens/chat/ChatRoomScreen';
import ChatHideSettingScreen from '../../screens/chat/ChatHideSettingScreen';
import ReportScreen from '../../screens/ReportScreen';
import RecommendIineScreen from '../../screens/iine/RecommendIineScreen';
import PointShoppingStack from './PointShoppingStack';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import CommonFunction from '../../utils/CommonFunction';
import UserSettingImageScreen from '../../screens/UserSettingImageScreen';
import TransitionSpec from '../../constants/TransitionSpec';

const Stack = createStackNavigator();

export default class RecommendIineStack extends Component {
  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName="RecommendIine" screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        <Stack.Screen 
          name="RecommendIine" 
          children={(props)=><RecommendIineScreen {...props} />} 
          options={{headerShown: false}}
        />
        <Stack.Screen 
          name="Profile" 
          children={(props)=><RecommendIineUserInfoScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="SameProfile" 
          children={(props)=><UserInfoScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatRoom" 
          children={(props)=><ChatRoomScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Report" 
          children={(props)=><ReportScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatHideSetting" 
          children={(props)=><ChatHideSettingScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="UserSettingImage" 
          children={(props)=><UserSettingImageScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}