import React,{ Component } from 'react';
import { Dimensions, Animated, View, Text, TouchableOpacity, Keyboard, Platform } from 'react-native';
import { AppContext } from '../AppContext';
import ProfileApi from '../services/api/Profile04Api';
import UserUtil from '../utils/UserUtil';
import Constants from '../constants/Constants';
import PopupWrapper from "../components/common/PopupWrapper";
import UserVerify from '../utils/UserVerify';
import CommonFunction from '../utils/CommonFunction';
import FixedPhrase23Api from '../services/api/FixedPhrase23Api';
import HeaderOptions from '../constants/HeaderOptions';
import MailBoxApi from "../services/api/MailBox05Api";
import Msg from '../constants/option/Msg.json';
import Storage from '../utils/StorageHelper2';
import ErrorCode from '../constants/ErrorCode';
import FootmarkApi from '../services/api/Footmark06Api';
import CalendarApi from '../services/api/Calendar11Api';
import Moment from 'moment';
import IineUtil from '../utils/IineUtil';
import IineApi from '../services/api/Iine10Api';
import OverlayWrapper from '../components/common/OverlayWrapper';
import HeadStyle from '../styles/HeadStyles';
import { AntDesign, FontAwesome, Ionicons, Entypo } from '@expo/vector-icons';
import LogoNoMargin from '../components/common/LogoNoMargin';
import styles from '../styles/UserInfoStyles';

const { width, height } = Dimensions.get('window');

export default class ProfileScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.mainImgHeight = 380;
    this.scrollHeight = height - HeaderOptions.headerTopMenuHeight;
    this.rowHeightAeruList = 25;
    this.maxHeightAeruList = this.rowHeightAeruList * 3;
    this.swiper = React.createRef();
    this.templateSwiper = React.createRef();
    this.BottomMenuRef = React.createRef();
    this.BottomMessageRef = React.createRef();
    this.MatchCompleteRef = React.createRef();
    this.NoPointRef = React.createRef();
    this.UsePointRef = React.createRef();
    this.GoodNoPointRef = React.createRef();
    this.GoodUsePointRef = React.createRef();
    this.formik = React.createRef();
    this.textInputMessage = React.createRef();
    this.messageBox = {
      minHeight: 131,
      maxHeight: 131
    }
    this.initialFormMessageValues = { message: ''};
    this.matchKeyname = 'matchComplete';
    this.keyname = 'searchResult';
    this.messageLength = 30;
    this.showPreviousUserInfo = true;
    this.state = {
      mainIndex: null,
      index: null,
      userList: [],
      currentUser: [],
      suggestList: [],
      loading: true,
      account: UserUtil.getDefaultAccountAppState(),
      heightAeruList: new Animated.Value(this.maxHeightAeruList),
      toggleAeruList: true,
      disableLayout: false,
      currentScreenWidth: width,
      messageBoxHeight: this.messageBox.minHeight,
      showPopupImg: false,
      imagesList: [],
      currentImageIndex: 0,
      backgroundOpacity: new Animated.Value(0.8),
      maskUp: false,
      editable: false,
      paymentFlag: 0,
      firstSendMessage: false,
      scrollEnabled: false,
      fireSuggest: false,
      loadingSuggest: false,
      template:[],
      showArrowIcon:true,
      messageBody: '',
      networkError: false,
      currentLoading: false
    }
    this.scrollRef = [];
    this.setIineMemberId = [];
    this.suggestAction = [];
    this.prevRoute = ['Footmark','ChatRoom', 'Profile'];
    this.matchList = [];
    this.iineList = [];
    this.popupRef = [];
    this.componentName = this.constructor?.name;
    this.templateSliderWidth = width-20;
    this.templateItemWidth = 270;
    this.shouldComponentUpdateFlg = true;
    this.actionEnabled = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.countMatchComplete = Constants.countMatchComplete;
      const { navigation, BottomTabNavigation } = this.props;
      this.navigation = navigation;
      this.BottomTabNavigation = BottomTabNavigation;
      this.setHeader();
      CommonFunction.setMainBottomTab(this.BottomTabNavigation, "none"); // hide main bottom tab

      this.myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
      
      this.subscription = Dimensions.addEventListener('change', (e) => {
        const { width } = e.window;
        this.setState({currentScreenWidth: width});
      });

      if(Platform.OS==='ios'){
        this.keyboardSub = Keyboard.addListener('keyboardDidShow', (e)=> {
          const keyboardHeight = e.endCoordinates?.height;
          this.BottomMessageRef?.setBottom(keyboardHeight);
        });
        this.keyboardSubHide = Keyboard.addListener('keyboardDidHide', ()=> {
          this.BottomMessageRef?.setBottom(0);
        });
      }
      
      this.setStateAccount();
      this.navigation?.addListener('focus', () => {
        this.setStateAccount();
      });
    }
  }

  setStateAccount = ()=>{
    const { appState } = this.context;
    if(appState?.account){
      this.setState({account: appState.account});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.checkBottomTab();
    this.subscription?.remove();
  }
  
  shouldComponentUpdate(nextProps, nextState){
    //if(Platform.OS==='web'){
    //  return true;
    //}else{
      if(this.shouldComponentUpdateFlg===false) {
        this.shouldComponentUpdateFlg = true;
          return false;
        }
    //}
    return true;
  }

  getDataFromApi = async(memberId, reload=false)=>{
    try{
      this.index = this.userList?.findIndex(
        item => memberId == item.memberId
      );

      /*
      if(this.index==-1) {
        if(this.navigation?.canGoBack()){
          setTimeout(() => {
            this.navigation.goBack();
          }, 1000);
          return;
        }
      }
      */

      this.setState({ currentLoading: true });
      /*
      if(Platform.OS==='web'){
        this.currentList = this.makeListData(this.userList, this.index);
      }else{
        this.currentList = this.makeListDataApp(this.userList, this.index);
      }
      */
      if(this.index!=-1 && this.index!=undefined) {
        this.currentList = this.makeListDataApp(this.userList, this.index);

        this.currentUser = this.userList[this.index];
        this.initialIndex = this.currentList?.findIndex(
          item => this.userList[this.index].memberId === item.memberId
        );
      }
      
      let profileGetListResponse = await ProfileApi.GetList(memberId);
      //check response status
      if(!CommonFunction.checkResponseStatus(profileGetListResponse)) return;

      if(profileGetListResponse!=undefined && profileGetListResponse?.status==1){
        let profileInfo = {};
        if(profileGetListResponse?.response?.profileList?.count>0){
          profileInfo = profileGetListResponse?.response?.profileList?.list[0];
        }

        if(JSON.stringify(profileInfo)=='{}') {
          setTimeout(() => {
            this.navigation?.navigate('SearchList');
          }, 1000);
          return;
        }

        // direct access 
        if(this.userList==undefined || this.userList?.length==0) {
          this.userList = [];
          let mainImageUrl = profileInfo?.mainImage?.url;
          if(profileInfo?.mainImage?.certify==0) mainImageUrl = profileInfo?.mainImage?.noImageUrl;
          profileInfo.mainImageUrl = mainImageUrl;

          this.index = 0, this.initialIndex = 0;
          this.userList[0] = profileInfo, this.currentUser = profileInfo;
          this.currentList = this.makeListDataApp(this.userList, this.index);
        }

        // set appContext value
        let account = UserUtil.getAccountAppState(profileGetListResponse, this.context);
        let paymentFlag = account?.subscription?.status;
        let firstSendMessage = false;
        if(paymentFlag==0 && profileInfo.sendMessageFlg==0){ // check first message
          paymentFlag = 1;
          firstSendMessage = true;
        }

        if(reload===false){
          if(account?.activated!=0){
            if(profileInfo?.noFootmarkFlg==0){
              // call footmark　api
              FootmarkApi.postFootmark(memberId);
            }
          }
        }

        if(account?.activated!=0){
          if(profileInfo?.secretStatus==0){
            const responseGetTargetList = await CalendarApi.GetTargetList(memberId);
            const aeruList = responseGetTargetList?.response?.aeru?.list;
            if(aeruList!=undefined){
              profileInfo.aeruList = aeruList;
            
              const meetToday = aeruList.filter((element)=>{
                return element.day==Moment().format('D') && element.month==Moment().format('M') && element.year==Moment().format('YYYY');
              });
              profileInfo.meetToday = (meetToday?.length>0)?1:0;
            }
          }
        }
      
        let imagesList = [];
        let images = { url: profileInfo?.mainImage?.url };
        imagesList.push(images);
        if(profileInfo.subImage?.list?.length>0){
          profileInfo.subImage?.list?.map((e,i) => {
            images = { url: e.imageUrlOrg?e.imageUrlOrg:e.imageUrl };
            imagesList.push(images);
          });
        }

        const template = await this.getTemplateFromApiByMemberId(memberId);
        const editable = this.validateEditable({
          ageVerficationStatus: account?.ageVerficationStatus,
          verified: profileInfo?.verified,
          paymentFlag: paymentFlag
        });

        this.setState({ 
          mainIndex: this.index,
          index: this.initialIndex,
          userList: this.currentList,
          currentUser: {...this.currentUser, ...profileInfo},
          account: account,
          suggestList: [],
          toggleAeruList: true,
          disableLayout: false,
          imagesList: imagesList,
          currentImageIndex: 0,
          paymentFlag: paymentFlag,
          firstSendMessage: firstSendMessage,
          fireSuggest: false,
          template: template,
          editable: editable,
          loading: false,
          networkError: false,
          currentLoading: false
        },()=>{
          if(Platform.OS==='web'){
            if(this.swiper?.snapToItem!=undefined){
              //this.swiper.snapToItem(this.initialIndex, false);
              this.scrollToTop();
            }
          }else{
            // case previous
            this.scrollToTop(this.initialIndex-1);
          }

          /*
          this.getTemplateFromApi();
          this.setState({ editable: this.validateEditable() });
          */
        });
      }else{
        this.setState({ networkError: true });
      }
    }catch(e){
      if(__DEV__) console.log(e);
      this.setState({ loading: false, currentLoading: false });
    }finally{
      //this.setState({ loading: false });
    }
  }

  makeListDataApp = (userList, index) =>{
    let list = [];
    if(userList!=undefined){
      const prevIndex = index-1;
      if(prevIndex>=0){
        for(var i=0; i<=prevIndex;i++){
          if(userList[i] && i==prevIndex) {
            list[i] = userList[i];
          }else{
            list[i] = [];
          }
        }
      }
      
      list[index] = userList[index];

      const nextIndex = index+1;
      if(nextIndex<=userList.length){
        if(userList[nextIndex]) {
          list[nextIndex] = userList[nextIndex];
        }else{
          list[nextIndex] = [];
        }
      }
    }
    
    return list;
  }

  makeListData = (userList, index) =>{
    let list = [];
    if(userList!=undefined){
      if(this.showPreviousUserInfo===true){
        const prevIndex = index-1;
        if(userList[prevIndex]) {
          list.push(userList[prevIndex]);
        }else{
          list.push([]);
        }
      }
      
      if(userList[index]) list.push(userList[index]);

      const nextIndex = index+1;
      if(userList[nextIndex]) {
        list.push(userList[nextIndex]);
      }else{
        list.push([]);
      }
    }
    
    return list;
  }

  getUserList = async(keyname)=>{
    if(keyname==undefined) keyname = this.keyname;
    let userList = [];
    const { appState } = this.context;
    if(appState[keyname] !=undefined){
      userList = appState[keyname];
    }else{
      userList = await Storage.getObjectData(keyname);
    }
    return userList;
  }
  
  getPopupRef = (key)=>{
    return this.popupRef[key];
  }

  showFixedMessage = (show)=>{
    if(show){
      this.popupRef?.FixedMessagePopup?.show();
    }else{
      this.popupRef?.FixedMessagePopup?.hide();
    }
  }

  getSuggestList = async()=>{
    if(!this.state.fireSuggest){
      try{
        //this.setState({loadingSuggest: true});

        const memberId = this.state.currentUser.memberId;
        let profileGetSuggestResponse = await ProfileApi.GetSuggest(memberId);
        if(profileGetSuggestResponse!=undefined && profileGetSuggestResponse.status==1){
          let suggestList = [];
          if(profileGetSuggestResponse?.response?.profileList?.count>0){
            suggestList = profileGetSuggestResponse?.response?.profileList?.list;
            CommonFunction.thumbnailUrlConver(suggestList);
          }
          
          this.setState({
            suggestList: suggestList, 
            fireSuggest: true
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        //this.setState({loadingSuggest: false});
      }
    }
  }

  getTemplateFromApi = async()=>{
    try{
      const parameter = { targetMemberId: this.state.currentUser?.memberId };
      let fixedPhraseResponse = await FixedPhrase23Api.TemplateGetList(parameter);
      if(fixedPhraseResponse!=undefined && fixedPhraseResponse?.status==1){
        const template = CommonFunction.createTemplate(fixedPhraseResponse?.response?.template);
        if(template){
          this.setState({template: template});
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  getTemplateFromApiByMemberId = async(targetMemberId)=>{
    let template = [];
    try{
      const parameter = { targetMemberId: targetMemberId };
      let fixedPhraseResponse = await FixedPhrase23Api.TemplateGetList(parameter);
      if(fixedPhraseResponse!=undefined && fixedPhraseResponse?.status==1){
        template = CommonFunction.createTemplate(fixedPhraseResponse?.response?.template);
      }
      return template;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  scrollToTop = (index)=>{
    //scroll to top
    if(index==undefined) index = this.state.index;
    if(index!=undefined){
      this.scrollRef[index]?.scrollTo({ x: 0, y: 0, animated: false });
    }
  }

  showNextUser = ()=>{
    if(this.state.currentLoading) return;

    if(this.state?.mainIndex >= (this.userList?.length-1)){
      // end, goto iine
      if(this.navigation?.canGoBack()){
        this.navigation.goBack();
        return;
      }
    }

    //scroll to top 
    //this.scrollToTop();
    //this.setState({scrollEnabled: true});

    this.nextIndex = this.state.index+1;
    if(this.state.userList[this.nextIndex]){
      if(Platform.OS==='web') this.scrollToTop(this.nextIndex);
      if(this.swiper?.snapToItem!=undefined){
        this.shouldComponentUpdateFlg = false;
        this.swiper.snapToItem(this.nextIndex, true);
      }
    }
  }

  showPrevUser = ()=>{
    if(this.state.currentLoading) return;

    //scroll to top
    //this.scrollToTop();
    //this.setState({scrollEnabled: true});
    this.prevIndex = this.state.index-1;
    if(this.state.userList[this.prevIndex]){
      if(Platform.OS==='web') this.scrollToTop(this.prevIndex);
      if(this.swiper?.snapToItem!=undefined){
        this.shouldComponentUpdateFlg = false;
        this.swiper.snapToItem(this.prevIndex, true);
      }
    }
  }

  showImg = (imageUrl) => {
    if(imageUrl!=undefined){
      let {currentUser} = this.state;
      currentUser.topMainImageUrl = imageUrl;
      this.setState({
        currentUser: currentUser
      });
    }
  }

  showAeruList = () => {
    this.setState({disableLayout: true}, ()=>{
      const { toggleAeruList } = this.state;
      Animated.timing(this.state.heightAeruList, {
        duration: 200,
        toValue: toggleAeruList ? (this.state.currentUser?.aeruList?.length * this.rowHeightAeruList) : this.maxHeightAeruList,
        useNativeDriver: false
      }).start(({ finished }) => {
        if(finished){     
          this.setState({ 
            toggleAeruList: !toggleAeruList, 
            disableLayout: false
          });
        }
      });
    });
  }

  checkNetworkError = ()=>{
    return this.state.networkError;
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account.activated, 
      navigation: this.navigation,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  checkAgePartner = ()=>{
    return UserVerify.checkAgePartner({
      ageVerficationStatus: this.state.currentUser.verified,
      navigation: this.navigation
    });
  }

  checkPayment = ()=>{
    if(UserVerify.checkPaymentAppState(this.context)) return true;

    return UserVerify.checkPayment({
      paymentFlag: this.state.paymentFlag,
      navigation: this.navigation
    });
  }

  validateAuthen = ()=>{
    if(!this.checkAge()) {
      this.BottomMessageRef?.hide();
      return false;
    }
    if(!this.checkAgePartner()) return false;

    if(this.myProfile?.sex==Constants.MALE){
      if(!this.checkPayment()) {
        this.BottomMessageRef?.hide();
        return false;
      }
    }
    
    return true;
  }

  validateEditable = ({ageVerficationStatus, verified, paymentFlag})=>{
    if(!ageVerficationStatus) ageVerficationStatus = this.state.account?.ageVerficationStatus;
    if(!verified) verified = this.state.currentUser?.verified;
    if(!paymentFlag) paymentFlag = this.state.paymentFlag;

    if(ageVerficationStatus!=Constants.AgeVerficationStatus.approve) return false;
    if(verified!=2) return false;

    if(this.myProfile?.sex==Constants.MALE){
      if(paymentFlag==Constants.SubscriptionStatus.notApprove) return false;
    }
    
    return true;
  }

  reloadCurrentProfileSendGood = async(responseSendGood)=>{
    try{
      let currentUser = this.state.currentUser;
      let goodCount = parseInt(currentUser.goodCount);
      if(goodCount!=undefined && !isNaN(goodCount)){
        goodCount = goodCount+1;
      }
      currentUser.goodFlg = 1;
      currentUser.goodCount = goodCount;
      currentUser.receiveMessageFlg = responseSendGood?.response?.receiveMessageFlg;
      currentUser.sendMessageFlg = responseSendGood?.response?.sendMessageFlg;
      currentUser.matchFlg = responseSendGood?.response?.matching;
      this.setState({ currentUser: currentUser });
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  reloadCurrentProfileSendMail = async(responseSendMail)=>{
    try{
      let currentUser = this.state.currentUser;
      let goodCount = parseInt(currentUser.goodCount);
      if(goodCount!=undefined && !isNaN(goodCount)){
        goodCount = goodCount+1;
      }

      currentUser.goodFlg = 1;
      currentUser.goodCount = goodCount;
      currentUser.receiveMessageFlg = responseSendMail?.response?.receiveMessageFlg;
      currentUser.sendMessageFlg = 1;
      currentUser.matchFlg = responseSendMail?.response?.matchFlg;

      let account = UserUtil.getAccountAppState(responseSendMail, this.context);
      let paymentFlag = account?.subscription?.status;
      let firstSendMessage = false;
      this.setState({ 
        currentUser: currentUser,
        account: account,
        paymentFlag: paymentFlag,
        firstSendMessage: firstSendMessage
       });
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  showPopUpMsg = ()=>{
    CommonFunction.hideOverlay();
    // show popup message
    this.BottomMessageRef?.showPopUp(true);
    this.setShowArrowIcon(false)
  }

  setShowArrowIcon = (show=true)=>{
    this.setState({showArrowIcon:show})
  }

  updateTextAreaSize = (height) => {
    if(height<this.messageBox.minHeight) {
      height = this.messageBox.minHeight;
    }else if(height > this.messageBox.maxHeight) {
      height = this.messageBox.maxHeight;
    }
    this.setState({ messageBoxHeight: height });
  }

  checkActionMessageBtn = ()=>{
    // validate SMS
    if(!this.checkSMS()) return;
    // validate Age
    if(!this.checkAge()) return;
    // validate Age Partner
    if(!this.checkAgePartner()) return;

    CommonFunction.checkAccessTime().then(response=>{
      if(response===true){
        if(this.state.currentUser?.receiveMessageFlg==1 || this.state.currentUser?.sendMessageFlg==1 
          || this.state.currentUser?.matchFlg==1){
          // goto chatroom
          this.navigation?.navigate('ChatRoom', {memberId: this.state.currentUser?.memberId});
        }else{
          this.showPopUpMsg();
        }
      }
    });
  }

  validateFormMessage = (values)=>{
    if(values.message=='') return false;
    return true;
  }

  resetFormMessage = ()=>{
    this.setState({messageBody: ''});
    this.formik?.setValues(this.initialFormMessageValues);
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    return true;
  };

  checkSuggestAction(index, height) {
    let suggestAction = 0;
    if(height < this.scrollHeight){
      suggestAction = 1;
    }
    this.suggestAction[index] = suggestAction;
  }

  checkBottomTab=()=>{
    let showBottomTab = true;
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
    }
    if(showBottomTab) {
      CommonFunction.setMainBottomTab(this.BottomTabNavigation, "flex");
    }
  }

  handleSubmitMessage = async(values, forceSubtract=false)=>{
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    // validate SMS
    if(!this.checkSMS()) {
      this.BottomMessageRef.showPopUp(false);
      return;
    }
    // validate Age
    if(!this.checkAge()){
      this.BottomMessageRef.showPopUp(false);
      return;
    }
    // validate Age Partner
    if(!this.checkAgePartner()) return;

    let checkMessage = CommonFunction.checkMessage(values.message);
    if(checkMessage==""){
        this.formik?.setValues({message:''});
        this.errorPopup?.showPopup();
        return;
    }
    const targetMemberId = this.state.currentUser?.memberId;
    let params = {
      targetMemberId: targetMemberId,
      messageType: 2,
      body: values.message,
      imageFile: null
    }
    if(forceSubtract===true) params.forceSubtract = 1;

    try{
      OverlayWrapper.show(true);
      const sendMailResponse = await MailBoxApi.SendMail(params, 0);
      if(sendMailResponse?.status==1){ // complete
        //reload data
        await this.reloadCurrentProfileSendMail(sendMailResponse);
        this.BottomMessageRef.hide();
        this.resetFormMessage();

        PopupWrapper.setOptions({timeout:500});
        PopupWrapper.showMessage(Msg.SendMessage.Success,'check',()=>{
          OverlayWrapper.hide();
        });
        
        if(this.state.firstSendMessage==true && this.state.paymentFlag==1){
          this.setState({paymentFlag: 0, editable: false});
        }else{
          this.setState({ editable: this.validateEditable() });
        }
      }else{
        OverlayWrapper.hide();
        if(JSON.stringify(sendMailResponse)=='{}'){
          return;
        }

        if(CommonFunction.checkIsInvalidErrorCode(sendMailResponse)){
          if(forceSubtract===false){
            const response = sendMailResponse?.response;
            const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
            const errorMessage = response?.errorMessage;
            if(errorCode==ErrorCode.MailBoxPostSendMail.NoIine){
              this.UsePointRef.setMessage(errorMessage);
              this.UsePointRef.show();
            }else if(errorCode==ErrorCode.MailBoxPostSendMail.NoPoint){
              this.NoPointRef.setMessage(errorMessage);
              this.NoPointRef.setOnNavigateAction(()=>{
                this.BottomMessageRef.hide();
              });
              this.NoPointRef.show();
            }else if(errorCode==ErrorCode.MailBoxPostSendMail.NoMember){
              PopupWrapper.showMessage('お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。','warning');
              this.resetFormMessage();
            }else{
              PopupWrapper.showMessage(Msg.SendMessage.Fail, 'warning');
              this.resetFormMessage();
            }
          }else{
            PopupWrapper.showMessage(Msg.SendMessage.Fail, 'warning');
            this.resetFormMessage();
          }
        }
      }
    }catch(e){
      OverlayWrapper.hide();
      if(__DEV__) console.log(e);
    }finally{
      this.setShowArrowIcon(true);
    }
  }

  sendGood = async(forceSubtract=false)=>{
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 500)


    // validate SMS
    if(this.state.currentUser?.receiveGoodFlg==0 && !this.checkSMS()) return;

    const memberId = this.state.currentUser?.memberId;
    let parameter = {
      targetMemberId: memberId, 
      messageType: 1, 
    };
    if(forceSubtract===true) parameter.forceSubtract = 1;

    try{
      CommonFunction.overLay();
      //const responseSendGood = await IineApi.sendGood(parameter, 1, 1);
      const responseSendGood = await IineApi.sendGood(parameter, 0);
      if(responseSendGood?.status==1){ // success
        // reload data
        await this.reloadCurrentProfileSendGood(responseSendGood);
        this.setMatchList(memberId);
        
        const response = responseSendGood?.response;
        const matching = response?.matching;
        if(matching==1){ // matching
          await CommonFunction.countMatchComplete(this.matchKeyname);
          let matchComplete = await CommonFunction.getMatchComplete(this.matchKeyname);
          if(matchComplete ==0){
            // show popup congratulations
            const options = {
              verified: this.state.account?.ageVerficationStatus, 
              partnerVerified: response?.verified, 
              partnerImageUrl: response?.partnerImageUrl, 
              ownImageUrl: response?.ownImageUrl,
              receiveMessageFlg: response?.receiveMessageFlg,
              sendMessageFlg: response?.sendMessageFlg,
              targetMemberId: memberId,
              matching: matching,
              activated: this.state.account.activated,
              account: this.state.account
            };
            this.MatchCompleteRef.setOptions(options);
            this.MatchCompleteRef.show();
            CommonFunction.hideOverlay();
          }else{
            this.showPopupComplete();
          }
        }else{
          this.showPopupComplete();
        }
      }else{
        CommonFunction.hideOverlay();
        if(JSON.stringify(responseSendGood)=='{}'){
          return;
        }

        if(CommonFunction.checkIsInvalidErrorCode(responseSendGood)){
          const response = responseSendGood?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
          let errorMessage = response?.errorMessage;
          let showError = false;
          if(forceSubtract===false){
            if(errorCode==ErrorCode.GoodPostSendGood.NoIine){
              this.GoodUsePointRef.setMessage(errorMessage);
              this.GoodUsePointRef.show();
            }else if(errorCode==ErrorCode.GoodPostSendGood.NoPoint){
              this.GoodNoPointRef.setMessage(errorMessage);
              this.GoodNoPointRef.show();
            }else if(errorCode==ErrorCode.GoodPostSendGood.NoMember){
              errorMessage = 'お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。';
              showError = true;
            }else{
              showError = true;
            }
          }else{
            showError = true;
          }
          if(showError===true && errorMessage) {
            if(this.componentName=='IineUserInfoScreen'){
              PopupWrapper.showModalMessage(errorMessage, '', this.showNextUser);
            }else{
              PopupWrapper.showModalMessage(errorMessage, '');
            }
          }
        }
      }
    }catch(e){
      CommonFunction.hideOverlay();
      if(__DEV__) console.log(e);
    }finally{
      //CommonFunction.hideOverlay();
    }
  }

  onChangeIndex = async(index)=> {
    this.setState({messageBody: ''});
    CommonFunction.overLay();
    if(this.state.userList[index]!=undefined){
      const memberId = this.state.userList[index].memberId;
      if(memberId==this.state.currentUser?.memberId) return;

      let params = {memberId: memberId};
      if(this.type!=undefined){
        params.type = this.type;
      }
      this.navigation?.setParams(params);
      await this.getDataFromApi(memberId);

      const mainIndex = this.state.userList?.findIndex(
        item => memberId == item.memberId
      );

      if(this.componentName == 'IineUserInfoScreen'){
        UserUtil.setAppState(this.context,'iineViewIndex',mainIndex);
      }else if(this.componentName == 'UserInfoSwiperScreen'){
        let indexMemberId = this.state.userList[index].listIndex;
        if(indexMemberId==undefined) indexMemberId = mainIndex;
        UserUtil.setAppState(this.context,'viewIndex',indexMemberId);
      }
    }
    //this.setState({scrollEnabled: false});
    CommonFunction.hideOverlay();
  }

  sendForceSubtractMessage = ()=>{
    const values = this.formik?.values;
    if(values){
      this.handleSubmitMessage(values, true);
    }else{
      this.BottomMessageRef.showPopUp(false);
    }
  }

  sendForceSubtractGood = ()=>{
    this.sendGood(true);
  }

  handleSuggestOnIine = async(targetMemberId)=>{
    try{
      const parameter = {
        activated: this.state.account?.activated, 
        targetMemberId: targetMemberId, 
        messageType: 1, 
        navigation: this.navigation
      };
      const success = await IineUtil.sendGood(parameter);
      return success;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  waitNextUser = ()=>{
    setTimeout(function(){
      this.showNextUser();
    }.bind(this), 1500);
  }

  setMatchList = (memberId)=>{
    if(memberId){
      this.matchList.push(memberId);
      let { appState, setAppState } = this.context;
      appState.matchList = this.matchList;
      setAppState(appState);
    }
  }

  setHeader = (items)=>{
    this.navigation.setOptions({
      header: () => (
        <View style={[HeadStyle.customHeader,{ borderBottomWidth:0}]}>
          <View style={{flex:1,justifyContent:'flex-start',flexDirection:'row'}}>
            <TouchableOpacity onPress={() => {
              if(this.navigation?.canGoBack()){
                this.checkBottomTab();
                this.navigation.goBack();
              }
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:3, flexDirection:'row',width:'100%',justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}}>
            <Text style={HeadStyle.headerTitlePage} numberOfLines={1}>{items?.name}</Text>
          </View>
          <View style={{flex:1,height:'100%',justifyContent:'center',alignContent:'flex-end', alignItems:'flex-end', alignSelf:'center'}}>  
            <TouchableOpacity onPress={()=>{
              if(this.checkNetworkError()) return;
              // validate SMS
              if(!this.checkSMS()) return;
              // validate Age
              if(!this.checkAge()) return;

              this.BottomMenuRef.showPopUp(true);
            }}>
              <Ionicons name="ellipsis-horizontal" style={styles.icShowPopup} />
            </TouchableOpacity>
          </View>
        </View>
      )
    });
  }
  handleChange = (text) => {
    this.setState({ messageBody: text });
    this.formik?.setValues({message: text});
  }
}