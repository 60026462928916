import React, { Component, Fragment } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, ImageBackground, Dimensions } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { CheckboxInput } from '../../components/form/FormInput';
import { Formik } from 'formik';
import Storage from '../../utils/StorageHelper2';
import UserVerify from '../../utils/UserVerify';

const windowWidth = Dimensions.get('window').width;

export default class MatchComplete extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.options = {
      verified: 0,
      partnerVerified: 0,
      partnerImageUrl: '',
      ownImageUrl: '',
      receiveMessageFlg: 0,
      sendMessageFlg: 0,
      targetMemberId: '',
      matching: 0,
      disableCallBack: false,
      activated: 0,
    }
    this.state = {
      show: false,
      options: this.options
    }
    this.initialValues = {
      accept: false
    }
    this.keyname = 'matchComplete';
  }

  checkSMS = ()=>{
    return UserVerify.checkSMS({
      activated: this.state.options.activated, 
      navigation: this.navigation,
      account: this.state.options?.account
    });
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  setOptions = (options)=>{
    const { 
      verified, partnerVerified, partnerImageUrl, ownImageUrl, receiveMessageFlg, sendMessageFlg, targetMemberId, matching, disableCallBack, activated, account
    } = options;
    
    if(verified!=undefined) this.options.verified = verified;
    if(partnerVerified!=undefined) this.options.partnerVerified = partnerVerified;
    if(partnerImageUrl!=undefined) this.options.partnerImageUrl = partnerImageUrl;
    if(ownImageUrl!=undefined) this.options.ownImageUrl = ownImageUrl;
    if(receiveMessageFlg!=undefined) this.options.receiveMessageFlg = receiveMessageFlg;
    if(sendMessageFlg!=undefined) this.options.sendMessageFlg = sendMessageFlg;
    if(targetMemberId!=undefined) this.options.targetMemberId = targetMemberId;
    if(matching!=undefined) this.options.matching = matching;
    if(disableCallBack!=undefined) this.options.disableCallBack = disableCallBack;
    if(activated!=undefined) this.options.activated = activated;
    if(account!=undefined) this.options.account = account;

    this.setState({ options: this.options});
  }

  show = ()=>{
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  isShow = ()=>{
    return this.state.show;
  }

  handleSubmit = async(values)=>{
    let matchComplete = 0;
    if(values.accept==true){
      matchComplete = 1;
    }
    /*
    if(values.accept==true){
      let matchComplete = await Storage.getData(this.keyname);
      matchComplete = parseInt(matchComplete);
      if(!matchComplete){
        matchComplete = 1;
      }else{
        matchComplete +=1;
      }
      Storage.storeData(this.keyname, matchComplete);
    }else{
      Storage.storeData(this.keyname, 0);
    }
    */
    Storage.storeData(this.keyname, matchComplete);
    this.hide();
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={styles.container}>
            <Formik
              initialValues={this.initialValues}
              onSubmit={values => this.handleSubmit(values) }
            >
              {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
              <Fragment>
                <View style={styles.blogCongrat}>
                  <ImageBackground source={require('../../assets/images/congratulations/txt-congratulations.png')} resizeMode="cover" style={styles.imgBGCongrat}>
                    <View style={styles.blogtxtCongrat}>
                      <Text style={styles.txtCongrat}>おめでとうございます！</Text>
                    </View>
                  </ImageBackground>
                </View>

                <View style={styles.avatarMatch}>
                  <Image source={{uri:this.state.options.partnerImageUrl}} style={styles.imgAvatarMatch}/>
                  {/*<Image source={require('../../assets/images/congratulations/ic-heart.png')} style={styles.imgHeartCenter}/>*/}
                  <Image source={{uri:this.state.options.ownImageUrl}} style={styles.imgAvatarMatch}/>
                </View>
                <View style={styles.blogTextCenter}>
                  <Text style={styles.txtLarge}>マッチングが成立しました！</Text>
                  {(this.state.options.verified==2 && this.state.options.partnerVerified==2) &&
                  <Text style={styles.txtMiddle}>
                  「いいねありがとう」と一緒にメッセージを
                  送るとスムーズなやり取りにつながります</Text>
                  }

                  {(this.state.options.verified==2 && this.state.options.partnerVerified!=2) &&
                  <Text style={styles.txtMiddle}>                  
                  お相手が年齢確認を完了されていない為、{"\n"}
                  メッセージをお送りすることはできません。{"\n"}
                  お相手の方が年齢確認完了後に{"\n"}
                  メッセージを送ってみましょう！
                  </Text>
                  }

                  {(this.state.options.verified!=2 && this.state.options.partnerVerified==2) &&
                  <Text style={styles.txtMiddle}>
                  メッセージ送信には年齢確認が必要です。{"\n"}
                  年齢確認完了後に{"\n"}
                  メッセージを送ってみましょう！</Text>
                  }

                </View>
                <View style={styles.blogCheckbox}>
                  <CheckboxInput fieldName="accept" NotdisplayError={true} containerStyle={{marginRight:3, paddingRight:0, marginLeft:0, margin:0, padding:0,}} />
                  <Text style={styles.txtCheckbox}>この画面をしばらく表示しない</Text>
                </View>
                
                <View style={styles.blogBtnArea}>
                {(this.state.options.verified==2 && this.state.options.partnerVerified==2) &&
                <>
                  {(this.state.options.receiveMessageFlg==1 || this.state.options.sendMessageFlg==1 || this.state.options.matching==1) &&
                  <TouchableOpacity style={styles.btnBtnSendMsg} onPress={()=>{
                    handleSubmit();
                    if(this.state.options?.targetMemberId){
                      if(this.props.callback!=undefined){
                        this.props.callback();
                      }
                      this.navigation.navigate('ChatRoom', {memberId: this.state.options.targetMemberId});
                    }
                  }}>
                    <Image source={require('../../assets/images/congratulations/ic-chat.png')} style={styles.icBtnSendMsg}/>
                    <Text style={styles.txtBtnSendMsg}>メッセージを送る</Text>
                  </TouchableOpacity>
                  }
                </>
                }

                  {(this.state.options.verified!=2 && this.state.options.partnerVerified==2) &&
                  <TouchableOpacity style={styles.btnBtnSendMsg} onPress={()=>{
                    handleSubmit();
                    if(this.props.callback!=undefined){
                      this.props.callback();
                    }
                    if(!this.checkSMS()) return;
                    //this.navigation.navigate('Identification');
                    this.navigation.navigate('Auth',{screen:'Identification'});
                  }}>
                    <Text style={styles.txtBtnSendMsg}>年齢確認をする</Text>
                  </TouchableOpacity>
                  }
                  <TouchableOpacity style={styles.btnBtnSeeLater} onPress={()=>{
                    handleSubmit();
                    if(this.props.hideCallback!=undefined && this.options?.disableCallBack!=true){
                      this.props.hideCallback();
                    }
                  }}>
                    <Text style={styles.txtBtnSeeLater}>また後で</Text>
                  </TouchableOpacity>
                </View>

              </Fragment>
              )}
            </Formik>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    flex:1,
    width:'100%',
    backgroundColor: 'rgba(255,255,255,0.9)',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center'
  },
  blogCongrat:{
    justifyContent:'center',
    width:'100%',
  },
  imgBGCongrat:{
    /*height:104,*/
    height:windowWidth<375? 89 : 104,
  },
  blogtxtCongrat:{
    paddingTop:10,
  },
  txtCongrat:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:16,
    color:'#333',
    textAlign:'center',
  },
  avatarMatch:{
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  imgAvatarMatch:{
    /*width:138,
    height:138,*/
    width:windowWidth<375? 118 : 138,
    height:windowWidth<375? 118 : 138,
    borderWidth:3,
    borderColor:'#fff',
    borderRadius:999,
  },
  imgHeartCenter:{
    /*marginLeft:-32,
    width:62,
    height:62,*/
    marginLeft:windowWidth<375? -28 : -32,
    width:windowWidth<375? 56 : 62,
    height:windowWidth<375? 56 : 62,
    position:'absolute',
    left:'50%',
    zIndex:1,
    elevation:1,
  },
  blogTextCenter:{
    marginTop:30,
    marginBottom:20,
    /*paddingLeft:24,
    paddingRight:24,*/
    paddingLeft:windowWidth<375? 12 : 24,
    paddingRight:windowWidth<375? 12 : 24,
    justifyContent:'center',
  },
  txtLarge:{
    marginBottom:20,
    /*fontSize:24,*/
    fontSize:windowWidth<375? 20 : 24,
    fontWeight:'bold',
    /*lineHeight:32,*/
    lineHeight:windowWidth<375? 24 : 32,
    color:'#333',
    textAlign:'center',
  },
  txtMiddle:{
    /*fontSize:16,*/
    fontSize:windowWidth<375 ? 13 : 16,
    fontWeight:'bold',
    /*lineHeight:24,*/
    lineHeight:windowWidth<375? 18 : 24, 
    color:'#333',
    textAlign:'center',
  },
  blogCheckbox:{
    flexDirection:'row',
    justifyContent:'center',
  },
  txtCheckbox:{
    /*fontSize:14,*/
    fontSize:windowWidth<375 ? 12 : 14,
    fontWeight:'bold',
    lineHeight:24,
    color:'#666',
    textShadowColor: 'rgba(255, 255, 255, 0.95)',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 10,
  },
  blogBtnArea:{
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    marginTop:60,
    width:'70%',
  },
  btnBtnSendMsg:{
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignItems:'center',
    width:'100%',
    padding:8,
    margin:8,
    /*height:50,*/
    height:windowWidth<375 ? 42 : 50,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  icBtnSendMsg:{
    marginRight:5,
    /*width:28,
    height:28,*/
    width:windowWidth<375 ? 22: 28,
    height:windowWidth<375 ? 22: 28,
  },
  txtBtnSendMsg:{
    /*fontSize:17,*/
    fontSize:windowWidth<375 ? 14 : 17,
    fontWeight:'bold',
    /*lineHeight:17,*/
    lineHeight:windowWidth<375 ? 14 : 17,
    color:'#fff',
    textAlign:'center',
  },
  btnBtnSeeLater:{
    /*marginTop:30,*/
    marginTop:windowWidth<375 ? 27 : 30,
    width:'80%',
    padding:8,
    /*height:36,*/
    height:windowWidth<375 ? 30 : 36,
    backgroundColor:'#fff',
    borderRadius:999,
    borderColor:'#666',
    borderWidth:1,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnSeeLater:{
    /*fontSize:15,*/
    fontSize:windowWidth<375 ? 13 : 15,
    fontWeight:'bold',
    /*lineHeight:20,*/
    lineHeight:windowWidth<375 ? 14 : 20,
    color:'#666',
    textAlign:'center',
  },
});