import React,{ Component } from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class PolicyScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = 'プライバシーポリシー';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }  

  setHeader = () =>{
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={styles.blogInner}>
          <View style={{}}>
            <Text style={styles.txtInfo}>本方針は、お客様がPappy(以下「本サービス」)をご利用されたことに伴い、当社が取得したお客様の個人情報の取り扱い方針を定めるものです。</Text>
          </View>
          <View style={{}}>
            <View style={styles.pMargin}>
              <Text style={styles.titleInfo}>個人情報保護に対する基本方針</Text>
            </View>
            <View>
              <Text style={styles.txtInfo}>
                当社は、個人情報保護に関する基本方針を以下の方法で徹底します。{"\n"}
                {"\n"}
                お客様の大切な個人情報は、ネット上に公開する情報と管理データを分け、ファイアウォールにより外部から保護された電算装置内に保存する事により、お客様の個人情報を保護いたします。{"\n"}
                当社は、クレジットカード決済・あと払い（ペイディ）の情報など特に重要な個人情報に関しましては、一切の情報を社内に保存することなく、 よりセキュリティーの高い外部機関にその保護を委託します。{"\n"}
                当社は社内においてお客様の個人情報に触れる事が出来る者を限定し、一部の外部委託業務に関しましては「秘密保持契約」を締結することで、お客様の個人情報保護を徹底いたします。{"\n"}
                当社は社団法人JAPHICマーク認証機関の対象業者です。（認定番号：1212280003(08)）{"\n"}
                第三者認証を取得し個人情報保護法を遵守する体制で運営しております。{"\n"}
              </Text>
            </View>
          </View>
          <View style={{}}>
            <View style={styles.pMargin}>
              <Text style={styles.titleInfo}>個人情報の利用目的</Text>
            </View>
            <View>
              <Text style={styles.txtInfo}>
                当社は本サービス会員登録の際に取得したお客様の個人情報について、以下の目的のために利用いたします。{"\n"}
                {"\n"}
                1. 本サービスを提供するため{"\n"}
                2. 新たな商品・サービス、各種イベント・キャンペーンの案内、システム変更、各種情報等の提供{"\n"}
                3. 本サービスにおけるコンテンツやサービス内容を充実させるため{"\n"}
                4. 本サービスについてのアンケートを行うため{"\n"}
                5. 当社がお客様に確認やお問い合わせに関しての連絡をするため{"\n"}
                ※ 上記以外に、当サイトでは広告の効果測定のため、第三者の運営するツールから当サイトの誘導元となった広告の情報（クリック日や広告掲載サイトなど）を取得し、当社サービスのご利用情報と照合する場合がございます。{"\n"}
              </Text>
            </View>            
          </View>
          <View style={{}}>
            <View style={styles.pMargin}>
              <Text style={styles.titleInfo}>個人情報の開示、及び取扱方法</Text>
            </View>
            <View>
              <Text style={styles.txtInfo}>
              当社は、本サービスの提供を離れて、お客様の個人情報を第三者に販売、貸し出しする業務は一切行いません。{"\n"}
                {"\n"}
                ただし、以下の場合に個人情報を開示することがあります。{"\n"}
                {"\n"}
                1. 本サービスを提供するため{"\n"}
                2. 上記利用目的の達成に必要な範囲内において個人情報の取り扱いの全部、一部を委託する場合。{"\n"}
                3. 情報開示や共有について、お客様に同意をいただいた場合。{"\n"}
                4. 裁判所・検察庁・警察庁・監督官庁等の公的機関から、法令に基づく照会を受けた場合。{"\n"}
                5. 人の生命、身体、財産等に対する差し迫った危険性があり、緊急性がある場合。{"\n"}
                {"\n"}
              </Text>
            </View>            
          </View>

          <View style={{}}>
            <View style={styles.pMargin}>
              <Text style={styles.titleInfo}>個人情報保護方針の変更・改定</Text>
            </View>
            <View>
              <Text style={styles.txtInfo}>
              当社はプライバシーポリシーの全部または一部を改定することがあります。{"\n"}
                {"\n"}
                重要な変更がある場合には、サイト上でお知らせします。{"\n"}
                {"\n"}                
              </Text>
            </View>            
          </View>
          
        </View>
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:15,
  },
  padding5: {
    padding:5,
  },
  pMargin: {
    marginTop:20,
    marginBottom:5,
  },
  titleInfo: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848'
  },
  txtInfo: {
    fontSize:14,
    lineHeight:17,
    color:'#444444',
  },
  font11: {
    fontSize:11,
    lineHeight:17,
    color:'#484848',
  },
});
