import { StyleSheet, Platform, Dimensions } from 'react-native';
import HeaderOptions from '../constants/HeaderOptions';

//height - header
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%', 
    height: '100%',
    ...Platform.select({
      ios: {},
      android: {},
      web:{
        marginTop: HeaderOptions.headerTopMenuHeight
    }})
  },
  scrollContainer: {
    ...Platform.select({
      ios: {
        flexGrow:1,
      },
      android: {
        flexGrow:1,
      },
      web:{
        height: scrollHeight,
    }}),
    backgroundColor:'#ffffff',
  }
});
