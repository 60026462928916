import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, View, ScrollView, Platform, 
  ImageBackground, Dimensions, SafeAreaView, TouchableOpacity, FlatList 
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import MyPage13Api from '../services/api/MyPage13Api';
import Payment15Api from '../services/api/Payment15Api';
import ActivityIndicator from '../components/common/ActivityIndicator';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import { Entypo } from '@expo/vector-icons';
import apiConfig from '../constants/ApiConfig';
import ListStyles from '../styles/ListStyles';
import UserVerify from '../utils/UserVerify';
import { RefreshControl } from 'react-native-web-refresh-control';
import PaidyFunction from '../utils/PaidyFunction';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PointShoppingScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      data: [],
      point: 0,
      account: UserUtil.getDefaultAccountAppState(),
      alreadyCallApi: false,
      isPaidy: false,
      memberId: ''
    };
    this.prevRoute = ['IinePlan','Profile','SameProfile','ChatRoom'];
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.parentNavigation = this.navigation?.getParent();
      
      this.setHeader();
      
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        this.getDataFromApi();
        CommonFunction.showBottomTab(this.navigation);
      });
      const { appState } = this.context;
      const userProfile = await CommonFunction.getProfile();  
      if(appState.account){
        this.setState({ account: appState.account, memberId: userProfile?.memberId});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }

    const routes = this.parentNavigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)==-1) CommonFunction.showBottomTab(this.parentNavigation);
    }
  }

  setHeader(){
    this.title = 'ポイント購入';
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.navigation,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  renderPackageItem = ({item, index}) => {
    let banner;
    if (this.state.isPaidy==true) {
      // paidy用のバナー画像を設定（productIdで判定）
      switch(item?.productId){
        case 'point1':
          banner = require('../assets/images/paidy/paidy_point10.png');
          break;
        case 'point2':
          banner = require('../assets/images/paidy/paidy_point30.png');
          break;
        case 'point3':
          banner = require('../assets/images/paidy/paidy_point55.png');
          break;
        case 'point4':
          banner = require('../assets/images/paidy/paidy_point110.png');
          break;
        case 'point5':
          banner = require('../assets/images/paidy/paidy_point550.png');
          break;
        default:
          break;
      }
    } else {
      switch(item?.productId){
        // テレコム用のバナー画像を設定（ポイントからproductIdの判定に変更）
        case 'purchase01':
          banner = require('../assets/images/point/point10.png');
          break;
        case 'purchase02':
          banner = require('../assets/images/point/point30.png');
          break;
        case 'purchase03':
          banner = require('../assets/images/point/point55.png');
          break;
        case 'purchase04':
          banner = require('../assets/images/point/point110.png');
          break;
        case 'purchase05':
          banner = require('../assets/images/point/point550.png');
          break;
        default:
          break;
      }    
    }

    if(banner){
      return (
        <View style={{flex:1, width:'100%', justifyContent:'center',alignSelf:'center',alignItems:'center',alignContent:'center',backgroundColor:'#f0f5f6'}}>
          <TouchableOpacity style={{width:'100%'}} onPress={async()=>{
            const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
            if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;
      
            if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
              const account = UserUtil.getAccountAppState(checkMemberStatResponse, this.context);
              this.setState({ account: account }, ()=>{
                if(!this.checkSMS()) return;
                if(!this.checkAge()) return;
                this.handleOnPress(item);
              });
            }
          }}>
            <Image style={styles.itemBlogRatePlan} source={banner} resizeMode="contain"/>
          </TouchableOpacity>  
        </View>        
      );
    }
  }
  
  noItemDisplay = () => {
    return <></>;
  }

  getDataFromApi = async ()=>{
    if(!this.state.isRefreshing && !this.state.alreadyCallApi){
      this.setState({ loading: true });
    }

    try{      
      // テレコムとpaidyのデータ一覧データ取得
      const orderResponse = await Payment15Api.PaymentGetOrder();
      if(!CommonFunction.checkResponseStatus(orderResponse)) return;

      if(checkUndefined(orderResponse) && orderResponse?.status==1){
        const data = orderResponse?.response;
        const point = orderResponse?.point;
        const account = UserUtil.getAccountAppState(orderResponse, this.context);
        this.setState({
          account: account,
          data: data,
          point: point,
          alreadyCallApi: true
        });
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false, isRefreshing: false});
    }
  }

  onRefresh = ()=>{
    this.setState({isRefreshing: true}, ()=>{
      this.getDataFromApi();
    });
  }

  createInputElement = (type='hidden',name,value)=>{
    let element = document.createElement('input');
    element.setAttribute("type", type);
    element.setAttribute("name", name);
    element.setAttribute("value", value);
    return element;
  }

  webSubmit = (item)=>{
    // javascript submit form
    let form = document.createElement('form');
    form.method = "POST";
    form.action = this.state.data?.telecom.orderUrl;
    const element1 = this.createInputElement('hidden', 'clientip', this.state.data?.telecom.clientip);
    const element2 = this.createInputElement('hidden', 'sendid', this.state.data?.telecom.sendid);
    const element3 = this.createInputElement('hidden', 'ordertype', 1); //1 point, 2 subscription
    const element4 = this.createInputElement('hidden', 'productid', item?.productId);
    const element5 = this.createInputElement('hidden', 'money', item?.money);
    const element6 = this.createInputElement('hidden', 'redirect_back_url', apiConfig.mainUrl+'/MyPage');
    form.appendChild(element1);
    form.appendChild(element2);
    form.appendChild(element3);
    form.appendChild(element4);
    form.appendChild(element5);
    form.appendChild(element6);
    document.body.appendChild(form);
    form.submit();

    if(__DEV__) console.log(form);
  }

  paidySubmit = (item)=>{

    var url = apiConfig.pappy_image_url + '/image/ppy-icon300.png';
    // Paidy初期設定
    var config = {
      "api_key": this.state.data?.paidy.apiKey,
      "logo_url": url,
      "closed": function(callbackData) {

        // paidy購入処理成功
        if(callbackData.status === 'authorized') {
          PaidyFunction.paidySuccessful(callbackData);
          return;
        }

        // paidy操作を中断（xボタンで閉じる）
        if(callbackData.status === 'closed') {
          PaidyFunction.paidyClosed();
          return;
        }
        // 決済データ作成否決時
        if(callbackData.status === 'rejected') {
          PaidyFunction.paidyRejected();
          return;
        }
      }
    };
    var paidyHandler = Paidy.configure(config);

    // ペイロード設定
    var order_ref = this.state.memberId+"_"+item.productId;
    var accountRegistDate = this.state.data?.paidy.accountRegistDate;
    var payload = {
      "amount": item.money,
      "currency" : "JPY",
      "store_name": "Pappy",
      "buyer_data": {
        "account_registration_date": accountRegistDate,
      },
      "order": {
         "items": [
            {
              "quantity":1,
              "title":"ポイント購入代金",
              "unit_price":item.money,
            }
         ],
         "order_ref": order_ref,
      },
    };
    paidyHandler.launch(payload);
  }

  handleOnPress = (item)=>{
    if(Platform.OS==='web'){
      if (this.state.isPaidy==true) {
        // paidyで決済
        this.paidySubmit(item);      
      } else {
        // クレカで決済
        this.webSubmit(item);      
      }
    }
  }

  getItemLayout = (data, index) => {
    const length = windowWidth<375? 114:134; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  changeList = (value) => {
    if (value==1) {
      this.setState({ isPaidy: true});
    } else {
      this.setState({ isPaidy: false});
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;
    let tab1;
    let tab2;
    let listData;
    // タブ画像切り替え
    if (this.state.isPaidy==true) {
      tab1 = require('../assets/images/paidy/paidy_tab02.png');
      tab2 = require('../assets/images/paidy/paidy_tab04.png');
      listData = this.state.data?.paidy.list;
    } else {
      tab1 = require('../assets/images/paidy/paidy_tab01.png');
      tab2 = require('../assets/images/paidy/paidy_tab03.png');
      listData = this.state.data?.telecom.list;
    }

    let tabH = 92;
    if (windowWidth<414) {
      tabH = 86;
      if (windowWidth<393) {
        tabH = 80;
        if (windowWidth<375) {
          tabH = 70;
        }
      }
    }

    return (
      <SafeAreaView style={ListStyles.container}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
          />
        }>
          <ImageBackground source={require('../assets/images/point/bg-top.png')} resizeMode="cover" style={styles.bgTopImg}>
          </ImageBackground>
          <View style={styles.blogPadding}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center', padding:10, width:'100%', backgroundColor:'#f1f1f1', borderRadius:8}}>
              <View style={{alignSelf: 'flex-start', paddingLeft: 5}}>
                <Text style={{fontSize:13, lineHeight:18,}}>現在のポイント</Text>
              </View>
              <View style={{alignSelf: 'flex-end', paddingRight: 5}}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                  <View style={{marginRight:5, width:18, height:18, backgroundColor:'#f8d549',borderRadius:999,textAlign:'center'}}>
                    <Text style={{fontSize:14, fontWeight:'bold', color:'#ffffff', lineHeight:18,textAlign:'center'}}>P</Text>
                  </View>
                  <Text style={{fontSize:14, lineHeight:18}}>{this.state.point}</Text>  
                </View>                
              </View>
            </View>
            <View style={{width:'100%',alignItems:'flex-end', marginTop: 7}}>
              <TouchableOpacity style={{flexDirection:'row', alignItems:'center'}} onPress={()=>{
                this.navigation.navigate('PointList');
              }}>
                <Text style={{fontSize:11, fontWeight:'bold', lineHeight:18, color:'#333333'}}>ポイント履歴を確認する</Text>
                <Entypo name="chevron-right" style={{fontSize:11, lineHeight:18, color: '#4b4b4b'}}/>
              </TouchableOpacity>
            </View>
          </View>
          {listData.length > 0 && (
          <>
          <View style={styles.blogPointPlan}>
            <Text style={styles.txtBlogPointPlan}>支払い方法・購入ポイントを選んでください</Text>
          </View>
          <View style={styles.paymentModeTab}> 
            <TouchableOpacity style={styles.paymentModeButton} onPress={()=>{
              this.changeList(0);
            }}>
              <Image style={[styles.paymentModeButtonImg, {height:tabH}]} source={tab1} resizeMode="contain"/>
            </TouchableOpacity>
            <TouchableOpacity style={styles.paymentModeButton} onPress={()=>{
              this.changeList(1);
            }}>
              <Image style={[styles.paymentModeButtonImg, {height:tabH}]} source={tab2} resizeMode="contain"/>
            </TouchableOpacity>
          </View>
          {this.state.isPaidy==true?
            <View style={styles.paidyInfoArea}>
              <TouchableOpacity style={styles.paidyInfoButton} onPress={()=>{
                this.navigation.navigate('PaidyInfo');
              }}>
                <Image style={styles.paidyInfoButtonImg} source={require('../assets/images/paidy/paidy_infoButton.png')} resizeMode="contain"/>
              </TouchableOpacity>
            </View>
          :null}          
          <View style={styles.blogRatePlanInside}> 
            <FlatList
              scrollEnabled={false}
              contentContainerStyle={{ flexGrow: 1 }}
              data={listData}
              extraData={listData}
              renderItem={this.renderPackageItem}
              ListEmptyComponent={this.noItemDisplay}
              keyExtractor={(item,index) => item?.point+index.toString()}
              inverted={true}
              getItemLayout={this.getItemLayout}
            />
          </View>
          </>
          )}

          <View style={[styles.blogTitle2, styles.bgGray]}>
            <Text style={styles.txtTitle}>ポイントを使って出来ること</Text>
          </View>

          <View style={styles.blogInner}>
            <View style={styles.blogInside}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その1</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>1ポイント＝1いいね！{"\n"}に交換できる</Text>
              </View>              
              <View style={styles.blogImg}>
                <Image style={styles.img01} source={require('../assets/images/point/point-img01.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>手持ちのいいね数が足りない時は{"\n"} <Text style={styles.txtGreen}>1ポイント＝1いいねに交換</Text>できます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>お相手とマッチングすればやりとりが可能になるので{"\n"}いいねを送って積極的にアタックをしましょう</Text>
              </View>
              {/*<ImageBackground source={require('../assets/images/point/bg-orange-1.png')} resizeMode="stretch" style={styles.imgBGOrange1}>
                <View style={{paddingTop:6}}>
                  <Text style={styles.txtImgBGOrange}>1ポイント＝1いいね！{"\n"}に交換できる</Text>
                </View>
              </ImageBackground>
              <View style={[styles.blogPadding, {marginBottom:30}]}>
                <View style={{padding:windowWidth<375? 7 : 10, width:'100%', backgroundColor:'#f2f2f2'}}>
                  <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23}}>お相手とマッチングすればやりとりが可能になるので{"\n"}いいねを送って積極的にアタックをしましょう</Text>
                </View>
              </View>*/}              
            </View>
            <View style={styles.blogInside}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その2</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>マッチングしていないお相手に{"\n"}ダイレクトメッセージを送る</Text>
              </View>              
              <View style={styles.blogImg}>
                <Image style={styles.img02} source={require('../assets/images/point/point-img02.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>1いいね＋3ポイントを消費すれば{"\n"}マッチングしていないお相手に{"\n"}<Text style={styles.txtGreen}>ダイレクトメッセージ</Text>を送れます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>お相手に直接メッセージを送る事ができるので{"\n"}いいねだけよりも効果的にアプローチが可能になります</Text>
              </View>
              {/*<ImageBackground source={require('../assets/images/point/bg-orange-1.png')} resizeMode="stretch" style={styles.imgBGOrange1}>
                <View style={{paddingTop:6}}>
                  <Text style={styles.txtImgBGOrange}>マッチングしていないお相手に{"\n"}ダイレクトメッセージを送る</Text>
                </View>
              </ImageBackground>
              <View style={[styles.blogPadding, {marginBottom:30}]}>
                <View style={{padding:windowWidth<375? 7 : 10, width:'100%', backgroundColor:'#f2f2f2'}}>
                  <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23}}>お相手に直接メッセージを送る事ができるので{"\n"}いいねだけよりも効果的にアプローチが可能になります</Text>
                </View>
              </View>
              */}
            </View>
            <View style={[styles.blogInside, {marginBottom:40,}]}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その3</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>セーフティチェックで{"\n"}お相手の利用状況が確認できる</Text>
              </View> 
              <View style={styles.blogImg}>
                <Image style={styles.img03} source={require('../assets/images/point/point-img03.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>お相手と<Text style={styles.txtGreen}>安心してやり取りする</Text>ための{"\n"}気になる情報を確認することができます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>直近1ヶ月間のお相手のご利用状況が分かるので{"\n"}安心してやりとりをしたい方にオススメです</Text>
              </View>
              {/*<ImageBackground source={require('../assets/images/point/bg-orange-1.png')} resizeMode="stretch" style={styles.imgBGOrange1}>
                <View style={{paddingTop:6}}>
                  <Text style={styles.txtImgBGOrange}>セーフティチェックで{"\n"}お相手の利用状況が確認できる</Text>
                </View>
              </ImageBackground>
              <View style={[styles.blogPadding, {marginBottom:60}]}>
                <View style={{padding:windowWidth<375? 7 : 10, width:'100%', backgroundColor:'#f2f2f2'}}>
                  <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23}}>直近1ヶ月間のお相手のご利用状況が分かるので{"\n"}安心してやりとりをしたい方にオススメです</Text>
                </View>
              </View>
              */}
            </View>
            <View style={[styles.blogTitle, {marginTop: 0, marginBottom: 20,}]}>
              <Text style={styles.txtTitle}>その他のポイント入手方法</Text>
            </View>
            <View style={[styles.blogInside, {marginBottom:30}]}>
              <View style={[styles.blogImg, {paddingTop: 20}]}>
                <Image style={styles.img04} source={require('../assets/images/point/point-img04.png')} resizeMode="contain"/>
              </View>
              <View style={[styles.blogPadding2, {paddingTop: 10, paddingBottom: 20,}]}>
                <Text style={[styles.txt18, {textAlign:'center'}]}>有料会員の方には{"\n"}<Text style={styles.txtOrange}>毎月50ポイントプレゼント</Text>致します</Text>
              </View>
            </View>            
            <View style={{marginBottom:45}}>
              <Text style={styles.headNotePlan}>ポイントでできること</Text>
              <View style={styles.notePlan}>
                <Text style={styles.txtNotePlan}>※価格はすべて税込みです{"\n"}
                ※保有できるポイントは最大10,000ポイントです{"\n"}
                ※ご購入いただいたポイントは返金できません{"\n"}
                ※ご購入いただいたポイントの有効期限は購入日を含め180日です</Text>
              </View>
              <TouchableOpacity onPress={()=>{
                this.navigation.navigate('Terms')
              }}>
                <Text style={styles.aLink}>利用規約</Text>
              </TouchableOpacity>
               <TouchableOpacity onPress={()=>{
                this.navigation.navigate('Policy')
              }}>
                <Text style={styles.aLink}>プライバシーポリシー</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
    </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  blogInner:{
    flex:1,
    padding:windowWidth<375? 15 : 22,
    backgroundColor:'#f2f2f2',
  },
  imgBGOrange1: {
    marginBottom:15,
    width:windowWidth<375? 320 : 375,
    height:windowWidth<375? 70 : 82,
    textAlign:'center',
  },
  imgBGOrange2: {
    width:windowWidth<375? 273 : 320,
    height:windowWidth<375? 68 : 80,
    textAlign:'center',
  },
  blogTitle: {
    marginTop: 35,
    width:'100%',
  },
  blogTitle2: {
    marginTop: 0,
    width:'100%',
    backgroundColor:'#f0f5f6',
  },
  bgBlue: {
    backgroundColor:'#f6fcff',
  },
  bgGray: {
    paddingTop: 50,
    paddingBottom: 35,
    backgroundColor:'#f0f5f6',
  },
  bgOrange: {
    backgroundColor:'#fcf5f2',
  },
  txtTitle: {
    fontSize:windowWidth<375? 19 : 22,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
    textAlign:'center',
  },
  blogInside: {
    position:'relative',
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    marginBottom:55,
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:8,
    shadowColor: "#afc9b1",
    shadowOffset: {width: 0,height: 0,},
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 5,
  },
  txtImgBGOrange: {
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 30,
    color:'#fff',
  },
  txtImgBGOrange2: {
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 30,
    color:'#333333',
    textAlign:'center',
  },
  blogImg:{
    marginBottom:10,
    width:'100%',
  },
  paddingT10:{
    paddingTop:10,
  },
  paddingT15:{
    paddingTop:15,
  },
  paddingB25:{
    paddingBottom:25,
  },
  img01:{
    width:'100%',
    height:windowWidth<375? 115 : 140,
  },
  img02:{
    width:'100%',
    height:windowWidth<375? 53 : 62,
  },
  img03:{
    width:'100%',
    height:windowWidth<375? 89 : 104,
  },
  img04:{
    width:'100%',
    height:windowWidth<375? 62 : 73,
  },
  txt18:{
    fontSize:windowWidth<375? 15 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 27 : 31,
    color:'#484848',
  },
  txt16:{
    fontSize:windowWidth<375? 13 : 16,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 25 : 29,
    color:'#484848',
  },
  txtBlue:{
    color:'#61bdd8',
  },
  txtGreen:{
    color:'#5cc8ca',
  },
  txtOrange:{
    color:'#fe895b',
  },
  blogPadding: {
    paddingLeft:windowWidth<375? 20 : 26,
    paddingRight:windowWidth<375? 20 : 26,
    width:'100%',
    textAlign:'center',
  },
  blogPadding2: {
    width:'100%',
    textAlign:'center',
  },
  blogPointPlan: {
    marginTop:30,
    marginBottom:12,
  },
  txtBlogPointPlan: {
    fontSize:17,
    fontWeight:'bold',
    textAlign:'center',
    lineHeight:22,
    letterSpacing:-.4
  },
  blogRatePlanInside:{
    backgroundColor:'#f0f5f6',
    paddingTop:10,
  },
  itemBlogRatePlan:{
    marginLeft:0,
    marginRight:0,
    width:'100%',
    height:windowWidth<375? 119:153,
    backgroundColor:'#f0f5f6',
  },
  bgTopImg: {
    marginBottom:18,
    width:'100%',
    height:windowWidth<375? 91:107,
  },
  headNotePlan: {
    fontSize:11,
    fontWeight:'bold',
    lineHeight:20,
    color: '#333'
  },
  notePlan: {
    marginBottom:10,
  },
  txtNotePlan: {
    fontSize:10,
    fontWeight:'normal',
    lineHeight:16,
    color: '#333'
  },
  aLink: {
    marginTop:10,
    fontSize:11,
    fontWeight:'bold',
    lineHeight:18,
    color: '#333'
  },
  bgPastNum:{
    position:'absolute',
    top: -13,
    left: 'auto',
    right: 'auto',
    width: 110,
    height:windowWidth<375? 26 : 27,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
  },
  txtBGPastNum:{
    fontSize:windowWidth<375? 12 : 13,
    fontWeight: '700',
    lineHeight:windowWidth<375? 26 : 27,
    color: '#fff',
    textAlign: 'center',
  },
  paymentModeTab:{
    flexDirection: 'row',
    backgroundColor:'#f0f5f6',
    paddingTop:0,
    paddingBottom:0,
    alignItems:'flex-end',
  },
  paymentModeButton:{
    width:'50%',
    backgroundColor:'#fff',
    paddingTop:10,
    textAlign:'center',
    alignContent:'flex-end', 
  },
  paymentModeButtonImg:{
    width:'100%',
    alignSelf:'flex-end', 
  },
  paidyInfoArea:{
    width:'100%',
    backgroundColor:'#f0f5f6',
  },
  paidyInfoButton:{
    width:'45%',
    justifyContent:'flex-end', 
    alignContent:'flex-end', 
    alignSelf:'flex-end',
    justifyContent:'center',
    backgroundColor:'#f0f5f6',
  },
  paidyInfoButtonImg:{
    width:'100%',
    height:40,
  },
});