import React,{ Component, Fragment } from 'react';
import { 
  StyleSheet, Text, View, Platform, StatusBar, TouchableOpacity, Dimensions, ScrollView, Image 
} from 'react-native';
import HeadStyle from '../../styles/HeadStyles';
import { Formik } from 'formik';
import CommonFunction from '../../utils/CommonFunction';
import UserSettingComponent  from '../../components/common/UserSetting';
import RightSlidePopup from '../../components/common/RightSlidePopup';
import OptionData  from '../../constants/option/OptionData.json';
import formStyles from '../../styles/FormStyles';
import Constants from '../../constants/Constants';
import Leave19Api from '../../services/api/Leave19Api';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { AppContext } from '../../AppContext';
import HeaderOptions from '../../constants/HeaderOptions';
import UserUtil from '../../utils/UserUtil';

const {checkUndefined} = CommonFunction;
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;
const screenWidth = Dimensions.get('window').width;

export default class WithdrawScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      point: 0, 
      goodPoint: 0,
      data: {},
    };
    this.initialValues = {
      category: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      this._unsubscribe = this.navigation.addListener('focus', async() => {
        this.myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
        // set bottom tab
        CommonFunction.hideBottomTab(this.navigation);
        this.getDatatFromApi();
      });
    }
  }

  setHeader = () =>{
    this.title = '退会手続き';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title, this.navigation);
      return;
    }
    const fromContact = this.props.route?.params?.contact;
    CommonFunction.setHeaderSetting(this.navigation, this.title, true, fromContact);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe !=undefined){
      this._unsubscribe();
    }
  }

  async getDatatFromApi(){
    if(this._isMounted){
      try{
        //this.setState({ loading: true });
        const leaveCheckResponse = await Leave19Api.LeaveGetLeaveCheck();
        if(!CommonFunction.checkResponseStatus(leaveCheckResponse,false)) return;
        
        if(checkUndefined(leaveCheckResponse) && leaveCheckResponse?.status==1){
          const point = leaveCheckResponse?.point;
          const goodPoint = leaveCheckResponse?.good;
          const data = leaveCheckResponse?.response;
          this.setState({
            point: point,
            goodPoint: goodPoint,
            data: data
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({ loading: false });
      }
    }
  };

  handleOnPress = async(values) =>{
    try{
      CommonFunction.overLay();
      /*
      if(this.myProfile?.sex==Constants.MALE && this.state.data?.checkResult!=1){
        this.popup?.showPopup();
        return false;
      }
      */
      this.navigation?.navigate('WithdrawInput', {reasonCategory: values['category']?.id});
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    if(this.state.loading) return (<ActivityIndicator />);

    return (
      <View style={[styles.container, HeadStyle.customHeaderTop]}>
        <ScrollView style={styles.scrollContainer}>
         <View style={[styles.desc]}>
          <Text style={styles.font12}>いつもPappyをご利用いただき、誠にありがとうございます。</Text>
          <Text style={styles.font12}>下記の注意事項をご確認後、退会手続きをお進みください。</Text>
         </View>
         <Formik
            initialValues={this.initialValues}
            onSubmit={values => this.handleOnPress(values) }
          >
            {({ values, handleChange, errors, setFieldTouched, touched, isValid, dirty, handleSubmit, setFieldValue }) => (
            <Fragment>
              {this.myProfile?.sex==Constants.FEMALE ?
              <View>
                <View style={{paddingTop:24}}>
                  <Text style={[styles.font13, styles.title]}>全てのデータが削除されます</Text>                
                  <View style={{paddingLeft:18,paddingRight:18}}>
                    <Text style={styles.font13}>これまでにお相手とやりとりしたメッセージ、</Text>
                    <Text style={styles.font13}>いいねなどは全て削除されます。</Text>
                  </View>                
                </View>          
                <View style={{paddingTop:30}}>
                  <Text style={[styles.font13, styles.title]}>再登録しても、過去のデータは引き継ぐことはできません。</Text>
                  <View style={{paddingLeft:10,paddingRight:10}}>
                    <Text style={styles.font13}>再登録された場合、過去のプロフィール、</Text>
                    <Text style={styles.font13}>いいねを引き継ぐことはできません。</Text>
                  </View>
                </View>
                <View style={{paddingTop:30}}>
                  <Text style={[styles.font13, styles.title]}>一度退会してしまうと、30日間は再登録ができません。</Text>
                  <View style={{paddingLeft:10,paddingRight:10}}>
                    <Text style={styles.font13}>不正利用を防止するため、退会後30日間は再登録することができません。</Text>
                  </View>
                </View>   
              </View>
              :
              <View>
                <View style={{paddingTop:20}}>
                  <Text style={[styles.font13, styles.title]}>全てのデータが削除されます</Text>                
                  <View style={{paddingLeft:10,paddingRight:10}}>
                    <Text style={styles.font13}>これまでにお相手とやりとりしたメッセージ、いいね、</Text>
                    <Text style={styles.font13}>ポイントなどは全て削除されます。</Text>
                  </View>                
                </View>          
                <View style={{paddingTop:30}}>
                  <Text style={[styles.font13, styles.title]}>再登録しても、過去のデータは引き継ぐことはできません。</Text>
                  <View style={{paddingLeft:10,paddingRight:10}}>
                    <Text style={styles.font13}>再登録された場合、過去のプロフィール、いいね、</Text>
                    <Text style={styles.font13}>ポイントを引き継ぐことはできません。</Text>
                  </View>
                </View>
                <View style={{paddingTop:30}}>
                  <Text style={[styles.font13, styles.title]}>一度退会してしまうと、30日間は再登録ができません。</Text>
                  <View style={{paddingLeft:10,paddingRight:10}}>
                    <Text style={styles.font13}>不正利用を防止するため、退会後30日間は再登録することができません。</Text>
                  </View>
                </View>   
                <View style={{paddingTop:30}}>
                    <Text style={[styles.font13, styles.title]}>有料プランの解約</Text>
                  <View style={{paddingLeft:10,paddingRight:10}}>
                    <Text style={styles.font13}>App Store/Google Playストアのアプリ内決済で購入された有料プランの解約には別途お手続きが必要です。</Text>
                    <Text style={styles.font13}>クレジットカード決済・あと払い（ペイディ）で購入された有料会員プランは、退会と同時に解約されます。</Text>
                  </View>
                </View> 
                <View style={styles.pointBox}>
                  <View style={styles.pointLabel}><Text style={styles.font13}>ポイント残高</Text></View>        
                  <View style={styles.pointText}>
                    <View style={{marginRight:5, width:18, height:18, backgroundColor:'#f8d549',borderRadius:999,textAlign:'center'}}><Text style={{fontSize:14, fontWeight:'bold', color:'#ffffff', lineHeight:18,textAlign:'center'}}>P</Text></View>
                    <Text style={styles.number}>{this.state?.point}</Text>
                    <Text style={styles.smallText} numberOfLines={1}> ポイント</Text>
                  </View>
                </View>

                <View style={[styles.pointBox, {marginTop:7}]}>
                <View style={styles.pointLabel}><Text style={styles.font13}>残りいいね! 数</Text></View>         
                  <View style={styles.pointText}>
                    <View style={{marginRight:5, width:18, height:18, backgroundColor:'#5cc8ca',borderRadius:999,justifyContent:'center', alignItems:'center'}}><Image resizeMode={'contain'} style={{width:12, height:11}} source={require('../../assets/images/icon/ic-iine.png')} /></View>
                    <Text style={styles.number}>{this.state?.goodPoint}</Text>
                    <Text style={styles.smallText} numberOfLines={1}> いいね!</Text>
                  </View>
                </View>
             
              </View>
              }
              <View style={{marginTop:50, marginBottom:10}}>
                <Text style={{fontSize:13, fontWeight:'bold', lineHeight:20, color:'#484848',}}>退会理由を選択してください</Text>
              </View>
              <View style={{marginLeft:4, marginRight:4,}}>
                <RightSlidePopup fieldName={'category'} title={'退会理由'} items={OptionData.Master.Witdrawal.Items} onChange={setFieldValue} values={values.category} single={true} showChild={true}>
                  <UserSettingComponent.DisplayLineTextBg values={values.categoryTxt} placeholder="カテゴリを選択"/>
                </RightSlidePopup>
              </View>
              
              <View style={[formStyles.buttonPanelSetting, {marginTop:50,}]}>
                <TouchableOpacity disabled={!(dirty)} onPress={handleSubmit} style={[formStyles.submitBtn, {width:'85%', opacity:(dirty) ?1:0.4, borderRadius:0, shadowOffset: {width: 0, height: 0, }, shadowOpacity: 0, shadowRadius: 0, elevation: 0, marginBottom:50}]} >
                  <Text style={styles.txtBtnBlue}>退会手続きを続ける</Text>
                </TouchableOpacity>
              </View>
          </Fragment>
          )} 
          </Formik>
        </ScrollView>

        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {
              this.popup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'87%', backgroundColor:'#fff', borderRadius: 12}}>
            <Text style={{fontSize:18, fontWeight:'bold', color:'#000', lineHeight:32}}>お客様は現在</Text>
            <Text style={{fontSize:18, fontWeight:'bold', color:'#000', lineHeight:32}}>有料会員を契約中です</Text>
            <View style={{marginTop:15, justifyContent:'center', alignItems:'center', alignSelf:'center', textAlign:'center'}}>
              <Text style={{fontSize:16, fontWeight:'normal', color:'#353535', lineHeight:22}}>退会の前に有料会員の</Text>
              <Text style={{fontSize:16, fontWeight:'normal', color:'#353535', lineHeight:22}}>解約手続きをお済ませください</Text>
            </View>
            <View style={styles.popupBox}>
              <View style={styles.popupBoxLeft}>
                <Text style={{fontSize:16, fontWeight:'bold', color:'#3abadf', lineHeight:24}}>有料会員</Text>
              </View>
              <View style={styles.popupBoxRight}>
                <View style={{flexDirection:'row', justifyContent:'center'}}>
                  <Text style={{fontSize:16, fontWeight:'bold', color:'#353535', lineHeight:30}}>
                    {this.state.data?.subscription?.planInfo}
                    </Text>
                </View>
                <Text style={{fontSize:12, fontWeight:'normal', color:'#353535', lineHeight:24, textAlign:'center'}}>次回更新日　{this.state.data?.subscription?.expire}</Text>
              </View>
            </View>
            <Text style={{fontSize:13, fontWeight:'bold', color:'#fe0000', lineHeight:24}}>有料会員を解約されますと期間内でも即時解約</Text>
            <Text style={{fontSize:13, fontWeight:'bold', color:'#fe0000', lineHeight:24}}>となりますのでご注意ください</Text>
            <View style={{width:'100%',paddingBottom:10,}}>
              <TouchableOpacity onPress={()=>{
                this.popup?.hidePopup();
              }} style={[formStyles.submitBtn, {marginTop:25, width:'100%'}]}>
                <Text style={{color:'#ffffff',fontWeight:'bold'}}>退会をやめる</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={()=>{
                this.popup?.hidePopup();
                this.navigation.navigate('CancelSubscription');
              }} style={[formStyles.submitBtn, {backgroundColor:'#b1bfc2', shadowColor: "#798789", marginTop:20, width:'100%'}]}>
                <Text style={{color:'#ffffff',fontWeight:'bold'}}>有料会員解約手続きへ</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Popup>
          
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  scrollContainer: {
    backgroundColor:'#ffffff',
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:12,
    paddingRight:12,
    ...Platform.select({
      ios: {
        flexGrow:1,
      },
      android: {
        flexGrow:1,
      },
      web:{
        height: scrollHeight,
    }}),
  },
  desc: {
    width:'100%',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
    paddingBottom:15,
  },
  title:{
    color: '#ff4a52'
  },  
  font13: {
    fontSize:13,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848'
  },
  font12: {
    fontSize:12,
    lineHeight:20,
    color:'#444444',
  },
  pointBox: {
    marginTop:34,
    marginLeft:4,
    marginRight:4,
    borderColor:'#f2f2f2',
    borderWidth: 4,
    borderRadius: 8,
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  },
  pointLabel:{
    backgroundColor:'#f2f2f2',
    width: '48%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    ...Platform.select({
      ios:{
        height: 32,
      },
      android:{
        height: 32,
      },
      default:{
        padding: 7,
      }
    })
  },
  pointText:{
    width: '52%',
    flexDirection:'row', 
    justifyContent:'flex-start',
    alignItems:'center', 
    alignContent:'center', 
    alignSelf:'center',
    ...Platform.select({
      ios:{
        height: 32,
        paddingLeft:screenWidth<=375?15:35,
      },
      android:{
        height: 32,
        paddingLeft:screenWidth<=375?15:35,
      },
      default:{
        padding: 7,
        paddingLeft:screenWidth<=375?15:35,
        textAlign:'center',
      }
    })
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  popupBox:{
    marginTop: 20,
    marginBottom: 15,
    width:'100%',
    borderColor: '#e2e2e2',
    borderWidth: 2,
    borderRadius: 4,
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  },
  popupBoxLeft:{
    width: '40%',
    height:100,
    textAlign:'center',
    backgroundColor: '#cef1f5',
    borderRightWidth: 2,
    borderRightColor: '#e2e2e2',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  },
  popupBoxRight:{
    width: '60%',
    textAlign:'center',
  },
  number:{
    fontSize:22, 
    fontWeight:'bold', 
    color:'#434343', 
    ...Platform.select({
      ios:{
        lineHeight:26
      },android:{
        lineHeight:26
      },default:{
        lineHeight:18
      }
    })
  },
  smallText:{
    marginTop:2, 
    fontSize:11, 
    fontWeight:'bold', 
    color:'#434343', 
    lineHeight:18
  },
  submitBtn:{
    marginTop:50,
    marginBottom:50
  }
});


