import React, { PureComponent } from 'react';
import { Image, Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export default class LogoNoMargin extends PureComponent {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <Image source={require('../../assets/images/logo-txt.png')} style={{width:windowWidth<375? 56:64,height:windowWidth<375? 21:24}} resizeMode="contain"/>
    );
  }
}
