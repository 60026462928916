import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Animated, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import OptionListSingleSelected from '../../screens/OptionListSingleSelected';
import { Portal } from 'react-native-paper'

export default class ListInput extends Component {  

  constructor(props){
    super(props);
    this.state = {
      visible: false,
      dailogSelected: [],
      dailogSelectedText: '',
      AnimateBottom: new Animated.Value(-300),
    }
    this.concat = ', ';
  }

  componentDidMount(){
    if(this.props.value){
      let value_txt = '';
      let value = this.props.value;
       /*
        value = value.replaceAll('"','').replaceAll('[','').replaceAll(']','');
        value = value.split(',');
             
        value.map( (s, i) =>  {
          let index = this.props.items.findIndex(
            item => s === item.id
          );
          if(index >=0){
            value_txt += this.props.items[index].value + this.concat;
          }
        });

        if(value_txt.slice(-2) === this.concat){
          value_txt = value_txt.substr(0,value_txt.length-2);
        }
      
      this.setState({
        dailogSelected : value,
        dailogSelectedText: value_txt
      });
      */
      
    }
  }

  setAnimation(direction) {
    this.setState({visible:true});
    Animated.timing(this.state.AnimateBottom, {
      duration: 250,
      toValue: direction=='down' ? -300 : 0,
	  useNativeDriver: false
    }).start(({ finished }) => {
      if(finished){
        if(direction=='down'){
          this.setState({visible: false});
        }
      }
    });
  };

  setDataSource = (listData)=>{   
    let txt = '';
    let sel_list = [];

    listData.map( (s, i) =>  {
      if(s.isSelect==true){
        if(this.props.multiple){
          sel_list.push(s.id);
          txt += s.value+this.concat;
        }else{
          sel_list=[s.id];
          txt = s.value;
        }
        
        sel_list=[s.id];
        txt = s.value;
      }
    });
    if(txt.slice(-2) === this.concat){
      //txt = txt.substr(0,txt.length-2);
      txt = txt.substring(0,txt.length-2);
    }
    this.setState({
      dailogSelected: sel_list,
      dailogSelectedText: txt
    });
  }

  showDialog(){
    this.setAnimation('up');
  }

  hideDialog(){
    this.setAnimation('down');
  }    

  OK(){
    if(this.state.dailogSelected.length > 0){
      let val = this.state.dailogSelected;
      if(val.length > 1){
        val = JSON.stringify(val);
      }else{
        val = val[0];
      }
      this.props.onChange(val);
    }
    this.setAnimation('down');
  }

  Cancel(){
    this.setState({
      visible: false,
      dailogSelected: [],
      dailogSelectedText: ''
    });
    let val = "";
    this.props.onChange(val);
    this.setAnimation('down');
  }

  _renderPopup(){
    if(this.state.visible){
      return (
        <Portal style={styles.portal}>
          <View style={styles.container}>
            <View style={styles.overlay}
            onStartShouldSetResponder={() => {
              this.setAnimation('down');
            }} />
            <Animated.View style={[styles.animateView,{bottom: this.state.AnimateBottom}]}> 
            <View style={styles.animateViewContainer}>
              <View style={{flexDirection:'row',justifyContent:'space-between',borderBottomWidth:2,borderBottomColor:'#e6e6e6'}}>
                <TouchableOpacity style={{alignSelf:'flex-start',marginLeft:20,}} onPress={()=>{this.Cancel()}}>
                  <Text style={styles.cancel}>キャンセル</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{alignSelf:'flex-end',marginRight:20,}} onPress={()=>{this.OK()}}>
                  <Text style={styles.ok}>完了</Text>
                </TouchableOpacity>
              </View>

              {/*
              {this.state.loading?
              <ActivityIndicator/>
              :*
              <OptionListSingleSelected item={this.props.items} setDataSource={this.setDataSource} multiple={this.props.multiple} dailogSelected={this.state.dailogSelected}/>
              }*/}

              <OptionListSingleSelected 
                item={this.props.items} 
                setDataSource={this.setDataSource} 
                multiple={this.props.multiple} 
                dailogSelected={this.state.dailogSelected}
              />
              
            </View>
          </Animated.View>
        </View>
      </Portal>
      )
    }
  }
    
  render() {
    let selected_value = '選択してください';
    if(this.props.title != undefined){
      selected_value = this.props.title;
    }
    if(this.state.dailogSelectedText !=''){
      selected_value = this.state.dailogSelectedText;
    }

    return (
      <View style={styles.inputView}>
        <TouchableOpacity 
          style={styles.inputText}
          onPress={() => this.showDialog()}
        >
          <Text>{selected_value}</Text>
          <Icon name={'caretdown'} size={12} style={styles.rightIcon} />
        </TouchableOpacity>
 
        {this._renderPopup()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    position:'absolute',
    top:0,
    left:0,
    height:'100%',
    width:'100%'
  },
  overlay:{
    height: '100%',
    width:'100%',
    backgroundColor:'#000000',
    opacity:0.5,
    position:'absolute',
    top:0,
    left:0
  },
  animateView:{
    flex:1,
    width:'100%',
    ...Platform.select({
      ios: {
        position:'absolute',
      },
      android: {
        position:'absolute',
      },
      default:{
        position:'fixed'
    }}),
    zIndex:1,
    justifyContent:'flex-start',
    alignItems:'flex-start',
    height:'50%',
    backgroundColor:'#ffffff'
  },
  animateViewContainer:{
    width:'100%',
    height:'100%'
  },
  inputView:{
    justifyContent:'center',
    width:'100%',
    height:50,
    backgroundColor:'#eef1f2',
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
  },
  inputText:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    lineHeight:20,
    color:"#000",
  },
  rightIcon:{
    position:'absolute',
    top:'50%',
    right:0,
    padding:10,
    color:'#000',
    ...Platform.select({
      ios: {
        
      },
      android: {
        
      },
      web:{
        transform:[{translateY:'-50%'}]
    }})
  },
  portal:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  ok:{
    //fontFamily:'NotoSansJP',
    fontSize:16,
    fontWeight:'bold',
    lineHeight:52,
    color:'#007bff',
  },
  cancel:{
    //fontFamily:'NotoSansJP',
    fontSize:16,
    fontWeight:'normal',
    lineHeight:52,
    color:'#007bff',
  },
});