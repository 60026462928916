import React,{ Component } from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, Dimensions } from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import ApiConfig from '../../constants/ApiConfig';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class SettlementScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = '資金決済法に基づく表示';
    if(windowWidth<=375) this.title = '　　'+this.title;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }  

  setHeader = () =>{
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title, this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
          <View style={{}}>
            <View>
              <Text style={styles.titleInfo}>◆サービス提供事業者</Text>
              <Text style={styles.txtInfo}>プロスゲイト株式会社</Text>
            </View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆支払い可能金額</Text></View>
            <View><Text style={styles.txtInfo}>10,000ポイントを超えて購入することはできません</Text></View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆有効期限</Text></View>
            <View><Text style={styles.txtInfo}>購入したポイントの有効期限は購入日を含めて180日です。当社は最終のアクセス
              から1年以上経過しているアカウントを予めお客様に通知することなく削除すること
              ができます。この場合、未使用のポイントは購入・付与分に関わらず失効します。
            </Text></View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆ご利用頂けるサービス</Text></View>
            <View><Text style={styles.txtInfo}>Pappy内でのサービスにお使い頂けます。</Text></View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆ご利用上の注意事項</Text></View>
            <View><Text style={styles.txtInfo}>・原則としてポイントの払い戻しはいたしません。{"\n"}
              ・サービスを退会されますと、ポイントも失効となりますのでご注意ください
            </Text></View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆未使用残高の確認方法</Text></View>
            <View><Text style={styles.txtInfo}>「マイページ」よりご確認頂けます。</Text></View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆所在地</Text></View>
            <View><Text style={styles.txtInfo}>神戸市中央区雲井通3-2-3</Text></View>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆お問合せ先 </Text></View>
            <TouchableOpacity onPress={async()=>{
              const token = await CommonFunction.getEncryptAccessToken();
              if(Platform.OS==='web'){
                if(token){
                  window.location.href = ApiConfig.contact.url+'?token='+token;
                }else{
                  window.location.href = ApiConfig.contact.url;
                }
              }else{
                if(token){
                  this.navigation.navigate('Inquiry');
                }else{
                  this.navigation.navigate('InquiryNonMember');
                }
              }
            }}>
              <Text style={[styles.txtInfo,{textDecorationLine:'underline'}]}>{ApiConfig.contact.url}</Text>
            </TouchableOpacity>
          </View>
          <View style={{}}>
            <View><Text style={styles.titleInfo}>◆利用規約</Text></View>
            <View><TouchableOpacity onPress={()=>{this.props.navigation?.navigate('Terms')}}><Text style={[styles.txtInfo,{textDecorationLine:'underline'}]}>https://www.pappy.jp/Terms</Text></TouchableOpacity></View>
          </View>

        </View>
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:15,
    paddingBottom:20,
  },
  titleInfo: {
    paddingBottom:8,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
    textAlign:'left',
  },
  txtInfo: {
    paddingBottom:16,
    fontSize:14,
    lineHeight:16,
    color:'#484848',
    textAlign:'left',
  }
});
