import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';

export default class Notification18Api extends ApiPappy{

  //1.1.1. お知らせ一覧取得
  static async GetNotificationList(values){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Profile/Get/Notification/",
      { 
        count: (values?.count!=undefined)?values.count:30,
        page: (values?.page!=undefined)?values.page:1,
        type: (values?.type!=undefined)?values.type:1,
      },
      true
      );
      if(__DEV__) console.log('/Profile/Get/Notification/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
          // check response field
          const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.NotificationGetNotification));
          console.log('/Profile/Get/Notification/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.お知らせ詳細取得
  static async GetNotificationDetail(values){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Profile/Get/NotificationDetail/",
      { 
        noticeId: values.noticeId,
        type: (values?.type!=undefined)?values.type:0,
      },
      true
      );
      if(__DEV__) console.log('/Profile/Get/NotificationDetail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.NotificationGetNotificationDetail));
            console.log('/Profile/Get/NotificationDetail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1. お知らせ削除
  static async DeleteNotification(noticeId){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Profile/Delete/Notification/",
      { 
        noticeId: noticeId 
      },
      true
      );
      if(__DEV__) console.log('/Profile/Delete/Notification/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
          // check response field
          const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
          console.log('/Profile/Delete/Notification/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}