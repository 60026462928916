import { Platform } from 'react-native';
import { MODE } from "@env"
import CommonFunction from "../utils/CommonFunction";

// production value
const AddjustProduction = {
  appToken: "sk37rt6d3rpc",
  appToken_ios: "rcfvtpz2oi68",
  appToken_android: "1ah0lh2226tc",
  environment: "production",
  environment_ios: "production",
  environment_android: "production"
};

// stage value
const AddjustStage = {
  appToken: "282a7yn4axog",
  appToken_ios: "rcfvtpz2oi68",
  appToken_android: "1ah0lh2226tc",
  environment: "sandbox",
  environment_ios: "sandbox",
  environment_android: "sandbox"
};

let Addjust = AddjustStage;
if(Platform.OS==='web'){
  if(!CommonFunction.checkWebStage()){
    Addjust = AddjustProduction;
  }
} else {
  const appEnv = process.env.APP_ENV || "development";  
  if (appEnv === "production") {
    Addjust = AddjustProduction;
  }

  if(MODE==='production'){
    Addjust = AddjustProduction;
  }
}

export default {
  Addjust: Addjust
};