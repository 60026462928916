import React from 'react';
import { StyleSheet, Text, View, SectionList, TouchableOpacity, PixelRatio, Dimensions, Image, Platform } from 'react-native';
import FootStepUserRowFormat from '../components/data/FootStepUserRowFormat';
import ActivityIndicator from '../components/common/ActivityIndicator';
import CommonFunction from '../utils/CommonFunction';
import HeadStyle from '../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';
import LoadMore from '../components/screens/LoadMore';
import FootmarkApi from '../services/api/Footmark06Api';
import UserUtil from '../utils/UserUtil';
import { RefreshControl } from 'react-native-web-refresh-control';
import { AppContext } from '../AppContext';
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout'
import ListStyles from '../styles/ListStyles';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { navigate } from '../navigation/RootNavigation';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class Footmark extends LoadMore {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      maskUp: false,
      maskUpStateData: false
    }
    this.fromPushFlag = 0;
  }

  componentDidMount(){
    super.componentDidMount();
    if(this._isMounted){

      this.setHeader();
      
      // from back page
      this.subscribeFocus = this.props.navigation?.addListener('focus', async() => {
        CommonFunction.checkAccessTime();
        CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "none"); // hide main bottom tab
        await this.checkRemoveSearchList();
      });

      if(Platform.OS!=='web' || (Platform.OS==='web' && CommonFunction.isAndroidUA)){
        if(this.props?.route?.params?.params?.fromPushFlag != undefined) {
          this.fromPushFlag = this.props?.route?.params?.params?.fromPushFlag;
        }
      }
    }
  }
  
  componentWillUnmount(){
    super.componentWillUnmount();
    CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState} = this.appContext;
    if(appState.setChatHideMemberId!=undefined){
      this.setState({maskUpStateData: true},()=>{
        let filterData = this.state.data;
        filterData = filterData?.filter(function (item) {
          return item.memberId != appState.setChatHideMemberId;
        });
        appState.setChatHideMemberId = null;
        appState.searchResult = filterData;
        setAppState(appState);
        this.setState({
          data: filterData, 
          maskUpStateData: false
        });
      });
    }
  }

  setSearchResult = ()=>{
    const uniqueId = [];
    const uniqueList = this.state.data?.filter(element => {
      const isDuplicate = uniqueId?.includes(element.memberId);
      if (!isDuplicate) {
        uniqueId.push(element.memberId);
        return true;
      }
      return false;
    });
    UserUtil.setsearchResult(this.appContext, uniqueList);
  }

  async getListFromApi(page){
    if(this._isMounted){
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }
      
      try{
        const params = {page: page};
        const getListResponse = await FootmarkApi.getList(params);
        //check login
        if(!CommonFunction.checkResponseStatus(getListResponse)) return;

        if(getListResponse?.status==1){
          const footmark = getListResponse?.response?.footmark;
          const account = UserUtil.getAccountAppState(getListResponse, this.appContext);
          let { data } = this.state;
          let longRefreshControl = true;

          if(footmark!=undefined){
            if(footmark.list?.length > 0){
              let list = footmark?.list;
              CommonFunction.thumbnailUrlConver(list);
              if(page > 1){
                data = data.concat(list);
              }else{
                data = list;
              }
            }
            this.no_more = (footmark?.nextVal == 0)?1:0
          }
          if(data?.length>0) longRefreshControl = false;
          this.setState({ 
            account: account, 
            data: data,
            longRefreshControl: longRefreshControl
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false,
        });
      }
    }
  }

  setHeader(){
    this.props.navigation?.setOptions({
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={[styles.icClose,{flexDirection:'row'}]}>
            <TouchableOpacity onPress={()=>{
              CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
              this.goBackActionCheck();
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch'}}><Text style={HeadStyle.headerTitlePage}>足あと</Text></View>
        </View>
      )
    });
  }

  goBackActionCheck() {
    if(this.fromPushFlag==1) {
      this.props?.navigation.navigate('Home', {screen:'MyPageStack', params:{ screen:'MyPage'}});
    } else {
      this.props?.navigation?.goBack();
    }
  }

  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;

    return (
      <View style={{flex:1, height:'100%'}}>
        <View style={{paddingTop: 100, paddingLeft: 15, paddingRight: 15, paddingBottom: 80, textAlign:'center',}}>
          <Text style={{fontSize:14, fontWeight:'normal', lineHeight:24, color:'#9aa4a6'}}>足あとはありません。{"\n"}
          気になるお相手には積極的にいいねを送りましょう！
          </Text>
        </View>
        <View style={{paddingLeft:80, paddingRight:80,}}>
          <TouchableOpacity style={styles.btnBlue} onPress={()=>{
            this.props?.navigation?.navigate('Search',{screen:'SearchList'});
          }}>
            <Text style={styles.txtBtnBlue}>お相手を探す</Text>
          </TouchableOpacity>  
        </View>        
      </View>
    );
  }

  makeSectionData = (rowData) => {
    let sectionList = [];
    if(rowData!=undefined && rowData?.length>0){
      let index = 0;
      rowData?.map((data) => {      
        let date = data.footmarkDateTime?.label;
        const checkIndex = sectionList?.findIndex(
          item => date === item.title
        );
        if(checkIndex==-1){
          let value = {title: date, data: [data], index: index}
          sectionList.push(value);
          index++;
        }else{
          sectionList[checkIndex].data.push(data);
        }
      });
    }
    
    return sectionList;
  }

  getItemLayout = sectionListGetItemLayout({
    // The height of the row with rowData at the given sectionIndex and rowIndex
    //getItemHeight: (rowData, sectionIndex, rowIndex) => sectionIndex === 0 ? 163 : 133,
    getItemHeight: (rowData, sectionIndex, rowIndex) => sectionIndex === 0 ? 170 : 140,
    // These four properties are optional
    getSeparatorHeight: () => 1 / PixelRatio.get(), // The height of your separators
    getSectionHeaderHeight: () => 30, // The height of your section headers
    getSectionFooterHeight: () => 0, // The height of your section footers
    listHeaderHeight: 0, // The height of your list header
  });

  render() {
    if ((this.state.loading && this.page === 1) || this.state.maskUpStateData===true) return <ActivityIndicator />
    
    let listData = this.makeSectionData(this.state.data);
    let initialNum = listData?.length+this.state.data?.length;
    if(initialNum<15) initialNum = 15; // default

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container, SafeAreaStyles(insets).container]}>
        {this.state.maskUp===true && (<View style={styles.maskUp}><ActivityIndicator /></View>)}
        <SectionList
          ref={(ref)=>this.sectionRef=ref}
          style={[ListStyles.scrollContainer,{flexGrow: 1}]}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              long={this.state.longRefreshControl}
            />
          }
          sections={listData}
          extraData={this.state.data}
          ListEmptyComponent={this.noItemDisplay}
          renderSectionHeader={({section}) => {
            if(section.data?.length>0){
              return (<Text style={styles.sectionHeader}>{section.title}</Text>)
            }
          }}
          renderItem={({ item, index, section }) => (
            <TouchableOpacity onPress={()=>{
              this.setSearchResult();
              this.props?.navigation?.navigate('Profile', { memberId:item.memberId });
            }} 
            >
              <FootStepUserRowFormat
                img={item.tmMainImageUrl}
                age={item.age} 
                area={item.currentPref} 
                nickname={item.name}
                msg={item.tweet!=""?item.tweet:item.introduction}
                item={item}
              />
            </TouchableOpacity>
          )}
          numColumns={1}
          keyExtractor={(item, index) => item?.memberId+index.toString()}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={0.5}
          onEndReached={this.handleLoadMore.bind(this)}
          getItemLayout={this.getItemLayout}
          scrollIndicatorInsets={{ right: 1 }}
          initialNumToRender={initialNum}
        />
      </View>
      }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

let style = {
  sectionHeader:{
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:12,
    paddingRight:12,
    width: '100%',
    backgroundColor: '#e6e6e6',
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  btnBlue:{
    width:'100%',
    padding:8,
    height:48,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:32,
    color:'#fff',
    textAlign:'center'
  },
  maskUp: {
    position:'absolute',
    backgroundColor:'#ffffff',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    zIndex:1,
    elevation:1
  },
  icClose:{
    position:'absolute',
    left: 0,
    elevation: 1,
    zIndex: 1
  },
};

if(windowWidth>375 && windowWidth<=414){
  style.sectionHeader = {
    ...style.sectionHeader,
    paddingTop:7,
    paddingBottom:7,
    paddingLeft:14,
    paddingRight:14,
  };
}

const styles = StyleSheet.create(style);