import ChatList from "./ChatList";

export default class ChatConversation extends ChatList {
  async getListFromApi(page){
    if(this._isMounted){
      this.type = 1;

      let parentState = this.props?.parentState();
      if(parentState!=undefined && parentState.type==this.type){ // unmount tab
        if(parentState?.page!=undefined){
          this.page = parentState.page;
        }
        if(parentState?.no_more!=undefined){
          this.no_more = parentState.no_more;
        }
        this.setState(parentState);
        this.props.parentState({}); // clear parent state
      }else{ // get new data
        const params = {page:page, type: this.type}; //1: やりとり中の一覧
        await this.getChatList(params);
      }
    }
  }
}