import React,{Component} from 'react';
import { TouchableOpacity, Text } from 'react-native';
import BottomMenu from '../../components/common/BottomMenuMedia';
import BottomMenuStyles from '../../styles/BottomMenuStyles';

export default class PopupMedia extends Component{
  constructor(props){
    super(props);
    this.BottomMenuRef = React.createRef();
  }

  show = (show=true)=>{
    this.BottomMenuRef?.showPopUp(show);
  }

  render() {
    return (
      <BottomMenu ref={ref=>this.BottomMenuRef=ref}>
        <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
          this.BottomMenuRef?.showPopUp(false);
          this.props?.pickImage();
        }}>
          <Text style={[BottomMenuStyles.txtListPopup]}>写真ライブラリ</Text>
        </TouchableOpacity>
        
        <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
          this.BottomMenuRef?.showPopUp(false);
          this.props?.pickCamera();
        }}>
          <Text style={BottomMenuStyles.txtListPopup}>写真を撮る</Text>
        </TouchableOpacity>
        
        <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.btnListPopupCancel} onPress={()=>{
          this.BottomMenuRef?.showPopUp(false);
        }}>
          <Text style={[BottomMenuStyles.txtBtnListPopupCancel, BottomMenuStyles.txtColorRed]}>キャンセル</Text>
        </TouchableOpacity>
      </BottomMenu>
    );
  }
}
