import React,{Component, Fragment } from 'react';
import { Text, TextInput, View, TouchableOpacity, ScrollView, Platform } from 'react-native';
import LoginApi from '../services/api/Login02Api';
import Validation from '../constants/Validation';
import { Formik } from 'formik';
import styles from '../styles/FormStyles';
import { Entypo } from '@expo/vector-icons';
import HeaderOptions from '../constants/HeaderOptions';
import GoBackIcon from '../components/common/GoBackIcon';
import CommonFunction from '../utils/CommonFunction';
import { AppContext } from '../AppContext';
import StorageHelper from '../utils/StorageHelper2';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';
import AuthHelper from '../utils/AuthHelper';
import Msg from '../constants/option/Msg.json';
import { CheckboxInput } from '../components/form/FormInput';

const inputAccessoryViewID = "inputAccessoryView1";

export default class LoginScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.initialValues = {
      accept: false,
      mail: '', 
      pass: '' 
    }
    this.state = {
      eyeIcon: 'eye',
      secureTextEntry: true,
      initialValues: this.initialValues,
      loading: true
    };
    this.keynameMail = 'LoginInfoMail';
    this.keynamePass = 'LoginInfoPass';
  }
 
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      this.getLoginInformation();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  setHeader(){
    this.navigation.setOptions({
      headerStyle: HeaderOptions.HeaderNoBorder2,
      headerTitle: '',
      headerLeft: () => <GoBackIcon navigation={this.navigation} />
    });
  }

  validateEmail = (email)=>{
    const result = email?.split('.')?.pop();
    if(result!=undefined && result.length >=2){
      return true;
    }

    AuthHelper.showModalError(Msg.CheckLoginError,null);
    return false;
  }

  getLoginInformation = async()=>{
    try{
      let loginInfoMail =  await StorageHelper.getData(this.keynameMail);
      let loginInfoPass =  await StorageHelper.getData(this.keynamePass);
      if((loginInfoMail) && (loginInfoPass)){
        this.initialValues.mail = loginInfoMail;
        this.initialValues.pass = loginInfoPass;
        this.initialValues.accept = true;
        this.setState({
          //eyeIcon: 'eye',
          initialValues: this.initialValues
        });
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
    this.setState({loading: false});
  }

  submit = async (values) => {
    //check login email
    if(!this.validateEmail(values.mail)) return;

    // call api
    try{
      CommonFunction.overLay();
      const response = await LoginApi.LoginByMail(values);
      // login complete
      if(response?.status==1){
        CommonFunction.setAccessTime();
        ProfileSettingApi.setCheckAccessFlg(false);
        await ProfileSettingApi.ProfileGetCheck(this.context);
      
        // ログイン情報
        if(values.accept==true){
          // ログイン上場を保持する
          StorageHelper.storeData(this.keynameMail, values.mail);
          StorageHelper.storeData(this.keynamePass, values.pass);
        }else{
          // ログイン情報を保持しない
          StorageHelper.removeItem(this.keynameMail);
          StorageHelper.removeItem(this.keynamePass);
        }

        // remove temp register data if exists
        const removeKeys = ['registProfile'];
        StorageHelper.removeItems(removeKeys);

        if(Platform.OS !== 'web'){
          StorageHelper.removeItem('deviceToken');
        } else{
          StorageHelper.removeItem('deviceToken_web');
        }

        // goto search screen
        this.navigation?.navigate('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  changeIcon = () =>{
    this.setState({
      eyeIcon: this.state.eyeIcon==='eye'?'eye-with-line':'eye',
      secureTextEntry: !this.state.secureTextEntry
    });
  }

  validateForm(values){
    if(values.mail=='' || values.pass==''){
      return false;
    }
    return true;
  }

  render() {
    if(this.state.loading) return <></>;

    return (
      <View style={styles.container}>
      <ScrollView style={styles.ScrollContainer}>
        <View style={styles.HeadFormSection}>
          <Text style={styles.txtHeadForm}>ログイン情報を</Text>
          <Text style={styles.txtHeadForm}>入力してください</Text>
        </View>
        <Formik        
          initialValues={this.state.initialValues}
          validationSchema={Validation.LoginSchema}
          onSubmit={values => this.submit(values) }
          validateOnChange={false}
          validateOnBlur={false}
        >                     
          {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
            <Fragment>
              <View style={styles.BodyFormSection}>
                <View style={styles.formGroup}>
                  <View style={styles.labelView} ><Text style={styles.labelText}>メールアドレス</Text></View>
                  <View style={styles.inputView} >
                    <TextInput style={styles.inputText}
                      value={values.mail}
                      onChangeText={handleChange('mail')}
                      onBlur={() => setFieldTouched('mail')}
                      inputAccessoryViewID={inputAccessoryViewID}
                    />
                  </View>
                  {touched.mail && errors.mail &&
                    <View style={[styles.labelView,]}>
                      <Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>{errors.mail}</Text>
                    </View>
                  }
                </View>
                <View style={styles.formGroup}>
                  <View style={styles.labelView} ><Text style={styles.labelText}>パスワード</Text></View>
                  <View style={[styles.inputView,{flexDirection:'row',alignItems:'center',justifyContent:'space-between'}]} >
                    <TextInput style={[styles.inputText,{alignContent:'flex-start',flex:1,}]}
                      value={values.pass}
                      onChangeText={handleChange('pass')}
                      onBlur={() => setFieldTouched('pass')}
                      secureTextEntry={this.state.secureTextEntry}
                      inputAccessoryViewID={inputAccessoryViewID}
                    />
                    <View style={styles.icPassword}>
                      <Entypo name={this.state.eyeIcon} style={styles.eyeIcPass} onPress={()=>this.changeIcon()} />
                    </View>
                  </View>
                  {touched.pass && errors.pass &&
                    <View style={[styles.labelView,]}>
                      <Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>{errors.pass}</Text>
                    </View>
                  } 
                </View>
                <View style={{flexDirection:"row"}}>
                  <View style={styles.blogCheckbox}>
                    <CheckboxInput fieldName="accept" NotdisplayError={true} containerStyle={{marginRight:3, paddingRight:0, marginLeft:0, margin:0, padding:0,}} checked={values.accept} />
                    <Text style={styles.labelText}>ログイン情報を保存する</Text>
                  </View>
                </View>
                <View style={styles.buttonPanel}>
                {this.validateForm(values) ?
                  <TouchableOpacity style={styles.loginBtn} onPress={handleSubmit}>
                    <Text style={styles.loginText}>ログインする</Text>
                  </TouchableOpacity>
                  :
                  <View style={[styles.loginBtn,{opacity:0.4}]}>
                    <Text style={styles.loginText}>ログインする</Text>
                  </View>
                }
                </View>
                <View style={{paddingTop:30,flexDirection:"row"}}>
                  <Text style={styles.labelText}>パスワードをお忘れの場合は </Text>
                  <TouchableOpacity onPress={()=>{ this.navigation.navigate('Reminder')}}>
                    <Text style={styles.forgot_link}>こちら</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Fragment>
          )}          
        </Formik>
        </ScrollView>
      </View>
    );
  }
}
