import React from 'react';
import { View } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import Block07Api from '../../services/api/Block07Api';
import CommonFunction from '../../utils/CommonFunction';
import { RefreshControl } from 'react-native-web-refresh-control';
import LoadMore from '../../components/screens/LoadMore';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import NoItemsBox from '../../components/common/NoItemsBox';
import BlockUserRowFormat from '../../components/data/BlockUserRowFormat';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class BlockListScreen extends LoadMore {
  setHeader = () =>{
    this.title = 'ブロックリスト';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.props.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.props.navigation, this.title);
  }

  componentDidMount(){
    this.setHeader();
    super.componentDidMount();    
  }
  
  async getListFromApi(page=1){
    if(this._isMounted){
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }
      
      try{
        const params = {page: page};
        const blockListResponse = await Block07Api.GetList(params);
        //check response
        if(!CommonFunction.checkResponseStatus(blockListResponse)) return;

        if(blockListResponse!=undefined && blockListResponse?.status==1){
          const blockList = blockListResponse?.response?.block;
          let { data } = this.state;
          let longRefreshControl = true;

          if(blockList!=undefined){
            if(blockList?.list?.length > 0){
              if(page > 1){
                data = data.concat(blockList?.list);
              }else{
                data = blockList?.list;
              }
            }
            if(data?.length>0) longRefreshControl = false;
            this.no_more = (blockList?.nextVal == 0)?1:0
          }

          this.setState({ 
            data: data, 
            longRefreshControl: longRefreshControl
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false
        });
      }
    }
  }

  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;

    return <NoItemsBox msg="ブロックしたユーザーはいません"/>
  }

  getItemLayout = (data, index) => {
    const length = 81; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  render() {
    if (this.state.loading && this.page === 1) return <ActivityIndicator />;

    let DATA = [{title:'', data: this.state.data}];
    let length = this.state.data?.length;
    if(length<15) length = 15;

    return (
      <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <SwipeListView  
          contentContainerStyle={ListStyles.scrollContainer}
          listViewRef={(ref)=>this.scrollRef=ref}
          useSectionList={true}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              long={this.state.longRefreshControl}
            />
          }
          sections={this.state.data.length > 0 ? DATA:[] }
          ListEmptyComponent={this.noItemDisplay}
          renderItem={({ item, index }) => (
            <View style={{ flex:1,flexDirection: 'row' }}>
              <BlockUserRowFormat item={item}  />
            </View>
          )}
          extraData={this.state.data}
          disableRightSwipe={true}
          closeOnRowPress={true}
          rightOpenValue={-75}
          keyExtractor={(item, index) => item?.memberId+index.toString()}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={2}
          onEndReached={this.handleLoadMore.bind(this)}
          getItemLayout={this.getItemLayout}
          scrollIndicatorInsets={{ right: 1 }}
          initialNumToRender={length}
        />
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}