import React, {Component} from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform
} from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import { Ionicons } from '@expo/vector-icons';
import OptionData  from '../constants/option/OptionData.json';
import moment from 'moment';
import CommonFunction from '../utils/CommonFunction';
import HeadStyle from '../styles/HeadStyles';
import HeaderOptions from '../constants/HeaderOptions';
import PortalStyle from '../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import AdvanceSearchListScreen from './AdvanceSearchListScreen';
import { AppContext } from '../AppContext';
import UserUtil from '../utils/UserUtil';
import CalendarApi from '../services/api/Calendar11Api';
import ActivityIndicator from '../components/common/ActivityIndicator';
import Popup from '../components/common/Popup';
import UserVerify from '../utils/UserVerify';
import CommonStyles from '../styles/CommonStyles';
import Logo from '../components/common/Logo';

export default class MeetCalendarScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: [],
      isSelectDate: false,
      loading: true,
      showPopUp: false,
      calendarSelectedDate: {},
      arrDateAdds: {},
      account: UserUtil.getDefaultAccountAppState()
    };

    this.arrDateAdds = {};
    this.clickDate = '';
    this.minDate = moment();
    this.maxDate = moment(this.minDate).add(29, 'days');
    this.weekDays = ['日', '月', '火', '水', '木', '金', '土'];
    this.months = ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];
    this.selectBackground = '#5cc8ca';
    this.textStyle = {
      color: '#484848',
      fontWeight:'bold',
      fontSize:20,
    };
    this.previousTitleStyle = {
      color:'#ccc',
      fontSize:14,
      fontWeight:'bold',
    };
    this.nextTitleStyle = {
      color:'#ff8d63',
      fontSize:14,
      fontWeight:'bold',
    };

    // remove Area id 0
    this.Area = OptionData.Master.Area.Items.filter(function(item){
      return item.id > 0;
    });
  }

  async componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      this.setHeader();
      CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "none"); // hide main bottom tab
      
      const { appState } = this.context;
      this.myProfile = await UserUtil.getUserProfileLocal(appState);
      const memberId = this.myProfile?.memberId;
      if(memberId){
        await this.getTargetList(memberId);
        this.origArrDateAdds = JSON.parse(JSON.stringify(this.arrDateAdds));
      }
      if(appState?.account){
        this.setState({account: appState.account});
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex"); // show main bottom tab
  }

  loadComplete = ()=>{
    this.setState({ loading: false });
  }

  getTargetList = async(memberId)=>{
    try{
      const response = await CalendarApi.GetTargetList(memberId);
      //check response status
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(response!=undefined && response?.status==1){
        const account = UserUtil.getAccountAppState(response, this.context);
        const aeru = response?.response?.aeru;
        let selectedDate = [], calendarSelectedDate = [];
        if(aeru!=undefined){
          aeru.list?.map(function(list) {
            let year = list.year;
            let month = list.month.length==1?'0'+list.month: list.month;
            let day = list.day.length==1?'0'+list.day: list.day;
            let date = year+'-'+month+'-'+day;
            selectedDate.push(date);

            let adds = [];
            const pref = list.pref;
            if(pref!=undefined){
              pref?.map(function(val) {
                const area = this.Area.filter(function(item){
                  return item.id == val.id;
                });
                if(area.length>0){
                  const data = {
                    id: area[0].id,
                    value: area[0].value,
                    isSelect: true
                  }
                  adds.push(data);
                }
              }, this);
            }
            calendarSelectedDate[date] = adds;
            this.setArrDateAdds(date, adds);
          }, this);
        }

        this.setState({
          selectedDate: selectedDate,
          calendarSelectedDate: calendarSelectedDate,
          account: account,
          loading: false
        });
      }else{
        this.loadComplete();
      }
    }catch(e){
      if(__DEV__) console.log(e);
      this.loadComplete();
    }finally{
    }
  }

  setHeader = () =>{
    this.props.navigation?.setOptions({
      header:()=>(
        <View style={[HeaderOptions.HeaderSetting, HeadStyle.appHeaderTop, { flexDirection:'row', alignItems:'center'}]}>
          <View style={{height:'100%',justifyContent:'center'}}><Logo/></View>
          <View style={{marginLeft:10}}>
            <Text style={[HeadStyle.headerTitlePage, HeadStyle.txtBold, HeadStyle.txt14]}>会える日を登録（複数選択可）</Text>
          </View>
          <View style={{position:'absolute', right:0}}>
            <TouchableOpacity onPress={() => {
            CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
            this.props.navigation?.navigate('MeetList');
          }}>
            <View style={[HeadStyle.btnCloseRight,{width:50, alignContent:'flex-end',justifyContent:'flex-end', alignItems:'flex-end'}]}>
              <Ionicons name="close-sharp" style={HeadStyle.icCloseRight}/>
              <Text style={[HeadStyle.txtCloseRight, HeadStyle.txtBold,]}>閉じる</Text>
            </View>
          </TouchableOpacity>
          </View>
        </View>
      )
    });
  }
  
  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.props.navigation,
      account: this.state.account
    });
  }
  
  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.props.navigation
    });
  }

  onDateChange = (alldate, date) => {
    //verify SMS
    if(!this.checkSMS()) return;
    //verify Age
    if(!this.checkAge()) return;

    this.clickDate = moment(date).format("YYYY-MM-DD");
    this.setState({showPopUp: true});
  }
  
  setArrDateAdds = async(date, adds)=>{
    const m = moment(date).format("M");
    const d = moment(date).format("D");
    const value = CommonFunction.makeId(adds, '_');
    if(value){
      const dateData = {
        [d]: value
      };

      let monthData = this.arrDateAdds[m];
      if(monthData==undefined){
        this.arrDateAdds[m] = dateData;
      }else{
        monthData[d] = value;
        this.arrDateAdds[m] = monthData;
      }
    }else{
      //delete
      if(this.arrDateAdds[m]?.hasOwnProperty(d)){
        this.arrDateAdds[m][d] = '';
      }
    }
  }

  setDateAdds = (adds)=>{
    const date = this.clickDate;
    this.setArrDateAdds(date, adds);

    let { calendarSelectedDate, selectedDate } = this.state;
    calendarSelectedDate[this.clickDate] = adds;
  
    const index = selectedDate?.findIndex(
      item => this.clickDate == item
    );

    if(calendarSelectedDate[this.clickDate]?.length>0){
      // add select date
      if(index==-1){
        selectedDate?.push(this.clickDate);
      }
    }else{
      // remove select date
      if(index!=-1){
        selectedDate?.splice(index, 1);
      }
    }

    let isSelectDate = false;
    if(JSON.stringify(this.origArrDateAdds)!=JSON.stringify(this.arrDateAdds)) isSelectDate = true;

    this.setState({
      calendarSelectedDate: calendarSelectedDate, 
      selectedDate: selectedDate,
      isSelectDate: isSelectDate,
      arrDateAdds: this.arrDateAdds
    });
    this.calendarRef.setSelections(selectedDate);

    // clear click date
    this.clickDate = '';
  }

  handleSubmit = async() => {
    try{
      CommonFunction.overLay();
      let values = this.state.arrDateAdds;
      const params = JSON.stringify(values);
      const response = await CalendarApi.PostRegist(params);
      //check login
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(response?.status==1){
        this.popup?.showPopup();
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  customDayHeaderStylesCallback = (date) => {
    switch(date.dayOfWeek) {
      case 7: 
      return {
        style: { },
        textStyle: {
          color:'red',
          fontSize:18,
          fontWeight:'normal',
        }
      };
      case 6: 
      return {
        style: { },
        textStyle: {
          color:'blue',
          fontSize:18,
          fontWeight:'normal',
        }
      };
      default:
        return {
        style: { },
        textStyle: {
          fontSize:18,
          fontWeight:'normal',
        }
      };
    }
  }

  customDatesStylesCallback = (date) => {
    return {
      style:{ },
      textStyle: {
        fontSize:16, 
        fontWeight:'bold',
        color:'#484848'
      }
    };
  }

  saveComplete = ()=>{
    CommonFunction.setMainBottomTab(this.props?.BottomTabNavigation, "flex");
    this.popup?.hidePopup();
    this.props.navigation?.navigate('MeetList');
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;
    
    const previousComponent = (
      <View style={styles.ArrowPrev}>
        <Ionicons name="chevron-back" style={styles.icArrowPrev}/>
        <Text style={styles.txtPrev}>前の月</Text>
      </View>
    );
      
    const nextComponent = (
      <View style={styles.ArrowNext}>
        <Text style={styles.txtNext}>次の月</Text>
        <Ionicons name="chevron-forward" style={styles.icArrowNext}/>
      </View>
    );

    return (
      <View style={CommonStyles.container}>
        <ScrollView contentContainerStyle={{flexGrow: 1}} style={styles.scrollContainer}>
          <View style={styles.calendarWrapper}>
            <CalendarPicker
              ref={ref=>this.calendarRef=ref}
              onMultiDateChange={this.onDateChange}
              minDate={this.minDate}
              maxDate={this.maxDate}
              initialDate={this.minDate}
              restrictMonthNavigation={true}
              weekdays={this.weekDays}
              previousComponent={previousComponent}
              nextComponent={nextComponent}
              selectedDayColor={this.selectBackground}
              textStyle={this.textStyle}
              previousTitleStyle={this.previousTitleStyle}
              nextTitleStyle={this.nextTitleStyle}
              customDayHeaderStyles={this.customDayHeaderStylesCallback}
              customDatesStyles={this.customDatesStylesCallback}
              dayLabelsWrapper={{borderTopWidth:0}}
              months={this.months}
              todayBackgroundColor={'#ffffff'}
              multipleSelected={true}
              selectedDate={this.state.selectedDate}
              selectedStartDate={moment(this.state.selectedDate[0])}
              selectedDayTextColor="#ffffff"
              selectedDayTextStyle={{color:'#ffffff'}}
              todayTextStyle={{color:'#000000'}}
            />
          </View>

          <View style={styles.footerArea}>
            {this.state.isSelectDate ?
            <TouchableOpacity style={[styles.submitBtn,{opacity:1}]} 
              onPress={this.handleSubmit}>
                <Text style={styles.submitText}>この内容で設定する</Text>
            </TouchableOpacity>
            :
            <View style={[styles.submitBtn,{opacity:0.45}]}>
              <Text style={styles.submitText}>この内容で設定する</Text>
            </View>
            }
          </View>
          
          {this.state.showPopUp &&
          <Portal style={PortalStyle.portal}>
            <View style={PortalStyle.portalContainer}>
              <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
                //this.setState({showPopUp: false});
              }} />
              <View style={{backgroundColor:'#ffffff', height:'70%', width:'90%',alignSelf:'center', borderRadius:10,paddingBottom:10}}>
                <View style={{position:'relative', flexDirection:'row', padding:10, paddingTop:18, paddingBottom:18, borderBottomWidth:1, borderBottomColor:'#ccc'}}>
                  <Text style={{textAlign:'center',width:'100%', fontWeight:'bold'}}>地域選択</Text>
                  <TouchableOpacity style={{position:'absolute', right:10, top: 16,}} onPress={()=>{
                    const adds = this.addsRef.handleBack();
                    this.setState({showPopUp: false});
                    this.setDateAdds(adds);
                  }}>
                    <Text style={{fontSize:12, fontWeight:'bold', color:'#484848', lineHeight: 22}}>閉じる</Text>
                  </TouchableOpacity>
                </View>
             
                <AdvanceSearchListScreen ref={ref=>this.addsRef=ref} fieldName={'adds'} items={this.Area} onChange={()=>{}} values={this.state.calendarSelectedDate[this.clickDate]} maxselect={3} />
                
              </View>
            </View>
          </Portal>
          }
          
        </ScrollView>

        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.saveComplete();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'65%', backgroundColor:'#fefefe', borderRadius: 12,}}>
            <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>送信しました</Text>
            <TouchableOpacity style={[styles.btnBlue, {marginTop:20,}]} onPress={()=>{ 
              this.saveComplete();
            }}>
              <Text style={styles.txtBtnBlue}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  scrollContainer:{
    width:'100%',
    height:'100%'
  },
  calendar: {
    marginBottom: 10
  },
  text: {
    textAlign: 'center',
    padding: 10,
    backgroundColor: 'lightgrey',
    fontSize: 16
  },
  section:{ },
  sectionWrapper:{
    backgroundColor:'#fff',
    borderBottomWidth:10,
    borderBottomColor:'#eeeeee',
  },
  sectionInside:{
    flexDirection:'row', 
    alignSelf:'stretch',
    minHeight:44,
    marginLeft:10,
    marginRight:10,
  },
  formLabel:{
    flex: 1,  
    alignSelf:'stretch', 
    alignItems:'flex-start'
  },
  formSelected:{
    flex: 1,
    alignItems:'flex-end',
    justifyContent:'center',
    maxWidth:190,
    fontSize:14,
    lineHeight:20,
    color:'#484848'
  },
  formSelectedInside:{
    paddingLeft:5,
    width:100,
    height:32,
    borderWidth:1,
    borderColor:'#eeeeee',
    borderRadius:5,
    color:'#484848'
  },
  label:{
    fontSize: 14,
    lineHeight: 19,
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    color:'#484848',
    fontWeight:'bold',
  },
  notSelectText:{
    color:'#9aa5a7',
    fontSize:14,
    lineHeight:19
  },
  selectText:{
    color:'#5cc8ca',
    fontSize:14,
    lineHeight:19
  },
  calendarWrapper:{
    width:'100%',
    height:'70%',
    backgroundColor:'#fff',
    ...Platform.select({
      android:{},
      default:{
        zIndex:1,
        elevation:1,
      }
    })
  },
  ArrowPrev:{
    flexDirection:'row',
    paddingTop:10,
    paddingBottom:10,
    color:'#01cccc',
    lineHeight:32,
  },
  ArrowNext:{
    flexDirection:'row',
    paddingTop:10,
    paddingBottom:10,
    color:'#01cccc',
    lineHeight:32,
  },
  icArrowPrev:{
    fontSize:20,
    color:'#01cccc',
    lineHeight:32,
  },
  icArrowNext:{
    fontSize:20,
    color:'#01cccc',
    lineHeight:32,
  },
  txtPrev:{
    color:'#01cccc',
    lineHeight:32,
  },
  txtNext:{
    color:'#01cccc',
    lineHeight:32,
  },
  footerArea:{
    height:70, 
    width:'100%', 
    backgroundColor:'#fff',
    justifyContent:'center', 
    alignItems:'center'
  },
  submitBtn:{
    alignItems:"center",
    justifyContent:"center",
    padding:8,
    width:'80%',
    height:45,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  submitText:{
    fontSize:16, 
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center'
  },
  btnBlue:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:30,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#ffffff',
    textAlign:'center'
  }
});
