import React from 'react';
import { StyleSheet, Text,FlatList, View } from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import { AppContext } from '../AppContext';
import ActivityIndicator from '../components/common/ActivityIndicator';
import ListStyles from '../styles/ListStyles';
import Point24Api from '../services/api/Point24Api';
import LoadMore from '../components/screens/LoadMore';
import { RefreshControl } from 'react-native-web-refresh-control';
import NoItemsBox from '../components/common/NoItemsBox';

export default class PointScreen extends LoadMore {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      CommonFunction.hideBottomTab(this.navigation);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    //CommonFunction.showBottomTab(this.navigation);
  }  

  setHeader(){
    this.title = 'ポイント履歴';
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  getListFromApi = async(page)=>{
    try{
      const params = { count:30, page: page};
      const pointHistoryResponse = await Point24Api.getHistory(params);
      //check response
      if(!CommonFunction.checkResponseStatus(pointHistoryResponse)) return;

      if(pointHistoryResponse !=undefined && pointHistoryResponse?.status==1){
        const history = pointHistoryResponse?.response?.history;
        const nextVal = pointHistoryResponse?.response?.nextVal;
        const longRefreshControl = (history?.length>0) ?false:true;
        let { data } = this.state;
        if(history!=undefined){
          if(page > 1){
            data = data.concat(history);
          }else{
            data = history;
          }
        }
        
        this.setState({
          data: data,
          longRefreshControl: longRefreshControl
        });
        this.no_more = (nextVal == 0)?1:0;
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false, isRefreshing: false });
    }
  }

  renderItem = ({ item }) => (
    <View style={styles.itemHistory}>
      <View style={styles.itemHistoryLeft}>
        <Text style={styles.dateHistory}>{item.pointDate}</Text>
        <Text style={styles.txtDateHistory}>{item.type}</Text>
      </View>
      <View style={styles.itemHistoryRight}>
        <Text style={styles.txtNumPoint}>{item.detail}</Text>
        <Text style={styles.numPoint}>{item.point}ポイント</Text>       
      </View>
    </View>
  );

  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;
   
    return (
      <NoItemsBox msg="ポイント履歴がありません" />
    );
  }

  getItemLayout = (data, index) => {
    const length = 68; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
      <View style={ListStyles.container}>
          <FlatList
            contentContainerStyle={[ListStyles.scrollContainer,{padding:15}]}
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefreshing}
                onRefresh={this.onRefresh}
                long={this.state.longRefreshControl}
              />
            }
            data={this.state.data}
            renderItem={this.renderItem}
            ListEmptyComponent={this.noItemDisplay}
            keyExtractor={(item, index) => item.pointDate+index.toString()}
            ListFooterComponent={this.renderFooter.bind(this)}
            onEndReachedThreshold={0.5}
            onEndReached={this.handleLoadMore.bind(this)}
            getItemLayout={this.getItemLayout}
            scrollIndicatorInsets={{ right: 1 }}
            initialNumToRender={this.state.data.length}
          />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  itemHistory: {
    flex:1,
    flexDirection:'row',
    marginBottom:15,
    paddingBottom:15,
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  itemHistoryLeft: {
    flex:1,
    justifyContent:'flex-start',
    alignContent:'flex-start',
    alignItems:'flex-start',
  },
  itemHistoryRight: {
    flex:3,
    justifyContent:'flex-start',
    alignContent:'flex-end',
    alignItems:'flex-end',
    paddingLeft:10,
    textAlign:'right',
  },
  dateHistory: {
    fontSize:12,
    lineHeight:19,
    color:'#a2a2a2',
  },
  txtDateHistory: {
    fontSize:14,
    lineHeight:19,
    color:'#484848',
  },
  numPoint: {
    fontSize:14,
    lineHeight:19,
    color:'#484848',
    textAlign:'center',
  },
  txtNumPoint: {
    fontSize:11,
    lineHeight:19,
    color:'#a2a2a2',
  },
});

