import React,{Component } from 'react';
import { View, StyleSheet, SafeAreaView, TouchableOpacity, Text, Platform } from 'react-native';
import ActivityIndicator from '../components/common/ActivityIndicator';
import CommonFunction from '../utils/CommonFunction';
import { WebView } from 'react-native-webview';
import ListStyles from '../styles/ListStyles';
import ApiConfig from '../constants/ApiConfig';
import HeadStyle from '../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';
import LogoNoMargin from '../components/common/LogoNoMargin';
import ExpoConstants from 'expo-constants';

export default class InquiryScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      contactUrl: '',
      userAgent:'',
      loading: true
    }
    this.fromPushFlag = false;
  }

  async componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      this.setHeader();
      CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "none");
      this.setWebviewParams();

      if(Platform.OS!=='web'){
        if(this.props?.route?.params?.params?.fromPushFlag != undefined) {
          this.fromPushFlag = this.props?.route?.params?.params?.fromPushFlag;
        }
      }
    }
  }
  
  componentWillUnmount(){
    CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
    this._isMounted = false;
  }

  setWebviewParams = async ()=>{
    try{
      const token = await CommonFunction.getEncryptAccessToken();
      const contactUrl = ApiConfig.contact.url+'?token='+token;
      let userAgent = '';
      await ExpoConstants.getWebViewUserAgentAsync().then(ua => {
        userAgent = ua;
      });
      userAgent += '/PAPPY';
  
      this.setState({
        contactUrl:contactUrl,
        userAgent: userAgent, 
        loading: false
      });
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  setHeader(){
    this.props.navigation?.setOptions({
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={{position:'absolute',left:0,zIndex:1,elevation:1,flexDirection:'row'}}>
            <TouchableOpacity onPress={()=>{
              CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
              this.goBackActionCheck();
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch'}}><Text style={HeadStyle.headerTitlePage}>お問い合わせ</Text></View>
        </View>
      )
    });
  }

  goBackActionCheck() {
    if(this.fromPushFlag==1) {
      this.props?.navigation.navigate('Home', {screen:'MyPageStack', params:{ screen:'MyPage'}});
    } else {
      this.props?.navigation?.goBack();
    }
  }

  INJECTED_JAVASCRIPT = `(function() {
      window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
  })();`;

  onMessage = (event)=>{
    const {
      nativeEvent: {data},
    } = event;
    
    switch(data){
      case 'Help':
        this.props.navigation?.navigate('Help');
        break;
      case 'Withdraw':
        this.props.navigation?.navigate('Withdraw');
        break;
    }
  }

  render() {
    if(this.state.loading) return <></>

    return (
      <SafeAreaView style={ListStyles.container}>
       <WebView
          androidLayerType={'hardware'}
          style={styles.webViewContainer}
          source={{ uri: this.state.contactUrl }}
          userAgent={this.state.userAgent}
          injectedJavaScript={this.INJECTED_JAVASCRIPT}
          onMessage={this.onMessage}
        /> 
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  webViewContainer:{
    flex:1,
    width:'100%',
    height:'100%',
    opacity: 0.99
  }
});
