import React,{ Component, Fragment } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, SafeAreaView 
} from 'react-native';
import { UploadCameraInput } from '../../components/form/FormInput';
import { Formik } from 'formik';
import Validation from '../../constants/Validation';
import CommonFunction from '../../utils/CommonFunction';
import MyPage13Api from '../../services/api/MyPage13Api';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import { AppContext } from '../../AppContext';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import ListStyles from '../../styles/ListStyles';
import WebUtil from '../../utils/WebUtil';
import { AntDesign } from '@expo/vector-icons';
import HeadStyle from '../../styles/HeadStyles';
import HeaderOptions from '../../constants/HeaderOptions';
import LogoNoMargin from '../../components/common/LogoNoMargin';
import PopupMedia from '../../components/common/PopupMedia';

const { resizeImage } = WebUtil;

export default class CameraUploadIdentificationScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    
    let defaultImage = '';
    if(this.props?.image){
      defaultImage = this.props.image;
    }

    this.initialValues = {
      image: defaultImage
    };

    this.state = {
      formData: { image: defaultImage }
    };
    this.popup = React.createRef();
    this.loadingPopup = React.createRef();
    this.actionEnabled = React.createRef();
    this.PopupMedia = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.navigation = this.props.navigation;
      this.actionEnabled.current = false;
      this.rightPopupRef = this.props?.getRightPopupRef();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setProfileImageRef(ref){
    this.ProfileImageRef = ref;
  }

  _pickImage = ()=>{
    this.ProfileImageRef?._pickImage();
  }

  _pickCamera = ()=>{
    this.ProfileImageRef?._pickCamera();
  }

  handleSubmit = async (values) => {
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    try{
      this.loadingPopup.showPopup();

      const stateValue = values['image'];
      const [, type] = stateValue.split(';')[0].split('/');
      const resp = await fetch(stateValue);
      const fblob = await resp.blob();
      const maxWidth = 1280;
      const config = {
        file: fblob,
        maxWidth: maxWidth
      };

      let postFile;
      if(Platform.OS==='web'){
        postFile = await resizeImage(config)
        if(__DEV__) console.log('resize file size',postFile.size);
      }else{
        const resizeStateValue = await CommonFunction.resizeImageApp(stateValue, maxWidth);
        if(resizeStateValue){
          const type = resizeStateValue?.type;
          const mainPath = resizeStateValue?.mainPath;
          postFile = {
            uri: mainPath,
            type: fblob.type,
            name: `profile.${type}`
          };
        }
      }

      if(postFile){
        // call Api
        let responseCertifyPostRequest = await MyPage13Api.CertifyPostRequest(postFile);
        
        //check login
        if(!CommonFunction.checkResponseStatus(responseCertifyPostRequest)) {
          this.loadingPopup.hidePopup();
          return;
        }

        if(responseCertifyPostRequest!=undefined && responseCertifyPostRequest?.status==1){
          this.loadingPopup.hidePopup();
          this.popup?.showPopup();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
      this.loadingPopup.hidePopup();
    }
  }

  onClose = ()=>{
    if(this.props?.callback){
      this.props.callback();
    }
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.header}>
          <View style={[styles.headerLeft,{flexDirection:'row',justifyContent:'center',alignItems:'center'}]}>
            <TouchableOpacity onPress={()=>{
              this.rightPopupRef?.ShowPopUp(false);
            }}>
              <AntDesign name={'close'} style={[HeadStyle.icBack, {paddingLeft:14, paddingRight:4}]} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
            <Text style={HeadStyle.headerTitlePage}>年齢確認</Text>
          </View>
        </View>

        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            <Formik
              initialValues={this.initialValues}
              validationSchema={Validation.UploadIdentificationSchema}
              onSubmit={values => this.handleSubmit(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
            <Fragment>
            <View style={[styles.labelView, {marginTop:0, marginBottom:15}]}>
              {errors.image ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>※{errors.image}</Text>) : null}
            </View>  
            <View>
              <UploadCameraInput fieldName="image" image={this.state.formData?.image} setRef={this.setProfileImageRef.bind(this)} />
            </View>
            <View style={styles.btnArea}>
              <TouchableOpacity disabled={!isValid} onPress={handleSubmit} style={[styles.btnBlue, {opacity:isValid ?1:0.4}]}>
                <Text style={styles.txtBtnBlue}>年齢確認写真を提出する</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={()=>{
                if(Platform.OS==='web'){
                  this.ProfileImageRef?._pickImage();
                }else{
                  this.PopupMediaRef.show(true);
                  //this.ProfileImageRef?._pickCamera();
                }
              }} style={[styles.btnBlue, {marginTop:20, backgroundColor:'#e6e6e6',shadowColor: "#cfcfcf"}]}>
                <Text style={[styles.txtBtnBlue,{color:'#484848'}]}>撮り直す</Text>
              </TouchableOpacity>
            </View>
            </Fragment>
            )}
            </Formik>
          </View>
        </ScrollView>

        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.popup?.hidePopup();
              this.onClose();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
            <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848', textAlign:'center'}}>審査完了までしばらくお待ちください</Text>
            </View>
            <TouchableOpacity onPress={()=>{
              this.popup?.hidePopup();
              this.onClose();
              }} style={{padding:15}}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7'}}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>
        <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <ActivityIndicator size={50} color={'rgba(255,255,255,0.6)'} useIcon={true} />
        </Popup>
        <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:'#ffffff',
  },
  blogInner: {
    padding:0
  },
  btnArea: {
    paddingTop:60,
    paddingBottom:30,
    paddingRight:15,
    paddingLeft:15,
  },
  btnBlue: {
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    textAlign:'center',
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  header:{
    flexDirection:'row',
    borderBottomWidth:1,
    backgroundColor:'#ffffff',
    borderBottomColor:'#b2b2b2',
    shadowColor: 'transparent',
    width:'100%',
    height:HeaderOptions.headerTopMenuHeight,
    ...Platform.select({
      ios: {
        marginTop:0,
       },
      android: { 
        marginTop:0, 
      },
      default: {
        
      }
    })
  },
  headerCenter:{
    flex:1, 
    alignItems:'stretch',
    justifyContent:'center',
    height:'100%', 
  },
  headerLeft:{
    position:'absolute', 
    justifyContent:'center', 
    height:'100%', 
    left:0, 
    top:0, 
    zIndex:1,
    elevation:1
  },
  headerRight:{
    position:'absolute', 
    justifyContent:'center', 
    height:'100%', 
    right:0, 
    top:0, 
    zIndex:1,
    elevation:1
  }
});
