import React,{Component} from 'react';
import { Image, View, TouchableOpacity, StyleSheet, Platform, Text, ImageBackground, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
//import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import { Camera } from 'expo-camera';
import PopupMedia from '../common/PopupMedia';
import PopupAppSetting from '../common/PopupAppSetting';
import Popup from '../common/Popup';
import PortalStyles from '../../styles/PortalStyles';

const windowWidth = Dimensions.get('window').width;

export default class ProfileImagePicker extends Component {
  _isMounted = false;
  constructor(props){
    super(props);
 
    if(this.props.sex===1){
      this.image = require("../../assets/images/noimg-male.png");
      this.popupImage = require("../../assets/images/popup-profile-image-guid-male.png"); 
    }else{
      this.image = require("../../assets/images/noimg-female.png");
      this.popupImage = require("../../assets/images/popup-profile-image-guid-female.png"); 
    }

    this.state = {
      image: null 
    };
    this.PopupMedia = React.createRef();
    this.PopupRef = React.createRef();
    this.popup = React.createRef();
  }
 
  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      //this.getPermissionAsync();
      if(this.props.mainImage!=null){
        this.setState(
          {
            image: this.props.mainImage
          }
        );
      }
    }
  }

  getPermissionAsync = async () => {
    if (Platform.OS==='ios') {
      const permission = await ImagePicker.requestCameraPermissionsAsync();
    }
  };

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if (Platform.OS==='ios') {
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  render() {
    let { image } = this.state;
    const popupWidth = windowWidth * 0.77
    const newHeight = popupWidth * 943 / 864
    return (
      <View style={styles.photoContainer}>
        <View style={styles.photoProfile}>
          <TouchableOpacity onPress={()=>{
            // プロフ画像登録促進　画像無しの場合にポップアップ表示
            if(image){
              if(Platform.OS==='web'){
                this._pickImage();
              }else{
                this.PopupMediaRef.show(true);
              }
            } else{
              this.popup?.showPopup()
            }
            // if(Platform.OS==='web'){
            //   this._pickImage();
            // }else{
            //   this.PopupMediaRef.show(true);
            // }
          }}>
          <View style={styles.photoThumb}>
            {image ?
            <Image source={{ uri: image }} style={styles.photoImg} />
            :
            <Image source={this.image} style={styles.photoImg} />
            }
            </View>
            <View style={styles.photoBtnIcon}>
            <Image source={require('../../assets/images/camera.png')} style={styles.photoIcon} />
            </View>
            </TouchableOpacity>
        </View>
        <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
        <PopupAppSetting ref={ref=>this.PopupRef=ref} />
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyles.overlay} 
              onStartShouldSetResponder={() => {
              }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', paddingTop: 5, width:'80%', backgroundColor:'#fefefe', borderRadius: 12,}}>
            <ImageBackground resizeMode={'cover'} 
              style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
              source={this.popupImage}
            ></ImageBackground>
            <TouchableOpacity style={[styles.btnBlue, {marginTop:0,}]} onPress={()=>{ 

              this.popup?.hidePopup()
              if(Platform.OS==='web'){
                this._pickImage();
              }else{
                this.PopupMediaRef.show(true);
              }
            }}>
              <Text style={styles.txtBtnBlue}>写真を選ぶ</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCancel} onPress={()=>this.popup?.hidePopup()}>
              <Text style={styles.txtBtnCancel}>閉じる</Text>
            </TouchableOpacity>
          </View>
        </Popup>
      </View>
    );
  }
}

const styles=StyleSheet.create({
  photoContainer:{
    flex:1,
    justifyContent:'center',
    alignItems:'center',
  },
  photoProfile:{
    marginTop:50,
    marginBottom:20,
    width:121,
    height:121,
  },
  photoThumb:{
    position:'relative',
    width:121,
    height:121,
    borderRadius:9999,
    shadowColor:"#b6b6b6",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:1,
    shadowRadius:4,
    elevation:10,
    zIndex:10
  },
  photoImg:{
    width:121,
    height:121,
    borderRadius:9999,
    borderWidth:5,
    borderColor:'#fff', 
  },
  photoBtnIcon: {
    position:'absolute',
    right:10,
    bottom:-10,
    zIndex:20,
    elevation:20
  },
  photoIcon:{
    width:38,
    height:38
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:10,
    marginBottom:10,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    
    textAlign:'center',
  },
  btnBlue:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:30,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#ffffff',
    textAlign:'center'
  }
});