import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { AntDesign, Entypo } from '@expo/vector-icons';
import { RefreshControl } from 'react-native-web-refresh-control';
import NoItemsBox from '../../components/common/NoItemsBox';
import styles from '../../styles/CommonStyles';
import HeadStyle from '../../styles/HeadStyles';
import LoadMore from '../../components/screens/LoadMore';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { SwipeListView } from 'react-native-swipe-list-view';
import CommonFunction  from '../../utils/CommonFunction';
import Notification18Api from "../../services/api/Notification18Api";
import { AppContext } from '../../AppContext';
import HeaderOptions from '../../constants/HeaderOptions';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const {checkUndefined} = CommonFunction;

export default class NotificationList extends LoadMore {
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      ...this.state,
      type: 0,
      badge: { unreadAllCount:0, unreadIndividualCount:0 },
      scrollHeight: 0
    }
  }

  getNotificationList = async(values)=>{
    if(this._isMounted){
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }
      
      try{
        const params = {page: values.page, type: values.type};
        const notiListResponse = await Notification18Api.GetNotificationList(params);

        //check login
        if(!CommonFunction.checkResponseStatus(notiListResponse)) return;

        if(notiListResponse!=undefined && notiListResponse?.status==1){
          const notiList = notiListResponse?.response; 

          if(checkUndefined(notiList)){
            const notification = notiList?.notification;

            // set tab badge
            if(checkUndefined(notification) && checkUndefined(notification.unread)){
              const badge = {unreadAllCount: notification.unread?.all, unreadIndividualCount: notification.unread?.individual };
              this.setState({ badge: badge });
              if(checkUndefined(this.props.setBadge)){
                this.props.setBadge(badge);
              }
            }
          
            if(checkUndefined(notification)){
              let { data } = this.state;
              let longRefreshControl = true;

              if(notification?.list?.length > 0){
                if(values.page > 1){
                  data = data.concat(notification.list);
                }else{
                  data = notification.list;
                }
              }
              if(data?.length>0) longRefreshControl = false;
              this.no_more = (notification?.nextVal == 0)?1:0;
              this.setState({ 
                data: data, 
                type: values.type, 
                longRefreshControl: longRefreshControl
              });
            }
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false
        });
      }
    }
  }

  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;

    return (
      <NoItemsBox msg='お知らせはありません' />
    );
  }

  isSectionsEmpty(){
    if(this.state.data.length >0) return false;
    else return true;
  }

  getItemLayout = (data, index) => {
    const length = 95; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  setViewIndex = (index)=>{
    let {appState, setAppState} = this.appContext;
    appState.chatViewIndex = index;
    setAppState(appState);
  }

  setParentState = ()=>{
    // other tab reference
    let otherTabRef = this.props?.otherTabRef();
    if(otherTabRef){
      let otherTabState = otherTabRef?.state;
      if(otherTabState!=undefined){
        if(otherTabRef.page!=undefined){
          otherTabState.page = otherTabRef.page;
        }
        if(otherTabRef?.no_more!=undefined){
          otherTabState.no_more = otherTabRef.no_more;
        }
        otherTabRef?.props?.parentState(otherTabState);
      }
    }
  }

  decreaseBadge = ()=>{
    let { data, badge } = this.state;
    let filter = [];
    if(data.length > 0){
      filter = data.filter(item => item.readFlg == 0);
      if(filter.length==0){
        if(this.state?.type==0) badge.unreadAllCount = 0;
        else badge.unreadIndividualCount = 0;
        
        if(checkUndefined(this.props.setBadge)){
          this.props.setBadge(badge);
        }
        this.setState({badge: badge})
      }
    }
  }

  handlerClick = (item) => {
    const noticeId = item.noticeId;
    if(noticeId!=undefined){
      let { data } = this.state;
      const findIndex = data?.findIndex(
        items => noticeId == items.noticeId
      );
  
      if(findIndex!=-1){
        if(data[findIndex]?.readFlg==0){
          data[findIndex].readFlg = 1;
          this.setState({data: data},()=>{
            this.decreaseBadge();
          });
        }
      }
    }

    this.setParentState();
    this.props.navigation.navigate('NotifyDetail',{noticeId: noticeId, type: this.type});
  }

  handlerDelete = async(rowData, rowMap)=>{
    const {item} = rowData;
    const noticeId = item?.noticeId;
    const rowIndex = noticeId+rowData?.index;
    
    if(checkUndefined(noticeId)){
      try{
        const response = await Notification18Api.DeleteNotification(noticeId);
        const success = response?.status;
        if(success==1){
          if(rowIndex!=undefined){
            rowMap[rowIndex]?.closeRow();
          }
        
          let {data} = this.state;
          if(data.length > 0){
            data = data.filter(item => item.noticeId !== noticeId);
            this.setState({data: data},()=>{
              if(rowData?.item?.readFlg==0){
                this.decreaseBadge();
              }
            });
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }
    }
  }

  renderItem = ({ item }) => (
    <TouchableOpacity activeOpacity={1} onPress={()=>{this.handlerClick(item)}}>
      <View style={innerStyles.itemList}>
        <View style={innerStyles.detailItem}>
          <Text style={innerStyles.titleDetailItem}>{item.title}</Text>
          <Text style={innerStyles.txtDetailItem}>{item.receiveDate}</Text>
        </View>
        <View style={{flex:1, flexDirection: "row", justifyContent:'flex-end', alignItems: 'center',}}>
            {item.readFlg==0 ? (<AntDesign name="exclamationcircle" style={innerStyles.icOrange} />) : null}          
            <Entypo name={'chevron-thin-right'} style={[HeadStyle.icBack, {paddingLeft:3, fontSize:20, color:'#9aa5a7'}]}/>
        </View>
      </View>
    </TouchableOpacity>
  );

  render() {
    if (this.state.loading && this.page === 1) return <ActivityIndicator />;

    let DATA = [{title:'', data: this.state.data}];
    let length = this.state.data?.length;
    if(length<15) length = 15;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[styles.container, SafeAreaStyles(insets).container]} onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        if(this.state.scrollHeight==0 && height>0){
          this.setState({scrollHeight: height});
        }
      }}>
        <SwipeListView
          contentContainerStyle={
            Platform.OS!=='web'?{ 
              flexGrow:1
            }:{}
          }
          style={
            Platform.OS==='web'?{ 
              height: this.state.scrollHeight
            }:{}
          }
          listViewRef={(ref)=>this.scrollRef=ref}
          useSectionList={true}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              long={this.state.longRefreshControl}
            />
          }
          sections={this.isSectionsEmpty() ? [] : DATA}
          ListEmptyComponent={this.noItemDisplay}
          renderItem={this.renderItem}
          renderHiddenItem={(data,rowMap) => (
            <TouchableOpacity style={innerStyles.hideContainer} onPress={()=>{ this.handlerDelete(data, rowMap)}}>
              <View style={innerStyles.hideWrapper}>
                <Text style={innerStyles.txtHide}>{this.state.type==0?'非表示':'削除'}</Text>
              </View>
            </TouchableOpacity>
          )}
          extraData={this.state.data}
          disableRightSwipe={true}
          disableLeftSwipe={this.state.type==0?true:false}
          closeOnRowPress={true}
          rightOpenValue={-75}
          keyExtractor={(item,index) => item?.noticeId+index.toString()}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={0.5}
          onEndReached={this.handleLoadMore.bind(this)}
          getItemLayout={this.getItemLayout}
          scrollIndicatorInsets={{ right: 1 }}
          initialNumToRender={length}
        />
      </View>
      }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const innerStyles = StyleSheet.create({
  hideContainer:{
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'flex-end',
    backgroundColor:'#e6e6e6',
    width:75, 
    height:'100%',
  },
  hideWrapper:{
    backgroundColor:'#484848',
    width:'98%',
    height:'98%',
    justifyContent:'center',
    alignContent:'center',
  },
  txtHide:{
    color:'#ffffff', 
    textAlign:'center',
  },
  itemList: {
    flex:1,
    flexDirection:'row',
    paddingTop:20,
    paddingBottom:16,
    paddingRight:10,
    paddingLeft:16,
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
    backgroundColor:'#ffffff'
  },
  detailItem: {
    flex:5,
  },
  dateDetailItem: {
    color:'#9aa5a7',
    fontSize:12,
    lineHeight:19,
    letterSpacing:-.5,
  },
  titleDetailItem: {
    color:'#484848',
    fontSize:14,
    lineHeight:20,
    fontWeight:'bold',
  },
  txtDetailItem: {
    marginTop:30,
    color:'#9aa5a7',
    fontSize:14,
    lineHeight:14,
  },
  icOrange: {
    fontSize:20,
    color:'#fe8d5a',

  }
});