import React,{Component} from 'react';
import { Image, View, TouchableOpacity, StyleSheet, Text, Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { Camera } from 'expo-camera';
import { FontAwesome } from '@expo/vector-icons';
import PopupMedia from '../common/PopupMedia';
import PopupAppSetting from '../common/PopupAppSetting';

export default class UploadImagePicker extends Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = { image: null };
    this.PopupMedia = React.createRef();
    this.PopupRef = React.createRef();
  }
  
  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      //this.getPermissionAsync();
      if(this.props.image){
        this.setState({ image: this.props.image });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPermissionAsync = async () => {
    if (Platform.OS==='ios') {
      const permission = await ImagePicker.requestCameraPermissionsAsync();
    }
  };

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if(Platform.OS==='ios'){
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  render() {
    let { image } = this.state;
    if(this.props.type==='identification'){
      return (
        <View style={styles.photoContainer}>
          <TouchableOpacity onPress={()=>{
            if(Platform.OS==='web'){
              this._pickImage();
            }else{
              this.PopupMediaRef.show(true);
            }
            }} 
            style={{borderWidth:1, borderColor:'#ededed', width:'100%', height:220, backgroundColor:'#ffffff', borderRadius:2, justifyContent:'center'}}>
            {image ?
              <View style={{flex:1, width:'100%', height:220}}>
                <Image source={{ uri: image }} style={{width:'100%', height:220}} />
              </View>
            :
            <Text style={{fontWeight:'bold', color:'#dddddd', fontSize:24, textAlign:'center'}}>ファイルを選択する</Text>
            }
          </TouchableOpacity>
          <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
          <PopupAppSetting ref={ref=>this.PopupRef=ref} />
        </View>
      );
    }

    return (
      <View style={styles.photoContainer}>
        <TouchableOpacity onPress={()=>{
          if(Platform.OS==='web'){
              this._pickImage();
            }else{
              this.PopupMediaRef.show(true);
            }
          }}>
          {image ?
            <View style={styles.photoThumb}>
              <Image source={{ uri: image }} style={styles.photoImg} />
            </View>
          :
          <FontAwesome name="cloud-upload" size={80} color="#cccccc" />
          }
        </TouchableOpacity>
        <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
        <PopupAppSetting ref={ref=>this.PopupRef=ref} />
      </View>
    );
  }
}

const styles=StyleSheet.create({
  photoContainer:{
    flex:1,
    justifyContent:'center',
    alignItems:'center',
    paddingTop:30,
    paddingBottom:30,
    paddingRight:15,
    paddingLeft:15,
  },
  photoThumb:{
    position:'relative',
    width:100,
    height:100,
    borderRadius:10,
    shadowColor:"#b6b6b6",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:1,
    shadowRadius:4,
    elevation:10,
    zIndex:10
  },
  photoImg:{
    width:100,
    height:100,
    borderRadius:10,
    borderWidth:5,
    borderColor:'#fff', 
  }
});