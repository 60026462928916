import CommonFunction from './CommonFunction';
import Constants from '../constants/Constants';
import Storage from './StorageHelper2';

export default class UserUtil{

  static getUserProfileLocal = async(appState)=>{
    let userProfile = appState?.userProfile;
    if(!userProfile || userProfile==undefined){
      userProfile = await CommonFunction.getProfile();
    }
    return userProfile;
  }

  static isMale = (userProfile)=>{
    if(userProfile!=undefined && Object.keys(userProfile).length > 0){
      if(userProfile.sex==Constants.MALE){
        return true;
      }
    }
    return false;
  }

  static isFeMale = (userProfile)=>{
    if(userProfile!=undefined && Object.keys(userProfile).length > 0){
      if(userProfile.sex==Constants.FEMALE){
        return true;
      }
    }
    return false;
  }

  static setsearchResult = async(appContext, data)=>{
    appContext.appState.searchResult = data;
    appContext?.setAppState(appContext.appState);
    await Storage.storeObjectData('searchResult', data);
  }

  static setIineRecommendResult = async(appContext, data)=>{
    appContext.appState.iineRecommendResult = data;
    appContext?.setAppState(appContext.appState);
    await Storage.storeObjectData('iineRecommendResult', data);
  }
  
  static getIineRecommendResult = async(appState)=>{
    if(appState.iineRecommendResult!=undefined){
      return appState.iineRecommendResult;
    }
    return await Storage.getObjectData('iineRecommendResult');
  }

  static setUserProfileLocal = async(appContext, data)=>{
    if(data!=undefined){
      appContext.appState.userProfile = data;
      appContext?.setAppState(appContext.appState);
      await Storage.storeObjectData('userProfile', data);
    }
  }

  static setAppStateUserProfile = async(appContext, data)=>{
    if(data!=undefined){
      appContext.appState.userProfile = data;
      appContext?.setAppState(appContext.appState);
    }
  }

  static convertUserProfile(userProfile){
    if(Object.keys(userProfile).length > 0){
      Object.keys(userProfile).map((key,index)=>{
        if(key=='height'){  // height
          userProfile[key] = {id: userProfile[key], value: userProfile[key]};
        }else if(key!='profileImage'){  // not image list
          if(userProfile[key].label!=undefined){
            userProfile[key].value = userProfile[key].label;
          }
          if(userProfile[key].body!=undefined){ // for introduction, tweet
            userProfile[key] = userProfile[key].body;
          }

          if(key=='vibe' || key=='personality'){ // for multiple select
            if(userProfile[key].list!=undefined){
              if(Array.isArray(userProfile[key].list)){
                userProfile[key].list.forEach((val,index)=>{
                  if(userProfile[key].list[index].label!=undefined){
                    userProfile[key].list[index].value = userProfile[key].list[index].label;
                  }
                });
              }

              userProfile[key] = userProfile[key].list;
            }
          }
        }
      });
    }
    return userProfile;
  }

  static getAccountAppState(response, context){
    let account = this.getDefaultAccountAppState();
    const { appState, setAppState } = context;
    
    if(response.activated!=undefined){
      account.activated = response?.activated;
      account.verified = response?.verified||0;
      account.subscription = response?.subscription;
      account.ageVerficationStatus = response?.ageVerficationStatus;
      account.ikuyoStatus = response?.ikuyoStatus;
      account.privilegeStatus = response?.privilegeStatus;
      account.level = response?.level;
      account.regProfileImage = response?.regProfileImage;

      appState.account = account;
      setAppState(appState);

      return account;
    }

    if(appState.account!=undefined) { 
      account.activated = appState.account?.activated;
      account.verified = appState.account?.verified;
      account.subscription = appState.account?.subscription;
      account.ageVerficationStatus = appState.account?.ageVerficationStatus;
      account.ikuyoStatus = appState.account?.ikuyoStatus;
      account.privilegeStatus = appState.account?.privilegeStatus;
      account.level = appState.account?.level;
      account.regProfileImage = appState.account?.regProfileImage;
    }

    return account;
  }

  static setAccountAppState(response, context){
    let account = this.getDefaultAccountAppState();
    const { appState, setAppState } = context;
    
    if(response.activated!=undefined){
      account.activated = response?.activated;
      account.verified = response?.verified||0;
      account.subscription = response?.subscription;
      account.ageVerficationStatus = response?.ageVerficationStatus;
      account.ikuyoStatus = response?.ikuyoStatus;
      account.privilegeStatus = response?.privilegeStatus;
      account.level = response?.level;
      account.regProfileImage = response?.regProfileImage;

      appState.account = account;
      setAppState(appState);
    }
  }

  static setAppState = async(appContext, name, value)=>{
    if(appContext!=undefined){
      appContext.appState[name] = value;
      appContext?.setAppState(appContext.appState);
      await Storage.storeObjectData(name, value);
    }
  }

  static getAppState = async(appContext, name)=>{
    let $return;
    if(appContext!=undefined){
      $return = appContext.appState[name];
    }

    if(!$return){
      $return = await Storage.getObjectData(name);
    }
    
    return $return;
  }

  static getDefaultAccountAppState(){
    const account = { 
      activated: 0, 
      verified: 0, 
      subscription: { 
        status: 0,
        purchased: 0
      }, 
      ageVerficationStatus: 0,
      ikuyoStatus: 0,
      privilegeStatus: 0,
      level: 0,
      regProfileImage: 0,
    };
    return account;
  }
}