import { NativeModulesProxy, EventEmitter, Subscription } from 'expo-modules-core';
import PurchaseModule from './src/PurchaseModule';
import { ChangeEventPayload } from './src/PurchaseModule.types';
const emitter = new EventEmitter(PurchaseModule);

// iOS
// イベント：プロダクトID取得完了
export function addGetProductItemListener(listenerAction) {
  emitter.addListener('getProductItemEvent', listenerAction);
}
// イベント：ストア購入処理完了
export function addCompPaymentEventListener(listenerAction) {
  emitter.addListener('compPaymentEvent', listenerAction);
}
// イベント；課金エラー発生時
export function addPurchaseErrorEventListener(listenerAction) {
  emitter.addListener('purchaseErrorEvent', listenerAction);
}

// イベント；リストア処理 課金中のアイテム取得完了
export function addGetRestoreItemEventListener(listenerAction) {
  emitter.addListener('getRestoreItemEvent', listenerAction);
}

export function removeGetProductItemListener() {
  emitter.removeAllListeners('getProductItemEvent');
}
// イベント：ストア購入処理完了
export function removeCompPaymentEventListener() {
  emitter.removeAllListeners('compPaymentEvent');
}
// イベント；課金エラー発生時
export function removePurchaseErrorEventListener() {
  emitter.removeAllListeners('purchaseErrorEvent');
}

// イベント；課金中のアイテム取得完了
export function removeGetRestoreItemEventListener() {
  emitter.removeAllListeners('getRestoreItemEvent');
}

// 【共通】初期化：トランザクション処理用オブザーバー（ログイン状態で実施）
export function purchaseModuleInit() {
  return PurchaseModule.purchaseModuleInit();
}

// 【共通】端末の課金が有効かを取得
export function isSystemPayment()  {
  return PurchaseModule.isSystemPayment();
}

// 【ポイント】初期化：ポイント購入用のプロダクトIDを取得する（ポイント購入画面遷移時に実施）
export function getProductionData() {
  
  return PurchaseModule.getProductionData();
}

// 【ポイント】ポイント購入処理を実施
export function pointPurchase(productId: string)  {
  return PurchaseModule.pointPurchaseOperation(productId);
}

// 【共通】プロダクションID別のバナー画像URLの取得
export function getProductDetail(productId: string, envFlg: boolean)  {
  return PurchaseModule.getProductDetail(productId, envFlg);
}

// 【サブスク】初期化：サブスク用のプロダクトIDを取得する（有料会員登録画面遷移時に実施）
export function getSubscProductionData(envFlg: Boolean) {
  return PurchaseModule.getSubscProductionData(envFlg);
}

// 【サブスク】ポイント購入処理を実施
export async function subscPurchase(productId: string)  {
  return await PurchaseModule.subscPurchaseOperation(productId);
}

// 【サブスク】ポイント購入処理を実施
export async function restoreTransaction()  {
  return await PurchaseModule.subscRestoreTransaction();
}

// Android
// Get the native constant value.
export const PI = PurchaseModule.PI;

export function getDeviceReceipt():  string {
  return PurchaseModule.getDeviceReceipt();
}

export async function queryPurchasesInApp() {
  return PurchaseModule.queryPurchasesInApp();
}

export async function queryPurchasesSubs() {
  return PurchaseModule.queryPurchasesSubs();
}

export async function checkPurchasedItem() {
  return PurchaseModule.checkPurchasedItem();
}

export function removeDeviceReceipt(): void  {
  return PurchaseModule.removeDeviceReceipt();
}
export function launchBillingFlow(productId: string) {
  return PurchaseModule.launchBillingFlow(productId);
}
export function launchBillingFlowSubs(productId: string) {
  return PurchaseModule.launchBillingFlowSubs(productId);
}

export async function setValueAsync(value: string) {
  return await PurchaseModule.setValueAsync(value);
}

export function setMode(value: string) {
  return PurchaseModule.setMode(value);
}

export function setProfileId(value: string) {
  return PurchaseModule.setProfileId(value);
}

export async function getProductIds() {
  return await PurchaseModule.getProductIds();
}

export async function getProductIdsSubs() {
  return await PurchaseModule.getProductIdsSubs();
}

export function addEventListener(listener: (event: ChangeEventPayload) => void): Subscription {
  return emitter.addListener<ChangeEventPayload>('onEvent', listener);
}

