import React,{ Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import HeadStyles from '../../styles/HeadStyles';
import TitleSetting from '../../components/common/TitleSetting';
import formStyles from '../../styles/FormStyles';
import CommonFunction from '../../utils/CommonFunction';
import ListStyles from '../../styles/ListStyles';

export default class CancelSubscriptionCompleteScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
    }
  }

  setHeader = () =>{
    this.title = '有料会員の解約';
    this.navigation?.setOptions({
      header: () => (
        <View style={HeadStyles.customHeader}>
          <View style={{flex:1, alignItems:'stretch'}}><TitleSetting title={this.title} numberOfLines={1} /></View>
        </View>
      )
    });
    
    // show bottom tab
    CommonFunction.showBottomTab(this.navigation);
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <View style={ListStyles.container}>
        <View style={{paddingTop:40,paddingLeft:18, paddingRight:18, marginBottom:75}}>
          <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848'}}>有料会員の解約が完了いたしました{"\n"}会員ステータス画面でも、ご確認いただけます</Text>
        </View>
        <View style={{alignItems:'center', width:"100%", }}>
          <TouchableOpacity onPress={()=>{
            this.navigation.navigate('MemberStatus');
          }} 
            style={[formStyles.submitBtn, {width:'75%', marginBottom:20,}]} >
            <Text style={{color:'#ffffff',fontWeight:'bold'}}>会員ステータスを見る</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}