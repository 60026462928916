import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, Linking, Dimensions
} from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import ApiConfig from '../../constants/ApiConfig';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class LawScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = '特定商取引法に基づく表記';
    if(windowWidth<=375) this.title = '　　'+this.title;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }  

  setHeader = () =>{
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title, this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  handleClick = (url) => {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={styles.blogInner}>
         
            <View style={{marginBottom:10}}><Text style={styles.titleInfo}>役務提供事業者</Text></View>
            <View>
              <Text style={[styles.txtInfo, {marginBottom:10,}]}>プロスゲイト株式会社</Text>
            </View>
         
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>代表者</Text></View>
            <View><Text style={styles.txtInfo}>佐藤裕久</Text></View>
          
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>所在地</Text></View>
            <View><Text style={styles.txtInfo}>兵庫県神戸市中央区雲井通3-2-3</Text></View>
          
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>お問い合わせ先</Text></View>
            <TouchableOpacity onPress={async()=>{
              const token = await CommonFunction.getEncryptAccessToken();
              if(Platform.OS==='web'){
                if(token){
                  window.location.href = ApiConfig.contact.url+'?token='+token;
                }else{
                  window.location.href = ApiConfig.contact.url;
                }
              }else{
                if(token){
                  this.navigation.navigate('Inquiry');
                }else{
                  this.navigation.navigate('InquiryNonMember');
                }
              }
            }}>
              <Text style={[styles.txtInfo,{textDecorationLine:'underline'}]}>{ApiConfig.contact.url}</Text>
            </TouchableOpacity>
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>電話番号</Text></View>
            <View><Text style={styles.txtInfo}>078-252-6667</Text></View>
         
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>サービス利用料金</Text></View>
            <Text style={[styles.txtInfo, {marginBottom:10}]}>クレジットカード決済</Text>
            <View style={styles.w180}><Text style={styles.txtInfo}>・有料会員</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>1ヶ月プラン</Text><Text style={styles.txtInfoR}>6,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>3ヶ月プラン</Text><Text style={styles.txtInfoR}>17,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>6ヶ月プラン</Text><Text style={styles.txtInfoR}>31,000円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>12ヶ月プラン</Text><Text style={styles.txtInfoR}>40,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>{"\n"}・ポイント</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>10ポイント</Text><Text style={styles.txtInfoR}>1,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>30ポイント</Text><Text style={styles.txtInfoR}>3,500円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>55ポイント</Text><Text style={styles.txtInfoR}>5,740円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>110ポイント</Text><Text style={styles.txtInfoR}>10,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>550ポイント</Text><Text style={styles.txtInfoR}>52,500円</Text></View>
            <Text style={[styles.txtInfo, {marginTop:10,marginBottom:10}]}>iPhoneアプリ内課金(AppleID決済)</Text>
            <View style={styles.w180}><Text style={styles.txtInfo}>・有料会員</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>1ヶ月プラン</Text><Text style={styles.txtInfoR}>7,500円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>3ヶ月プラン</Text><Text style={styles.txtInfoR}>19,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>6ヶ月プラン</Text><Text style={styles.txtInfoR}>34,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>12ヶ月プラン</Text><Text style={styles.txtInfoR}>45,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>{"\n"}・ポイント</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>10ポイント</Text><Text style={styles.txtInfoR}>1,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>30ポイント</Text><Text style={styles.txtInfoR}>3,500円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>55ポイント</Text><Text style={styles.txtInfoR}>5,700円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>110ポイント</Text><Text style={styles.txtInfoR}>10,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>550ポイント</Text><Text style={styles.txtInfoR}>52,800円</Text></View>
            <Text style={[styles.txtInfo, {marginTop:10,marginBottom:10}]}>Androidアプリ内課金(GooglePlay決済)</Text>
            <View style={styles.w180}><Text style={styles.txtInfo}>・有料会員</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>1ヶ月プラン</Text><Text style={styles.txtInfoR}>7,000円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>3ヶ月プラン</Text><Text style={styles.txtInfoR}>18,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>6ヶ月プラン</Text><Text style={styles.txtInfoR}>33,000円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>12ヶ月プラン</Text><Text style={styles.txtInfoR}>42,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>{"\n"}・ポイント</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>10ポイント</Text><Text style={styles.txtInfoR}>1,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>30ポイント</Text><Text style={styles.txtInfoR}>3,500円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>55ポイント</Text><Text style={styles.txtInfoR}>5,700円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>110ポイント</Text><Text style={styles.txtInfoR}>10,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>500ポイント</Text><Text style={styles.txtInfoR}>48,000円</Text></View>
            <Text style={[styles.txtInfo, {marginTop:10,marginBottom:10}]}>あと払い（ペイディ）</Text>
            <View style={styles.w180}><Text style={styles.txtInfoL}>・ポイント</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>10ポイント</Text><Text style={styles.txtInfoR}>1,400円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>30ポイント</Text><Text style={styles.txtInfoR}>3,500円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>55ポイント</Text><Text style={styles.txtInfoR}>5,740円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>110ポイント</Text><Text style={styles.txtInfoR}>10,800円</Text></View>
            <View style={styles.w180}><Text style={styles.txtInfoL}>550ポイント</Text><Text style={styles.txtInfoR}>52,500円</Text></View>
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>サービス利用料以外の必要料金</Text></View>
            <View><Text style={styles.txtInfo}>インターネット接続料金、通信料金等はお客様のご負担となります。</Text></View>
         
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>対価の支払い時期</Text></View>
            <View><Text style={styles.txtInfo}>課金申請及び更新手続き時</Text></View>
          
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>お支払い方法</Text></View>
            <View>
              <Text style={styles.txtInfo}>クレジットカード決済{"\n"}
                iPhone アプリ内課金（AppleID決済）{"\n"}
                Android アプリ内課金（GooglePlay決済）{"\n"}
                あと払い（ペイディ）
              </Text>
            </View>
         
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>支払い時期</Text></View>
            <View><Text style={styles.txtInfo}>
            代金決済時{"\n"}
            有料会員をお申込みの方は、解約手続きを行わない限り、決済内容記載の期間ごとに自動的かつ無期限に更新され、決済内容記載の請求金額が決済されます
            </Text></View>
         
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>有料会員の解約方法</Text></View>
            <View><Text style={styles.txtInfo}>
            ・購入手続完了後のお客様ご都合によるキャンセル（中途退会による場合を含みます）はできません。{"\n"}
            ・お客様が解約手続（自動更新の停止）を行わない限り、上記決済内容記載の期間ごとに自動的かつ無期限に更新され、上記決済内容記載の請求金額が決済されます。{"\n"}
            ・解約手続は、期間が終了する24時間前までに行ってください。それまでに解約手続を完了いただかないと自動更新される場合があります。{"\n"}
            ・上記決済内容記載の期間の途中において解約手続を行なった場合でも、お支払い済みの料金は返金されません（日割計算による返金もいたしかねます）。{"\n"}
            　期間・自動更新日の確認は、下記から可能です。{"\n"}
            　マイページ＞会員ステータス{"\n"}{"\n"}

            ・解約手続（自動更新の停止）は、下記から可能です。{"\n"}{"\n"}

            ■クレジットカード決済・あと払い（ペイディ）の場合{"\n"}{"\n"}

            マイページ＞会員ステータス＞有料会員の解約{"\n"}{"\n"}

            ■Apple ID決済の場合{"\n"}{"\n"}

            1.期間・自動更新日の確認解約手続（自動更新の停止）については、マイページ→会員ステータスよりご確認ください。{"\n"}{"\n"}

            2.Apple ID決済は、Apple社提供のサービスになるため、Pappy上で解約手続を行うことができません。{"\n"}
              解約手続の詳細については
              <TouchableOpacity onPress={()=>{this.handleClick('https://support.apple.com/ja-jp/HT202039')}}>
              <Text style={styles.aLink}>Apple社のヘルプ</Text>
              </TouchableOpacity>
              を直接ご参照ください。{"\n"}{"\n"}

            ■Google Play決済の場合{"\n"}{"\n"}

            1.期間・自動更新日の確認解約手続（自動更新の停止）については、マイページ→会員ステータスよりご確認ください。{"\n"}{"\n"}

            2.Google Play決済は、Google社提供のサービスになるため、Pappy上で解約手続を行うことができません。{"\n"}
              解約手続の詳細については
              <TouchableOpacity onPress={()=>{this.handleClick('https://support.google.com/googleplay/answer/7018481')}}>
              <Text style={styles.aLink}>Google社のヘルプ</Text>
              </TouchableOpacity>
              を直接ご参照ください。{"\n"}

            </Text></View>
         
          <View style={{marginBottom:20}}>
            <View style={styles.marginT20B10}><Text style={styles.titleInfo}>キャンセル・返金</Text></View>
            <View><Text style={styles.txtInfo}>
            購入手続き完了後のお客様ご都合によりキャンセル・返金(中途退会により場合も含みます)はできません。{"\n"}
            ※契約期間中にご利用されたなかった場合も。日割計算による減額は行いません。
              </Text></View>
          </View>

        </View>
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:15,
  },
  marginT20B10: {
    marginTop:20,
    marginBottom:10,
  },
  w180: {
    flexDirection: 'row',
    width: 180,
  },
  titleInfo: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848',
    textAlign:'left',
  },
  txtInfo: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
    textAlign:'left',
  },
  txtInfoL: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
    width: '50%',
    textAlign:'left',
  },
  txtInfoR: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
    width: '50%',
    textAlign:'right',
  },
  aLink:{
    color:'#0070c9'
  }
});
