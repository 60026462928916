import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Platform, Dimensions } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import * as RootNavigation from '../../navigation/RootNavigation';

const windowWidth = Dimensions.get('window').width;

export default class PaymentVerify2 extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
    this.hideAction = null;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }

    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  render() {
    if(!this.state.show) return null;

    const imageSource = Platform.OS==='web'?require('../../assets/images/bg-openchat-web.jpg'):require('../../assets/images/bg-openchat.jpg');
    const popupWidth = windowWidth * 0.77
    const newHeight = popupWidth * 577 / 822


    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
            <ImageBackground resizeMode={'cover'} 
              style={{width: Platform.OS==='web'?popupWidth:'100%', height: Platform.OS==='web'?newHeight:201}} 
              imageStyle={{borderTopLeftRadius:15, borderTopRightRadius:15}} 
              source={imageSource}
            >
              {Platform.OS !== 'web' &&
              <View style={{position: 'absolute', bottom: 0, padding: 10, backgroundColor: 'rgba(51,189,218, 0.85)', width: '100%', height: 65}}>
                <Text style={{fontSize: 16, fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 22}}>
                  メッセージの開封・返信には{"\n"}有料会員登録が必要です
                </Text>
              </View>
              }
            </ImageBackground>
               
            <View style={styles.btnArea}>
              <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                this.hide();
                this.hideAction = null;
                this.navigation?.navigate('Auth', {screen:'Purchase'});
              }}>
                {Platform.OS==='web'?
                <View style={{ width: '100%',height:'100%' }}>
                  <Text style={[styles.txtBtnBlue, {justifyContent: 'center', position: 'absolute',margin: 'auto', top:0,left:0,right:0,bottom:0}]}>有料プランの詳細を確認する</Text>
                  <Text style={[styles.txtBtnBlue2, {position: 'absolute',right:0}]}>{'>'}</Text>
                </View>
                :
                <Text style={styles.txtBtnBlue}>有料会員に登録する</Text>
                }
              </TouchableOpacity>
          
              <TouchableOpacity style={Platform.OS==='web'?styles.btnCancelWeb:styles.btnCancel} onPress={()=>{
                this.hide();
                if(this.hideAction){
                  this.hideAction();
                  this.hideAction = null;
                }
              }}>
                {/*<Text style={styles.txtBtnCancel}>キャンセル</Text>*/}
                <Text style={Platform.OS==='web'?styles.txtBtnCancelWeb:styles.txtBtnCancel}>閉じる</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:Platform.OS==='web'?'auto':332, 
    width:'77%',
    alignSelf:'center', 
    borderRadius:15
  },
  btnBlue:{
    marginBottom:10,
    width:'100%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:Platform.OS==='web'?16:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  txtBtnBlue2:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'right'
  },
  btnCancel:{
    width:'100%',
    padding:8,
    height:42,
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#a2aaab',
    textAlign:'center',
  },

  btnCancelWeb:{
    width:'65%',
    padding:2,
    height:30,
    marginTop:5,
    marginBottom:10,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancelWeb:{
    fontSize:12,
    fontWeight:'bold',
    lineHeight:26,
    textAlign:'center',
  },
  btnArea: {
    paddingTop:25,
    paddingBottom:Platform.OS==='web'?10:25,
    paddingRight:27,
    paddingLeft:27,
  },
});