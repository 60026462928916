import React,{ Component } from 'react';
import { StyleSheet, Text, View, Platform, StatusBar, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import HeadStyle from '../../styles/HeadStyles';
import CommonFunction from '../../utils/CommonFunction';
import OptionData  from '../../constants/option/OptionData.json';
import formStyles from '../../styles/FormStyles';
import Leave19Api from '../../services/api/Leave19Api';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import AuthHelper from '../../utils/AuthHelper';
import HeaderOptions from '../../constants/HeaderOptions';
import StorageHelper from '../../utils/StorageHelper2';

const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;

export default class WithdrawConfirmScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reasonCategory: '',
      category_txt: '',
      reasonDetail:'',
      opinionImprovement:'',
      opinionFunction:'',
      opinionOther:'',      
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();

      const reasonCategory = this.props?.route?.params?.reasonCategory; 
      const reasonDetail = this.props?.route?.params?.reasonDetail; 
      const opinionImprovement = this.props?.route?.params?.opinionImprovement; 
      const opinionFunction = this.props?.route?.params?.opinionFunction; 
      const opinionOther = this.props?.route?.params?.opinionOther; 
      let category_txt = "";
      OptionData.Master.Witdrawal.Items.forEach((item) => {
        if(item.id==reasonCategory) category_txt=item.value;
      });

      this.setState({
        reasonCategory: reasonCategory,
        category_txt: category_txt,
        reasonDetail: reasonDetail,
        opinionImprovement: opinionImprovement,
        opinionFunction: opinionFunction,
        opinionOther: opinionOther
      });
    }
  }

  setHeader = () =>{
    this.title = '退会手続き';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }    

  withdraw = async() =>{
    try{
      CommonFunction.overLay();
      const leaveResponse = await Leave19Api.LeaveDeleteAccount(
        this.state.reasonCategory, 
        this.state.reasonDetail, 
        this.state.opinionImprovement, 
        this.state.opinionFunction, 
        this.state.opinionOther
      );

      //check response status
      if(!CommonFunction.checkResponseStatus(leaveResponse, false)) return;

      if(leaveResponse!=undefined){
        if(leaveResponse?.status==1){          
          this.popup?.showPopup();   
        }
      }
      
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    return (
      <View style={[styles.container, HeadStyle.customHeaderTop]}>
        <ScrollView style={styles.scrollContainer}>
          <View style={{paddingBottom:30}}>
            <Text style={[styles.font13, styles.title, {marginBottom:16}]}>お客様が退会される理由</Text>                
            <Text style={styles.font13}>{this.state.category_txt}</Text>
          </View>          

          <View style={{paddingBottom:30}}>
            <View style={[styles.title, {flexDirection:'row',marginBottom:16}]}>
              <Text style={[styles.font13, {fontWeight:'bold'}]}>具体的な退会理由</Text>
              <Text style={[styles.font13, {fontWeight:'bold',color:'red'}]}>（必須）</Text>
            </View>
            <View style={{}}>
              <Text style={styles.confirmTxt}>{this.state.reasonDetail}</Text>
            </View>
          </View>  

          <View style={{paddingBottom:30}}>
            <Text style={[styles.font13, {fontWeight:'bold'}]}>Pappyに改善してほしいポイントを教えてください</Text>
            <View 
              style={[{paddingTop: 10}]}>
              <Text style={styles.confirmTxt}>{this.state.opinionImprovement}</Text>
            </View>
          </View>

          <View style={{paddingBottom:30}}>
            <Text style={[styles.font13, {fontWeight:'bold'}]}>Pappyに欲しかった機能があれば教えてください</Text> 
            <View 
              style={[{paddingTop: 10}]}>
              <Text style={styles.confirmTxt}>{this.state.opinionFunction}</Text>
            </View>
          </View>

          <View style={{paddingBottom:30}}>
            <Text style={[styles.font13, {fontWeight:'bold'}]}>その他、ご意見ご感想</Text>
            <View 
              style={[{paddingTop: 10}]}>
              <Text style={styles.confirmTxt}>{this.state.opinionOther}</Text>
            </View>
          </View>

          <View style={[formStyles.buttonPanelSetting, styles.submitPanel]}>
            <TouchableOpacity onPress={()=>{
              this.navigation.navigate('MyPage');
            }} 
              style={[formStyles.submitBtn, {width:'87%', marginBottom:20}]} >
              <Text style={styles.txtBtnBlue}>Pappyを続ける</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{
              this.withdraw();
            }} 
              style={[formStyles.submitBtn, {backgroundColor:'#b1bfc2', shadowColor: "#798789", width:'87%'}]}>
              <Text style={{color:'#ffffff',fontWeight:'bold'}}>退会する</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>

        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {
              //this.popup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12}}>
            <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24}}>退会しました</Text>
            <TouchableOpacity onPress={()=>{
              this.popup?.hidePopup();
              AuthHelper.logout();
              // ログイン情報を初期化
              const removeLoginKeys = ['LoginInfoMail','LoginInfoPass'];
              StorageHelper.removeItems(removeLoginKeys);
              }} style={[formStyles.btnGray, {marginTop:20}]}>
              <Text style={formStyles.txtBtnGray}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  scrollContainer: {
    backgroundColor:'#ffffff',
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:15,
    paddingRight:15,
    ...Platform.select({
      ios: {
        flexGrow:1,
      },
      android: {
        flexGrow:1,
      },
      web:{
        height: scrollHeight,
    }})
  },
  desc: {
    width:'100%',
    paddingBottom:20,
  },
  title:{
    fontWeight:'bold',
    width: '70%'
  },
  txtLimit:{
    fontSize:13,
    color: '#98a3a5',
    width: '30%',
    textAlign: 'right',
    alignItems: 'baseline'
  },  
  font13: {
    fontSize:13,    
    lineHeight:21,
    color:'#454545'
  },
  confirmTxt: {
    fontSize:13,
    lineHeight:21,
    color:'#555555',
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  submitPanel:{
    flexDirection:'column', 
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    ...Platform.select({
      ios:{
        marginBottom:50
      },
      android:{
        marginBottom:50
      }
    })
  }
});