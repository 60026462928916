import React,{ Component } from 'react';
import { 
  View, FlatList, StyleSheet, Text, StatusBar, TouchableOpacity, Platform
} from 'react-native';
import { Entypo,AntDesign } from '@expo/vector-icons'; 
import SafeAreaStyles from '../styles/SafeAreaStyles';
import { AppContext } from '../AppContext';

// Single Selected
export default class AdvanceSearchListSingleScreen extends Component { 
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      dataSource: {},
      selectedId: {}
    }
  }
  
  componentDidMount(){
    this.values = this.props.values ? this.props.values : {};
    this.dataSource = this.props.items ? this.props.items : [];
    this.childItems = this.props.childItems ? this.props.childItems : [];
    this.defaultIndex = this.dataSource.findIndex(
      item => 0 == item.id
    );

    if(this.props.novalue!=undefined){
      this.novalue = this.props.novalue;
    }else{
      this.novalue = '';
    }

    if(typeof this.values==='object' && Object.keys(this.values).length == 0){
      //default id=0
      if(this.defaultIndex!=-1){
        let defaultValue = this.dataSource[this.defaultIndex];
        this.values = defaultValue;
      }
    }
    
    this.setState({
      dataSource: this.dataSource,
      selectedId: this.values,
      ShowId: []
    });
  }

  toggle = (id, childItemsIndex) =>{
    let {ShowId} = this.state;
    if((ShowId[id]===false || typeof ShowId[id]==='undefined') ){
      ShowId[id] = true;
    }else{
      if(childItemsIndex!=-1){
        ShowId[id] = true;
      }else{
        ShowId[id] = false;
      }
    }
    this.setState({ShowId: ShowId});
  }
  
  selectItem = data => {
    let selectItem = data.item;
    if(this.defaultIndex>-1){
      // allow un-check
      if(this.state.selectedId.id==selectItem.id){
        selectItem = this.dataSource[this.defaultIndex];
      }
    }
    this.setState({ selectedId: selectItem});
  };

  handleBack(){
    const selectedId = this.state.selectedId;
    this.props.onChange(this.props.fieldName, selectedId);
    let fieldNameTxt = this.props.fieldName+'Txt';
    let value = selectedId.value;
    if(selectedId.childValue){
      value = selectedId.childValue;
    }
    this.props.onChange(fieldNameTxt, value);

    if(this.props.setDataSource){
      this.props.setDataSource(selectedId, selectedId.value);
    }

    return selectedId;
  }

  handleCancel(){
    let selectedId = {};
    this.setState({selectedId:selectedId});
    this.props.onChange(this.props.fieldName, selectedId);
    let fieldNameTxt = this.props.fieldName+'Txt';
    this.props.onChange(fieldNameTxt, '');
    if(this.props.setDataSource){
      this.props.setDataSource(selectedId, '');
    }
  }

  handleCheck(itemId) {
    return itemId==this.state.selectedId.id;
  }

  renderItem = (data) => {
    let selected = this.handleCheck(data.item.id);
    let value = data.item.value;
    if(data.item.id==0 && this.novalue){
      value = this.novalue;
    }

    let { ShowId } = this.state;
    let toggle = ShowId[data.item.id];
    let childItems = [];
    let childItemsIndex = -1;
    if(this.childItems?.parent?.length>0){
      let parent = this.childItems.parent?.filter(function (item) {
        return item.id == data.item.id;
      });
      if(Array.isArray(parent)){
        if(parent.length>0 && parent[0]?.value!=undefined) {
          let childsValueId = parent[0].value;
          childsValueId?.forEach((val,index)=>{
            let childItem = this.childItems?.Items?.filter(function (item) {
              return item.id == val;
            });
            if(childItem[0]!=undefined){
              let childItemValueId = parent[0]?.id +"|"+ childItem[0]?.id;
              let childValue = value+'\n\t↳'+childItem[0]?.value;
              let childItemValue = {"id":childItemValueId, value:childItem[0]?.value, childValue:childValue};
              childItems.push(childItemValue);
            } 
          });
          
          childItemsIndex = childItems.findIndex(
            item => this.state.selectedId.id == item.id
          );
          if(childItemsIndex!=-1) {
            toggle = true;
          }
        }
      }
    }

    let rightIcon = 'down';
    if(toggle=== true) rightIcon = 'up';

    return (
      <>
      <View style={selected?styles.selectedList:styles.notSelectedList}>
        <View style={[styles.sectionInside, selected?styles.selectedList:styles.notSelectedList]}>
          <TouchableOpacity onPress={()=>{
            if(childItems.length > 0){
              this.toggle(data.item.id, childItemsIndex);
            }else{
              this.selectItem(data);
            }
          }} style={{flexDirection:'row', width:'100%'}}>
            <View style={styles.formLabel}>
              <Text style={styles.label}>{value}</Text>
            </View>
            <View style={styles.formSelected}>
            {selected?
              <View style={styles.circleSelectCheck}><Entypo name="check" style={styles.icSelectCheck}/></View>
            :
              null
            }
            {childItems.length > 0 && <AntDesign name={rightIcon} style={styles.rightIcon} /> }
            </View>
          </TouchableOpacity>
        </View>
      </View>

      {toggle=== true && ( 
        childItems?.map((subItem,subIndex) => {
        let selected = this.handleCheck(subItem.id);
        return ( 
          <View key={subIndex.toString()} style={selected?styles.selectedList:styles.notSelectedList2}>
            <View style={[styles.sectionInside, selected?styles.selectedList:styles.notSelectedList2]}>
              <TouchableOpacity onPress={()=>{
                let data = {item:subItem};
                this.selectItem(data);
              }} style={{flexDirection:'row', width:'100%'}}>
                <View style={[styles.formLabel, {marginLeft:30}]}>
                  <Text style={styles.label}>{subItem.value}</Text>
                </View>
                <View style={styles.formSelected}>
                {selected?
                  <View style={styles.circleSelectCheck}><Entypo name="check" style={styles.icSelectCheck}/></View>
                :
                  null
                }       
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )
      }))}

    </>
    );
  }

  render() {    
    const insets = this.context?.appState?.insets;
    return (
      <View  style={styles.container}>
        <FlatList
          data={this.state.dataSource}
          renderItem={item => this.renderItem(item)}
          keyExtractor={item => item.id}
          //extraData={this.state.selectedId}
          initialNumToRender={this.state.dataSource?.length}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex:1,
    width:'100%',
  },
  section:{
    marginTop: 20,
  },
  sectionInside:{
    backgroundColor:'#fff',
    borderBottomWidth:.55,
    borderBottomColor:'#e6e6e6',
    flexDirection:'row', 
    alignSelf:'stretch',
    minHeight:44,
    ...Platform.select({
      android:{
        borderBottomWidth:.7,
      }
    })
  },
  formLabel:{
    flex: 1,  
    alignSelf:'stretch', 
    alignItems:'flex-start',
    marginLeft:16
  },
  formSelected:{
    alignItems:'flex-end',
    justifyContent:'center',
    maxWidth:190,
    fontSize:14,
    lineHeight:19,
    color:'#5cc8ca',
    marginRight:20
  },
  label:{
    fontSize: 14,
    lineHeight: 19,
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    color:'#484848'
  },
  checkIcon:{
      width:20,
      height:20
  },
  selectedList:{
    backgroundColor:'#eef1f2'
  },
  notSelectedList:{
    backgroundColor:'#fff'
  },
  notSelectedList2:{
    backgroundColor:'#f9f9f9'
  },
  circleSelectCheck: {
    display:'flex',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:21,
    height:21,
    backgroundColor:'#5cc8ca',
    borderRadius:9999,
  },
  icSelectCheck: {
    fontSize:14, 
    color:'#fff'
  },
  rightIcon:{
    right:10,
    fontSize:16,
    color:'#484848',
  },
});
