import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import ApiConfig from '../../constants/ApiConfig';

/**
 * API: 03_API仕様/レスポンス仕様書/24_ポイント/ポイント機能 レスポンス仕様.xlsx
 */
export default class Point24Api extends ApiPappy{
  //1.1.1.ポイント履歴取得
  static async getHistory(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Point/Get/History/",
        {
          count: (values?.count!=undefined)?values.count:30,
          page: (values?.page!=undefined)?values.page:1
        },
        true
      );
      if(__DEV__) console.log('/Point/Get/History/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Point/Get/History/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.いいね交換
  static async putExchangeGood(value, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Point/Put/ExchangeGood/",
        {point: value},
        true
      );
      if(__DEV__) console.log('/Point/Put/ExchangeGood/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Point/Put/ExchangeGood/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}