import React,{ Component } from 'react';
import { StyleSheet, Text, View, Platform, TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import CommonFunction from '../../utils/CommonFunction';
import Constants from '../../constants/Constants';
import SwitchToggle from "react-native-switch-toggle";
import Setting17Api from '../../services/api/Setting17Api';
import PopupWrapper from "../../components/common/PopupWrapper";
import Msg from '../../constants/option/Msg.json';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import UserVerify from '../../utils/UserVerify';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class PrivateModeScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOn: false,
      account: UserUtil.getDefaultAccountAppState()
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
      
      // from back page
      this.subscribeFocus = this.navigation.addListener('focus', () => {
        this.checkAccountState();
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }  

  setHeader(){
    this.title = 'プライベートモード設定';
    if(this.props.setHeader!=undefined){
      this.props?.setHeader(this.title, this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  checkAccountState = async()=>{
    const {appState, setAppState} = this.context;
    const account = appState?.account;
    this.myProfile = await UserUtil.getUserProfileLocal(appState);

    this.setState({ account: account },()=>{
      this.getDatatFromApi();
    });
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.navigation,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  checkPayment = ()=>{
    if(UserVerify.checkPaymentAppState(this.context)) return true;

    return UserVerify.checkPayment({
      paymentFlag: this.state.account?.subscription?.status,
      navigation: this.navigation
    });
  }

  validateAuthen = ()=>{
    if(this.state.account?.activated==0) return false;
    if(this.state.account?.ageVerficationStatus!=2) return false;

    if(this.myProfile?.sex==Constants.MALE){
      if(this.state.account?.subscription?.status==0) return false;
    }
    
    return true;
  }

  getToggleValue = (val)=>{
    if(val==1 || val==true) return true;
    else return false;
  }
  
  async getDatatFromApi(){
    if(this._isMounted){
      try{
        const privateResponse = await Setting17Api.GetPrivate();
        //check response
        if(!CommonFunction.checkResponseStatus(privateResponse)) return;   

        if(privateResponse!=undefined){
          if(privateResponse?.status==1){
            const data = privateResponse.response;
            const account = UserUtil.getAccountAppState(privateResponse, this.context);
            this.setState({isOn: this.getToggleValue(data?.flag), account: account});
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({ loading: false });
      }
    }
  }

  handleOnPress = async() =>{
    const value = !this.state.isOn;
    this.setState({isOn: value});

    try{
      CommonFunction.overLay();
      const saveResponse = await Setting17Api.PutPrivate(
        value==true?1:0
      );
      //check response
      if(!CommonFunction.checkResponseStatus(saveResponse)) return;

      if(saveResponse!=undefined){
        if(saveResponse.status==1){
          PopupWrapper.showMessage(Msg.Update.Success); // complete popup
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator />

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <View style={styles.blogInner}>
          <View style={styles.blockLabel}><Text style={styles.labelText}>プライベートモード設定</Text></View>
          <View style={styles.blockFormSwitch}>
            <SwitchToggle
              switchOn={this.state.isOn}
              onPress={() => this.handleOnPress()}
              circleColorOn='#fefefe'
              circleColorOff='#fefefe'
              backgroundColorOn='#5cc8ca'
              backgroundColorOff='#fdfdfd'
              containerStyle={{
                marginTop: 0,
                width: 47,
                height: 29,
                borderRadius: 999,
                padding: this.state.isOn? 2 : 0,
                borderWidth: this.state.isOn? 1 : 2,
                borderColor: this.state.isOn? '#5cc8ca' : '#e4e4e4'
              }}
              circleStyle={{
                width: this.state.isOn? 26 : 29,
                height: this.state.isOn? 26 : 29,
                borderRadius: 999,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: this.state.isOn? 0.22 : 0.48,
                shadowRadius: this.state.isOn? 2.22 : 4.8,
                elevation: this.state.isOn? 3 : 3,
              }}
            />
          </View>
        </View>
        <View style={styles.bottomPanel}>
          <TouchableOpacity 
            style={{flexDirection:'row', alignContent:'flex-end', alignItems:'flex-end', alignSelf:'flex-end'}}
            onPress={() => {this.navigation.navigate(this.myProfile?.sex==Constants.MALE?'MalePrivateMode':'FemalePrivateMode')}}
            >
            <Text style={{paddingRight:10, fontSize:12, color:'#484848'}}>プライベートモードの詳細を見る</Text>
            <Entypo name={'chevron-thin-right'} style={{fontSize:16, color:'#c2c2c2'}}/>
          </TouchableOpacity>
        </View>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    paddingTop:8,
    paddingBottom:8,
    paddingLeft:20,
    paddingRight:12,
    flexDirection:'row',
    width:'100%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },  
  blockLabel:{
    flex:3,
    alignItems:'flex-start',
  },
  labelText:{
    fontSize: 14,
    fontWeight:'normal',
    color:'#333',    
  },
  blockFormSwitch:{
    flex:1,
    alignItems:'flex-end',
    ...Platform.select({
      ios: { },
      android: { },
      default: { },
    }),
  },
  bottomPanel:{
    paddingTop:15, 
    paddingRight:15,
    justifyContent:'center'
  },
});
