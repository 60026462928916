import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import SettingScreen from '../../screens/SettingScreen';
import MailInputScreen from '../../screens/setting/MailInputScreen';
import MailValidateScreen from '../../screens/setting/MailValidateScreen';
import NotifInputScreen from '../../screens/setting/NotifInputScreen';
import PassInputScreen from '../../screens/setting/PassInputScreen';
import BlockListScreen from '../../screens/setting/BlockListScreen';
import HideListScreen from '../../screens/setting/HideListScreen';
import RuleScreen from '../../screens/setting/RuleScreen';
import PolicyScreen from '../../screens/setting/PolicyScreen';
import LawScreen from '../../screens/setting/LawScreen';
import SettlementScreen from '../../screens/setting/SettlementScreen';
import TermsScreen from '../../screens/setting/TermsScreen';
import MalePrivateModeScreen from '../../screens/MalePrivateModeScreen';
import FemalePrivateModeScreen from '../../screens/FemalePrivateModeScreen';
import PrivateModeScreen from '../../screens/setting/PrivateModeScreen';
import SecretModeScreen from '../../screens/setting/SecretModeScreen';
import WithdrawScreen from '../../screens/withdraw/WithdrawScreen';
import WithdrawInputScreen from '../../screens/withdraw/WithdrawInputScreen';
import WithdrawConfirmScreen from '../../screens/withdraw/WithdrawConfirmScreen';
import CancelSubscriptionScreen from '../../screens/cancelsubscription/CancelSubscriptionScreen';
import CancelSubscriptionCompleteScreen from '../../screens/cancelsubscription/CancelSubscriptionCompleteScreen';
import HeadStyles from '../../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';
import CommonFunction from '../../utils/CommonFunction';
import PointShoppingStack from './PointShoppingStack';
import LogoNoMargin from '../../components/common/LogoNoMargin';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import InquiryScreen from '../../screens/InquiryScreen';
import InquiryNonMemberScreen from '../../screens/InquiryNonMemberScreen';
import TransitionSpec from '../../constants/TransitionSpec';
import NotifInfo from '../../screens/NotifInfoScreen';
const Stack = createStackNavigator();

export default class SettingStack extends Component {
  constructor(props){
    super(props);

    // setting previous route without bottom tab
    this.prevRoute = ['Purchase'];
  }

  componentDidMount(){
    CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, 'none');
  }

  componentWillUnmount(){
    this.checkBottomTab();
  }

  checkBottomTab=()=>{
    let showBottomTab = true;
    const routes = this.props.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
    }
    if(showBottomTab) {
      CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
    }
  }

  goBack = (navigation)=>{
    if(navigation!=undefined){
      navigation?.goBack()
    }else{
      this.props.navigation?.goBack();
    }
  }

  setHeader = (title, navigation, customHeader=null)=>{
    if(customHeader){
      this.props.navigation?.setOptions(customHeader);
    }else{
      this.props.navigation?.setOptions({
        header: () => (
          <View style={HeadStyles.customHeader}>
            <View style={[HeadStyles.touchIcBack,{flexDirection:'row',left:16}]}>
              <TouchableOpacity onPress={()=> this.goBack(navigation)}>
                <Entypo name={'chevron-thin-left'} style={HeadStyles.icBack2}/>
              </TouchableOpacity>
              <LogoNoMargin/>
            </View>
            <View style={{flex:1, alignItems:'stretch'}}><Text style={[HeadStyles.headerTitlePage]}>{title}</Text></View>
          </View>
        )
      });
    }
  }

  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName='Setting' screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        <Stack.Screen name="Setting" options={{headerShown: false, title:'Setting'}}>
          {props => <SettingScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="MailInput" options={{headerShown: false}}>
          {props => <MailInputScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="MailValidate" options={{headerShown: false}}>
          {props => <MailValidateScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="NotifInput" options={{headerShown: false}}>
          {props => <NotifInputScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="PassInput" options={{headerShown: false}}>
          {props => <PassInputScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="BlockList" options={{headerShown: false}}>
          {props => <BlockListScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="HideList" options={{headerShown: false}}>
          {props => <HideListScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="Rule" options={{headerShown: false}}>
          {props => <RuleScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="Policy" options={{headerShown: false}}>
          {props => <PolicyScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="Law" options={{headerShown: false}}>
          {props => <LawScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="Settlement" options={{headerShown: false}}>
          {props => <SettlementScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="Terms" options={{headerShown: false}}>
          {props => <TermsScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="PrivateMode" options={{headerShown: false}}>
          {props => <PrivateModeScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="SecretMode" options={{headerShown: false}}>
          {props => <SecretModeScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="MalePrivateMode" options={{headerShown: false}}>
          {props => <MalePrivateModeScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="FemalePrivateMode" options={{headerShown: false}}>
          {props => <FemalePrivateModeScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="Withdraw" options={{headerShown: false}}>
          {props => <WithdrawScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="WithdrawInput" options={{headerShown: false}}>
          {props => <WithdrawInputScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="WithdrawConfirm" options={{headerShown: false}}>
          {props => <WithdrawConfirmScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="CancelSubscription" options={{headerShown: false}}>
          {props => <CancelSubscriptionScreen {...props} setHeader={this.setHeader}/>}
        </Stack.Screen>
        <Stack.Screen name="CancelSubscriptionComplete" options={{headerShown: false}}>
          {props => <CancelSubscriptionCompleteScreen {...props} setHeader={this.setHeader} />}
        </Stack.Screen>
        <Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>
        <Stack.Screen name="Inquiry" options={{headerShown: false}}>
          {props => <InquiryScreen {...props} setHeader={this.setHeader} />}
        </Stack.Screen>
        <Stack.Screen name="InquiryNonMember" options={{headerShown: false}}>
          {props => <InquiryNonMemberScreen {...props} setHeader={this.setHeader} />}
        </Stack.Screen>
        <Stack.Screen name="NotifInfo" options={{headerShown: false}}>
          {props => <NotifInfo {...props} setHeader={this.setHeader} />}
        </Stack.Screen>
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}
