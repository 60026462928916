import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, Linking, Dimensions
} from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import ApiConfig from '../../constants/ApiConfig';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class SubscriptionCancelMethodScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = '解約方法';
    if(windowWidth<=375) this.title = this.title;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }  

  setHeader = () =>{
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title, this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  handleClick = (url) => {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={styles.blogInner}>
         
            <View style={{marginBottom:10}}><Text style={styles.titleInfo}>▼Apple決済の解約方法</Text></View>
            <View>
              <Text style={[styles.txtInfo, {marginBottom:10,}]}>
                Apple IDで購入された場合、お客様ご自身で解約のお手続きが必要です。{"\n"}
                解約手順は
                <TouchableOpacity onPress={()=>{this.handleClick('https://support.apple.com/ja-jp/HT202039')}}>
              <Text style={[styles.aLink, {marginBottom:-3,}]}>AppStoreのヘルプページ</Text>
              </TouchableOpacity>
                をご参照ください。{"\n"}
                有料会員を解約してもすぐに「無料会員」にはならず、購入した期間はPappyの有料機能が利用できます。
              </Text>
            </View>
            <View style={{marginBottom:10}}><Text style={styles.titleInfo}>▼google Play決済の解約方法</Text></View>
            <View>
              <Text style={[styles.txtInfo, {marginBottom:10,}]}>
              Google Playで購入された場合、お客様ご自身で解約のお手続きが必要です。{"\n"}
                解約手順は
                <TouchableOpacity onPress={()=>{this.handleClick('https://support.google.com/googleplay/answer/7018481')}}>
              <Text style={[styles.aLink, {marginBottom:-3,}]}>GooglePlayのヘルプページ</Text>
              </TouchableOpacity>
                をご参照ください。{"\n"}
                有料会員を解約してもすぐに「無料会員」にはならず、購入した期間はPappyの有料機能が利用できます。
              </Text>
            </View>
            <View style={{marginBottom:10}}><Text style={styles.titleInfo}>▼クレジットカード決済・あと払い（ペイディ）の解約方法</Text></View>
            <View>
              <Text style={[styles.txtInfo, {marginBottom:30,}]}>
                1.ブラウザ版Pappyにログイン{"\n"}
                2.「マイページ」の「会員ステータス」をタップ{"\n"}
                3.「有料会員の解約」をタップ{"\n"}
                4.「有料会員」画面下部の「解約する」をタップ{"\n"}
                マイページ＞各種設定＞お問い合わせ＞退会からも解約と同時に退会することが可能です。{"\n"}
                解約と同時に「有料会員」の期間が残っていても「無料会員」に切り替わります。
              </Text>
            </View>
            <View>
              <Text style={[styles.txtInfo, {marginBottom:10,}]}>
                ・アプリをアンインストールをしても、有料会員は解約されません。{"\n"}
                ・自動更新は期限が切れる24時間以内に自動的に課金される仕組みです。{"\n"}
                ・自動更新をご希望ではない場合は期限が切れる24時間前までに解約を完了させてください。{"\n"}
                ・更新日前に解約のお手続きをされた場合、残りの期間については月割り・日割りでの返金は行っておりません。
              </Text>
            </View>
        </View>
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:15,
  },
  marginT20B10: {
    marginTop:20,
    marginBottom:10,
  },
  w180: {
    flexDirection: 'row',
    width: 180,
  },
  titleInfo: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848',
    textAlign:'left',
  },
  txtInfo: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
    textAlign:'left',
  },
  txtInfoL: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
    width: '50%',
    textAlign:'left',
  },
  txtInfoR: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
    width: '50%',
    textAlign:'right',
  },
  aLink:{
    color:'#0070c9'
  }
});
