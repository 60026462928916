import SMSManager from "./SMSManager";

export default class SMSWrapper {
  static showSMS(...args){
    const ref = SMSManager?.getDefault();
    ref?.show(...args);
  }

  static hideSMS(){
    const ref = SMSManager?.getDefault();
    ref?.hide();
  }

  static isShow(){
    const ref = SMSManager?.getDefault();
    return ref?.isShow();
  }
}