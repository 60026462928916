import React,{ Component } from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView, Animated, Platform } from 'react-native';
import { AntDesign, Entypo } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import { LinearGradient } from 'expo-linear-gradient';
import { AppContext } from '../AppContext';
import ActivityIndicator from '../components/common/ActivityIndicator';
import UserUtil from '../utils/UserUtil';
import CalendarApi from '../services/api/Calendar11Api';
import OnlineStatus from '../components/common/OnlineStatus';
import Constants from '../constants/Constants';
import OptionData  from '../constants/option/OptionData.json';
import {
  checkValue, Block16, Block16Male, Block17, Block18, Block18Male, Block19, Block20
} from '../components/common/UserInfo';
import CommonFunction from '../utils/CommonFunction';
import styles from '../styles/UserInfoStyles';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const mainImgHeight = 380;
const isWeb = Platform.OS === 'web';

export default class PreviewUserInfoScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.rowHeightAeruList = 25;
    this.maxHeightAeruList = this.rowHeightAeruList * 3;
    this.state = {
      loading: true,
      account: UserUtil.getDefaultAccountAppState(),
      heightAeruList: new Animated.Value(this.maxHeightAeruList),
      toggleAeruList: true,
      currentImageIndex: 0,
      backgroundOpacity: 0.8,
      currentUser:[],
    }
    this.scrollRef = React.createRef();

    // reset check access
    ProfileSettingApi.resetCheckAccessFlg();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      CommonFunction.hideBottomTab(this.navigation);
      this.myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
      await this.getDataFromApi();
      const { appState } = this.context;
      if(appState.account){
        this.setState({ account: appState.account});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  getDataFromApi = async()=>{
    try{
      const userProfileResponse = await ProfileSettingApi.ProfileGetCheck(this.context);
      //check response status
      if(!CommonFunction.checkResponseStatus(userProfileResponse)) return;

      if(userProfileResponse?.status==1){
        const account = UserUtil.getAccountAppState(userProfileResponse, this.context);
        let profileInfo = [];
        const userProfile = userProfileResponse?.userProfile;
       
        if(userProfile?.introduction?.certify==0) {
          if(userProfile.introduction?.body!=undefined) userProfile.introduction.body = '';
        }
        if(userProfile?.tweet?.certify==0) {
          if(userProfile.tweet?.body!=undefined) userProfile.tweet.body = '';
        }

        if(Object.keys(userProfile).length > 0){
          Object.keys(userProfile).map((key,index)=>{
            if(userProfile[key]?.label!=undefined){
              profileInfo[key] = userProfile[key]?.label;
            }else if(userProfile[key]?.body!=undefined){
              profileInfo[key] = userProfile[key]?.body;
            }else{
              profileInfo[key] = userProfile[key];
            }
          });
        }

        const profileImage = profileInfo?.profileImage;
        profileInfo.topMainImageUrl = profileImage?.noImageUrlBig;
        if(profileImage?.count > 0){
          const mainImage = CommonFunction.getMainImage(profileImage?.list);
          if(mainImage?.url){
            profileInfo.topMainImageUrl = mainImage?.url;
          }
        }

        if(account?.activated!=0){
          const memberId = this.myProfile?.memberId;
          const responseGetTargetList = await CalendarApi.GetTargetList(memberId);
          if(responseGetTargetList?.status==1){
            const aeruList = responseGetTargetList?.response?.aeru?.list;
            if(aeruList!=undefined){
              profileInfo.aeruList = aeruList;
            }
          }
        }
        
        this.setHeader(profileInfo);

        this.setState({ 
          currentUser: profileInfo,
          account: account,
          toggleAeruList: true,
          currentImageIndex: 0,
        });
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false });
    }
  }

  showImg(imageUrl){
    if(imageUrl!=undefined){
      let {currentUser} = this.state;
      currentUser.topMainImageUrl = imageUrl;
      this.setState({
        currentUser: currentUser
      });
    }
  }

  showAeruList = () => {
    const { toggleAeruList } = this.state;
    Animated.timing(this.state.heightAeruList, {
      duration: 200,
      toValue: toggleAeruList ? (this.state.currentUser?.aeruList?.length * this.rowHeightAeruList) : this.maxHeightAeruList,
      useNativeDriver: false
    }).start(({ finished }) => {
      if(finished){     
        this.setState({ 
          toggleAeruList: !toggleAeruList, 
        });
      }
    });
  }
  
  setHeader = (item)=>{
    this.navigation.setOptions({
      header: () => (
        <View style={[HeadStyle.customHeader,{ borderBottomWidth:0}]}>
          <View style={{position:'absolute',left:0,zIndex:1,elevation:1,justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
            <TouchableOpacity onPress={() => {
              if(this.navigation.canGoBack()){
                this.navigation.goBack();
              }
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{width:'100%',justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}}>
            <Text style={HeadStyle.headerTitlePage} numberOfLines={1}>{item?.name}</Text>
          </View>
        </View>
      )
    });
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    const item = this.state.currentUser;

    let heightStyle = '';
    if(checkValue(item.height) && item?.height>0){
      heightStyle = item.height + 'cm';
    }
    if(checkValue(item.style)){
      heightStyle += (heightStyle!='')?' / '+item.style:item.style;
    }
    
    let goodCount = item?.goodCount;
    if(goodCount==undefined || isNaN(goodCount)){
      goodCount = '';
    }
    if(this.myProfile?.sex==Constants.MALE && this.state.account?.subscription?.status!=1){
      goodCount = '?';
    }
    if(goodCount==''){
      goodCount = 0;
    }

    const onlineItem = OptionData.Master.Online.Items.filter((element)=>{
      return element.id==1;
    });

    let border1width = 0;
    if(item?.introduction || (item?.aeruList?.length>0)) border1width = 1;
    
    let income = '';
    if(item?.sex==Constants.MEN || item?.sex==Constants.MALE){
      heightStyle = '';
      if(checkValue(item?.income)) income = item.income;
      if(!isWeb) income = '';
    }

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[styles.container, SafeAreaStyles(insets).container]}>
        <View style={HeadStyle.customHeaderTop}>
          <ScrollView 
           ref={ref =>this.scrollRef=ref} 
           scrollToOverflowEnabled={true}
           style={[styles.scrollContainer, {width: '100%', paddingBottom:0}]}>
            <View style={{width:'100%', height: mainImgHeight, justifyContent: 'center', alignItems:'stretch'}}>
              <LinearGradient
                colors={['rgba(0,0,0,0.18)', 'rgba(255, 255, 255, 0)']}
                style={{position: 'absolute',left: 0,right: 0,top: 0,height: 380,zIndex: 100}}
              />
              <Image style={styles.myProfileImg} source={{uri : item.topMainImageUrl}} />
            </View>

            {item.profileImage?.count>0 &&
            <View style={{width:'100%'}}>
              <View style={styles.thumbList}>
                <ScrollView horizontal={true} style={{width:'100%'}} contentContainerStyle={{paddingLeft:14}}>
                {item.profileImage?.list?.map((subImage,subIndex) => {
                  return ( 
                    <TouchableOpacity key={subIndex.toString()} style={styles.separator} onPress={()=> {
                      this.showImg(subImage?.url);
                      this.setState({currentImageIndex: subIndex+1});
                    }}>
                      <Image resizeMode={'cover'} style={styles.thumbImg} source={{uri : subImage?.url}} />
                    </TouchableOpacity>
                  )
                })}
                </ScrollView>
              </View>  
            </View>                    
            }

            <View style={{paddingTop:12, paddingRight: 16, paddingBottom: 20, paddingLeft: 16,width:'100%'}}>
              <View style={[styles.section,{ alignItems:'flex-start'}]}>
                <View style={{flexDirection:'row', alignContent:'center', alignItems:'center', marginBottom:12}}>
                  <View style={{marginRight:8, maxWidth:150}}>
                    <Text numberOfLines={1} style={{fontSize:20, fontWeight:"700", lineHeight:30,color:'#484848'}}>{item.name}</Text>
                  </View>
                  <View style={{marginRight:8, flexDirection:'row'}}>
                    <Text style={{fontSize:20, fontWeight:"700", lineHeight:30, color:'#484848'}}>{item.age}</Text>
                    <Text style={{fontSize:20, fontWeight:"700", lineHeight:30, color:'#484848',marginLeft:8}}>{item.currentPref}</Text>
                  </View>
                  {item.newFlag==1 &&
                  <View style={{marginLeft:7, paddingLeft:7, paddingRight:7, height:18, backgroundColor: '#ff8d63', borderRadius: 4,}}>
                    <Text style={{fontSize:12, fontWeight:"700", lineHeight:18, color:'#fff', textAlign:'center',}}>NEW</Text>
                  </View>
                  }
                </View>
              </View>
                      
              <View style={[styles.section,{borderBottomColor:'#e6e6e6', borderBottomWidth: border1width}]}>
              {checkValue(item.tweet) ?
                <View style={{marginBottom:12}}>
                  <Text style={{fontSize:14, lineHeight:19}}>{item.tweet}</Text>
                </View>
                : null
              }
              {checkValue(item.occupation) ?
                <View style={{}}>
                  <Text style={{fontSize:14, lineHeight:19}}>{item.occupation}</Text>
                </View>
                : null
              }

              {checkValue(heightStyle) ?
                <View style={{}}>
                  <Text style={{fontSize:14, lineHeight:19}}>{heightStyle}</Text>
                </View>
                : null
              }
              {checkValue(income) ?
                <View style={{}}>
                  <Text style={{fontSize:14, lineHeight:19}}>{income}</Text>
                </View>
                : null
              }

                <View style={{alignItems:'flex-end'}}>
                  <View style={{flexDirection:'row', alignContent:'center', paddingBottom:12, paddingTop:12, justifyContent:'center'}}>
                    <AntDesign name="heart" size={15} style={{marginTop: 1, color:'#5cc8ca'}} />
                    <Text style={{marginLeft:4, marginRight:8, fontSize:14}}>{goodCount}件</Text>
                    <View style={{justifyContent:'center'}}><OnlineStatus online={1} /></View>
                    <Text style={{marginLeft:4, fontSize:14}}>{onlineItem.length>0?onlineItem[0]?.value:''}</Text>
                  </View>
                </View>
              </View> 
              {item.introduction?
              <View style={styles.section}>
                <Text style={styles.h3}>自己紹介</Text>
                <Text style={{fontSize:16}}>{item.introduction}</Text>
              </View>
              :null
              }
              {item.aeruList?.length>0 &&
                <View style={styles.section}>
                {item.aeruList?.length<=3 ?
                <>
                  <Text style={styles.h3}>デート可能日</Text>
                  {item.aeruList?.map((val,i) => {
                    return (
                    <View key={i} style={{height:this.rowHeightAeruList}}>
                      <Text style={{fontSize:16}}>{val.year}年 {val.month}月 {val.day}日 ({val.wDay})</Text>
                    </View>
                    )
                  })}
                </>
                :
                <>
                  <Text style={styles.h3}>デート可能日</Text>
                  <Animated.View style={{height:this.state.heightAeruList, overflow:'hidden'}}>
                    {item.aeruList?.map((val,i) => {
                      return (
                      <View key={i} style={{height:this.rowHeightAeruList}}>
                        <Text style={{fontSize:16}}>{val.year}年 {val.month}月 {val.day}日 ({val.wDay})</Text>
                      </View>
                      )
                    })}
                  </Animated.View>
                  <TouchableOpacity style={{flexDirection:'row', alignItems:'center'}} onPress={()=>{
                      this.showAeruList();
                    }}>
                    <Text style={{justifyContent:'center'}}>もっとみる </Text>
                    <AntDesign name={this.state.toggleAeruList?"down":"up"} size={16} style={{color:'#484848', paddingLeft:5}} />
                  </TouchableOpacity>
                </>
                }
              </View>
              }
            </View>
              
            <View style={[styles.insideBG,{...Platform.select({ios:{marginBottom:50}, android:{marginBottom:20}}) }]}>
            {(item.sex==Constants.MEN || item.sex==Constants.MALE)?
            <>
              <Block18Male item={item} />
              <Block17 item={item} />
              <Block16Male item={item} />
              <Block19 item={item} />
              <Block20 item={item} />
            </>
            :
            <>
              <Block16 item={item} />
              <Block17 item={item} />
              <Block18 item={item} />
              <Block19 item={item} />
              <Block20 item={item} />
            </>
            }
            </View>
          </ScrollView>
        </View>
      </View>
      }
    </SafeAreaInsetsContext.Consumer>
    )
  }
}
