import React,{ Component } from 'react';
import { StyleSheet, Text, View, Platform, StatusBar, TouchableOpacity, ScrollView } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import Notification18Api from '../services/api/Notification18Api';
import CommonFunction  from '../utils/CommonFunction';
import ActivityIndicator from '../components/common/ActivityIndicator';
import ListStyles from '../styles/ListStyles';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const {checkUndefined} = CommonFunction;

export default class NotificationDetailScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      notificationDetail : {},
      loading: true, 
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.setHeader();
      this.getDatatFromApi();
    }
  }

  async getDatatFromApi(){
    if(this._isMounted){
      try{
        const noticeId = this.props?.route?.params?.noticeId;
        const type = this.props?.route?.params?.type;
        const params = {noticeId: noticeId, type: type};
        const notiResponse = await Notification18Api.GetNotificationDetail(params);
        //check login
        if(!CommonFunction.checkResponseStatus(notiResponse)) return;

        if(notiResponse!=undefined && notiResponse?.status==1){
          const data = notiResponse?.response;
          if(checkUndefined(data)){
            this.setState({ notificationDetail: data.notificationDetail });  
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({loading: false});
      }
    }
  };

  setHeader(){
    this.props.navigation?.setOptions({
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={[styles.icClose,{flexDirection:'row'}]}>
            <TouchableOpacity onPress={()=> this.props.navigation?.goBack()}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch', alignContent:'center', height:24,}}><Text style={HeadStyle.headerTitlePage}>お知らせ</Text></View>
        </View>
      )
    });
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container, SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={[styles.viewInside, {borderBottomColor:'#e6e6e6', borderBottomWidth:1}]}>
            <Text style={styles.titleDetailItem}>{this.state.notificationDetail?.title}</Text>
            <Text style={styles.txtDetailItem}>{this.state.notificationDetail?.receiveDate}</Text>
          </View>
          <View style={styles.viewInside}>
            <Text style={styles.txtDetailItemDesc}>
              {this.state.notificationDetail?.content}
            </Text>
          </View>
        </ScrollView>
      </View>
      }
    </SafeAreaInsetsContext.Consumer>
    );  
  }
}

const styles = StyleSheet.create({
  titleDetailItem: {
    color:'#484848',
    fontSize:16,
    lineHeight:22,
    letterSpacing:-.4,
    fontWeight:'bold',
  },
  txtDetailItem: {
    marginTop:10,
    color:'#9aa5a7',
    fontSize:14,
    lineHeight:19,
    letterSpacing:-.4,
  },
  viewInside: {
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:15,
    paddingRight:15,
  },
  txtDetailItemDesc: {
    color:'#484848',
    fontSize:14,
    lineHeight:24,
    letterSpacing:-.4,
  },
  icClose:{
    position:'absolute',
    left: 0,
    elevation: 1,
    zIndex: 1
  },
});

