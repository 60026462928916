import React, { Component } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Platform } from 'react-native';
//import { FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import Constants from '../../constants/Constants';
import OnlineStatus from '../common/OnlineStatus';
import { AppContext } from '../../AppContext';

const isWeb = Platform.OS === 'web';

export default class UserColumnFormat extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(){
    super();
    this.state = { 
      loading: true, 
      goodFlg: 0 
    };
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      const { item, numColumns, onPress, onIine } = this.props;
      this.item = item;
      this.numColumns = numColumns;
      this.onPress = onPress;
      this.onIine = onIine;

      let goodFlg = this?.item?.goodFlg;
      if(goodFlg==undefined) goodFlg = 0;
      this.setState({loading: false, goodFlg: goodFlg});
    }
  }

  componentDidUpdate(prevProps, prevState){
    /*
    if(this.props?.item?.goodFlg!=undefined){
      if(this.props.item.goodFlg!=prevState.goodFlg){
        this.setState({goodFlg: this.props.item.goodFlg});
      }
    }
    */
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  handleOnPress = async(item)=>{
    let goodFlg = await this.onIine(item.memberId);
    if(goodFlg!='notactivated'){
      this.setState({goodFlg: goodFlg});

      let { appState, setAppState } = this.context;
      if(appState.setIineMemberId!=undefined && Array.isArray(appState.setIineMemberId)){
        appState.setIineMemberId.push(item.memberId);
      }else{
        appState.setIineMemberId = [item.memberId];
      }
      setAppState(appState);
    }
  }

  render(){
    if(this.state.loading) return <></>;

    // thumbnail height
    let thumbnailHeight = 170;
    let isMale = false;
    if(this.numColumns===Constants.numColumnsMale){
      thumbnailHeight = 250;
      isMale = true;
    }

    // area
    let area = this.item?.currentPref;
    if(Constants.NOTSETTEXT.indexOf(this.item?.currentPref) != -1) area = '';
    
    // tweet
    let tweet = '';
    if(this.item?.sex==Constants.MEN){
      if(Constants.NOTSETTEXT.indexOf(this.item?.income) == -1) tweet = this.item?.income;
      if(!isWeb){
        tweet = (this.item?.tweet!='') ? this.item?.tweet:this.item?.introduction;
      }
    }else if(this.item?.sex==Constants.WOMEN){
      tweet = (this.item?.tweet!='') ? this.item?.tweet:this.item?.introduction;
    }

    // online
    let online = 0;
    if(this.item?.online !=undefined) online = this.item.online?.id;
    
    return (
      <TouchableOpacity style={styles.ThumbnailProfile} onPress={this.onPress}>
        <View style={{width:'100%',height:thumbnailHeight, borderRadius:10, alignItems: 'center', justifyContent: 'center'}}>
          <Image source={{uri : this.item.mainImageUrl}} resizeMode={'cover'} style= {[styles.ThumbnailProfileImg,{ height:thumbnailHeight}]} />
          {/*{this.item.sex==Constants.MEN &&
          (this.state.goodFlg==0 ?
          <TouchableOpacity style={styles.favorite} onPress={()=>{
            this.handleOnPress(this.item);
          }}>
            <Image style={styles.favorite_icon} source={require('../../assets/images/favorite.png')}/>
          </TouchableOpacity>
          :
          <View style={[styles.chat]}>
            <Image style={styles.chat_icon} source={require('../../assets/images/ic-chat.png')}/>
          </View>
          )}*/}
          </View>
          <LinearGradient 
            colors={['transparent','rgba(0,0,0,0.8)']} 
            style={isMale?styles.bgLinearGradientMale:styles.bgLinearGradient}
          ></LinearGradient>
          <View style={isMale?styles.itemProfileMale:styles.itemProfile}>
            <View style={styles.profile}>
              <View style={styles.profileItem}>
                <View style={styles.descItem}>
                  <OnlineStatus online={online} />
                  <Text style={isMale?styles.fontMediumMale:styles.fontMedium}>{this.item?.age} {area}</Text>
                </View>
                {/** 
                {item.imageCount > 0 &&
                <View style={styles.photoProfile}>
                  <FontAwesome name="image" style={styles.icPhotoProfile} />
                  <Text style={styles.numPhotoProfile}>{item.imageCount}</Text>
                </View>
                }
                */}
              </View>
              <View style={isMale?styles.descProfileMale:styles.descProfile}><Text numberOfLines={1} style={isMale?styles.txtDescProfileMale:styles.txtDescProfile}>{tweet}</Text></View>
            </View>
          </View>
        </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  ThumbnailProfile: {
    flexDirection: 'column',
    marginLeft:2,
    marginRight:2,
  },
  bgLinearGradient:{
    position:'absolute',
    left:0,
    right:0,
    bottom:0,
    width:'100%',
    height:50,
    zIndex:1,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10,
  },
  bgLinearGradientMale:{
    position:'absolute',
    left:0,
    right:0,
    bottom:0,
    width:'100%',
    height:60,
    zIndex:1,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10,
  },
  itemProfile:{
    position:'absolute',
    bottom:0,
    padding:5,
    width:'100%',
    zIndex:10,  
  },
  itemProfileMale:{
    position:'absolute',
    bottom:0,
    paddingLeft:8,
    paddingRight:8,
    paddingTop:12,
    paddingBottom:7,
    width:'100%',
    zIndex:10,  
  },
  profile:{
    //height:43,
  },
  fontMedium: {
    fontSize: 12,
    lineHeight:20,
    alignSelf: 'flex-start',
    fontWeight:"600",
    color: '#fff',
    letterSpacing:-.2,
  },
  fontMediumMale: {
    fontSize: 13,
    lineHeight:20,
    alignSelf: 'flex-start',
    fontWeight:"600",
    color: '#fff',
    letterSpacing:-.2,
  },
  favorite:{
    backgroundColor:'#eef1f2', 
    position:'absolute', 
    right:-4, 
    bottom:-4, 
    width:36, 
    height:36, 
    borderRadius: 18, 
    borderWidth:1,
    borderColor: '#eef1f2',
    alignContent:'center', 
    justifyContent:'center'
  },
  favoriteSelected:{
    backgroundColor:'#5cc8ca', 
    borderColor: '#10a4c3',
  },
  favorite_icon:{
    width:16,
    height:16,
    alignSelf:'center'
  },
  chat:{
    backgroundColor:'#ff8d63', 
    position:'absolute', 
    right:-4, 
    bottom:-4, 
    width:36, 
    height:36, 
    borderRadius: 18, 
    borderWidth:1,
    borderColor: '#ff8d63',
    alignContent:'center', 
    justifyContent:'center'
  },
  chat_icon:{
    width:20,
    height:20,
    alignSelf:'center'
  },
  warning:{
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  warning_icon:{
    width: 22,
    height: 20,
    marginLeft:4,
    marginBottom: 4
  },
  royal:{
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  royal_icon:{
    width: 24,
    height: 24,
    marginLeft:4,
    marginBottom: 4
  },
  ThumbnailProfileImg:{
    width: '100%', 
    height: 170, 
    borderRadius:10
  },
  profileItem: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    alignSelf:'stretch',
  },
  descItem: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'flex-start',
  },
  photoProfile: {
    flex:1,
    flexDirection:'row',
    justifyContent:'flex-end',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  icPhotoProfile: {
    marginRight:4,
    fontSize:14,
    color:'#9AA5A7',
  },
  numPhotoProfile: {
    color:'#fff',
    fontSize:14,
    lineHeight:19,
    letterSpacing:-.4,
  },
  descProfile:{
    paddingLeft:2,
    height:12,
  },
  descProfileMale:{
    paddingLeft:2,
    height:16,
  },
  txtDescProfile:{
    fontSize:10,
    lineHeight:12,
    color:'#fff',
    letterSpacing:-.2,
  },
  txtDescProfileMale:{
    fontSize:11,
    lineHeight:16,
    color:'#fff',
    letterSpacing:-.2,
  },
});
