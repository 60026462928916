import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView,StatusBar, StyleSheet, Platform, SafeAreaView, Dimensions } from 'react-native';
import {FormikStep, FormikStepper} from '../components/common/FormikStepper'
import Validation from '../constants/Validation';
import { 
  FormTextInput, FormDateInput, ProfileImageInput, FormPasswordInput, CheckboxInput, PickerListInputNew 
} from '../components/form/FormInput';
import styles from '../styles/FormStyles';
import { AntDesign } from '@expo/vector-icons';
import { Portal } from 'react-native-paper';
import MasterData from '../constants/option/OptionData.json';
import Constants from '../constants/Constants';
import StorageHelper from '../utils/StorageHelper2';
import { AppContext } from '../AppContext';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';
//import BackHandler from '../components/common/BackHandler';
import Token from '../utils/Token';

const windowWidth = Dimensions.get('window').width;

export default class RegisterScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor() {
    super();

    this.initialValues = {
      name: '',
      currentPref: {id:'', value:''},
      sex: {id:'', value:''},
      birthDate: '',
      accept: false,
      agree: false,
      dateHope: {id:'', value:''},
      meetHope: {id:'', value:''},
      mainImage: '',
      mail: '',
      pass: '',
      back_step: '',
      code: '',
      token: ''
    }

    this.state = {
      loading: true,
      showModalMale : false,
      showModalFeMale : false,
      showPopupStep5: false,
      formData: this.initialValues
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      // focus screen
      this._unsubscribe = this.props.navigation?.addListener('focus', () => {
        //this.checkAccessToken();
        Token.checkAccessToken(this.props.navigation, this.context);
      });

      await StorageHelper.getObjectData('registProfile').then(res=>{
        if(res){
          if(res.expire > (new Date().getTime()-(600*1000))){
            this.setState({ formData: res });
          }else{
            // expire
            StorageHelper.removeItem('registProfile');
          }
        }
        this.setState({ loading: false });
      });
    }
  }

  checkAccessToken = async()=>{
    let userProfile = {};
    const accessToken = await StorageHelper.getData('accessToken');
    if(accessToken){
      this.setState({loading: true}, async()=>{
        ProfileSettingApi.setCheckAccessFlg(false);
        userProfile = await ProfileSettingApi.ProfileGetCheck(this.context, false);
        if(userProfile){
          if(Object.keys(userProfile).length >0){
            // goto search screen
            this.props?.navigation?.replace('Auth', {screen:'Home', params: {screen: 'Search', params: {screen:'SearchList'}}});
          }
        }
        this.setState({loading: false});
      });
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }

  componentDidUpdate(prevProps, prevState){
    // clear form data if change sex
    let clearData = false;
    let prevGender = prevState?.formData?.sex;
    let nowGender = this.state?.formData?.sex;
    
    if(prevGender?.id!=undefined && nowGender?.id!=undefined){
      if(prevGender?.id != nowGender?.id){
        clearData = true
      }else if(nowGender.id==Constants.MALE){
        if(prevState.formData?.currentPref?.id != this.state.formData?.currentPref?.id){
          clearData = true
        }
      }
    }

    if(clearData){
      let { formData } = this.state;
      formData.code = '';
      formData.mail = '';
      formData.pass = '';
      formData.mainImage = '';
      formData.dateHope = {id:'', value:''};
      formData.meetHope = {id:'', value:''};
      formData.token = ''
      this.setState({
        formData: formData
      });

      this.formik?.setValues(this.state.formData);
    }
  }

  showModalStep3Male(show){
    if(show){
      this.setState({showModalMale: true});
    }else{
      this.setState({showModalMale: false});
    }
    this.setState({showModalFeMale: false});
  }

  showModalStep3FeMale(show){
    if(show==true){
      this.setState({showModalFeMale: true});
    }else{
      this.setState({showModalFeMale: false});
    }
    this.setState({showModalMale: false});
  }

  stepHandler(values, step, formId, skip){
    if(step==0){
      values.accept = false;
      this.formik?.setFieldValue({ accept: false });
    }
    this.setState({ formData: values });

    let sex = values?.sex?.id;
    if((formId=='step3' || formId=='step3f') && skip===true){  
      if(sex==Constants.FEMALE){ //female
        this.showModalStep3FeMale(true);
      }else{
        this.showModalStep3Male(true);
      }
    }
    if(this.state.formData.mainImage!==''){
      this.showModalStep3FeMale(false);
      this.showModalStep3Male(false);
    }
  }

  setProfileImageRef(ref){
    this.ProfileImageRef = ref;
  }

  setFormRef(ref){
    this.formik = ref;
  }

  getFormRef = ()=>{
    return this.formik;
  }

  render() {
    if(this.state.loading) return <></>;
    
    const { navigation } = this.props;

    // remove Area id 0
    let Area = MasterData.Master.Area.Items;
    Area = Area.filter(function(item){
      return item.id > 0;
    });
  
    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.ScrollContainer}>
          <FormikStepper
            initialValues={this.state.formData}
            onSubmit={async(values) => {}}
            navigation={navigation}
            stepHandler={this.stepHandler.bind(this)}
            innerRef={p => {this.setFormRef(p)} }
            appContext={this.context}
            getFormRef={this.getFormRef}
          >
            <FormikStep id="step1" label="m,f" validationSchema={Validation.RegisterStep1Schema}>
              <View style={[styles.HeadFormSection,{paddingBottom:windowWidth>375?10:0}]}>
                <Text style={[styles.txtHeadForm,{marginBottom:windowWidth>375?10:0}]}>まずは、あなたのことを</Text>
                <Text style={[styles.txtHeadForm, {marginBottom:0}]}>教えてください</Text>
              </View>
              <View style={styles.BodyFormSection}>
                <View style={styles.formGroup}>
                  <View style={styles.labelView}><Text style={styles.labelText}>ニックネーム</Text></View>
                  <FormTextInput fieldName="name" placeholder="入力してください" />
                </View>
                <View style={styles.formGroup}>
                  <View style={styles.labelView}><Text style={styles.labelText}>居住地</Text></View>
                  <PickerListInputNew fieldName="currentPref" items={Area} multiple={false} />
                </View>
                <View style={styles.formGroup}>
                  <View style={styles.labelView}><Text style={styles.labelText}>性別</Text></View>
                  <PickerListInputNew fieldName="sex" items={MasterData.Master.Sex.Items} multiple={false} />
                </View>
                <View style={styles.formGroup}>
                  <View style={styles.labelView}><Text style={styles.labelText}>生年月日</Text></View>
                  <FormDateInput fieldName="birthDate" placeholder="" />
                </View>
                <View style={[styles.formGroup,{ marginBottom:0}]}>
                  <View style={{flexDirection:'row',alignItems:'center',marginTop:5}}>
                    <CheckboxInput fieldName="accept" NotdisplayError={true} containerStyle={{padding:0}} />
                    <TouchableOpacity onPress={()=>{ 
                      //if(Platform.OS==='web') BackHandler.stopEvent();
                      navigation?.navigate('Terms');
                    }}>
                      <Text style={styles.txtLink}>利用規約</Text>
                    </TouchableOpacity>
                    <Text style={styles.txtnoLink}>に同意する</Text>
                  </View>
                </View>
              </View>
            </FormikStep>
          
            <FormikStep id="step2" label="m,f" validationSchema={Validation.RegisterStep2FemaleSchema}>
              <View style={styles.HeadFormSection}>
                <Text style={styles.txtHeadForm}>次に、あなたの目的・希望 を教えてください</Text>
              </View>
              <View style={styles.BodyFormSection}>
                <View style={styles.formGroup}>
                  <View style={styles.labelView}><Text style={styles.labelText}>デートでしたいことはありますか？</Text></View>
                  <PickerListInputNew fieldName="dateHope" items={MasterData.Master.DateHope.Items} multiple={false} />
                </View>
                <View style={styles.formGroup}>
                  <View style={styles.labelView}><Text style={styles.labelText}>出会うまでの希望を教えてください</Text></View>
                  <PickerListInputNew fieldName="meetHope" items={MasterData.Master.MeetHope.Items} multiple={false} />
                </View>
              </View>
            </FormikStep>

            <FormikStep id="step3" label="m">
              <View style={styles.HeadFormSection}>
                <Text style={styles.txtHeadForm}>プロフィール写真を{"\n"}登録しましょう</Text>
                <Text style={[styles.descHeadForm,{marginRight:-10,}]}>
                写真を登録するとマッチング率が大きくアップします！{"\n"}
                あとで変更できるので、あまり悩まずに登録してみましょう</Text>
              </View>
              <View style={styles.BodyFormSection}>
                <ProfileImageInput fieldName="mainImage" mainImage={this.state.formData.mainImage} sex={Constants.MALE} setRef={this.setProfileImageRef.bind(this)} />
              </View>
            
              {this.state.showModalMale &&
              <Portal style={[styles.portal,{justifyContent:'center'}]}>
                <View style={styles.popupContainer}>
                  <View style={styles.overlay}
                  onStartShouldSetResponder={() => {
                    this.showModalStep3Male(false);
                  }} />
                  <View style={styles.popupInner}>
                    <View style={[styles.popupHeader,styles.bgBlue]}>
                      <View style={[styles.headerRow1,{marginTop:10,}]}>
                        <Text style={[styles.txtHeaderPopupRow1,styles.txtBlue]}>女性の</Text>
                        <Text style={[styles.txtHeaderPopupRow2,styles.txtBlue]}>約</Text>
                        <Text style={[styles.txtHeaderPopupRow3,styles.txtBlue]}>70</Text>
                        <Text style={[styles.txtHeaderPopupRow1,styles.txtBlue]}>％</Text>
                        <Text style={[styles.txtHeaderPopupRow4,styles.txtBlue]}>が</Text>
                      </View>
                      <View style={[styles.headerRow1,{marginBottom:5,}]}>
                        <Text style={[styles.txtHeaderPopupRow2,styles.txtBlue]}>写真のある男性</Text>
                        <Text style={[styles.txtHeaderPopupRow4,styles.txtBlue]}>とやり取り中!!</Text>
                      </View>
                      <View style={styles.headerRow2}>
                        <View style={styles.bubblePopup}><Text style={styles.txtBubblePopup} numberOfLines={1}>写真がないとどんな人なのか分からなくて不安…</Text></View>
                        <View style={styles.bubbleTriangle}><Image style={styles.imgTriangle} source={require('../assets/images/icon/triangle.png')}/></View>
                        <View style={styles.bubbleImg}>
                          <Image style={styles.imgPopup1} source={require('../assets/images/register/img-f.png')}/>
                        </View>
                      </View>
                      <View style={styles.headerRow3}>
                        <Text style={styles.txtDescPopup1}>
                          写真を登録しないと{"\n"}
                          返信を貰える可能性が低くなります…{"\n"}
                          このまま次に進みますか？
                        </Text>
                      </View>
                    </View> 
                    <View style={styles.blocPopupInner}>
                      <View style={{textAlign:'center'}}>
                        <Text style={styles.txtDescPopup2}>＼ あなたの雰囲気が伝わる写真ならOK ／</Text>
                      </View>
                      <View style={styles.imgPopup2Inner}>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-m1.png')}/></View>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-m2.png')}/></View>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-m3.png')}/></View>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-m4.png')}/></View>
                      </View>  
                    </View>
                    <View style={styles.btnLink1}>
                      <TouchableOpacity onPress={()=>{
                        this.ProfileImageRef._pickImage();
                        this.showModalStep3Male(false);
                      }}>
                        <View style={{flexDirection:'row',}}>
                          <View style={{flex:1,alignItems:'center',textAlign:'center'}}><Text style={styles.txtBtnLink1}>写真を登録する</Text></View>
                          <View style={{alignItems:'flex-end'}}><AntDesign name="right" style={styles.icTxtBtnLink1}/></View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  
                    <View style={styles.btnLink2}>
                      <TouchableOpacity onPress={()=>{ 
                        this.showModalStep3Male(false);
                        this.formik.submitForm();
                      }}><Text style={styles.txtBtnLink2}>いまはしない</Text></TouchableOpacity>
                    </View>

                  </View>
                </View>
              </Portal>
              }
            </FormikStep>
          
            <FormikStep id="step3f" label="f">
              <View style={styles.HeadFormSection}>
                <Text style={styles.txtHeadForm}>プロフィール写真を{"\n"}登録しましょう</Text>
                <Text style={[styles.descHeadForm,{marginRight:-10,}]}>写真を登録するとマッチング率が大きくアップします！{"\n"}
                あとで変更できるので、あまり悩まずに登録してみましょう</Text>
              </View>
              <View style={styles.BodyFormSection}>
                <ProfileImageInput fieldName="mainImage" mainImage={this.state.formData.mainImage} sex={Constants.FEMALE} setRef={this.setProfileImageRef.bind(this)} />
              </View>
              {this.state.showModalFeMale &&
                <Portal style={[styles.portal,{justifyContent:'center'}]}>
                <View style={styles.popupContainer}>
                  <View style={styles.overlay}
                  onStartShouldSetResponder={() => {
                    this.showModalStep3FeMale(false);
                  }} />
                  <View style={styles.popupInner}>
                    <View style={[styles.popupHeader,styles.bgPink]}>
                      <View style={[styles.headerRow1,{marginTop:10,}]}>
                        <Text style={[styles.txtHeaderPopupRow1,styles.txtPink]}>男性の</Text>
                        <Text style={[styles.txtHeaderPopupRow2,styles.txtPink]}>約</Text>
                        <Text style={[styles.txtHeaderPopupRow3,styles.txtPink]}>70</Text>
                        <Text style={[styles.txtHeaderPopupRow1,styles.txtPink]}>％</Text>
                        <Text style={[styles.txtHeaderPopupRow4,styles.txtPink]}>が</Text>
                      </View>
                      <View style={[styles.headerRow1,{marginBottom:5,}]}>
                        <Text style={[styles.txtHeaderPopupRow2,styles.txtPink]}>写真のある女性</Text>
                        <Text style={[styles.txtHeaderPopupRow4,styles.txtPink]}>とやり取り中!!</Text>
                      </View>
                      <View style={styles.headerRow2}>
                        <View style={styles.bubblePopup}><Text style={styles.txtBubblePopup} numberOfLines={1}>プロフ写真がないとイメージが出来ないなぁ…</Text></View>
                        <View style={styles.bubbleTriangle}><Image style={styles.imgTriangle} source={require('../assets/images/icon/triangle.png')}/></View>
                        <View style={styles.bubbleImg}>
                          <Image style={styles.imgPopup1} source={require('../assets/images/register/img-m.png')}/>
                        </View>
                      </View>
                      <View style={styles.headerRow3}>
                        <Text style={styles.txtDescPopup1}>
                          写真を登録しないと{"\n"}
                          返信を貰える可能性が低くなります…{"\n"}
                          このまま次に進みますか？
                        </Text>
                      </View>
                    </View> 
                    {/* <View style={styles.blocPopupInner}>
                      <View style={{textAlign:'center'}}>
                        <Text style={styles.txtDescPopup2}>＼ あなたの雰囲気が伝わる写真ならOK ／</Text>
                      </View>
                      <View style={styles.imgPopup2Inner}>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-f1.png')}/></View>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-f2.png')}/></View>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-f3.png')}/></View>
                        <View style={styles.imgPopupShadow}><Image style={styles.imgPopup2} source={require('../assets/images/register/img-f4.png')}/></View>
                      </View>  
                    </View> */}
                    <View style={styles.btnLink1}>
                      <TouchableOpacity onPress={()=>{
                        this.ProfileImageRef._pickImage();
                        this.showModalStep3Male(false);
                      }}>
                        <View style={{flexDirection:'row',}}>
                          <View style={{flex:1,alignItems:'center',textAlign:'center'}}><Text style={styles.txtBtnLink1}>写真を登録する</Text></View>
                          <View style={{alignItems:'flex-end'}}><AntDesign name="right" style={styles.icTxtBtnLink1}/></View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    
                    <View style={styles.btnLink2}>
                      <TouchableOpacity onPress={()=>{ 
                        this.showModalStep3FeMale(false);
                        this.formik.submitForm();
                      }}><Text style={styles.txtBtnLink2}>いまはしない</Text></TouchableOpacity>
                    </View>

                  </View>
              </View>
            </Portal>
            }
          </FormikStep>
            
          <FormikStep id="step4" label="m,f" validationSchema={Validation.RegisterStep5Schema}>
            <View style={styles.HeadFormSection}>
              <Text style={styles.txtHeadForm}>メールアドレスとパスワード{"\n"}を入力してください</Text>
              <Text style={[styles.descHeadForm,{fontSize:12}]}>ご本人確認のためにメールによる認証をお願いします。</Text>
            </View>
            <View style={[styles.BodyFormSection,{marginBottom:windowWidth>375?10:0, paddingLeft:30, paddingRight:30,}]}>
              <View style={styles.formGroup}>
                <View style={[styles.labelView,{}]}><Text style={styles.labelText}>メールアドレス</Text></View>
                <FormTextInput fieldName="mail" type="mail" placeholder="入力してください" autoComplete="off" />
              </View>
              <View style={styles.formGroup}>
                <View style={[styles.labelView,{}]}><Text style={styles.labelText}>パスワード</Text></View>
                <FormPasswordInput fieldName="pass" type="password" placeholder="4~8桁の数字または英数字" autoComplete="off" />
                <Text style={[styles.commentLabelView,{marginTop:5, marginBottom:5, fontSize:13,}]}>※1111のような同じ数字のパスワードは使えません</Text>
              </View>
              <View style={[styles.formGroup,{padding:6, width:'100%', backgroundColor:'#edf1f2', borderRadius:5,}]}>
                <Text style={[styles.commentLabelView,{fontSize:13,}]}>
                ご登録後に通知設定から通知をオンにしてください。メッセージ、いいね、足あとなどのお知らせが届きます。{"\n"}
                ※通知設定はいつでも変更が可能です。
                </Text>                
              </View>
              <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', paddingTop:10}}>
                <CheckboxInput fieldName="agree" NotdisplayError={true} containerStyle={{padding:0, margin:0}} />
                <Text style={[styles.txtnoLink, {fontWeight:'bold'}]}>上記内容に同意しました</Text>
              </View>
            </View>
          </FormikStep>

          <FormikStep id="step5" label="m,f">
            <View style={styles.HeadFormSection}>
              <Text style={styles.txtHeadForm}>届いたコードを{"\n"}入力してください</Text>
              <Text style={[styles.descHeadForm,{fontSize:12}]}>ご本人確認のためにメールによる認証をお願いします。</Text>
            </View>
            <View style={[styles.BodyFormSection,{marginBottom:50}]}>
              <View style={styles.formGroup}>
                <View style={[styles.labelView,{flexDirection:'column',marginBottom:3}]}>
                  <Text style={[styles.labelText,{color:'#2e8bff'}]}>{this.state.formData.mail}</Text>
                  <Text style={[styles.labelText]}>に届いたコード</Text>
                </View>
                <FormTextInput fieldName="code" placeholder="届いたコードを入力してください" autoComplete="off" />
              </View>
              
            </View>
            <View style={styles.BodyContent}>
              
              <View style={{flexDirection:'row',padding:10,borderBottomColor:'#cccccc',borderBottomWidth:1}}>
                <Text style={styles.txtBodyContent}>コードが届かない場合は、まず</Text>
                <TouchableOpacity onPress={()=>{this.setState({showPopupStep5: true});}}><Text style={[styles.txtBodyContent,{color:'#2e8bff',}]}>こちら</Text></TouchableOpacity>
                <Text style={styles.txtBodyContent}>をご覧ください。</Text>
              </View>

              <TouchableOpacity onPress={()=>{
                this.state.formData.back_step = 1;
                this.setState({formData: this.state.formData});
                this.formik?.setValues(this.state.formData);
                this.formik.submitForm();
              }}>
                <View style={{flexDirection:'row',padding:10,borderBottomColor:'#cccccc',borderBottomWidth:1,}}>
                  <View style={{flex:1,alignSelf:'stretch',alignItems:'flex-start',justifyContent:'center'}}><Text style={styles.txtBodyContent}>メールアドレスを変更・修正する</Text></View>
                  <View style={{alignItems:'flex-end',}}><AntDesign name="right" style={styles.icArrowRight} /></View>
                </View>
              </TouchableOpacity>
            </View>

            {this.state.showPopupStep5 &&
              <Portal style={[styles.portal,{justifyContent:'center'}]}>
                <View style={styles.popupContainer}>
                  <View style={styles.overlay}/>
                  <View style={[styles.popupInner, {width:'82%',}]}>
                    <View style={{width:'100%',}}>
                      <View style={{padding:10, width:'100%', backgroundColor:'#5bb5f2', borderTopLeftRadius:12, borderTopRightRadius:12,}}>
                        <TouchableOpacity style={{justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', width:'100%', height:40, backgroundColor:'#fff', borderRadius:999, textAlign:'center', }}>
                          <Text style={{fontSize:14, fontWeight:'normal', lineHeight:14, color:'#5bb5f2',}}>メールが届かない場合の対処法</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{padding:15,}}>
                        <Text style={[popstyles.text14, popstyles.textBold, {marginBottom:10,}]}>Gメールアドレスなどに届かない場合</Text>
                        <Text style={[popstyles.text12,  {marginBottom:35,}]}>迷惑メールフォルダに届く場合がございますのでGメールの迷惑メールフォルダをご確認ください</Text>
                        <Text style={[popstyles.text14, popstyles.textBold, {marginBottom:10,}]}>各キャリアのアドレスに届かない場合</Text>
                        <Text style={popstyles.text12}>ドメイン指定受信設定をされている方は<Text style={{color:'#0000ff',}}>pappy.jp</Text>を許可してください</Text>
                        <View style={{marginTop:15, marginBottom:15, paddingTop:15, paddingBottom:15, ...Platform.select({ ios:{ borderStyle: 'solid' }, android:{ borderStyle: 'dashed' }, default:{ borderStyle: 'dashed' }}), borderColor:'#d0d0d0', borderTopWidth:1, borderBottomWidth:1,}}>
                          <Text>インターネット経由のメールを受信否定されている方は解除してください</Text>
                        </View>
                        <Text>URLリンク付きメールを否定されている方は「受け取る」に設定してください</Text>
                        <View style={{justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', width:'100%',}}>
                          <TouchableOpacity style={{marginTop:15, padding:10, width:'85%', height:34, backgroundColor:'#ebebeb', borderRadius:999, textAlign:'center',}} onPress={()=>{
                            this.setState({showPopupStep5: false});
                          }}>
                            <Text style={{fontSize:14, fontWeight:'bold', lineHeight:14, color:'#484848', textAlign:'center'}}>閉じる</Text>
                          </TouchableOpacity>
                        </View>
                        
                      </View>
                    </View>
                  </View>
                </View>
              </Portal>
            }
          </FormikStep>

          <FormikStep id="step6" label="m,f">
            <></>
          </FormikStep>

        </FormikStepper>
      </ScrollView>
    </SafeAreaView>
    );
  }
}

const popstyles = StyleSheet.create({
  text14: {
    fontSize:14,
    fontWeight:'normal',
    lineHeight:19,
    color:'#484848',
  },
  text12: {
    fontSize:12,
    fontWeight:'normal',
    lineHeight:14,
    color:'#484848',
  },
  textBold: {
    fontWeight:'bold',
  },
  txtComment: {
    fontSize:10,
    fontWeight:'normal',
    lineHeight:16,
    color:'#9aa5a7',
    textAlign:'center',
  }
});