import SearchList from '../../screens/search/SearchList';
import Storage from '../../utils/StorageHelper2';
import Constants from '../../constants/Constants';
import CommonFunction from '../../utils/CommonFunction';
import UserUtil from '../../utils/UserUtil';
import Badge14Api from '../../services/api/Badge14Api';
import Search03Api from '../../services/api/Search03Api';
import StorageHelper from '../../utils/StorageHelper2';
import { Platform, Image } from 'react-native';

const fInterval = 5;
const oInterval = 17;

export default class SearchOrder extends SearchList {
  constructor() {
    super();
    this.searchParams = {
      count: 42,
      page: 1,
      sortOrder: 0,
      mainImage: '',
      currentPref: 0,
      ageFrom: '',
      ageTo: '',
      heightFrom: '',
      heightTo: '',
      style: 0,
      vibe: 0,
      personality: 0,
      occupation: 0,
      income: 0,
      assets: 0,
      education: 0,
      birthCity: 0,
      alcohol: 0,
      cigarette: 0,
      holiday: 0,
      dateHope: 0,
      meetHope: 0,
      lastLoginTime: 0,
      recentJoin: 0,
    };

    // set api not check access time
    Search03Api.setCheckAccessFlg(false);
  }

  loadComplete(){
    this.setState({
      loading: false,
      isRefreshing: false
    });
  }

  // set badge
  async setBadge(){
    try{
      let badgeResponse = await Badge14Api.GetBadge();
      if(badgeResponse?.status==1){
        let badgeList = badgeResponse?.response;
        if(this.props.setBadge!=undefined){
          this.props?.setBadge(badgeList);
        }
        if(this.props.setTopBadge!=undefined){
          this.props?.setTopBadge(badgeList);
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  async setPrivilegeFlag(privilegeFlag){
    if(this.props.setPrivilegeFlag!=undefined){
      this.props?.setPrivilegeFlag(privilegeFlag);
    }
  }

  async setTrialIconData(data, oriCount){
    if (oriCount!=0) {
      let scrollIndex = (Math.floor((oriCount-fInterval) / oInterval));
      scrollIndex++;
      for (var i = scrollIndex; i <= scrollIndex+3; i++) {
        let nextInterval = (fInterval + (oInterval*i)) -1;
        if (nextInterval<data.length) {
          let inData = {trialPlan: '1'};
          data.splice(nextInterval,0,inData)
        }
      }
    } else {
      for (var i = 0; i <= 3; i++) {
        let nextInterval = (fInterval + (oInterval*i)) -1;
        if (nextInterval<data.length) {
          let inData = {trialPlan: '1'};
          data.splice((fInterval + oInterval*i)-1,0,inData)
        }
      }
    }
  }

  async setListIndex(data, oriCount){
    for (var i = oriCount; i < data.length; i++) {
      var item = data[i];
      item["listIndex"] = i;
    }
  }

  async setListRefresh(){
    location.reload();
  }

  async getSearchList(page, sortOrder=1){
    if (this._isMounted) {
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }

      const userProfileAppState = this.appContext.appState?.userProfile;
      const userProfile = await CommonFunction.getProfile();
      if(userProfileAppState?.memberId!=userProfile?.memberId){
        await this.setColumn(userProfile);
        this.appContext.appState.userProfile = userProfile;
        this.appContext?.setAppState(this.appContext.appState);
      }

      let searchInfo = await CommonFunction.getSearchHistory(userProfile?.memberId);
      let searchMale = false;
      if(userProfile?.sex==Constants.FEMALE){
        searchMale = true;
      }

      this.searchParams.page = page;
      this.searchParams.sortOrder = sortOrder;
      if(searchInfo!=null){
        this.searchParams.mainImage = searchInfo.mainImage?1:0;
        this.searchParams.currentPref = CommonFunction.makeTabData(searchInfo.adds);
        if(Array.isArray(searchInfo.age) && searchInfo.age.length>0){
          this.searchParams.ageFrom = searchInfo.age[0];
          this.searchParams.ageTo = searchInfo.age[1];
        }else{
          this.searchParams.ageFrom = '';
          this.searchParams.ageTo = '';
        }
        if(Array.isArray(searchInfo.height) && searchInfo.height.length>0){
          this.searchParams.heightFrom = searchInfo.height[0];
          this.searchParams.heightTo = searchInfo.height[1];
        }else{
          this.searchParams.heightFrom = '';
          this.searchParams.heightTo = '';
        }
        this.searchParams.style = CommonFunction.makeTabData(searchInfo.style);
        this.searchParams.occupation = CommonFunction.makeTabData(searchInfo.occupation);
        this.searchParams.education = CommonFunction.makeTabData(searchInfo.education);
        this.searchParams.birthCity = CommonFunction.makeTabData(searchInfo.born);
        if(typeof searchInfo.alcohol.id !='undefined'){
          this.searchParams.alcohol = searchInfo.alcohol.id;
        }else{
          this.searchParams.alcohol = 0;
        }
        if(typeof searchInfo.cigarette.id !='undefined'){
          this.searchParams.cigarette = searchInfo.cigarette.id;
        }else{
          this.searchParams.cigarette = 0;
        }
        this.searchParams.holiday = CommonFunction.makeTabData(searchInfo.holiday);
        this.searchParams.dateHope = CommonFunction.makeTabData(searchInfo.dateHope);
        this.searchParams.meetHope = CommonFunction.makeTabData(searchInfo.meetHope);
        if(typeof searchInfo.lastLoginTime.id !='undefined'){
          this.searchParams.lastLoginTime = searchInfo.lastLoginTime.id;
        }else{
          this.searchParams.lastLoginTime = 0;
        }
        this.searchParams.recentJoin = searchInfo.recentJoin?1:0;
       
        if(searchMale){
          this.searchParams.income = CommonFunction.makeTabData(searchInfo.income);
          this.searchParams.assets = CommonFunction.makeTabData(searchInfo.assets);
        }else{
          this.searchParams.vibe = CommonFunction.makeTabData(searchInfo.vibe);
          this.searchParams.personality = CommonFunction.makeTabData(searchInfo.personality);
        }
      }
      
      try{
        const getListResponse = await Search03Api.SearchProfileGetList(this.searchParams);     
        //check response status
        if(!CommonFunction.checkResponseStatus(getListResponse,false)) return;
        
        if(getListResponse!=undefined && getListResponse?.status==1){
          CommonFunction.setAccessTime();

          StorageHelper.storeData('ikuyoStatus', Number(getListResponse.ikuyoStatus));
          if(this.props.setIkuyoStatus!=undefined){
            this.props?.setIkuyoStatus(Number(getListResponse.ikuyoStatus));
          }

          const account = UserUtil.getAccountAppState(getListResponse, this.appContext);          
          let trialPlan = getListResponse?.response?.trialPlan;
          let saveTrialPlan = await StorageHelper.getObjectData('trialPlan');
          StorageHelper.storeData('trialPlan', trialPlan);
          if (saveTrialPlan!=undefined && saveTrialPlan!=trialPlan) {
            if (saveTrialPlan==true) {
              this.setListRefresh();
            }
          }
          const profileList = getListResponse?.response?.profileList;
          let data = this.state.data;
          //if(Platform.OS!=='web' && this.state.isRefreshing) data = [];
          if(this.state.isRefreshing) data = [];
          let longRefreshControl = true;
          if(profileList!=undefined){
            if(profileList?.count > 0){
              let oriCount = data.length;
              data = data.concat(profileList?.list);
              CommonFunction.thumbnailUrlConver(data);
              if (!searchMale) {
                if (trialPlan==true) {
                  // 男性でお試しプラン購入可能な場合
                  this.setTrialIconData(data,oriCount);
                }
              }
              this.setListIndex(data,oriCount);
              if(Platform.OS==='android'){
                //cache image
                let count = 1;
                for (let ele of profileList?.list) {
                  const cacheUrl = ele?.tmMainImageUrl
                  if(cacheUrl){
                    if(count<=12){
                      await Image.prefetch(cacheUrl);
                    }else{
                      Image.prefetch(cacheUrl);
                    }
                    count++;
                  }
                }
              }
            }
            this.no_more = (profileList?.nextVal == 0)?1:0;
            if(data?.length >0){
              longRefreshControl = false;
            }
          }
          this.setState({
            account: account,
            data: data,
            sortOrder: sortOrder,
            longRefreshControl: longRefreshControl
          });

          this.setPrivilegeFlag(getListResponse?.response?.privilegeFlag)
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.loadComplete(); 
      }
    }
  }
}
