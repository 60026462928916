import AgeManager from "./AgeManager";

export default class AgeWrapper {
  static showAge(...args){
    const ref = AgeManager?.getDefault();
    ref?.show(...args);
  }

  static showAgePartner(...args){
    const ref = AgeManager?.getDefault();
    ref?.showPartner(...args);
  }

  static hideAge(){
    const ref = AgeManager?.getDefault();
    ref?.hide();
  }
}