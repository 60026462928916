import React, { PureComponent } from 'react';
import { StyleSheet, View, Image, Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export default class Logo extends PureComponent {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <View style={{marginLeft:windowWidth<375? 30:36}}>
        <Image source={require('../../assets/images/logo-txt.png')} style={{width:windowWidth<375? 56:64,height:windowWidth<375? 21:24,}} resizeMode="contain"/>  
      </View>
    );
  }
}

const styles = StyleSheet.create({
  
});