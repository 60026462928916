import Colors from './Colors';

export default {
  labelStyle: { 
    fontSize: 15,
    marginTop:-4
  },
  labelStyle2: { 
    fontSize: 15,
    marginTop:-4
  },
  style: { 
    backgroundColor: '#ffffff', 
    borderRadius:5, 
    borderTopLeftRadius: 0, 
    borderTopRightRadius: 0, 
    borderBottomColor:'#9aa5a7',
    borderBottomWidth:1, 
    height:37, 
    shadowColor: 'transparent', 
    elevation: 0, 
  },
  style2: { 
    backgroundColor: '#ffffff', 
    borderBottomColor:'#97a2a4',
    borderBottomWidth:1, 
    height:37, 
    shadowColor: 'transparent', 
    elevation: 0, 
  },
  indicatorStyle: {
    borderBottomWidth:3, 
    borderBottomColor: Colors.TabBarBackgroundColor,
    backgroundColor: Colors.TabBarBackgroundColor,
  },
  activeTintColor: Colors.activeColor,
  inactiveTintColor: Colors.inactiveColor,
};