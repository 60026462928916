import React, { Component } from 'react';
import {Dimensions, View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import * as RootNavigation from '../../navigation/RootNavigation';
import MailBoxApi from "../../services/api/MailBox05Api";
import CommonFunction from '../../utils/CommonFunction';
import PopupWrapper from '../../components/common/PopupWrapper';

const windowWidth = Dimensions.get('window').width;

export default class FavoriteSetting extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      rowIndex: '',
      show: false,
      item: [],
    }
    this.InputFavorite = 0;
    this.messageBoxId = null;
    this.rowData = [];
    this.InputFavoriteNames = [];
    this.nonFavoriteName = '名称未設定';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (param)=>{
    this.InputFavorite = param.targetFavorite;
    this.messageBoxId = param.targetMessageBoxId;
    this.rowData = param.targetRowData;
    this.InputFavoriteNames = param.favoriteNames;
    this.setState({ selected: this.InputFavorite , show: true });
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  setFavorite = async()=>{
    if (this.InputFavorite!=this.state.selected) {
      const params = {messageBoxId: this.messageBoxId, favorite: this.state.selected};
      const favoriteResponse = await MailBoxApi.Favorite(params);
      if(!CommonFunction.checkResponseStatus(favoriteResponse)) return;
      if(favoriteResponse?.status==1){
        PopupWrapper.showMessage('設定しました','check',()=>{
          CommonFunction.hideOverlay();
        });
        const params = { targetMessageBoxId: this.messageBoxId, targetFavorite: this.state.selected, targetRowData: this.rowData };
        this.props.favoriteUpdata(params)
      }
    } else {
      PopupWrapper.showMessage('設定しました','check',()=>{
        CommonFunction.hideOverlay();
      });
    }
    this.hide();
  }

  setReFavorite = async()=>{
    if (this.InputFavorite!=0) {
      const params = {messageBoxId: this.messageBoxId, favorite: 0};
      const favoriteResponse = await MailBoxApi.Favorite(params);
      if(!CommonFunction.checkResponseStatus(favoriteResponse)) return;
      if(favoriteResponse?.status==1){
        PopupWrapper.showMessage('設定しました','check',()=>{
          CommonFunction.hideOverlay();
        });
        const params = { targetMessageBoxId: this.messageBoxId, targetFavorite: 0, targetRowData: this.rowData };
        this.props.favoriteUpdata(params)
      }
    } else {
      PopupWrapper.showMessage('設定しました','check',()=>{
        CommonFunction.hideOverlay();
      });
    }
    this.hide();
  }

  favoriteNameChange = (slectNameChange)=>{
    this.hide();
    const params = {selected: this.state.selected, slectNameChange: slectNameChange};
    this.props.nameSetting(params);
  }

  changeDispText = (textData)=>{
    if (textData.length==0) {
      textData = this.nonFavoriteName;
    }
    return textData;
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
            <View style={styles.closeBtnArea}>
              <TouchableOpacity style={styles.closeBtn} onPress={()=>{
                this.hide();
              }}>
                <Image resizeMode={'cover'} style={{width:36, height:36}} source={require('../../assets/images/icon/close.png')}/>
              </TouchableOpacity>
            </View>
            <View style={styles.textArea}>
              <View style={{bottom: 0, width: '100%', height: 70}}>
                <Text style={{fontSize: 20, fontWeight: 'bold', color: '#333', textAlign: 'center', lineHeight: 45}}>
                  お気に入りマーク仕分け設定
                </Text>
                <Text style={{fontSize:windowWidth<375? 10 : 11, color: '#333', textAlign: 'center', lineHeight: 20}}>
                  お相手を三段階で仕分け設定できます。
                </Text>
              </View>
            </View>
            <View style={styles.btnFavoritbaseArea}>
              <View style={styles.btnFavoritbase}>
                <TouchableOpacity style={styles.btnFavoriteArea} onPress={()=>{
                  this.setState({ selected: 1 });
                }}>
                  {this.state.selected==1?
                    <Image style={styles.favoritImage1} source={require('../../assets/images/favorite/favoriteSetting_08.png')} />
                  :
                    <Image style={styles.favoritImage1} source={require('../../assets/images/favorite/favoriteSetting_07.png')} />
                  }
                  <Text style={styles.nameText}>{this.changeDispText(this.InputFavoriteNames.single)}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.nameChangeBtn} onPress={()=>{
                  this.favoriteNameChange(1);
                }}>
                  <Text style={styles.nameChangeBtnText}>名称を変更</Text>
                  <Image style={styles.nameChangeBtnIcon} source={require('../../assets/images/favorite/favoriteNameSetIcon.png')} />
                </TouchableOpacity>
              </View>
              <View style={[styles.btnFavoritbase, {borderRightWidth: 2,  borderLeftWidth: 2, borderColor: "#eef1f2"}]}>
                <TouchableOpacity style={styles.btnFavoriteArea} onPress={()=>{
                  this.setState({ selected: 2 });
                }}>
                  {this.state.selected==2?
                    <Image style={styles.favoritImage2} source={require('../../assets/images/favorite/favoriteSetting_10.png')} />
                  :
                    <Image style={styles.favoritImage2} source={require('../../assets/images/favorite/favoriteSetting_09.png')} />
                  }
                  <Text style={styles.nameText}>{this.changeDispText(this.InputFavoriteNames.double)}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.nameChangeBtn} onPress={()=>{
                  this.favoriteNameChange(2);
                }}>
                  <Text style={styles.nameChangeBtnText}>名称を変更</Text>
                  <Image style={styles.nameChangeBtnIcon} source={require('../../assets/images/favorite/favoriteNameSetIcon.png')} />
                </TouchableOpacity>
              </View>
              <View style={styles.btnFavoritbase}>
                <TouchableOpacity style={styles.btnFavoriteArea} onPress={()=>{
                  this.setState({ selected: 3 });
                }}>
                  {this.state.selected==3?
                    <Image style={styles.favoritImage3} source={require('../../assets/images/favorite/favoriteSetting_12.png')} />
                  :
                    <Image style={styles.favoritImage3} source={require('../../assets/images/favorite/favoriteSetting_11.png')} />
                  }
                  <Text style={styles.nameText}>{this.changeDispText(this.InputFavoriteNames.triple)}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.nameChangeBtn} onPress={()=>{
                  this.favoriteNameChange(3);
                }}>
                  <Text style={styles.nameChangeBtnText}>名称を変更</Text>
                  <Image style={styles.nameChangeBtnIcon} source={require('../../assets/images/favorite/favoriteNameSetIcon.png')} />
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.btnArea}>
              <View style={{paddingTop: 0}}>
                <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                  this.setFavorite();
                }}>
                  <Text style={styles.txtBtnBlue}>決定</Text>
                </TouchableOpacity>
              </View>
              <View style={{paddingTop: 20}}>
              <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                this.setReFavorite();
              }}>
                <Text style={styles.txtBtnCancel}>リセット</Text>
              </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:338, 
    width:'90%',
    alignSelf:'center', 
    borderRadius:8,
  },
  closeBtnArea:{
    paddingTop: -36,
  },
  closeBtn:{
    position:'absolute',
    right:5,
    bottom:0,
  },
  btnFavorite:{
    width:'100%',
    height:70,
  },
  btnBlue:{
    width:178,
    height:34,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignSelf:'center', 
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:34,
    color:'#fff',
    textAlign:'center',
  },
  btnCancel:{
    width:120,
    borderRadius:999,
    backgroundColor:'#eef1f2',
    height:20,
    alignSelf:'center', 
  },
  txtBtnCancel:{
    fontSize:12,
    lineHeight:18,
    color:'#333',
    textAlign:'center',
  },
  textArea: {
    paddingTop: 15,
  },
  btnArea: {
    itemAlign:'center',
    paddingTop:15,
  },
  btnFavoritbaseArea:{
    flexDirection: 'row',
    paddingTop:0,
    width:windowWidth<375? 290 : 324,
    height:140,
    alignSelf: 'center', 
  },
  btnFavoritbase:{
    flexDirection: 'column',
    paddingTop:0,
    width:windowWidth<375? 97 : 108,
    height:90,
    alignSelf: 'center', 
  },
  btnFavoriteArea:{
    width:windowWidth<375? 97 : 108,
    height:50,
    alignItems: 'center',
    alignSelf: 'center', 
  },
  favoritImage1:{
    width:84,
    height:25,
    alignItems: 'center',
    alignSelf: 'center', 
  },
  favoritImage2:{
    width:84,
    height:25,
    alignItems: 'center',
    alignSelf: 'center', 
  },
  favoritImage3:{
    width:84,
    height:25,
    alignItems: 'center',
    alignSelf: 'center', 
  },
  nameText:{
    fontSize:windowWidth<375? 9 : 10,
    flexDirection: 'row',
    color:'#333',
    textAlign: 'center',
    marginTop:20,
    width:106,
    height:20,
    alignItems: 'center',
    alignSelf: 'center', 
  },
  nameChangeBtn:{
    flexDirection: 'row',
    marginTop: 15,
    paddingTop:0,
    width:windowWidth<375? 80 : 84,
    height:24,
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor:'#eef1f2',
  },
  nameChangeBtnText:{
    fontSize:windowWidth<375? 9 : 10,
    flexDirection: 'row',
    color:'#333',
    textAlign: 'center',
    marginTop: 10,
    lineHeight: 15,
    paddingTop:0,
    width:windowWidth<375? 80 : 84,
    height:24,
    alignItems: 'center',
    alignSelf: 'center',
  },
  nameChangeBtnIcon:{
    marginLeft:-12,
    width:8,
    height:8,
  },
});