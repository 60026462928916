import React, { Component } from 'react';
import { View, StyleSheet }  from 'react-native';
import { Portal } from 'react-native-paper';

export default class ComponentPopup extends Component {
  _isMounted = false;

  constructor(){
    super();
    this.state = {
      visible: false
    }
  }
  
  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      
    }
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  show = () => {
    this.setState({ visible:true });
  }

  hide = () => {
    this.setState({ visible:false });
  }

  render(){
    if(this.state.visible){
      return (
        <Portal>
          <View style={styles.container}>
            {this.props?.component}
          </View>
        </Portal>
      );
    }
    
    return null;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:'#ffffff',
    justifyContent: 'center',
  }
});