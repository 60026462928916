import React from 'react';
import { View, TouchableOpacity, Platform, Image, Dimensions, Alert, Linking } from 'react-native';
import HeaderOptions from '../constants/HeaderOptions';
import GoBackIcon from '../components/common/GoBackIcon';
import TitleSetting from '../components/common/TitleSetting';
import { showMessage, hideMessage } from "react-native-flash-message";
import StorageHelper from '../utils/StorageHelper2';
import Constants from '../constants/Constants';
import ResponseConstants from '../constants/ResponseConstants';
import HeadStyles from '../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';
import AuthHelper from './AuthHelper';
import * as RootNavigation from '../navigation/RootNavigation';
import PopupWrapper from '../components/common/PopupWrapper';
import ApiConfig from '../constants/ApiConfig';
import CryptoJS from 'crypto-js';
import base64 from 'react-native-base64';
import { nanoid } from 'nanoid/non-secure';
import Msg from "../constants/option/Msg.json";
import moment from 'moment';
import OverlayWrapper from '../components/common/OverlayWrapper';
import ContextWrapper from '../components/common/ContextWrapper';
import Logo from '../components/common/Logo';
import LogoNoMargin from '../components/common/LogoNoMargin';
import ExpoConstants from 'expo-constants';
import { manipulateAsync } from 'expo-image-manipulator';
import ShortcutBadge from 'react-native-app-badge/badge.ios';
import { compare } from 'compare-versions';
import appJson from '../app.json';
const version = appJson.expo.version;
const windowWidth = Dimensions.get('window').width;
const ENV_KEY_PRODUCT = 'https://www.194964.com/';
const ENV_KEY_STAGE = 'https://www-stage.194964.com/';
const SCHEME_KEY_APP = 'ikukuru://';
const STORE_KEY_APPLE = 'https://apps.apple.com/jp/app/6449415798';
const STORE_KEY_GOOGLE = 'https://play.google.com/store/apps/details?id=jp.prosgate.pappy.app';

const removeWithdrawState = async(state, save=true)=>{
  const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';
  if(state==undefined){
    let savedStateString = await StorageHelper.getData(PERSISTENCE_KEY);
    state = savedStateString ? JSON.parse(savedStateString) : undefined;
  }

  if(state==undefined) return;

  const keyAuth = state?.routes?.findIndex(item => 'Auth' === item.name);
  if(keyAuth==-1) return state;
  
  const keyHome = state?.routes[keyAuth]?.state?.routes?.findIndex(item => 'Home' === item.name);
  if(keyHome==-1) return state;
  
  const keyMypageStack = state?.routes[keyAuth]?.state?.routes[keyHome]?.state?.routes?.findIndex(item => 'MyPageStack' === item.name);
  if(keyMypageStack==-1) return state;
  
  let mypageStateComponent = [{
    name : 'MyPage',
    key: `MyPage-${nanoid()}`
  }]

  if(state?.routes[keyAuth]?.state?.routes[keyHome]?.state?.routes[keyMypageStack]?.state !=undefined){
    let stateComponent = state.routes[keyAuth].state.routes[keyHome].state.routes[keyMypageStack].state.routes;
    let newStateComponent = stateComponent.filter(function (item) {
      return (item.name != 'Withdraw' && item.name != 'WithdrawInput' && item.name != 'WithdrawConfirm')
    });
    if(newStateComponent?.length==0){
      newStateComponent = mypageStateComponent;
    }

    state.routes[keyAuth].state.routes[keyHome].state.routes[keyMypageStack].state.routes = newStateComponent;
  }else{
    state.routes[keyAuth].state.routes[keyHome].state.routes[keyMypageStack].state = {
      index: 0,
      routes: mypageStateComponent
    };
  }

  if(save){
    StorageHelper.storeObjectData(PERSISTENCE_KEY, state);
  }
  
  return state;
}

function setHeaderSetting(navigation, title, border=true, fromContact=0){
  navigation.setOptions({
    header: () => (
      <View style={border?HeadStyles.customHeader:HeadStyles.customHeaderNoBorder}>
        <View style={[HeadStyles.touchIcBack, {flexDirection:'row',left:16}]}>
          <TouchableOpacity onPress={async()=> {
            if(fromContact==1 && Platform.OS==='web'){
              removeWithdrawState();
              window.location.href = ApiConfig.contact.url+'contact-form';
            }else{
              navigation.goBack();
            }
          }}>
            <Entypo name={'chevron-thin-left'} style={HeadStyles.icBack2}/>
          </TouchableOpacity>
          <LogoNoMargin/>
        </View>
        <View style={{flex:1, alignItems:'stretch'}}><TitleSetting title={title} numberOfLines={1} /></View>
      </View>
    )
  });
}

function setHeaderTitle(navigation, title){
  let headerStyle = HeaderOptions.HeaderSetting[0];
  if(Platform.OS==='ios'){
    headerStyle.height = ExpoConstants.statusBarHeight+HeaderOptions.headerTopMenuHeight;
  }

  navigation.setOptions({
    headerStyle: headerStyle,
    headerTitleAlign:'center',
    headerTitle: () => <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
      <TitleSetting title={title} numberOfLines={1} /></View>,
    headerLeft: () => <GoBackIcon navigation={navigation} />,
    headerRight: () => <View/>
  });
}

function setHeaderTitleWithLogo(navigation, title){
  let headerStyle = HeaderOptions.HeaderSetting[0];
  if(Platform.OS==='ios'){
    headerStyle.height = ExpoConstants.statusBarHeight+HeaderOptions.headerTopMenuHeight;
  }

  navigation.setOptions({
    headerStyle: headerStyle,
    headerTitleAlign:'center',
    headerTitle: () => <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
      <TitleSetting title={title} numberOfLines={1} /></View>,
    headerLeft: () => <View style={{flexDirection:'row'}}><GoBackIcon navigation={navigation} />
      <View>
        <Image source={require('../assets/images/logo-txt.png')} style={{width:windowWidth<375? 56:64,height:windowWidth<375? 21:24,}} resizeMode="contain"/>  
      </View>
    </View>,
    headerRight: () => <View/>
  });
}

function overLay(){
  showMessage({
    message: '',
    type: "default",
    backgroundColor:'rgba(255,255,255,0)',
    autoHide:false,
    hideOnPress: false,
    style: {
      flex:1,
      height:'100%', 
      width:'100%',
      padding:0,
      margin:0,
    }
  });
}

function hideOverlay(){
  hideMessage();
}

function makeTabData(obj){
  let array = [];
  if(typeof obj=='object'){
    obj.map((val) => {
      if(val) array.push(val.id)  
    });
  }

  if(array.length>0) 
    return array.join('\t');
  else 
    return 0;  
}

function makeTabValue(obj){
  let array = [];
  if(typeof obj=='object'){
    obj.map((val) => {
      if(val) array.push(val.value)  
    });
  }

  if(array.length>0) 
    return array.join(',');
  else 
    return 0;  
}

function makeId(obj, separate="_"){
  let array = [];
  if(typeof obj=='object'){
    obj.map((val) => {
      if(val) array.push(val.id)  
    });
  }

  if(array.length>0) 
    return array.join(separate);
  else 
    return '';  
}

function checkResponseField(response={}, checkResponse=[], defaultResponse={}){
  let notFound = [];
  let $return = {notFound:[], response:{}};
  for (const element of checkResponse) {
    if(!response.hasOwnProperty(element)){
      notFound.push(element);
      if(defaultResponse[element]!=undefined){
        response[element] = defaultResponse[element];
      }
    }
  }
  $return.notFound = notFound;
  $return.response = response;

  return $return;
}

function checkAllResponseField(response={}, checkResponse=[]){
  let notFound = [];
  const allKeys = getKeys(response);
  for (const element of checkResponse) {
    if(allKeys?.indexOf(element)==-1){
      notFound.push(element);
    }
  }

  return notFound;
}

function getKeys(o) {
  let result = [];
  for (let key in o) {
    result.push(key);
    if(o[key] && typeof o[key] === "object") result.push(...getKeys(o[key]));
  }

  result = result.filter((val, id, array) => {
    return array.indexOf(val) == id;  
  }).filter((val, id, array) => {
    return isNaN(val);  
  });

  return result;
}

async function getProfile(userProfile){
  if(!userProfile || userProfile==undefined){
    userProfile = await StorageHelper.getObjectData('userProfile');
  }
  
  if(userProfile){
    let responseField = checkResponseField(userProfile, ResponseConstants.ProfileGetCheck, ResponseConstants.DefaultProfileGetCheck);
    if(__DEV__){
      console.log('ProfileGetCheck: ', responseField.notFound.join(','));
    }

    return responseField.response;
  }else return {};
}

function getMainImage(list){
  let $return = {pictureId: null, url: null, mainFlag: null, certify: null};
  list.forEach(obj => {
    if(obj.mainFlag==1){
      $return = obj;
      return $return;
    }
  });

  return $return;
}

function checkProfileImage(list){
  let $return = {pictureId: null, url: null, mainFlag: null, certify: null};
  if(list!=undefined && list!=null) $return = list;

  return $return;
}

function listHeight(min=130, max=220){
  let ListHeight = [{"id":0, "value":'未設定'}];
  for(let i=min; i<=max; i++){
    ListHeight.push({"id":i, "value":i});
  }

  return ListHeight;
}

function resetKeys(array){
  let compactArray = array.filter(function (item) {
    return item !== undefined;
  });

  return compactArray;
}

function checkValue(value){
  if(value!=undefined) return value;
  else return null;
}

function checkUndefined(value){
  if(value!=undefined) return true;
  else return false;
}

function getJPWeekday(day){
  const weekDays = ['日', '月', '火', '水', '木', '金', '土'];
  return weekDays[day];
}

function checkResponseStatus(response, checkAccess=true){
  let validAccessToken = true;
  if(checkUndefined(response)){
    const errorGroup = response?.response?.errorGroup;
    const errorCode = response?.response?.errorCode;
    const code = `${errorGroup}-${errorCode}`;

    if(response?.status==0 ){
      if(Constants.InvalidAccessTokenCode.indexOf(code) >-1 || Constants.MaintenanceCode.indexOf(code) >-1){
        validAccessToken = false;
      }
    }
  }

  hideOverlay();
  if(validAccessToken && checkAccess){
    //checkAccessTime();
  }

  return validAccessToken;
}

function checkInvalidErrorCode(code, errorMessage){
  let validAccessToken = true;
  if(checkUndefined(code)){    
    if(Constants.InvalidAccessTokenCode.indexOf(code) >-1){ 
      validAccessToken = false;
      // goto top page
      AuthHelper.logout();
    }else if(Constants.InvalidAccount.indexOf(code) >-1){
      validAccessToken = false;
      // show popup, click ok to logout
      PopupWrapper?.showModalMessage(errorMessage, null, ()=>{
        AuthHelper.logout();
      });
    }else if(Constants.MaintenanceCode.indexOf(code) >-1){
      validAccessToken = false;
      // show popup, click ok to logout
      PopupWrapper?.showModalMessage(errorMessage, null, ()=>{
        AuthHelper.logout();
      });
    }
  }
  
  return validAccessToken;
}

function checkErrorCode(response, auth=true, modal=false, icon='warning'){
  let res = response.response;
  if(res==undefined) res = response;

  if(res!=undefined){
    const errorGroup = res?.errorGroup;
    const errorCode = res?.errorCode;
    let errorMessage = res?.errorMessage;
    const code = `${errorGroup}-${errorCode}`;
    
    if(auth==true){
      //check errorCode
      if(!checkInvalidErrorCode(code, errorMessage)) return;
    }

    if(errorMessage){
      if(Constants.NotShowErrorCode.indexOf(code)==-1) {
        /*
        if(code=='99-00001'){
          errorMessage = Msg.CheckTelError; 
        }
        */
        if(code=='99-00015'){
          errorMessage = 'お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。'; 
        }

        if(Constants.RemainGoodCountCode.indexOf(code) >-1){
          //AuthHelper.showError(errorMessage, icon);
          PopupWrapper.showModalMessage(errorMessage, '', null, false);
        }else if(Constants.CheckTelCode.indexOf(code) >-1){
          errorMessage = Msg.CheckTelApiError;
          PopupWrapper.setOptions({textAlign:'left'});
          PopupWrapper.showModalMessage(errorMessage, '', null, false);
        }else{
          if(modal){
            AuthHelper.showModalError(errorMessage, icon);
          }else{
            AuthHelper.showError(errorMessage, icon);
          }
        }
      }
    }
  }
}

function checkErrorCodeWithoutAlert(response, auth=true){
  let res = response.response;
  if(res==undefined) res = response;

  if(res!=undefined){
    const errorGroup = res?.errorGroup;
    const errorCode = res?.errorCode;
    const errorMessage = res?.errorMessage;
    const code = `${errorGroup}-${errorCode}`;
    
    if(auth==true){
      //check errorCode
      if(!checkInvalidErrorCode(code, errorMessage)) return;
    }
  }
}

function checkIsInvalidErrorCode(response){
  let validAccessToken = true;
  let res = response.response;
  if(res==undefined) res = response;

  if(res!=undefined){
    const errorGroup = res?.errorGroup;
    const errorCode = res?.errorCode;
    const code = `${errorGroup}-${errorCode}`;
    
    if(Constants.InvalidAccessTokenCode.indexOf(code) >-1){ 
      validAccessToken = false;
    }else if(Constants.InvalidAccount.indexOf(code) >-1){
      validAccessToken = false;
    }else if(Constants.MaintenanceCode.indexOf(code) >-1){
      validAccessToken = false;
    }
  }

  return validAccessToken;
}

async function getMatchComplete(keyname){
  let matchComplete = await StorageHelper.getData(keyname);
  if(isNaN(matchComplete)) matchComplete = 0;
  matchComplete = parseInt(matchComplete);

  return matchComplete;
}

async function countMatchComplete(keyname){
  let matchComplete = await getMatchComplete(keyname);
  if(matchComplete > 0){
    matchComplete = matchComplete+1;
    if(matchComplete % (Constants.countMatchComplete+2)==0) matchComplete = 0;
    StorageHelper.storeData(keyname, matchComplete);
  }
}

function CryptoJSAesEncrypt(plain_text){
  let salt = CryptoJS.lib.WordArray.random(8);
  let iv = CryptoJS.lib.WordArray.random(16);
  let key = CryptoJS.PBKDF2(ApiConfig.contact.crypto_key, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });
  let encrypted = CryptoJS.AES.encrypt(plain_text, key, {iv: iv});

  let data = {
    ciphertext : CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    salt : CryptoJS.enc.Hex.stringify(salt),
    iv : CryptoJS.enc.Hex.stringify(iv)    
  }

  return base64.encode(JSON.stringify(data));
}

function CryptoJSAesDecrypt(encrypted_json_string){
  let obj_json = base64.decode(JSON.parse(encrypted_json_string));
  let encrypted = obj_json.ciphertext;
  let salt = CryptoJS.enc.Hex.parse(obj_json.salt);
  let iv = CryptoJS.enc.Hex.parse(obj_json.iv);   
  let key = CryptoJS.PBKDF2(ApiConfig.contact.crypto_key, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});
  let decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

  return decrypted.toString(CryptoJS.enc.Utf8);
}

function checkMessage(checkMessage){
  if(checkMessage && checkMessage!=''){
    checkMessage = checkMessage?.replaceAll("\n","");      
    checkMessage = checkMessage?.replaceAll(" ","");
    checkMessage = checkMessage?.replaceAll("　",""); // space 2 byte
  }
  
  return checkMessage;
}

async function getEncryptAccessToken (){
  const accessToken = await StorageHelper.getData('accessToken');
  if(accessToken){
    const encryptedString = CryptoJSAesEncrypt(accessToken);
    return encryptedString;
  }

  return false;
}

function checkWebStage(){
  if(Platform.OS==='web'){
    const initialUrl = window.location.hostname;
    const port = window.location.port;
    if(initialUrl.indexOf('www-stage')!=-1 || initialUrl.indexOf('localhost')!=-1 || port=='19006'){
      return true;
    }
  }
  
  return false;
}

function showBottomTab(navigation){
  if(Platform.OS!='web'){
    showBottomTabApp(navigation);
    return;
  }

  navigation?.getParent()?.setOptions({ tabBarStyle: 
    { 
      display: 'flex', 
      position: 'absolute', 
      bottom: 0
    }
  });
}

function showBottomTabApp(navigation){
  navigation?.getParent()?.setOptions({ tabBarStyle: 
    { 
      display: 'flex'
    }
  });
}

function hideBottomTabApp(navigation){
  navigation?.getParent()?.setOptions({ tabBarStyle: 
    { 
      display: 'none'
    }
  });
}

function hideBottomTab(navigation){
  if(Platform.OS!='web'){
    hideBottomTabApp(navigation);
    return;
  }

  navigation?.getParent()?.setOptions({ tabBarStyle: { display: 'none' } });
}

function setMainBottomTab(BottomTabNavigation, display){
  if(Platform.OS!='web'){
    setMainBottomTabApp(BottomTabNavigation, display);
    return;
  }

  if(display=='none'){
    BottomTabNavigation?.setOptions({tabBarStyle: {display: 'none'}});
  }else{
    BottomTabNavigation?.setOptions({tabBarStyle: {display: display, position: 'absolute',  bottom: 0 }});
  }
}

function setMainBottomTabApp(BottomTabNavigation, display){
  let showDisplay = 'flex';
  if(display=='none') showDisplay = 'none';
  BottomTabNavigation?.setOptions({ tabBarStyle: 
    { 
      display: showDisplay
    }
  });
}

function getIineRemainGoodCountImg(remainGoodCount){
  remainGoodCount = parseInt(remainGoodCount);
  switch(remainGoodCount){
    case 0:
      return require('../assets/images/aitai/0.png');
    break;
    case 1:
      return require('../assets/images/aitai/1.png');
    break;
    case 2:
      return require('../assets/images/aitai/2.png');
    break;
    case 3:
      return require('../assets/images/aitai/3.png');
    break;
    case 4:
      return require('../assets/images/aitai/4.png');
    break;
    case 5:
      return require('../assets/images/aitai/5.png');
    break;
    case 6:
      return require('../assets/images/aitai/6.png');
    break;
    case 7:
      return require('../assets/images/aitai/7.png');
    break;
    case 8:
      return require('../assets/images/aitai/8.png');
    break;
    case 9:
      return require('../assets/images/aitai/9.png');
    break;
    case 10:
      return require('../assets/images/aitai/10.png');
    break;
  }

  return false;
}

function getRemainGoodCountImg(remainGoodCount){
  remainGoodCount = parseInt(remainGoodCount);
  switch(remainGoodCount){
    case 0:
      return require('../assets/images/aitai/aitai_0.png');
    break;
    case 1:
      return require('../assets/images/aitai/aitai_1.png');
    break;
    case 2:
      return require('../assets/images/aitai/aitai_2.png');
    break;
    case 3:
      return require('../assets/images/aitai/aitai_3.png');
    break;
    case 4:
      return require('../assets/images/aitai/aitai_4.png');
    break;
    case 5:
      return require('../assets/images/aitai/aitai_5.png');
    break;
    case 6:
      return require('../assets/images/aitai/aitai_6.png');
    break;
    case 7:
      return require('../assets/images/aitai/aitai_7.png');
    break;
    case 8:
      return require('../assets/images/aitai/aitai_8.png');
    break;
    case 9:
      return require('../assets/images/aitai/aitai_9.png');
    break;
    case 10:
      return require('../assets/images/aitai/aitai_10.png');
    break;
  }

  return false;
}

function checkErrorRemainGoodCount(response){
  let res = response.response;
  if(res==undefined) res = response;

  if(res!=undefined){
    const errorMessage = res?.errorMessage;
    const errorGroup = res?.errorGroup;
    const errorCode = res?.errorCode;
    const code = `${errorGroup}-${errorCode}`;
    if(Constants.RemainGoodCountCode.indexOf(code) >-1){
      PopupWrapper.showModalMessage(errorMessage,'',null,false);
      return true;
    }
  }
  return false;
}

function replaceTemplate(string, replaceVar){
  if(string){
    string = string?.split("__TARGET_NAME__").join(replaceVar?.name);
  }
  return string;
}

function createTemplate(template){
  let newTemplate = [];
  if(template){
    let counter = 1;
    const myTemplate = template?.myTemplate;
    if(myTemplate){
      myTemplate?.map((e,i) => {
        let text2 = '';
        if(e.text) text2 = e.text?.replaceAll("\n","");
        let data = {
          id: e.id,
          text: e.text,
          text2: text2,
          counter: counter,
          myTemplate: 1
        }
        newTemplate.push(data);
        counter++;
      });
    }
    counter = 1;
    const defaultTemplate = template?.defaultTemplate;
    if(defaultTemplate){
      defaultTemplate?.map((e,i) => {
        let text2 = e.text;
        //if(e.text) text2 = e.text.replaceAll("\n","");
        let data = {
          id: e.id,
          text: e.text,
          text2: text2,
          counter: counter,
          myTemplate: 0
        }
        newTemplate.push(data);
        counter++;
      });
    }
  }

  return newTemplate;
}

function setAccessTime(){
  StorageHelper.storeData('accessTime', moment().unix());
}

async function backToIkuyo(){
  const origin = await StorageHelper.getData('origin');
  const ikuyoStatus = await StorageHelper.getData('ikuyoStatus');
  if (Number(ikuyoStatus)==1) {
    if (origin=='ios') {
      const agent = window.navigator.userAgent.toLowerCase()
      if (agent.indexOf("edg") != -1) {
      } else if (agent.indexOf("edge") != -1) {
      } else if (agent.indexOf("firefox") != -1) {
      } else if (agent.indexOf("fxios") != -1) {
      } else {
        if ((agent.indexOf("chrome") != -1) || (agent.indexOf("safari") != -1)) {
          open(SCHEME_KEY_APP);
        }
      }
    } else {
      if(this.checkWebStage()){
        open(ENV_KEY_STAGE);
      } else {
        open(ENV_KEY_PRODUCT);
      }
    }
  }
}

async function checkAccessTime(){
  const accessTime = await StorageHelper.getData('accessTime');
  const lastAccessTime = moment().unix();
  const diffTime = lastAccessTime - accessTime;
  setAccessTime();
  if(diffTime > ApiConfig.noActivityTime){ 
    OverlayWrapper.show();
    if(ContextWrapper!=undefined){
      ContextWrapper?.getContext(({appState, setAppState})=>{
        appState.reloadApp = 1;
        if(setAppState!=undefined){
          setAppState(appState);
        }
      });
    }

    setTimeout(() => {
      try{
        RootNavigation.navigate('Search', { screen:'SearchList'});
      }catch(e){
        RootNavigation.navigate('Auth', { screen:'Home', params: { screen:'Search', params: { screen:'SearchList' }}});
      }
    }, 1000);

    setTimeout(() => {
      OverlayWrapper.hide();
    }, 1500);

    return false;
  }
  return true;
}

function checkBottomTab(navigation, checkPrevRoute){
  let showBottomTab = true;
  const routes = navigation?.getState()?.routes;
  if(routes!=undefined && checkPrevRoute!=undefined){
    const prevRoute = routes[routes.length - 1];
    if(checkPrevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
  }
  return showBottomTab;
}

function getPrevRoute(navigation){
  if(navigation==undefined) return '';

  let routeName = '';
  const routes = navigation?.getState()?.routes;
  if(routes!=undefined){
    const prevRoute = routes[routes.length - 2];
    if(prevRoute!=undefined){
      routeName = prevRoute?.name;
    }
  }
  return routeName;
}

function gestureEnabled(){
  if(Platform.OS==='web') return true;
  return false;
}

async function resizeImageApp(source, maxWidth=1280){
  if(!source) return undefined;

  let type = source?.split('.')?.pop();
  let mainPath = source;
  if(Platform.OS==='ios'){
    mainPath = source?.replace("file://", "");
  }

  const { imgWidth, imgHeight } = await new Promise((resolve) => {
    Image.getSize(source, (width, height) => {
      resolve({imgWidth: width, imgHeight: height});
    });
  });

  if(imgWidth < maxWidth){
    maxWidth = imgWidth;
  }

  const manipResult = await manipulateAsync(
    source,
    [{ resize: { width: maxWidth } }]
  );

  if(manipResult?.uri!=undefined){
    type = manipResult.uri?.split('.').pop();
    if(Platform.OS==='ios'){
      mainPath = manipResult.uri?.replace("file://", "");
    }else{
      mainPath = manipResult.uri;
    }

    if(__DEV__) {
      console.log('resize file width:'+imgWidth+' to:'+manipResult.width);
    }
  }

  return {
    type: type, 
    mainPath: mainPath
  };
}

function getInitialValues(){
  return {
    mainImage: true,         //メイン写真
    recentJoin: false,        //最近入会
    adds: [],                 //居住地
    age: [],                  //年齢
    height: null,             //身長
    style: [],                //スタイル
    vibe: [],                 //雰囲気
    personality: [],          //性格・タイプ
    occupation: [],           //お仕事
    education: [],            //学歴
    born:[],                  //出身地
    alcohol: [],              //お酒
    cigarette: [],            //タバコ
    dateHope: [],             //デートでしたいこと
    meetHope: [],             //出会うまでの希望
    lastLoginTime: [],        //出会うまでの希望
    assets: [],
    income: [],
    holiday: []               //休日
  }
}

async function getSearchHistory(memberId){
  if(!memberId) return undefined;
  
  let history;
  let searchHistory = await StorageHelper.getObjectData('searchHistory');
  searchHistory = await StorageHelper.getObjectData('searchHistory');
  if(Array.isArray(searchHistory)){
    const searchHistoryIndex = searchHistory?.findIndex(
      item => memberId == item?.memberId
    );
    
    if(searchHistoryIndex!=-1){
      history = searchHistory[searchHistoryIndex]?.data;
    }
  }
  const userProfile = await getProfile();
  if(userProfile.sex!=Constants.FEMALE){
    let isSearchConfigured = await StorageHelper.getObjectData('isSearchConfigured'+memberId);
    if(!isSearchConfigured){
      if(!history){
        history = getInitialValues();
        await setSearchHistory(memberId, history);
      } else{
        history.mainImage = true
        await setSearchHistory(memberId, history);
      }
      await StorageHelper.storeObjectData('isSearchConfigured'+memberId, true);
    }
  }
  return history;
}

async function setSearchHistory(memberId, values){
  if(!memberId) return;

  const searchValues = {
    memberId: memberId, 
    data: values
  };
  let searchHistory = await StorageHelper.getObjectData('searchHistory');
  if(!Array.isArray(searchHistory)) searchHistory = [];
  
  const searchHistoryIndex = searchHistory?.findIndex(
    item => memberId == item?.memberId
  );
  if(searchHistoryIndex!=-1){
    searchHistory[searchHistoryIndex] = searchValues;
  }else{
    searchHistory.push(searchValues);
  }
 
  await StorageHelper.storeObjectData('searchHistory', searchHistory);
}

function isObjectEmpty(objectName){
  return JSON.stringify(objectName) === "{}";
}

function iconBadgeUpdata(count){
  if(Platform.OS==='ios') {
    ShortcutBadge.setCount(count);
  }
}

// バージョンチェック（アプリ専用）
function checkAppVersion(minVer, maxVer){
  let les = true;
  let checkMinVer = minVer;
  let checkMaxVer = maxVer;
  if(compare(version, checkMinVer, '<')==true){
    this.showForceVersion(checkMinVer, checkMaxVer);
    les = false;
  } else if(compare(version, checkMaxVer, '<')==true){
    this.showUpgradeVersion();
  }
  return les;
}

async function showForceVersion(minVer, maxVer){
  const isShowVersionPopup = await StorageHelper.getData('isShowVersionPopup');
  if(isShowVersionPopup==0){
    StorageHelper.storeData('isShowVersionPopup', 1);
    
    // 強制的にTopページに遷移させる
    AuthHelper.goTopPage();
    let message = "";
    let btnMessage = "";
    let url = "";
    if(Platform.OS==='ios'){
      message = '最新のバージョンのアプリがあります。お手数ですがAppStoreより最新バージョンのアプリでアップデートをお願いします';
      btnMessage = 'AppStoreを開く';
      url = 'https://apps.apple.com/jp/app/6449415798';
    }else{
      message = '最新のバージョンのアプリがあります。お手数ですがGooglePlayより最新バージョンのアプリでアップデートをお願いします';
      btnMessage = 'GooglePlayを開く';
      url = 'https://play.google.com/store/apps/details?id=jp.prosgate.pappy.app';
    }
    Alert.alert(
      '最新のPappyアプリにアップデートしてください。', message,
    [
      {text: btnMessage, onPress: () => this.moveStoreAction(minVer, maxVer, url)},
    ]);
  }
}

async function showUpgradeVersion(){
  let versionUpData = await StorageHelper.getData('versionUp');
  if (version===versionUpData) {
    return;
  }
  StorageHelper.storeData('versionUp', version);
  let message = "";
  let btnMessage = "";
  let url = "";
  if(Platform.OS==='ios'){
    message = '最新のバージョンのアプリがあります。お手数ですがAppStoreより最新バージョンのアプリでアップデートをお願いします';
    btnMessage = 'AppStoreで確認';
    url = 'https://apps.apple.com/jp/app/6449415798';
  }else{
    message = '最新のバージョンのアプリがあります。お手数ですがGooglePlayより最新バージョンのアプリでアップデートをお願いします';
    btnMessage = 'GooglePlayで確認';
    url = 'https://play.google.com/store/apps/details?id=jp.prosgate.pappy.app';
  }
  Alert.alert(
    '最新のPappyアプリにアップデートしてください。', message,
  [
    {text: '閉じる'},
    {text: btnMessage, onPress: () =>  Linking.openURL(url)},
  ]);
}

async function moveStoreAction(minVer, maxVer, url){
  // ポップアップ表示フラグを初期化
  await StorageHelper.storeData('isShowVersionPopup', 0);

  // ポップアップを再表示してからストアに遷移
  if(compare(version, minVer, '<')==true){
    this.showForceVersion(minVer, maxVer);
    setTimeout(() => {
      Linking.openURL(url);
      }, 100);
  }
}

function animationEnabled(){
  if(Platform.OS==='android') return true;
  return false;
}

function isAndroidUA(){
  // return true;
  var ua = navigator.userAgent;
  if(ua){
    if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('firefox') == -1 && ua.indexOf('Firefox') == -1 ) return true;
  }
  return false;
}

function isAndroidUAOnly(){
  var ua = navigator.userAgent;
  if(ua){
    if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) return true;
  }
  return false;
}

// サムネURL変換
function thumbnailUrlConver(data){
  for (let item of data) {
    var mainImage = item["mainImageUrl"];
    var tmMainImage = mainImage.replace(".jpg", "_300.jpg");
    item["tmMainImageUrl"] = tmMainImage;
  }
}

function mainImageUrlConver(item){
  var mainImage = item["mainImageUrl"];
  var tmMainImage = mainImage.replace(".jpg", "_300.jpg");
  item["tmMainImageUrl"] = tmMainImage;
}

function urlConver(urlStr){
  var mainImage = urlStr;
  var tmMainImage = mainImage.replace(".jpg", "_300.jpg");
  return tmMainImage;
}


export default { 
  setHeaderSetting, 
  overLay, 
  makeTabData, 
  makeTabValue, 
  getProfile, 
  checkResponseField, 
  getMainImage, 
  listHeight, 
  checkProfileImage, 
  resetKeys, 
  checkValue, 
  checkUndefined,
  getJPWeekday,
  checkAllResponseField,
  getKeys,
  makeId,
  setHeaderTitle,
  checkResponseStatus,
  checkErrorCode,
  hideOverlay,
  getMatchComplete,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
  removeWithdrawState,
  checkMessage,
  checkErrorCodeWithoutAlert,
  countMatchComplete,
  getEncryptAccessToken,
  checkWebStage,
  showBottomTab,
  hideBottomTab,
  setMainBottomTab,
  checkIsInvalidErrorCode,
  getRemainGoodCountImg,
  getIineRemainGoodCountImg,
  checkErrorRemainGoodCount,
  replaceTemplate,
  createTemplate,
  checkAccessTime,
  setAccessTime,
  checkBottomTab,
  getPrevRoute,
  setHeaderTitleWithLogo,
  backToIkuyo,
  gestureEnabled,
  showBottomTabApp,
  hideBottomTabApp,
  setMainBottomTabApp,
  resizeImageApp,
  getSearchHistory,
  setSearchHistory,
  isObjectEmpty,
  iconBadgeUpdata,
  checkAppVersion,
  showForceVersion,
  showUpgradeVersion,
  moveStoreAction,
  animationEnabled,
  isAndroidUA,
  isAndroidUAOnly,
  thumbnailUrlConver,
  mainImageUrlConver,
  urlConver
}
