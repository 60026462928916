import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';

/**
 * API: 03_API仕様\レスポンス仕様書\14_バッジ情報取得\バッジ情報取得_レスポンス仕様.xlsx
 */
export default class Badge14Api extends ApiPappy{

  //1.1.1.バッジカウント取得
  static async GetBadge(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/Badge/",
        {},
        true
      );
      if(__DEV__) console.log('/Profile/Get/Badge/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.ProfileGetBadge));
            console.log('/Profile/Get/Badge/', responseField?.join(','));
          }

          if(data?.status==1){
            $return = data;
          }
        }
      }

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}