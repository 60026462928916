import React, { Component } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Platform } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import { Portal } from 'react-native-paper';
import PortalStyle from '../../styles/PortalStyles';
import PopupManager from "./PopupManager";
import Msg from "../../constants/option/Msg.json";

const defaultTimeout = 1500;
const defaultIcon = 'check';

export default class MyPopup extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      show: false,
      message: Msg.Save.Success,
      icon: defaultIcon
    }
    this.timeout = defaultTimeout;
    this.modal = false;
    this.onStartShouldSetResponder = true;
    this.textAlign = 'center';
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      if(this.props.timeout!=undefined) this.timeout = this.props.timeout;
      if(this.props.modal!=undefined) this.modal = this.props.modal;
      PopupManager.register(this);
    }
  }
  
  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
    PopupManager.unregister();
  }

  showPopup = (message, icon='check', hideAction=null) => {
    if(message==undefined && this.props.message!=undefined) message = this.props.message;
    if(hideAction) this.hideAction = hideAction;

    this.setState({
      show: true, 
      message: message, 
      icon: icon
    });

    if(!this.modal){
      // auto hide
      setTimeout(function(){
        this.hidePopup();
      }.bind(this), this.timeout);
    }
  }

  showModalPopup = (message, icon='check', hideAction=null, onStartShouldSetResponder=true) => {
    if(this._isMounted){
      this.modal = true;
      if(message==undefined && this.props.message!=undefined) message = this.props.message;
      if(hideAction) this.hideAction = hideAction;
      this.onStartShouldSetResponder = onStartShouldSetResponder;

      this.setState({
        show: true, 
        message: message, 
        icon: icon
      });
    }
  }

  resetOptions = ()=>{
    this.modal = false;
    this.timeout = defaultTimeout;
    this.onStartShouldSetResponder = true;
    this.textAlign = 'center';
    this.setState({
      message: Msg.Save.Success,
      icon: defaultIcon
    });
  }

  callHideAction = ()=>{
    if(this.hideAction) {
      this.hideAction();
      this.hideAction = null;
    }
  }

  hidePopup = () => {
    this.setState({show: false});
    this.callHideAction();
    this.resetOptions();
  }

  setOptions = ({timeout, modal, icon, onStartShouldSetResponder, textAlign})=>{
    if(timeout!=undefined) this.timeout = timeout;
    if(modal!=undefined) this.modal = modal;
    if(onStartShouldSetResponder!=undefined) this.onStartShouldSetResponder = onStartShouldSetResponder;
    if(textAlign!=undefined) this.textAlign = textAlign;
    if(icon!=undefined) this.setState({icon: icon});
  }

  render(){
    if(!this.state.show) return <></>;

    if(this.modal==true){
      return (
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {
              if(this.onStartShouldSetResponder){
                this.hidePopup();
              }
            }} />
            <View style={styles.container}>
              <View style={[PortalStyle.popupMsgShadow,{paddingTop:20, paddingBottom:20,width:'80%',backgroundColor:'rgba(255, 255, 255,1)'}]}>
                <View style={{paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9'}}>
                  {this.state.icon ? (
                  <AntDesign name={this.state.icon} style={[PortalStyle.icCheck,{fontSize:40}]}/>
                  ):null}
                  <Text style={[PortalStyle.txtPopupMsg2,{textAlign:this.textAlign}]}>{this.state.message}</Text>
                </View>
                <TouchableOpacity onPress={()=>{
                  this.hidePopup();
                }}>
                  <Text style={{textAlign:'center', fontSize:14, fontWeight:'bold', color:'#5888f7', paddingTop:10}}>OK</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Portal>   
      );
    }
    
    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={styles.container}>
            <View style={[PortalStyle.popupMsgShadow, styles.popupWidth]}>
            {this.state.icon ? (
              <AntDesign name={this.state.icon} style={PortalStyle.icCheck}/>
            ):null}
              <Text style={[PortalStyle.txtPopupMsg2,{textAlign:this.textAlign}]}>{this.state.message}</Text>
            </View>
          </View>
        </View>
      </Portal>   
    );
    
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'center',
    textAlign:'center',
    width:'100%',
  },
  popupWidth:{
    ...Platform.select({
      android:{
        width:'75%'
      },
      ios:{
        width:'75%'
      },
      default:{

      }
    })
  }
});
