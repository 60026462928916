import React,{ Component } from 'react';
import { View, Image, Text, StyleSheet, Dimensions, TouchableOpacity, ScrollView } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import Constants from '../constants/Constants';
import { AppContext } from '../AppContext';
import UserUtil from '../utils/UserUtil';
import ListStyles from '../styles/ListStyles';
import HeadStyle from '../styles/HeadStyles';
import HeaderOptions from '../constants/HeaderOptions';
import CommonFunction from '../utils/CommonFunction';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class ProfileImageInfo extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      myProfile: { sex: 0 }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.setHeader();
      this.checkAccountState();
      
      // from back page
      this.subscribeFocus = this.navigation.addListener('focus', () => {
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }  

  checkAccountState = async()=>{
    const {appState, setAppState} = this.context;
    const account = appState?.account;
    let myProfile = await UserUtil.getUserProfileLocal(appState);
    this.setState({ myProfile: myProfile });

    this.setState({ account: account },()=>{

    });
  }

  setHeader = () =>{
    let dispTitle = '写真の設定で返信率が大幅UP!';
    let headerStyle = HeaderOptions.HeaderSetting[0];
    
    this.props.navigation?.setOptions({
    headerStyle: headerStyle,
      headerTitleStyle: HeaderOptions.HeaderIineTitle,
      headerTitleAlign:'center',
    header: () => (
      <View style={HeadStyle.customHeader}>
        <View style={[HeadStyle.touchIcBack,{flexDirection:'row',left:16}]}>
          <TouchableOpacity onPress={()=> this.props.navigation?.goBack()}>
            <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack2}/>
          </TouchableOpacity>
          <LogoNoMargin/>
        </View>
        <View style={{flex:1, alignItems:'stretch'}}><Text style={[HeadStyle.headerTitlePage,{fontSize:13}]}>{dispTitle}</Text></View>
      </View>
    )
    });
  }

  render() {
    const newHeight = windowWidth * 5768 / 1125
          
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
      <ScrollView style={ListStyles.scrollContainer} 
        ref={scroller => { this.scroller = scroller; }} 
        scrollToOverflowEnabled={true}
        scrollIndicatorInsets={{ right: 1 }}>      

        <View style={{flexDirection:'column', alignItems:'center'}}>
        {this.state.myProfile?.sex==Constants.FEMALE?
          <Image style={{width:windowWidth, height:newHeight}} source={require('../assets/images/photo-w.jpg')} resizeMode="contain"/>
        :
          <Image style={{width:'100%', height:newHeight}} source={require('../assets/images/photo-m.jpg')} resizeMode="stretch"/>
        }
        </View>

      </ScrollView>
      <View pointerEvents="box-none" style={{ shadowColor: '#fff', shadowOffset: { width: 0, height: -15 }, shadowOpacity: 0.88, shadowRadius: 5, width:'100%', alignSelf:'center',position:'absolute', bottom:0,backgroundColor:'#ffffffdd'} }>
        <View pointerEvents="box-none" style={[styles.textContainer,{marginTop:0, alignItems:'center', marginBottom:30}]}>
          <Text pointerEvents="none" style={{fontSize:14,fontWeight:'bold',lineHeight:21,color:'#484848',textAlign:'center', marginBottom:10,letterSpacing:2}}>＼ 返信率が大幅UP／</Text>
          <TouchableOpacity style={[styles.submitBtn]} onPress={()=>{ 
            this.navigation.navigate('UserSettingImage');
          }}>
            <Text style={[styles.textsubmitBtnWhite,{letterSpacing:2}]}>プロフィール写真を設定する</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  textContainer:{
    flex:1,
    width:'auto',
    textAlign: 'center',
    justifyContent:'center',
  },
  submitBtn:{
    alignItems:"center",
    justifyContent:"center",
    padding:8,
    width:'80%',
    height:45,
    backgroundColor:'#7CD7BB',
    borderRadius:999,
  },
  textsubmitBtnWhite: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:26,
    color:'#ffffff',
    textAlign:'center',
  },
});
