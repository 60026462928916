import React, {Component} from 'react';
import { View, Platform } from 'react-native';
import NoItemsBox from '../common/NoItemsBox';
import ActivityIndicator from '../../components/common/ActivityIndicator'
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';

export default class LoadMore extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super();
    this.page = 1;

    this.state = {
      account: UserUtil.getDefaultAccountAppState(),
      loading: true,
      isRefreshing: false, //pull refresh
      refreshControl: true,
      longRefreshControl: false,
      data: [],
      size: 0,
      error: '',
      commonVar: {},
      currentLoadData: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      const {appState, setAppState} = this.context;
      this.appContext = {
        appState: appState,
        setAppState: setAppState
      }
      this.props?.navigation?.addListener('focus',() => {
        if(appState?.account){
          this.setState({account: appState.account});
        }
      });
      
      this.getListFromApi(this.page);
    }
  }
 
  componentWillUnmount() {
    this._isMounted = false;
  }

  async getListFromApi(page){

  }

  noItemDisplay(txt){
    return (
      <NoItemsBox msg='{txt}' />
    );
  }

  renderHeader(){
    if(__DEV__) console.log('header page:'+this.page,this.state.loading);

    if (this.state.isRefreshing && this.page>1){
      return (
        <View style={{
          width: '100%',
          flex:1,
          padding: 10,
          justifyContent: 'center',
          alignContent: 'center'
        }}>
          <ActivityIndicator />
        </View>
      );
    }else return null;
  };

  renderFooter(){
    if(__DEV__) console.log('footer',this.state.loading);

    if (this.state.loading && this.page>1){
      return (
        <View style={{
          width: '100%',
          flex:1,
          padding: 10,
          justifyContent: 'center',
          alignContent: 'center'
        }}>
          <ActivityIndicator />
        </View>
      );
    }else return null;
  };

  async handleLoadMore(){
    if(this.state.currentLoadData==false){
      if (!this.state.loading && !this.no_more) {
        if(__DEV__) console.log('more: '+this.no_more,this.state.loading);
        
        this.setState({ currentLoadData: true}, async()=>{
          this.page = this.page + 1;
          await this.getListFromApi(this.page);
          this.setState({ currentLoadData: false});
        });

        if(__DEV__) console.log(this.constructor.name+' , load page='+this.page);
      }
    }
  };

  onRefresh = () => {
    if(__DEV__) console.log('refresh',this.state.loading);

    if (this._isMounted) {
      if(this.state.currentLoadData==false){
        if(Platform.OS==='web'){
          this.setState({
            isRefreshing: true,
            data: [],
            size: 0,
            currentLoadData: true
          }, async()=>{
            this.no_more = 0;
            this.page = 1;
            await this.getListFromApi(this.page);
            this.setState({ currentLoadData: false});
          });
        }else{
          this.setState({
            isRefreshing: true,
            size: 0,
            data: [],
            currentLoadData: true
          }, async()=>{
            this.no_more = 0;
            this.page = 1;
            await this.getListFromApi(this.page);
            this.setState({ currentLoadData: false});
          });
        }
      }
    }
  }

  onReload = async() => {
    if (this._isMounted) {
      if(this.state.currentLoadData==false){
        this.setState({
          data: [],
          size: 0,
          currentLoadData: true
        }, async()=>{
          this.no_more = 0;
          this.page = 1;
          await this.getListFromApi(this.page);
          this.setState({ currentLoadData: false});
        });
      }
    }
  }
}
