import React, {Component} from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { AntDesign, Entypo } from '@expo/vector-icons';

export default class NotificationMail extends Component{
  render() {
    return (
      <TouchableOpacity style={{paddingTop:1}} onPress={()=>this.props.navigation.navigate('SettingStack',{ screen:'MailInput'})}>
        <View style={styles.header}>
          <View style={{alignItems:'flex-start',flexDirection:'row',}}>
            <AntDesign name="exclamationcircle" style={styles.iconLeft} />
            <Text style={styles.txtHeader}>メールが受信できない状態です。解除方法はこちら</Text>
          </View>          
          <View style={{alignItems:'flex-end',}}>
            <Entypo name="chevron-thin-right" style={styles.iconRight}/>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection:'row',
    justifyContent:'space-between',
    alignContent:'center',
    alignItems:'center',
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:10,
    paddingRight:10,    
    backgroundColor:'#ff8d63'
  },
  txtHeader:{
    fontSize:13,
    fontWeight:'normal',
    lineHeight:20,
    color:'#fff'
  },
  iconLeft:{
    marginRight:5,
    fontSize:15,
    lineHeight:20,
    color:'#fff'
  },
  iconRight:{
    fontSize:18,
    lineHeight:24,
    color:'#fff'
  },
});