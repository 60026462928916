import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import FireBaseCommon from "../utils/FireBaseCommon";

const firebaseConfig = {
  apiKey: "AIzaSyBM1rNICUbjOzpGr6XVks53xuZg8gBvZJA",
  authDomain: "pappy-project-57be6.firebaseapp.com",
  projectId: "pappy-project-57be6",
  storageBucket: "pappy-project-57be6.appspot.com",
  messagingSenderId: "57380395379",
  appId: "1:57380395379:web:4f99a205c9c5c7ca0bcd06",
  measurementId: "G-GKRWPSC05N"
};



initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = async() => {
  return await getToken(messaging, { vapidKey: "BKRi16yamCF0ML6Z119B8_72xiqGZPyUxhenFBCruXlUCb3upwslbFsgNGxKh84VRJnl7N78LIZOMxOIgzk2AnI" })
    .then(async(currentToken) => {
      if (currentToken) {
        if(__DEV__) console.log("currentToken ", currentToken);
      } else {
        if(__DEV__) console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      if(__DEV__) console.log("An error occurred while retrieving token. ", err);
      return getWebToken();
    });
  };

export const getWebToken = () => {
    return getToken(messaging, { vapidKey: "BKRi16yamCF0ML6Z119B8_72xiqGZPyUxhenFBCruXlUCb3upwslbFsgNGxKh84VRJnl7N78LIZOMxOIgzk2AnI" });
  }
  
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      if(__DEV__) console.log("payload", payload);
      resolve(payload);
    });
  });
