import React, { Component } from 'react';
import { View, StyleSheet, Text, Platform }  from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Constants from '../../constants/Constants';

export default class DisplayText extends Component {
  _isMounted = false;

  constructor(){
    super();
  }
  
  componentDidMount(){
    this._isMounted = true;
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  render(){
    let { values, icon, placeholder } = this.props;
    if(Constants.NOTSETTEXT.indexOf(values)!=-1) values = '';
    if(icon==undefined) icon = "right";
    if(placeholder==undefined) placeholder = "選択する";

    if(values){
      return (
        <View style={styles.container}>
          <Text style={[styles.formListItemSelected,styles.selectText]} numberOfLines={1}>{values}</Text>
          <AntDesign name={icon} style={styles.icArrowRight} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <Text style={[styles.formListItemSelected,styles.notSelectText]}>{placeholder}</Text>
        <AntDesign name={icon} style={styles.icArrowRight} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    flex:1,
    width:'100%', 
    flexDirection:'row', 
    justifyContent:'flex-end', 
    alignItems:"center"
  },
  notSelectText:{
    color:'#9aa5a7',
    fontSize:14,
    lineHeight:19,
    textAlign:'right'
  },
  selectText:{
    color:'#5cc8ca',
    fontSize:14,
    lineHeight:19,
    textAlign:'right'
  },
  formListItemSelected: {
    paddingTop:10,
    paddingBottom:10,
    paddingRight:20,
    paddingLeft:10,
  },
  icArrowRight:{
    position:'absolute',
    top:'50%',
    right:-2,
    fontSize:16,
    color:'#484848',
    ...Platform.select({
      ios: {
        marginTop:-8,
      },
      android: {
        marginTop:-8,
      },
      default: {
        marginTop:-10,
      },
    })
  },
});