
const ErrorCode = {
  "GoodPostSendGood": {
    'NoIine': '04-00008',
    'NoPoint': '04-00009',
    'NoMember': '99-00015'
  },
  "MailBoxGetMailBoxDetail":{
    'NoMember': '99-00015'
  },
  "MailBoxPostSendMail": {
    'NoIine': '04-00010',
    'NoPoint': '04-00011',
    'NoMember': '99-00015'
  },
  "ProfileGetSafetyInfo": {
    'NoPoint': '15-00001',
    'NoMember': '99-00015'
  },
  "CalendarPostSendMeet": {
    'NoIine': '04-00012',
    'NoPoint': '04-00013',
    'NoMember': '99-00015'
  },
  "CheckSubscriptionStat": {
    'NoTrial': '13-00024',
    'Activated': '99-00010',
    'AgeVerfication': '99-00011',
    'UnProcessed': '17-00004'
  },
  "GetNoticeReceive": {
    'NoMailAddress': '11-00006'
  },
  "PurchasePointAppStore": {
    'Duplication': '13-00028'
  },
  "PurchasePointGooglePlay": {
    'incorrectReceipt': '13-00006',
    'overlappedReceipt': '13-00028'
  },
  "MailInput":{
      'registMailMismatch': '11-00008'
  }
};

export default ErrorCode;
