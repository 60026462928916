import React, { Fragment, Component } from 'react';
import { Formik } from 'formik';
import { Alert, AppState, View, Text, TouchableOpacity, StyleSheet, Platform, ScrollView, Linking, PermissionsAndroid } from 'react-native';
import formStyles from '../../styles/FormStyles';
import { FormSwitchToggleInput } from '../../components/form/FormInput';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import CommonFunction from '../../utils/CommonFunction';
import Setting17Api from '../../services/api/Setting17Api';
import PopupWrapper from "../../components/common/PopupWrapper";
import Msg from '../../constants/option/Msg.json';
import { AppContext } from '../../AppContext';
import ListStyles from '../../styles/ListStyles';
import ErrorCode from '../../constants/ErrorCode';
import firebase from '@react-native-firebase/app';
import { AntDesign } from '@expo/vector-icons';
import SwitchToggle from "react-native-switch-toggle";
import NotificationManager from 'react-native-check-notification-enable'
import FireBaseCommon from '../../utils/FireBaseCommon';
import Popup from '../../components/common/Popup';
import PortalStyles from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';


export default class NotifInput extends Component {
  _isMounted = false;
  static contextType = AppContext;
  activeCount = 0;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isNoMallAddress: false,
      notificationEnabled: 0,
      plStatus: false,
      permissionDefault: false,
      appState: AppState.currentState,
    };
    this.params = '';
    this.popup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.props?.setHeader('通知の設定',this.navigation);

      this.getDatatFromApi();

      // from back page
      this.subscribeFocus = this.navigation?.addListener('focus', () => {

        this.props?.setHeader('通知の設定',this.navigation);
        
      });

      if(Platform.OS!=='web' || CommonFunction.isAndroidUA()){
        // アプリがフォアグラウンドになった場合の処理
        this.subscription = AppState.addEventListener('change', () => {
          if (AppState.currentState === 'active') {
            this.state.loading = true,
            this.getDatatFromApi();
          }
        });
      }
    }
  }

  componentWillUnmount(){
    if(this.subscription)
      this.subscription.remove();
  }

  async getDatatFromApi(){
    if(this._isMounted){

      if(Platform.OS!=='web'){
        this.state.plStatus = true;
        if (Platform.OS === 'android') {
          if(Platform.Version > 32){
            const enabled  = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS);
            if (enabled) {
              this.state.notificationEnabled = 0;
            } else {
              this.state.notificationEnabled = 1;
            }
          }else{
            const enabled  = await NotificationManager.areNotificationsEnabled();
            if (enabled) {
              this.state.notificationEnabled = 0;
            } else {
              this.state.notificationEnabled = 1;
            }
          }
        } else{
          const enabled = await firebase.messaging().hasPermission();
          if (enabled) {
            this.state.notificationEnabled = 0;
          } else {
            this.state.notificationEnabled = 1;
          }
        }
      } else{
        if(CommonFunction.isAndroidUA()){
          this.state.permissionDefault = window.Notification.permission === "default";
          this.state.plStatus = true;
          const enabled  =  window.Notification.permission === "granted";
          if (enabled) {
            this.state.notificationEnabled = 0;
          } else {
            this.state.notificationEnabled = 1;
          }
        }
      }



      try{
        const notiResponse = await Setting17Api.GetNoticeReceive();
        //check response
        const res = notiResponse?.response;
        const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
        // if(errorCode==ErrorCode.GetNoticeReceive.NoMailAddress){
        //   this.setState({ isNoMallAddress: true });
        //   return;
        // }else{
          if(!CommonFunction.checkResponseStatus(notiResponse)) return;
        // }
        
        if(notiResponse!=undefined && notiResponse?.status==1){
          const data = notiResponse?.response;
          const mailaddress = data?.mailaddress;
          if(mailaddress == 0){
            this.setState({ isNoMallAddress: true });
            if(!CommonFunction.isAndroidUA()) return;
          }
          if(Platform.OS!=='web' || CommonFunction.isAndroidUA){
            if (this.state.notificationEnabled==0) {
              this.setState({
                footmark: data?.push?.footmark,
                good: data?.push?.good,
                matching: data?.push?.matching,
                message: data?.push?.message,
                notice: data?.push?.notice
              });
            } else {
              this.setState({
                footmark: 0,
                good:0,
                matching: 0,
                message: 0,
                notice: 0
              });            
            }
          }
          this.setState({
            goodReceive: data?.goodReceive,
            matchingReceive: data?.matchingReceive,
            messageReceive: data?.messageReceive,
            noticeReceive: data?.noticeReceive,
            footmarkReceive: data?.footmarkReceive,
            goodPush: data?.push?.good,
            matchingPush: data?.push?.matching,
            messagePush: data?.push?.message,
            noticePush: data?.push?.notice,
            footmarkPush: data?.push?.footmark
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({ loading: false });
      }
    }
  };

  async updateData(values){
    

    for (var key in values) {
      if (values.hasOwnProperty(key)) {           
        if(values[key]==false){
          values[key] = 0;
        }else{
          values[key] = 1;
        }
      }
    }

    try{
      CommonFunction.overLay();
      if(Platform.OS!=='web' && this.state.notificationEnabled==0){
        this.params = {
          footmarkPush:Number(values['footmark']),
          goodPush:Number(values['good']),
          matchingPush:Number(values['matching']),
          messagePush:Number(values['message']),
          noticePush:Number(values['notice']),
          footmarkReceive:Number(values['footmarkReceive']),
          goodReceive:Number(values['goodReceive']),
          matchingReceive:Number(values['matchingReceive']),
          messageReceive:Number(values['messageReceive']),
          noticeReceive:Number(values['noticeReceive'])
        };
      } else {
        if(CommonFunction.isAndroidUA() && this.state.notificationEnabled==0){
          this.params = {
            footmarkPush:Number(values['footmark']),
            goodPush:Number(values['good']),
            matchingPush:Number(values['matching']),
            messagePush:Number(values['message']),
            noticePush:Number(values['notice']),
            footmarkReceive:Number(values['footmarkReceive']),
            goodReceive:Number(values['goodReceive']),
            matchingReceive:Number(values['matchingReceive']),
            messageReceive:Number(values['messageReceive']),
            noticeReceive:Number(values['noticeReceive'])
          };
        } else{
          this.params = {
            footmarkReceive:Number(values['footmarkReceive']),
            goodReceive:Number(values['goodReceive']),
            matchingReceive:Number(values['matchingReceive']),
            messageReceive:Number(values['messageReceive']),
            noticeReceive:Number(values['noticeReceive']),
          };
        }
      }
      const saveResponse = await Setting17Api.PutNoticeReceive(this.params)
      //check response
      if(!CommonFunction.checkResponseStatus(saveResponse)) return;

      if(saveResponse!=undefined && saveResponse?.status==1){
        PopupWrapper.showMessage(Msg.Update.Success); // complete popup
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  // 端末の設定画面に移動する
  handleDeviceSetting() {
    Linking.openSettings();
  }

  // 端末のプッシュ受信拒否時のスイッチアクション
  handleDeviceSettingNone() {
    if(Platform.OS !== 'web'){
      Alert.alert('', '端末の設定からPappyのプッシュ通知の受信を有効にしてください', [
        {
          text: 'OK',
        },
      ]);
    }else{
      alert('ブラウザの設定からPappyのプッシュ通知の受信を有効にしてください');
    }
  }
  handleOnPress = async() =>{
    console.log('handleOnPress', 'handleOnPress')
    return false;
  }

  requestPermission = ()=>{
    this.popup.hidePopup();
    if(Platform.OS === 'web' && CommonFunction.isAndroidUA()){
      window.Notification.requestPermission().then(async(permission) => {
        if (permission === 'granted') {
          const { requestForToken } = require('../../firebase/firebase');
          const token = await requestForToken();
          if(token)
            FireBaseCommon.setupWebDeviceToken();
        }
        if (AppState.currentState === 'active') {
          this.state.loading = true,
          this.getDatatFromApi();
        }
      });
    }
  }
  render() {
    if (this.state.loading) return <ActivityIndicator />;
    if (this.state.isNoMallAddress && !CommonFunction.isAndroidUA()) return (
      <View style={ListStyles.container}>
        <View style={{paddingTop: 60, paddingLeft: 25, paddingRight: 25, paddingBottom: 50, textAlign:'center',}}>
          <Text style={{fontSize:16, fontWeight:'normal', lineHeight:24, color:'#333', textAlign:'left'}}>通知設定にはメールアドレスの登録が必要です。</Text>
        </View>
        <View style={{paddingLeft:40, paddingRight:40,}}>
          <View style={{marginTop:55,marginBottom:20,flexDirection:'row', justifyContent:'center'}}>
            <TouchableOpacity style={Style.btnBlue} 
              onPress={() => this.props.navigation.navigate('MailInput')}>
                <Text style={Style.txtBtnBlue}>メールアドレス登録</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
    let nofityMessage = '端末の通知設定がオフになっています';
    if(Platform.OS === 'web' ){
      if(this.state.permissionDefault){
        nofityMessage = '通知の利用を開始する';
      }else{
        nofityMessage = 'ブラウザの通知設定がオフになっています';
      }
    }

    return (
      <View style={ListStyles.container}>
        <Formik
          initialValues={{
            footmark: this.state.footmark,
            good: this.state.good,
            matching: this.state.matching,
            message: this.state.message,
            notice: this.state.notice,
            goodReceive: this.state.goodReceive,
            matchingReceive: this.state.matchingReceive,
            messageReceive: this.state.messageReceive,
            noticeReceive: this.state.noticeReceive,
            footmarkReceive: this.state.footmarkReceive,
            goodPush: this.state.goodPush,
            matchingPush: this.state.matchingPush,
            messagePush: this.state.messagePush,
            noticePush: this.state.noticePush,
            footmarkPush: this.state.footmarkPush
          }}
          onSubmit={values => this.updateData(values) }
        >
          {({ formikProps , values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
            <Fragment>
              <ScrollView style={ListStyles.scrollContainer}>
                {this.state.plStatus == true ? 
                  <View style={{flexDirection:'column'}}>
                    {this.state.notificationEnabled == 0? 
                    <View style={{flexDirection:'column'}}>
                      <View style={[Style.sectionHeader, {width:'100%', height:30, backgroundColor:'#eef0f1'}]}>
                        <Text style={Style.txtSectionHeader}>プッシュ通知</Text>
                      </View>
                      <View style={Style.listContainer}>
                        <View style={Style.blockBase}>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>足あとがついた時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="footmark"/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>いいねを受信した時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="good"/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>マッチングした時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="matching"/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>メッセージをもらった時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="message"/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>その他運営からのお知らせ</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="notice"/></View>
                          </View>
                          {Platform.OS === 'web' && CommonFunction.isAndroidUA()?
                          <View style={{width:'100%', paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15}}>
                            <Text style={[Style.labelText,{fontSize:12, lineHeight:25}]}>※Chromeブラウザをご利用の方は、通知をプッシュで受け取ることができます。その他ブラウザの動作は保証しておりません。{"\n"}シークレットブラウザはご利用いただけません。</Text>
                          </View>
                          :
                          <View style={Style.blockInner}> 
                          </View>
                          }
                          
                        </View>
                        
                      </View>
                    </View>
                    :
                    <View style={{flexDirection:'column'}}>
                      <View style={[Style.sectionHeader, {width:'100%', height:30, backgroundColor:'#eef0f1'}]}>
                        <Text style={Style.txtSectionHeader}>プッシュ通知</Text>
                      </View>
                      <View style={[Style.sectionHeader,  {width:'100%', height:40, flexDirection:'row', alignItems:'center', backgroundColor:'#fafae1'}]}>
                        <AntDesign name="exclamationcircle" style={[Style.iconLeft, {color:'#ef9265'}]} />
                        <Text style={[Style.txtSectionMessage,{width:'73%'}]}>{nofityMessage}</Text>
                        {Platform.OS !== 'web' ? 
                        <TouchableOpacity style={[formStyles.submitBtn,{width:55, height:25, borderRadius:5}]} onPress={()=>{
                          this.handleDeviceSetting();
                        }}>
                          <Text style={formStyles.loginText}>設定</Text>
                        </TouchableOpacity>
                        :
                        <View>
                          {this.state.permissionDefault ?
                          <TouchableOpacity style={[formStyles.submitBtn,{width:55, height:25, borderRadius:5}]} onPress={()=>{
                            this.popup.showPopup()
                          }}>
                            <Text style={formStyles.loginText}>OK</Text>
                          </TouchableOpacity>
                          :
                          <TouchableOpacity style={[formStyles.submitBtn,{width:75, height:25, borderRadius:5}]} onPress={()=>{
                            this.props.navigation.navigate('NotifInfo')
                          }}>
                            <Text style={formStyles.loginText}>設定方法</Text>
                          </TouchableOpacity>
                          }
                        </View>
                        
                        }
                      </View>
                      <TouchableOpacity style={Style.blockBase} onPress={this.handleDeviceSettingNone}>
                        <View style={Style.listContainer}>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>足あとがついた時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="footmark" disabled={true} settingNone={this.handleDeviceSettingNone}/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>いいねを受信した時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="good" disabled={true} settingNone={this.handleDeviceSettingNone}/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>マッチングした時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="matching"  disabled={true} settingNone={this.handleDeviceSettingNone}/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>メッセージをもらった時</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="message" disabled={true} settingNone={this.handleDeviceSettingNone}/></View>
                          </View>
                          <View style={Style.blockInner}>
                            <View style={Style.blockLabel}><Text style={Style.labelText}>その他運営からのお知らせ</Text></View>
                            <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="notice" disabled={true} settingNone={this.handleDeviceSettingNone}/></View>
                          </View>
                          {Platform.OS === 'web' && CommonFunction.isAndroidUA()?
                          <View style={{width:'100%', paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15}}>
                            <Text style={[Style.labelText,{fontSize:12, lineHeight:25}]}>※Chromeブラウザをご利用の方は、通知をプッシュで受け取ることができます。その他ブラウザの動作は保証しておりません。{"\n"}シークレットブラウザはご利用いただけません。</Text>
                          </View>
                          :
                          <View style={Style.blockInner}> 
                          </View>
                          }
                        </View>
                      </TouchableOpacity>
                    </View>
                    }
                  </View>
                :null}
                {(Platform.OS === 'web' && CommonFunction.isAndroidUA() && !this.state.isNoMallAddress ) && 
                <View>
                  {this.state.plStatus && 
                    <View>
                      <SubmitBtn onPress={handleSubmit} bottom={false}/>
                  </View>
                  }
                </View>
                }
                {(Platform.OS === 'web' && CommonFunction.isAndroidUA && this.state.isNoMallAddress) ? 
                  null
                :
                <View>
                <View style={[Style.sectionHeader, {backgroundColor:'#eef0f1'}]}>
                  <Text style={Style.txtSectionHeader}>メール通知</Text>
                </View>
                <View style={Style.blockInner}>
                  <View style={Style.blockLabel}><Text style={Style.labelText}>足あとがついた時</Text></View>
                  <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="footmarkReceive"/></View>
                </View>
                <View style={Style.blockInner}>
                  <View style={Style.blockLabel}><Text style={Style.labelText}>いいねを受信した時</Text></View>
                  <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="goodReceive"/></View>
                </View>
                <View style={Style.blockInner}>
                  <View style={Style.blockLabel}><Text style={Style.labelText}>マッチングした時</Text></View>
                  <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="matchingReceive"/></View>
                </View>
                <View style={Style.blockInner}>
                  <View style={Style.blockLabel}><Text style={Style.labelText}>メッセージをもらった時</Text></View>
                  <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="messageReceive"/></View>
                </View>
                <View style={Style.blockInner}>
                  <View style={Style.blockLabel}><Text style={Style.labelText}>その他運営からのお知らせ</Text></View>
                  <View style={Style.blockFormSwitch}><FormSwitchToggleInput fieldName="noticeReceive"/></View>
                </View>
                </View>
                }
                {this.state.plStatus && 
                  <View>
                  {this.state.isNoMallAddress && this.state.notificationEnabled == 1?
                    <DisabledSubmitBtn onPress={this.handleDeviceSettingNone} bottom={false}/>
                    :
                    <SubmitBtn onPress={handleSubmit} bottom={false}/>
                  }
                  </View>
                }
                {(Platform.OS === 'web' && CommonFunction.isAndroidUA && this.state.isNoMallAddress) ? 
                  <View>
                    <View style={[Style.sectionHeader, {backgroundColor:'#eef0f1'}]}>
                      <Text style={Style.txtSectionHeader}>メール通知</Text>
                    </View>
                    <View style={{paddingTop: 20, paddingLeft: 25, paddingRight: 25, paddingBottom: 10, textAlign:'center',}}>
                      <Text style={{fontSize:16, fontWeight:'normal', lineHeight:24, color:'#333', textAlign:'left'}}>メールの通知設定にはメールアドレスの登録が必要です。</Text>
                    </View>
                    <View style={{paddingLeft:0, paddingRight:0,}}>
                      <View style={{marginTop:10,marginBottom:20,flexDirection:'row', justifyContent:'center'}}>
                        <TouchableOpacity style={formStyles.submitBtn} 
                          onPress={() => this.props.navigation.navigate('MailInput')}>
                          <Text style={formStyles.loginText}>メールアドレス登録</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                
                :null}
              </ScrollView>
              {!this.state.plStatus && <SubmitBtn onPress={handleSubmit} bottom={true}/>}
            </Fragment>
          )}          
        </Formik>
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <Portal style={PortalStyles.portal}>
            <View style={PortalStyles.portalContainer}>
              <View style={PortalStyles.overlay} />
              <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12,}}>
                <Text style={{fontSize:14, fontWeight:'bold', color:'#484848', lineHeight:24,}}>プッシュ通知の許可設定</Text>
                <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>プッシュ通知の許可設定を行います。{"\n"}ブラウザの設定によっては許可設定が表示されない場合があります。{"\n"}ブラウザの[設定]＞[通知]を選択＞[ブラウザ名]のすべての通知がOFFになっている場合は有効にしてからお試しください。</Text>
                <TouchableOpacity style={[Style.btnNext, {marginTop:20,}]} onPress={()=>{ 
                  this.requestPermission()
                }}>
                  <Text style={Style.txtBtnNext}>次へ</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Portal>
        </Popup>
      </View>
    );
  }
}

const SubmitBtn = ({onPress, bottom})=>{
  return (
    <View style={[formStyles.buttonPanelSetting, bottom?Style.btnAbsoluteBottom:Style.btnBottom]}>
      <TouchableOpacity style={formStyles.submitBtn} onPress={onPress}>
        <Text style={formStyles.loginText}>変更する</Text>
      </TouchableOpacity>
    </View>
  )
}

const DisabledSubmitBtn = ({onPress, bottom})=>{
  return (
    <View style={[formStyles.buttonPanelSetting, bottom?Style.btnAbsoluteBottom:Style.btnBottom]}>
      <TouchableOpacity style={[formStyles.submitBtn, {backgroundColor:'#bee9ea',shadowColor: "#aadfea"}]} onPress={onPress}>
        <Text style={[formStyles.loginText]}>変更する</Text>
      </TouchableOpacity>
    </View>
  )
}

// export function NoTouchToggle(){
//   return (
//     <View style={{width:'80%'}}>
//       <SwitchToggle
//         switchOn={false}
//         circleColorOn='#fefefe'
//         circleColorOff='#fefefe'
//         backgroundColorOn='#5cc8ca'
//         backgroundColorOff='#fdfdfd'
//         containerStyle={{
//           marginTop: 0,
//           width: 47,
//           height: 29,
//           borderRadius: 999,
//           padding: false? 2 : 0,
//           borderWidth: false? 1 : 2,
//           borderColor: false? '#5cc8ca' : '#e4e4e4'
//         }}
//         circleStyle={{
//           width: false? 26 : 29,
//           height: false? 26 : 29,
//           borderRadius: 999,
//           shadowColor: "#000",
//           shadowOffset: {
//             width: 0,
//             height: 2,
//           },
//           shadowOpacity: false? 0.22 : 0.48,
//           shadowRadius: false? 2.22 : 4.8,
//           elevation: false? 3 : 3,
//         }}
//       />
//     </View>
//   )
// }

const Style = StyleSheet.create({
  blockBase:{
    flex:1,
  },
  listContainer:{
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%', 
    height: '100%',
    // ...Platform.select({
    //   ios: {},
    //   android: {},
    //   web:{
    //     marginTop: HeaderOptions.headerTopMenuHeight
    // }})
  },
  blockInner:{
    flex:1,
    flexDirection:'row',
    paddingTop:7,
    paddingBottom:7,
    paddingLeft:16,
    width:'100%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  blockLabel:{
    flex:3,
    alignItems:'flex-start',
  },
  labelText:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:32,
    color:'#333',
  },
  blockFormSwitch:{
    flex:1,
    alignItems:'flex-end',
    ...Platform.select({
      ios: {},
      android: { },
      default: {},
    }),
  },
  sectionHeader:{
    paddingLeft:20,
    width:'100%',
    backgroundColor:'#eef0f1',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',      
  },
  txtSectionMessage: {
    fontWeight:'normal',
    lineHeight:32,
    color:'#333',
    ...Platform.select({
      ios: {fontSize:14},
      android: { fontSize:14},
      default: {fontSize:13},
    }),
  },
  txtSectionHeader: {
    fontSize:14,
    fontWeight:'normal',
    lineHeight:32,
    color:'#333',
  },
  btnBlue:{
    padding:8,
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    height:50,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center',
    letterSpacing:1,
  },
  btnBottom:{
    paddingBottom:40
  },
  btnAbsoluteBottom:{
    position:'absolute',
    bottom:40,
  },
  btnBottom:{
    paddingBottom:40
  },
  btnAbsoluteBottom:{
    position:'absolute',
    bottom:40,
  },
  badge:{
    width:18,
    height:18, 
    backgroundColor:'#ff8d63', 
    borderRadius:999, 
   },
   textBadge: {
    textAlign:'center',
    color:'#fff', 
    fontSize:12,
    fontWeight:'normal',
    lineHeight:18, 
   },
  iconLeft:{
    marginRight:5,
    fontSize:15,
    lineHeight:20,
    color:'#fff'
  },
  disabledSwich:{
    marginRight:5,
    fontSize:15,
    lineHeight:20,
    color:'#fff'
  },
  btnNext:{
    width:'50%',
    height:30,
    alignSelf:'center', 
    backgroundColor:'#c3c3c3',
    alignItems:"center",
    justifyContent:"center",
    borderRadius:999,
    letterSpacing:2,
    marginRight:5
  },
  txtBtnNext:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
});