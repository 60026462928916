import React,{ Fragment, Component } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, Platform, TextInput, ScrollView } from 'react-native';
import { Formik } from 'formik';
import Msg from '../../constants/option/Msg.json';
import { AppContext } from '../../AppContext';
import Validation from '../../constants/Validation';
import FixedPhrase23Api from '../../services/api/FixedPhrase23Api';
import PopupWrapper from '../../components/common/PopupWrapper';
import CommonFunction from '../../utils/CommonFunction';
import { Entypo } from '@expo/vector-icons';
import HeadStyle from '../../styles/HeadStyles';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const inputAccessoryViewID = "inputAccessoryView1";

export default class MyFixedMessageScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isSubmitForm: false,
      initialValues: {
        body: ''
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted) {
      this.refname = this.props?.refname;
      this.parentRef = this.props?.getRef(this.refname);
      if(this.props.item!=undefined){
        const initialValues = {
          id: this.props.item?.id,
          body: this.props.item?.text
        }
        this.setState({initialValues: initialValues});
      }

      this.setState({loading:false});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  callback = ()=>{
    if(this.props.callback!=undefined){
      this.props.callback();
    }
  }

  close = ()=>{
    if(this.parentRef) {
      this.parentRef?.hide();
    }
  }

  handleSubmit = async(values)=>{
    let checkBody = CommonFunction.checkMessage(values.body);
    if(checkBody==""){
      PopupWrapper.showMessage('メッセージが入力されていません','warning');
      return false;
    }

    try{
      this.setState({isSubmitForm: true});
      let postTemplateResponse;
      if(values.id!=undefined){
        postTemplateResponse = await FixedPhrase23Api.putTemplate(values);
      }else{
        postTemplateResponse = await FixedPhrase23Api.postTemplate(values);
      }
      //check response
      if(!CommonFunction.checkResponseStatus(postTemplateResponse)) return;

      if(postTemplateResponse!=undefined){
        if(postTemplateResponse?.status==1){
          PopupWrapper.setOptions({timeout:500});
          PopupWrapper.showMessage(Msg.Update.Success,'check', ()=>{
            this.close();
            this.callback();
          });
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({isSubmitForm: false});
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;
    const insets = this.context?.appState?.insets;

    return (
      <View style={[styles.container,SafeAreaStyles(insets).containerMargin]}>
        <View style={HeadStyle.customHeader}>
          <Text style={HeadStyle.headerTitlePage}>定型メッセージ</Text>
          <TouchableOpacity style={{position:'absolute', left:0, height:'100%', justifyContent:'center'}} onPress={()=>{
            this.close();
          }}>
            <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
          </TouchableOpacity>
        </View>
        <ScrollView 
          style={[HeadStyle.customHeaderTop, styles.scrollContainer]}
          contentContainerStyle={{ flexGrow: 1 }}>
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={Validation.MyTemplate}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => this.handleSubmit(values) }
          >
          {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
            <Fragment>
              <View style={styles.msgTemplate}>
                <View style={styles.titleMsgTemplatePage}>
                  <Text style={styles.txtTitleMsgTemplatePage}>マイ定型メッセージ</Text>
                </View>    
                <View style={styles.textAreaView}>
                  <View style={styles.viewTextAreaInput}>
                  <TextInput  
                    style={[styles.textAreaInput, {marginLeft:0, height:180,}]}
                    onChangeText={handleChange('body')}
                    onBlur={() => setFieldTouched('body')}
                    value={values.body}
                    multiline={true} 
                    numberOfLines={6} 
                    placeholder={'定型メッセージを記入する'} 
                    placeholderTextColor="#9aa5a7"
                    inputAccessoryViewID={inputAccessoryViewID}
                  />
                </View>
                </View>
              </View>
              <View style={styles.btnSendMsg}>
                {this.state.isSubmitForm ?
                <View style={[styles.btnExsample,{opacity:0.4}]} >
                  <Text style={styles.txtBtnSendMsg}>送信内容を確認</Text>
                </View>
                :
                <TouchableOpacity 
                  style={[styles.btnExsample,{opacity:(dirty) ?1:0.4}]} 
                  disabled={!(dirty)}
                  onPress={handleSubmit}
                >
                  <Text style={styles.txtBtnSendMsg}>送信内容を確認</Text>
                </TouchableOpacity>
                }
                
              </View>
            </Fragment>
          )}          
          </Formik>
          
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:'#ffffff',
  },
  scrollContainer:{
    backgroundColor:'#eef1f2',
  },
  msgTemplate:{
    //padding:10,
    //backgroundColor:'#fff',
  },
  titleMsgTemplatePage:{
    marginTop:10,
    padding:10,
  },
  txtTitleMsgTemplatePage:{
    fontSize:16,
    lineHeight:21,
    fontWeight:'bold',
    color:'#333'
  },
  btnSendMsg:{
    position:'absolute', 
    justifyContent:"center", 
    alignItems: 'center', 
    bottom:20,
    width:'100%',
  },
  txtBtnSendMsg:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#fff',
    textAlign:'center',
  },
  textAreaView:{
    justifyContent:"center",
    width:"100%",
    backgroundColor:"#ffffff",
    ...Platform.select({
      ios: { paddingTop:20 },
      android: {},
      default: {},
    }),
  },
  viewTextAreaInput: {
    paddingRight:8,
    paddingLeft:16,
    width:'100%',
    backgroundColor:'#ffffff',
  },
  textAreaInput:{
    padding:16,
    paddingLeft:0,
    width:'100%',
    ...Platform.select({
      ios: {},
      android: { textAlignVertical:'top', },
      default: {},
    }),
  },
  btnExsample:{
    height:56,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
    textAlign:'center',
    width:'80%'
  }
});