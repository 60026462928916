import SMSWrapper from "../components/common/SMSWrapper";
import AgeWrapper from "../components/common/AgeWrapper";
import PaymentWrapper from "../components/common/PaymentWrapper";
import CommonFunction from "./CommonFunction";
import Constants from "../constants/Constants";
import PopupWrapper from '../components/common/PopupWrapper';
import Msg from "../constants/option/Msg.json";

export default class UserVerify {
  static checkSMS = (params)=>{
    const {activated, navigation, redirect, account} = params;
    if(activated==Constants.ActivatedStatus.notApprove){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          SMSWrapper.showSMS(navigation, redirect);
        }
      });
      return false;
    }

    if(account?.level==Constants.Level.restrict){
      PopupWrapper.showModalMessage(Msg.AccountRestricted, '');
      return false;
    }
    
    return true;
  }

  static checkAge = (params)=>{
    const {ageVerficationStatus, navigation, hideAction} = params;
    if(ageVerficationStatus==Constants.AgeVerficationStatus.notApprove || ageVerficationStatus==Constants.AgeVerficationStatus.pending){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          AgeWrapper.showAge(navigation, hideAction);
        }
      });
      return false;
    }
    return true;
  }

  static checkAgePartner = (params)=>{
    const {ageVerficationStatus, navigation, hideAction} = params;
    if(ageVerficationStatus==Constants.AgeVerficationStatus.notApprove || ageVerficationStatus==Constants.AgeVerficationStatus.pending){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          AgeWrapper.showAgePartner(navigation, hideAction);
        }
      });
      return false;
    }
    return true;
  }

  static checkPayment = (params)=>{
    const {paymentFlag, navigation, hideAction} = params;
    if(paymentFlag==Constants.SubscriptionStatus.notApprove){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          PaymentWrapper.show(navigation, hideAction);
        }
      });
      return false;
    }
    return true;
  }

  static checkExchangeMessage = (params)=>{
    const {checkExchangeMessage, navigation} = params;
    if(checkExchangeMessage==Constants.ExchangeMessageStatus.notApprove){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          PaymentWrapper.show(navigation, hideAction);
        }
      });
      return false;
    }
    return true;
  }

  static checkSMSAppState = (context)=>{
    if(context){
      const { appState } = context;
      if(appState?.account?.activated==Constants.ActivatedStatus.approve){
        if(appState?.account?.level==Constants.Level.restrict){
          PopupWrapper.showModalMessage(Msg.AccountRestricted, '');
          return false;
        }
        return true;
      }
    }
    return false;
  }

  static checkAgeAppState = (context)=>{
    if(context){
      const { appState } = context;
      if(appState?.account?.ageVerficationStatus==Constants.AgeVerficationStatus.approve){
        return true;
      }
    }
    return false;
  }

  static checkPaymentAppState = (context)=>{
    if(context){
      const { appState } = context;
      if(appState?.account?.subscription?.status==Constants.SubscriptionStatus.approve){
        return true;
      }
    }
    return false;
  }
}