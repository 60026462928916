import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import SmsInputScreen from '../screens/setting/SmsInputScreen';
import BottomTabNavigator from './BottomTabNavigator';
import IdentificationScreen from '../screens/setting/IdentificationScreen';
import UploadIdentificationScreen from '../screens/setting/UploadIdentificationScreen';
import NotificationScreen from '../screens/NotificationScreen';
import NotificationDetailScreen from '../screens/NotificationDetailScreen';
import HelpScreen from '../screens/HelpScreen';
//import SubscriptionCompleteScreen from '../screens/SubscriptionCompleteScreen';
import PurchaseScreen from '../screens/PurchaseScreen';
import PurchaseScreen_ios from '../screens/PurchaseScreen_ios';
import PurchaseTrialScreen from '../screens/PurchaseTrialScreen';
import TermsScreen from '../screens/setting/TermsScreen';
import MemberStatusScreen from '../screens/MemberStatus';
import RecommendIineStack from './stack/RecommendIineStack';
//import PointShoppingStack from './stack/PointShoppingStack';
import ErrorBoundary from '../components/common/ErrorBoundary';
import CommonFunction from '../utils/CommonFunction';
import PurchaseScreen_android from '../screens/PurchaseScreen_android';
import TransitionSpec from '../constants/TransitionSpec';

const Stack = createStackNavigator();

export default function AuthStackNavigator({ navigation, route, props }){
  return (
    <Stack.Navigator initialRouteName="Home" screenOptions={({ route }) => ({
      gestureEnabled: CommonFunction.gestureEnabled(), 
      animationEnabled: CommonFunction.animationEnabled(),
      transitionSpec: TransitionSpec
    })}>
      <Stack.Screen name="Home" {...props} 
      children={(props)=><ErrorBoundary {...props}><BottomTabNavigator {...props} /></ErrorBoundary>}
      options={{headerShown: false}} />
      <Stack.Screen name="SmsInput" 
      children={(props)=><ErrorBoundary {...props}><SmsInputScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="Identification"  
      children={(props)=><ErrorBoundary {...props}><IdentificationScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="UploadIdentification" 
      children={(props)=><ErrorBoundary {...props}><UploadIdentificationScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="Notify"  
      children={(props)=><ErrorBoundary {...props}><NotificationScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="NotifyDetail" 
      children={(props)=><ErrorBoundary {...props}><NotificationDetailScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="Help" 
      children={(props)=><ErrorBoundary {...props}><HelpScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      {/*<Stack.Screen name="SubscriptionComplete" component={SubscriptionCompleteScreen} options={{headerShown: true}}/>*/}
      {Platform.OS==='web'?
        <Stack.Screen name="Purchase" 
        children={(props)=><ErrorBoundary {...props}><PurchaseScreen {...props} /></ErrorBoundary>}
        options={{headerShown: true}}/>
      :null}
      {Platform.OS==='ios'?
        <Stack.Screen name="Purchase" 
        children={(props)=><ErrorBoundary {...props}><PurchaseScreen_ios {...props} /></ErrorBoundary>}
        options={{headerShown: true}}/>
      :null}
      {Platform.OS==='android'?
        <Stack.Screen name="Purchase" 
        children={(props)=><ErrorBoundary {...props}><PurchaseScreen_android {...props} /></ErrorBoundary>}
        options={{headerShown: true}}/>
      :null}
      {Platform.OS==='web'?
        <Stack.Screen name="PurchaseTrial" 
        children={(props)=><ErrorBoundary {...props}><PurchaseTrialScreen {...props} /></ErrorBoundary>}
        options={{headerShown: true}}/>
      :null}
      <Stack.Screen name="MemberStatus" 
      children={(props)=><ErrorBoundary {...props}><MemberStatusScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="Terms" 
      children={(props)=><ErrorBoundary {...props}><TermsScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}}/>
      <Stack.Screen name="RecommendIineStack" 
      children={(props)=><ErrorBoundary {...props}><RecommendIineStack {...props} /></ErrorBoundary>} 
      options={{headerShown: false}}/>
      {/*<Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>*/}
    </Stack.Navigator>
  );
}