import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions  } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import Constants from '../../constants/Constants';
import * as RootNavigation from '../../navigation/RootNavigation';

const windowWidth = Dimensions.get('window').width;

export default class LoginPrivilegeCampaignPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      partnerMsg: false,
      myProfile: { sex: 0 }
    }
    this.hideAction = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;

      const { appState } = this.context;
      const myProfile = await UserUtil.getUserProfileLocal(appState);
      if(myProfile){
        this.setState({ myProfile: myProfile });
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }
    this.setState({ show: true});
  }

  showPartner = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }
    this.setState({ show: true, partnerMsg: true});
  }

  hide = ()=>{
    this.setState({ show: false, partnerMsg: false});
  }

  render() {
    if(!this.state.show) return <></>;

    const popupWidth = windowWidth * 0.77
    const newHeightTop = popupWidth * 773 / 811
    const newHeightBottom = popupWidth * 215 / 811

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
          {this.state.myProfile?.sex==Constants.FEMALE?
              <ImageBackground resizeMode={'cover'} 
              style={{width: popupWidth, height:newHeightTop}} 
              imageStyle={{borderTopLeftRadius:15, borderTopRightRadius:15}} 
              source={require('../../assets/images/pappy-entry-pop-w.png')}
            > 
            </ImageBackground>
              :
              <ImageBackground resizeMode={'cover'} 
              style={{width: popupWidth, height:newHeightTop}} 
              imageStyle={{borderTopLeftRadius:15, borderTopRightRadius:15}} 
              source={require('../../assets/images/pappy-entry-pop-m-0m.png')}
            >
            </ImageBackground>

          }
 {this.state.myProfile?.sex==Constants.FEMALE?
            <View style={styles.btnArea}>
            <TouchableOpacity style={styles.btnBlue} 
                onPress={() =>this.hide()}>
                  <Text style={styles.txtBtnBlue}>Pappyを使ってみる</Text>
              </TouchableOpacity>
            </View>
            :
            <View style={styles.btnArea}>
            <TouchableOpacity style={styles.btnBlue} 
                onPress={() =>this.hide()}>
                  <Text style={styles.txtBtnBlue}>Pappyを無料で使ってみる</Text>
              </TouchableOpacity>
            </View>
          }
            <ImageBackground resizeMode={'cover'} 
              style={{width: popupWidth, height:newHeightBottom}} 
              imageStyle={{borderBottomLeftRadius:15, borderBottomRightRadius:15}} 
              source={require('../../assets/images/pappy-entry-pop-bottom.png')}
            > 
            </ImageBackground>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:'auto', 
    width:'77%',
    alignSelf:'center', 
    borderRadius:15
  },
  btnBlue:{
    marginBottom:10,
    width:'100%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'100%',
    padding:8,
    height:42,
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#323232',
    textAlign:'center',
  },
  btnArea: {
    paddingTop:5,
    paddingBottom:5,
    paddingRight:27,
    paddingLeft:27,
  },
  btnBlue:{
    width:'100%',
    padding:8,
    height:45,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center',
    letterSpacing:1,
  },
});