import ApiPappy from '../../utils/ApiPappy';
import ApiConfig from '../../constants/ApiConfig';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import CommonFunction from '../../utils/CommonFunction';

/**
 * API: 03_API仕様\23_定型文
 */
export default class FixedPhrase23Api extends ApiPappy{
  //1.1.1.定型文取得
  static async TemplateGetList(values, showError=0){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Template/Get/List/",
        {targetMemberId: (values.targetMemberId!=undefined)?values.targetMemberId:''},
        true
      );
      if(__DEV__) console.log('/Template/Get/List/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Template/Get/List/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.定型文登録
  static async postTemplate(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Template/Post/Template/",
        {body: (values.body!=undefined)?values.body:''},
        true
      );
      if(__DEV__) console.log('/Template/Post/Template/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Template/Post/Template/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.定型文更新
  static async putTemplate(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Template/Put/Template/",
        {
          id: (values.id!=undefined)?values.id:'',
          body: (values.body!=undefined)?values.body:''
        },
        true
      );
      if(__DEV__) console.log('/Template/Put/Template/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Template/Put/Template/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1.定型文削除
  static async deleteTemplate(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Template/Delete/Template/",
        { id: (values.id!=undefined)?values.id:'' },
        true
      );
      if(__DEV__) console.log('/Template/Delete/Template/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Template/Delete/Template/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}