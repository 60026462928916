import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, FlatList, View, ScrollView, TouchableOpacity, Platform, ImageBackground, Dimensions
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import ActivityIndicator from '../components/common/ActivityIndicator';
import Payment15Api from '../services/api/Payment15Api';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import ConfirmPopupStyle from '../styles/ConfirmPopupStyles';
import SMSWrapper from "../components/common/SMSWrapper";
import AgeWrapper from "../components/common/AgeWrapper";
import ListStyles from '../styles/ListStyles';
import ErrorCode from '../constants/ErrorCode';
import PopupWrapper from '../components/common/PopupWrapper';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';
import StorageHelper from '../utils/StorageHelper2';
import apiConfig from '../constants/ApiConfig';
import PaidyFunction from '../utils/PaidyFunction';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PurchaseScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      trialPlan: false,
      isPaidy: false,
      memberId: '',
      name:''
    };
    // setting previous route without bottom tab
    this.prevRoute = ['ChatRoom'];
    this.popup = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
      let trialPlan = await StorageHelper.getObjectData('trialPlan');
      this.setState({
        trialPlan: trialPlan,
      });
      const { appState } = this.context;
      const userProfile = await CommonFunction.getProfile();  
      if(appState.account){
        this.setState({ account: appState.account, memberId: userProfile?.memberId, name: userProfile?.name});
      }
      this.getDataFromApi();
      CommonFunction.hideBottomTab(this.navigation);
      
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        this.getDataFromApi();
        CommonFunction.hideBottomTab(this.navigation);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.checkBottomTab();
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }  

  checkBottomTab=()=>{
    let showBottomTab = true;
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
    }
    if(showBottomTab) {
      CommonFunction.showBottomTab(this.navigation);
    }
  }

  getDataFromApi = async ()=>{
    try{
      const checkAccessTimeFlg = await CommonFunction.checkAccessTime();
      if(!checkAccessTimeFlg) return;
      // テレコムとPaidy用のデータ取得API
      const orderResponse = await Payment15Api.SubscriptionUrl();
      if(!CommonFunction.checkResponseStatus(orderResponse)) return;

      if(checkUndefined(orderResponse) && orderResponse?.status==1){
        let data = orderResponse?.response;
        let account = UserUtil.getAccountAppState(orderResponse, this.context);
        this.setState({
          account: account,
          data: data
        });
        let paidyStatus = data.paidy.status;
        // paidyの決済処理中のエラー
        if (paidyStatus==true) {
          this.paidyUnProcessed();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false});
    }
  }

  // paidyの決済処理中のエラー
  paidyUnProcessed=()=>{
    // paidyの処理中のステータスを受信した場合はポップアップを表示する
    PopupWrapper.showMessage('ペイディの決済処理中です。\n完了までしばらくお待ちください。','warning',()=>{
      this.navigation.navigate('Home',{ screen:'MyPageStack', params:{ screen:'MyPage' }});
    });
  }

  setHeader = () =>{
    CommonFunction.setHeaderSetting(this.navigation, '有料会員登録', true);
  }
  
  renderItem = ({item, index}) => {
    let banner;
    let bannerStyle = styles.itemBlogRatePlan;
    if (this.state.isPaidy==true) {
      // paidy用のバナー画像を設定（productIdで判定）
      switch(item.productId){
        case 'subsc12m':
          banner = require('../assets/images/purchase/360day40800yen.png');
          break;
        case 'subsc06m':
          banner = require('../assets/images/purchase/180day31000yen.png');
          break;
        case 'subsc03m':
          banner = require('../assets/images/purchase/90day17400yen.png');
          break;
        case 'subsc01m':
          banner = require('../assets/images/purchase/30day6800yen.png');
          bannerStyle = styles.itemBlogRatePlanOther;
          break;
        case 'subscTest':
          banner = require('../assets/images/purchase/1day6800yen.png');
          bannerStyle = styles.itemBlogRatePlanOther;
          break;
        default:
          break;
      }
    } else {
      // テレコム用のバナー画像を設定（productIdで判定）
      switch(item.productId){
        case '360day40800yen':
          banner = require('../assets/images/purchase/360day40800yen.png');
          break;
        case '180day31000yen':
          banner = require('../assets/images/purchase/180day31000yen.png');
          break;
        case '90day17400yen':
          banner = require('../assets/images/purchase/90day17400yen.png');
          break;
        case '30day6800yen':
          banner = require('../assets/images/purchase/30day6800yen.png');
          bannerStyle = styles.itemBlogRatePlanOther;
          break;
        case '1day6800yen':
          banner = require('../assets/images/purchase/1day6800yen.png');
          bannerStyle = styles.itemBlogRatePlanOther;
          break;
        default:
          break;
      }
    }
    
    if(banner){
      return (
        <View style={{flex:1, width:'100%', justifyContent:'center',alignSelf:'center',alignItems:'center',alignContent:'center'}}>
        <TouchableOpacity key={index.toString()} style={{width:'100%'}} onPress={()=>{
          this.handleOnPress(item);
        }}>
          <Image style={bannerStyle} source={banner} resizeMode="contain"/>
        </TouchableOpacity>
        </View>
      );
    }
  }

  noItemDisplay = () => {
    return <></>;
  }

  createInputElement = (type='hidden',name,value)=>{
    let element = document.createElement('input');
    element.setAttribute("type", type);
    element.setAttribute("name", name);
    element.setAttribute("value", value);
    return element;
  }

  handleOnPress = async(item)=>{
    if(Platform.OS==='web'){
      // check api
      const checkSubscriptionStatResopnse = await Payment15Api.CheckSubscriptionStat(0);
      if(!CommonFunction.checkResponseStatus(checkSubscriptionStatResopnse)) return;

      if(checkSubscriptionStatResopnse?.status==1){
        if (this.state.isPaidy==true) {
          // paidyで決済
          this.paidySubscSubmit(item);      
        } else {
          // クレカで決済
          this.webSubscSubmit(item);      
        }
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(checkSubscriptionStatResopnse)){
          if(checkSubscriptionStatResopnse.response!=undefined){
            const res = checkSubscriptionStatResopnse.response;
            const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
            const errorMessage = res?.errorMessage;
            if(errorCode==ErrorCode.CheckSubscriptionStat.Activated){ // sms
              SMSWrapper.showSMS(this.navigation);
            }else if(errorCode==ErrorCode.CheckSubscriptionStat.AgeVerfication){ // age
              AgeWrapper.showAge(this.navigation);
            }else if(errorCode==ErrorCode.CheckSubscriptionStat.UnProcessed){ // paidy
              this.paidyUnProcessed();
            }else{
              if(errorMessage) PopupWrapper.showMessage(errorMessage, 'warning');
            }
          }
        }
      }
    }
  }

  // Paidy：サブスク処理
  paidySubscSubmit = (item)=>{
    var url = apiConfig.pappy_image_url + '/image/ppy-icon300.png';
    var navi = this.navigation;
    // Paidy初期設定
    var config = {
      "api_key": this.state.data?.paidy.apiKey,
      "logo_url": url,
      "closed": function(callbackData) {
        // console.log(callbackData);
        // token発行成功（成功の時だけコールバックあり、中断・失敗時はスルー）
        if(callbackData.status === 'active') {
          PaidyFunction.paidySubscSuccessful(callbackData,navi);
          return;
        }
      },
      "token": {
        "wallet_id": item.productId,
        "type": "recurring",
      },
    };
    var paidyHandler = Paidy.configure(config);
  
    // ペイロード設定
    var payload = {
      "store_name": "Pappy",
      "buyer": {
        "name1": this.state.name,
      },
      "metadata": {
        "member_id": this.state.memberId,
        "product_id": item.productId
      },
    };
    paidyHandler.launch(payload);
  }

  // テレコム：サブスク処理
  webSubscSubmit = async(item)=>{
    let form = document.createElement('form');
    form.method = "POST";
    form.action = this.state.data?.telecom.orderUrl;
    const element1 = this.createInputElement('hidden', 'clientip', this.state.data?.telecom.clientip);
    const element2 = this.createInputElement('hidden', 'money', item.money);
    const element3 = this.createInputElement('hidden', 'sendid', this.state.data?.telecom.sendid);
    const element4 = this.createInputElement('hidden', 'productid', item.productId); 
    const element5 = this.createInputElement('hidden', 'redirect_url', this.state.data?.telecom.redirectUrl);
    const element6 = this.createInputElement('hidden', 'ordertype', this.state.data?.telecom.orderType);
    form.appendChild(element1);
    form.appendChild(element2);
    form.appendChild(element3);
    form.appendChild(element4);
    form.appendChild(element5);
    form.appendChild(element6);
    document.body.appendChild(form);
    form.submit();
    if(__DEV__) console.log(form);
  }

  changeList = (value) => {
    if (value==1) {
      this.setState({ isPaidy: true});
    } else {
      this.setState({ isPaidy: false});
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;
    let tab1;
    let tab2;
    let listData;
    // タブ画像切り替え
    if (this.state.isPaidy==true) {
      tab1 = require('../assets/images/paidy/paidy_tab02.png');
      tab2 = require('../assets/images/paidy/paidy_tab04.png');
      listData = this.state.data?.paidy.list;
    } else {
      tab1 = require('../assets/images/paidy/paidy_tab01.png');
      tab2 = require('../assets/images/paidy/paidy_tab03.png');
      listData = this.state.data?.telecom.list;
    }

    let tabH = 92;
    if (windowWidth<414) {
      tabH = 86;
      if (windowWidth<393) {
        tabH = 80;
        if (windowWidth<375) {
          tabH = 70;
        }
      }
    }

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} 
        ref={scroller => { this.scroller = scroller; }} 
        scrollToOverflowEnabled={true}
        scrollIndicatorInsets={{ right: 1 }}>
          <View style={styles.titlePage}>
              <View style={styles.titlePageTxt}>
                <Text style={styles.txtTitlePageBig}>有料会員登録</Text><Text style={styles.txtTitlePage}>をすると•••</Text>
                </View>
            </View>
          <View style={styles.blogInner}>
            <ImageBackground source={require('../assets/images/purchase/img01.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>何人でもメッセージの{"\n"}やりとりが無制限で{"\n"}できるように！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img02.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>お相手の女性の{"\n"}人気度が{"\n"}一目でわかる！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img03.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>有料会員登録と{"\n"}同時にポイントが{"\n"}もらえる！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img04.png')} resizeMode="stretch" style={styles.imgBG3BannerLast}>
              <View style={styles.imgBG3BannerInsideLast}>
                <Text style={styles.txtBG3BannerLast}><Text style={styles.txtBG3BannerLast1}>6</Text><Text style={styles.txtBG3BannerLast2}>ヶ月以上</Text><Text style={styles.txtBG3BannerLast3}>の</Text>{"\n"}プランがおすすめ</Text>
              </View>
            </ImageBackground>
            <View style={[styles.blogRatePlan, {paddingBottom:10}]}>
              <Text style={styles.txtBlogRatePlan}>支払い方法・料金プランを選択してください</Text>
            </View>
            <View style={styles.paymentModeTab}> 
              <TouchableOpacity style={styles.paymentModeButton} onPress={()=>{
                this.changeList(0);
              }}>
                <Image style={[styles.paymentModeButtonImg, {height:tabH}]} source={tab1} resizeMode="contain"/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.paymentModeButton} onPress={()=>{
                this.changeList(1);
              }}>
                <Image style={[styles.paymentModeButtonImg, {height:tabH}]} source={tab2} resizeMode="contain"/>
              </TouchableOpacity>
            </View>
            {this.state.isPaidy==true?
              <View style={styles.paidyInfoArea}>
                <TouchableOpacity style={styles.paidyInfoButton} onPress={()=>{
                  this.navigation.navigate('PaidyInfo');
                }}>
                  <Image style={styles.paidyInfoButtonImg} source={require('../assets/images/paidy/paidy_infoButton.png')} resizeMode="contain"/>
                </TouchableOpacity>
              </View>
            :
              <View style={[styles.blogRatePlan, {marginTop:0, backgroundColor:'#f0f5f6', height:20}]}></View>
            }
            {this.state.trialPlan==true && this.state.isPaidy==false ?
              <View style={[styles.blogRatePlan, {marginTop:0}]}>
                <TouchableOpacity style={{}} onPress={()=>{
                  this.props.navigation?.navigate('PurchaseTrial');
                }}>
                  <Image style={styles.itemBlogRatePlan} source={require('../assets/images/trial/banner_trial_plan.png')} resizeMode="contain"/>
                </TouchableOpacity>
                <Text style={styles.txtBlogRatePlanInfo}>※お試しプランの期間が終了するまでに解約されない場合、1ヶ月プランに自動更新されます</Text>
              </View>
            :null}
            <View style={styles.blogRatePlanInside}> 
              <FlatList
                scrollEnabled={false} 
                contentContainerStyle={{ flexGrow: 1 }}
                data={listData}
                renderItem={this.renderItem}
                ListEmptyComponent={this.noItemDisplay}
                keyExtractor={(item,index) => item.productId}
                inverted={true}
              />
            </View>
            <View style={styles.blogInside1}>
              <Text style={styles.txtBlogInside1Top}>\ 会員登録するとこんなメリットが！/</Text>
              <Text style={styles.txtBlogInside1Bottom}>有料会員ができること</Text>
            </View>
            <View style={styles.blogImgTitle01}>
              <Text style={styles.txtBlogInside1Title}>メッセージし放題！</Text>
            </View>
            <View style={styles.blogDesc01}>
              <Text style={styles.txtBlogDesc}><Text style={styles.txtOrange}>いつでも何人とでも</Text>女性との{"\n"}メッセージのやりとりが可能です。</Text>
            </View>
            <View style={styles.blogChat}>
              <Image style={styles.imgChat01} source={require('../assets/images/purchase/chat01.png')} resizeMode="contain"/>
              <Image style={styles.imgChat02} source={require('../assets/images/purchase/chat02.png')} resizeMode="contain"/>
            </View>
            <View style={styles.blogPlusMonthlyGifts}>
              <Image style={styles.imgBlogPlusMonthlyGifts} source={require('../assets/images/purchase/img09.png')} resizeMode="contain"/>
              <View style={styles.blogPlusMonthlyGiftsInside}>
                <Text style={styles.txtBlogPlusMonthlyGiftsInside}>毎月有料会員の方に50ポイントを{"\n"}プレゼント致します。</Text>
              </View>              
            </View>
            <View style={[styles.blogRatePlan, {paddingBottom:10}]}>
              <Text style={styles.txtBlogRatePlan}>支払い方法・料金プランを選択してください</Text>
            </View>
            <View style={styles.paymentModeTab}> 
              <TouchableOpacity style={styles.paymentModeButton} onPress={()=>{
                this.changeList(0);
              }}>
                <Image style={[styles.paymentModeButtonImg, {height:tabH}]} source={tab1} resizeMode="contain"/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.paymentModeButton} onPress={()=>{
                this.changeList(1);
              }}>
                <Image style={[styles.paymentModeButtonImg, {height:tabH}]} source={tab2} resizeMode="contain"/>
              </TouchableOpacity>
            </View>
            {this.state.isPaidy==true?
              <View style={styles.paidyInfoArea}>
                <TouchableOpacity style={styles.paidyInfoButton} onPress={()=>{
                  this.navigation.navigate('PaidyInfo');
                }}>
                  <Image style={styles.paidyInfoButtonImg} source={require('../assets/images/paidy/paidy_infoButton.png')} resizeMode="contain"/>
                </TouchableOpacity>
              </View>
            :
              <View style={[styles.blogRatePlan, {marginTop:0, backgroundColor:'#f0f5f6', height:20}]}></View>
            }
            {this.state.trialPlan==true && this.state.isPaidy==false ?
              <View style={[styles.blogRatePlan, {marginTop:0}]}>
                <TouchableOpacity style={{}} onPress={()=>{
                  this.props.navigation?.navigate('PurchaseTrial');
                }}>
                  <Image style={styles.itemBlogRatePlan} source={require('../assets/images/trial/banner_trial_plan.png')} resizeMode="contain"/>
                </TouchableOpacity>
                <Text style={styles.txtBlogRatePlanInfo}>※お試しプランの期間が終了するまでに解約されない場合、1ヶ月プランに自動更新されます</Text>
              </View>
            :null}
            <View style={styles.blogRatePlanInside}> 
              <FlatList
                scrollEnabled={false}
                contentContainerStyle={{ flexGrow: 1 }}
                data={listData}
                renderItem={this.renderItem}
                ListEmptyComponent={this.noItemDisplay}
                keyExtractor={(item,index) => item.productId}
                inverted={true}
              />
            </View>
            <View style={styles.blogNote}>
              <View style={styles.blogNoteTitle}><Text style={styles.txtBlogNoteTitle}>有料会員の注意事項</Text></View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>お申し込みを起算日とし、12か月後（12ヶ月プラン）・6か月後（6ヶ月プラン）・3か月後（3ヶ月プラン）・1か月後（1ヶ月プラン）に自動更新されます</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>自動更新は期限が切れる24時間以内に自動的に課金される仕組みです</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>自動更新をご希望ではない場合は期限が切れる24時間前までに解約を完了させてください</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>次回更新日は、
                  <TouchableOpacity onPress={()=>{this.navigation.navigate('MemberStatus')}}>
                    <Text style={[styles.txtBlue, styles.txtUnderline]}>会員ステータス</Text>
                  </TouchableOpacity>
                  ページでご確認いただけます</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>更新日前に解約のお相手続きをされた場合、残りの期間については月割り・日割りでの返金はいたしません</Text>
                </View>
              </View>
            </View>
            <TouchableOpacity style={styles.blogFooter} onPress={()=>{this.navigation.navigate('Terms')}}>
              <Text style={[styles.txtSkyBlue, styles.txtUnderline, {textAlign:'center',}]}>利用規約</Text>
            </TouchableOpacity>
          </View>         
        </ScrollView>
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {}} />
          <View style={ConfirmPopupStyle.popupContainer}>
            <View style={ConfirmPopupStyle.popupTextItem}>
              <Text style={ConfirmPopupStyle.popupText}>有料会員登録にはメールアドレスの登録が必要です。</Text>
            </View>
            <View style={ConfirmPopupStyle.btnContainer}>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderRight]} onPress={()=>{
                this.popup?.hidePopup();
                this.navigation.navigate('SettingStack',{screen:'MailInput'});
              }}>
                <Text style={ConfirmPopupStyle.btnText}>OK</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderLeft]} onPress={()=>{
                this.popup?.hidePopup();
              }}>
                <Text style={ConfirmPopupStyle.btnText}>今はしない</Text>
              </TouchableOpacity>  
            </View>              
        </View>
      </Popup>
    </View>
  }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  titlePage:{
    paddingTop:15,
    paddingBottom:15,
    alignSelf:'center', 
  },
  titlePageTxt:{
    flexDirection:'row',
    textAlign:'center',
  },
  txtTitlePageBig:{
    fontSize:windowWidth<375? 23:27,
    fontWeight:'bold',
    lineHeight:38,
    color:'#5A70B6',
    letterSpacing:1,
  },
  txtTitlePage:{
    fontSize:windowWidth<375? 18:22,
    fontWeight:'bold',
    paddingTop:8,
    lineHeight:30,
    color:'#5A70B6',
    letterSpacing:1,
  },
  blogInner:{},
  imgBG3Banner:{
    width:'100%',
    height:windowWidth<375? 138:161,
  },
  imgBG3BannerInside:{
    paddingTop:windowWidth<375? 65:75,
    paddingLeft:windowWidth<375? 52:55,
    paddingRight:windowWidth<375? 138:150,
    textAlign:'center',
  },
  txtBG3Banner:{
    fontSize:windowWidth<375? 12:15,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 19:23,
    color:'#555555',
    letterSpacing:1,
    textAlign:'center',
  },
  imgBG3BannerLast:{    
    marginTop:26,
    width:'100%',
    height:windowWidth<375? 121:141,
  },
  imgBG3BannerInsideLast:{
    paddingTop:windowWidth<375? 52:60,
    paddingLeft:20,
  },
  txtBG3BannerLast:{
    fontSize:windowWidth<375? 16:19,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#65bd39',
  },
  txtBG3BannerLast1:{
    fontSize:windowWidth<375? 26:31,
  },
  txtBG3BannerLast2:{
    fontSize:windowWidth<375? 23:27,
  },
  txtBG3BannerLast3:{
    fontSize:windowWidth<375? 20:23,
  },
  blogRatePlan: {
    marginTop:22,
    marginBottom:0,
    textAlign:'center',
  },
  txtBlogRatePlan: {
    fontSize:windowWidth<375? 14:18,
    fontWeight:'bold',
    lineHeight:22,
    color:'#555',
    textAlign:'center'
  },
  txtBlogRatePlanInfo: {
    height:30,
    backgroundColor:'#f0f5f6',
    fontSize:windowWidth<375? 7:9,
    lineHeight:15,
    color:'#555',
    textAlign:'center',
  },
  blogRatePlanInside:{
    backgroundColor:'#f0f5f6',
    // paddingTop:15,
  },
  itemBlogRatePlanOther:{
    width:'100%',
    height:windowWidth<375? 51:59,
  },
  itemBlogRatePlan:{
    marginLeft:0,
    marginRight:0,
    width:'100%',
    // height:windowWidth<375? 170:178,
    height:windowWidth<375? 114:134,
    backgroundColor:'#f0f5f6',
  },
  blogInside1:{
    paddingTop:30,
    paddingBottom:15,
    paddingLeft:15,
    paddingRight:15,
    textAlign:'center',
  },
  txtBlogInside1Top:{
    fontSize:windowWidth<375? 12:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#445F8E',
    textAlign:'center',
  },
  txtBlogInside1Bottom:{
    fontSize:windowWidth<375? 23:27,
    fontWeight:'bold',
    lineHeight:38,
    color:'#5A70B6',
    letterSpacing:1,
    textAlign:'center',
  },
  blogImgTitle01:{
    marginBottom:10,
    textAlign:'center',
    backgroundColor:'#6FC5E5',
  },
  txtBlogInside1Title:{
    fontSize:windowWidth<375? 23:27,
    paddingTop:10,
    paddingBottom:10,
    fontWeight:'bold',
    lineHeight:38,
    color:'#FFFFFF',
    letterSpacing:1,
    textAlign:'center',
  },
  imgTitle01:{
    width:'100%',
    height:windowWidth<375? 49:58,
  },
  blogImgTitle02:{ },
  imgTitle02:{
    width:'100%',
    height:windowWidth<375? 69:81,
  },
  blogDesc01:{
    padding:15,
    textAlign:'center',
  },
  txtBlogDesc:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#555',
    textAlign:'center',
  },
  blogDesc02:{
    padding:30,
    textAlign:'center',
  },
  blogChat:{
    marginTop:20,
    marginBottom:42,
  },
  imgChat01:{
    width:'100%',
    height:windowWidth<375? 247:290,
  },
  imgChat02:{
    width:'100%',
    height:windowWidth<375? 220:258,
  },
  blogImg05:{
    marginBottom:38,
  },
  img05:{
    width:'100%',
    height:windowWidth<375? 147:173,
  },
  blogImgBG3:{
    marginBottom:10,
  },
  imgBlogImgBG3:{
    width:'100%',
    height:windowWidth<375? 49:58,
  },
  imgBlogImgBG3Last:{
    height:windowWidth<375? 71:84,
  },
  imgBlogImgBG3Inside:{
    paddingTop:16,
    paddingBottom:16,
    paddingLeft:80,
    paddingRight:30,
  },
  txtBlogImgBG3:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#555',
  },
  blogPlusMonthlyGifts:{
    marginTop:30,
    marginBottom:30,
    paddingTop:30,
    paddingBottom:25,
    backgroundColor:'#fffce1',
  },
  imgBlogPlusMonthlyGifts:{
    width:'100%',
    height:windowWidth<375? 136:160,
  },
  blogPlusMonthlyGiftsInside:{
    paddingTop:16,
    paddingLeft:16,
    paddingRight:16,
    textAlign:'center',
  },
  txtBlogPlusMonthlyGiftsInside:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#9a5f16',
    textAlign:'center',
  },
  blogNote:{
    paddingTop:30,
    paddingBottom:30,
    paddingLeft:16,
    paddingRight:16,
  },
  blogNoteTitle:{
    marginBottom:10,
  },
  txtBlogNoteTitle:{
    fontSize:windowWidth<375? 11:13,
    fontWeight:'bold',
    lineHeight:16,
    color:'#555',
  },
  blogNoteInside:{
    flexDirection:'row',
  },
  blogNoteInsideLeft:{
    flexDirection:'column',
    width:12,
  },
  blogNoteInsideRight:{
    flex:1,
    flexDirection:'column',
    paddingLeft:3,
  },
  txtBlogNote:{
    fontSize:windowWidth<375? 10:12,
    fontWeight:'normal',
    lineHeight:windowWidth<375? 17:20,
    color:'#555',
  },
  blogFooter:{
    paddingBottom:35,
    textAlign:'center',
  },
  txtOrange:{
    color:'#F19B38',
  },
  txtBlue:{
    color:'#004eae',
  },
  txtSkyBlue:{
    color:'#61bbd7',
  },
  txtUnderline:{
    textDecorationLine:'underline',
  },




  paymentModeTab:{
    flexDirection: 'row',
    backgroundColor:'#f0f5f6',
    paddingTop:0,
    paddingBottom:0,
    alignItems:'flex-end',
  },
  paymentModeButton:{
    width:'50%',
    backgroundColor:'#fff',
    paddingTop:10,
    textAlign:'center',
    alignContent:'flex-end', 
  },
  paymentModeButtonImg:{
    width:'100%',
    alignSelf:'flex-end', 
  },
  paidyInfoArea:{
    width:'100%',
    backgroundColor:'#f0f5f6',
  },
  paidyInfoButton:{
    width:'45%',
    justifyContent:'flex-end', 
    alignContent:'flex-end', 
    alignSelf:'flex-end',
    justifyContent:'center',
    backgroundColor:'#f0f5f6',
  },
  paidyInfoButtonImg:{
    width:'100%',
    height:40,
  },

});

