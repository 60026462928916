import React,{ Component } from 'react';
import { View, Image, Text, StyleSheet, Dimensions, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import CommonFunction from '../../utils/CommonFunction';
import Constants from '../../constants/Constants';
import Setting17Api from '../../services/api/Setting17Api';
import PopupWrapper from "../../components/common/PopupWrapper";
import Msg from '../../constants/option/Msg.json';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class SecretModeScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: true,
      isOn: false,
      myProfile: { sex: 0 }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
      this.checkAccountState();
      
      // from back page
      this.subscribeFocus = this.navigation.addListener('focus', () => {
        // this.checkAccountState();
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }  

  setHeader(){
    this.title = 'シークレットモード設定';
    if(this.props.setHeader!=undefined){
      this.props?.setHeader(this.title, this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  checkAccountState = async()=>{
    const {appState, setAppState} = this.context;
    const account = appState?.account;
    let myProfile = await UserUtil.getUserProfileLocal(appState);
    this.setState({ myProfile: myProfile });

    this.setState({ account: account },()=>{
      this.getDatatFromApi();
    });
  }

  getToggleValue = (val)=>{
    if(val==1 || val==true) return true;
    else return false;
  }
  
  async getDatatFromApi(){
    if(this._isMounted){
      try{
        const secretResponse = await Setting17Api.GetSecret();
        //check response
        if(!CommonFunction.checkResponseStatus(secretResponse)) return;   

        if(secretResponse!=undefined){
          if(secretResponse?.status==1){
            const data = secretResponse.response;
            this.setState({isOn: this.getToggleValue(data?.flag)});
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({ loading: false });
      }
      this.setState({ show: true});
    }
  }

  handleOnPress = async() =>{

    let value = this.state.isOn;
    if (value == true) {
      value = false;
    } else {
      value = true;
    }

    try{
      CommonFunction.overLay();
      const saveResponse = await Setting17Api.PutSecret(
        value==true?1:0
      );
      //check response
      if(!CommonFunction.checkResponseStatus(saveResponse)) return;

      if(saveResponse!=undefined){
        if(saveResponse.status==1){
          this.setState({isOn: value});
          PopupWrapper.showMessage(Msg.Update.Success); // complete popup
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    if(!this.state.show) return null;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
      <ScrollView style={ListStyles.scrollContainer} 
      ref={scroller => { this.scroller = scroller; }} 
      scrollToOverflowEnabled={true}
      scrollIndicatorInsets={{ right: 1 }}>      

      <View style={{flexDirection:'column', alignItems:'center'}}>
          {this.state.myProfile?.sex==Constants.FEMALE?
            <Image style={styles.img01} source={require('../../assets/images/secret_mode/female/img_w01.png')} resizeMode="stretch"/>
          :
            <Image style={styles.img01} source={require('../../assets/images/secret_mode/male/img_m01.png')} resizeMode="stretch"/>
          }
      </View>

      <View style={{flexDirection:'column'}}>
        <View style={[styles.textContainer,{marginTop:20}]}>
          <Text style={styles.txt1}>そのお悩みは</Text>
          <Text style={{fontWeight:'bold',color:'#1ab3b0',fontSize:34, lineHeight:50,textAlign:'center', }}>シークレットモード</Text>
          <Text style={styles.txt1}>で解決ができます</Text>
        </View>
      </View>

      <View style={{flexDirection:'column', alignItems:'center'}}>
        {this.state.myProfile?.sex==Constants.FEMALE?
          <Image style={styles.img02} source={require('../../assets/images/secret_mode/female/img_w02.png')} resizeMode="stretch"/>
        :
          <Image style={styles.img02} source={require('../../assets/images/secret_mode/male/img_m02.png')} resizeMode="stretch"/>
        }
      </View>

      <View style={{flexDirection:'column'}}>
        <View style={[styles.textContainer,{marginTop:Platform.OS==='web'?10:25}]}>
          <Text style={styles.txt2}>シークレットモードをONにすると、</Text>
          <Text style={styles.txt2}>プロフィール画面などに表示されている</Text>
          <Text style={styles.txt2}>ログイン状況がマッチング相手には非表示に！</Text>
        </View>
        <View style={{marginTop:Platform.OS==='web'?10:20,}}>
          <View style={styles.greyBox}>
            <View style={styles.textContainer}>
              <Text style={styles.txt3}>色付きマークや「オンライン」「24時間以内」などの表示が、すべての画面で非表示になります。</Text>
            </View>
          </View>  
        </View> 
      </View>

      <View style={{flexDirection:'column', alignItems:'center', width: '100%'}}>
        {this.state.myProfile?.sex==Constants.FEMALE?
          <Image style={styles.img03} source={require('../../assets/images/secret_mode/female/img_w03.png')} resizeMode="stretch"/>
        :
          <Image style={styles.img03} source={require('../../assets/images/secret_mode/male/img_m03.png')} resizeMode="stretch"/>
        }
      </View>

      <View style={{flexDirection:'column'}}>
        <View style={[styles.textContainer,{marginTop:Platform.OS==='web'?10:20}]}>
          <Text style={styles.txt2}>マッチング相手がプロフィール一覧を見ても</Text>
          <Text style={styles.txt2}>あなたは表示されなくなるため</Text>
          {this.state.myProfile?.sex==Constants.FEMALE?
            <Text style={styles.txt2}>気楽に他の男性にアプローチが可能に！</Text>
          :
            <Text style={styles.txt2}>気楽に他の女性にアプローチが可能に！</Text>
          }
        </View>
        <View style={{marginTop:Platform.OS==='web'?10:15,}}>
          <View style={styles.greyBox}>
            <View style={styles.textContainer}>
              <Text style={styles.txt3}>「ログインしているのに何で返信を貰えないの？」というようなトラブルを避ける事ができます</Text>
            </View>
          </View>  
        </View> 
      </View>

      <View style={{flexDirection:'column', alignItems:'center'}}>
        {this.state.myProfile?.sex==Constants.FEMALE?
          <Image style={styles.img04} source={require('../../assets/images/secret_mode/female/img_w04.png')} resizeMode="stretch"/>
        :
          <Image style={styles.img04} source={require('../../assets/images/secret_mode/male/img_m04.png')} resizeMode="stretch"/>
        }
      </View>

      <View style={{flexDirection:'column'}}>
        <View style={[styles.textContainer,{marginTop: Platform.OS==='web'?10:20}]}>
          <Text style={styles.txt2}>足あとが残らないため、気兼ねなくお相手の</Text>
          <Text style={styles.txt2}>プロフィールをいつでもチェックできます</Text>
        </View>
      </View>

      <View style={{flexDirection:'column'}}>
        <View style={[styles.textContainer,{marginTop:110}]}>
          <Text style={styles.txt1}>もっと気楽にお相手探し</Text>
          <Text style={{fontWeight:'bold',color:'#1ab3b0',fontSize:34, lineHeight:50, textAlign:'center'}}>シークレットモード</Text>
          {this.state.isOn ?
            <Text style={styles.txt1}>現在の設定 : ON</Text>
          :
            <Text style={styles.txt1}>現在の設定 : OFF</Text>
          }
        </View>
      </View>

      <View style={{flexDirection:'column', ...Platform.select({ios:{marginBottom:30},android:{},default:{}}) }}>
        <View style={[styles.textContainer,{marginTop:20, marginBottom:60, alignItems:'center'}]}>
            <TouchableOpacity style={[styles.submitBtn]} onPress={()=>{ 
                this.handleOnPress();
              }}>
               {this.state.isOn ?
                <Text style={styles.textsubmitBtnWhite}>OFFにする</Text>
              :
                <Text style={styles.textsubmitBtnWhite}>ONにする</Text>
              } 
            </TouchableOpacity>
        </View>
      </View>
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  img01:{
    marginTop:0,
    alignItems:'center',
    height:windowWidth<375? 265 : 310,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  img02:{
    marginTop:40,
    alignItems:'center',
    height:windowWidth<375? 217 : 254,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  img03:{
    marginTop:50,
    alignItems:'center',
    height:windowWidth<375? 218 : 256,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  img04:{
    marginTop:50,
    alignItems:'center',
    height:windowWidth<375? 217 : 254,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  textContainer:{
    flex:1,
    width:'100%',
    textAlign: 'center',
    justifyContent:'center',
  },
  greyBox:{
    marginLeft:10,
    marginRight:10,
    backgroundColor: '#f4f4f4',
    borderRadius:10,
    padding:10,
    alignItems:'center',
  },
  txt1:{
    fontSize:windowWidth<375? 16 : 18,
    lineHeight:35,
    fontWeight:'bold',
    textAlign:'center',  
  },
  txt2:{
    fontSize:windowWidth<375? 13 : 15,
    textAlign:'center',
    ...Platform.select({
      ios:{
        lineHeight: 24,
      },
      android:{
        lineHeight: 24,
      },
      default:{

      }
    })
  },
  txt3:{
    fontSize:windowWidth<375? 12 : 14,
    textAlign:'center', 
    textAlign:'center',
    ...Platform.select({
      ios:{
        lineHeight: 24,
      },
      android:{
        lineHeight: 24,
      },
      default:{

      }
    })
  },
  submitBtn:{
    alignItems:"center",
    justifyContent:"center",
    padding:8,
    width:'80%',
    height:45,
    backgroundColor:'#7CD7BB',
    borderRadius:999,
  },
  textsubmitBtnWhite: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:26,
    color:'#ffffff',
    textAlign:'center',
  },
});
