import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  btnText:{
    fontSize:14, 
    fontWeight:'bold', 
    color:'#5888f7', 
    lineHeight:20, 
    textAlign:'center'
  },
  btnItem:{
    padding:12, 
    width:'50%',
  },
  btnContainer:{
    flexDirection:'row', 
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    width:'100%'
  },
  popupText:{
    fontSize:14, 
    fontWeight:'bold', 
    color:'#484848', 
    lineHeight:20, 
    textAlign:'center'
  },
  popupTextItem:{
    paddingTop:25, 
    paddingBottom:18, 
    paddingLeft:20, 
    paddingRight:20, 
    width:'100%', 
    borderBottomColor:'#d8d8dc', 
    borderBottomWidth:2,
  },
  popupContainer:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    alignContent:'center', 
    padding: 0, 
    width:'70%', 
    backgroundColor:'#fff', 
    borderRadius: 12,
  },
  borderRight:{
    borderRightWidth:1, 
    borderRightColor:'#d8d8dc'
  },
  borderLeft:{
    borderLeftWidth:1, 
    borderLeftColor:'#d8d8dc'
  }
});
