import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  listPopupFirst:{
    margin:0,
    padding:16,
    width:'100%',
    backgroundColor:'#fff',
    borderTopLeftRadius:13,
    borderTopRightRadius:13,
    borderTopWidth:0,
    borderBottomColor:'#d6d6da',
    borderBottomWidth:1,
    textAlign:'center',
  },
  listPopupItem:{
    margin:0,
    padding:16,
    width:'100%',
    backgroundColor:'#fff',
    borderTopWidth:0,
    borderBottomColor:'#d6d6da',
    borderBottomWidth:1,
    textAlign:'center',
  },
  listPopupLast:{
    margin:0,
    padding:16,
    width:'100%',
    backgroundColor:'#fff',
    borderBottomLeftRadius:13,
    borderBottomRightRadius:13,
    textAlign:'center',
  },
  txtColorRed:{
    color:'#f53d3d',
  },
  txtColorBlue:{
    color:'#007aff',
  },
  btnListPopupCancel:{
    marginTop:10,
    padding:16,
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:13,
    textAlign:'center',
  },
  txtBtnListPopupCancel:{
    fontSize:18,
    fontWeight:'bold',
    color:'#007aff',
    lineHeight:18,
    textAlign:'center',
    ...Platform.select({
      android:{
        lineHeight:22,
      },
      ios:{
        lineHeight:22,
      }
    })
  },
  txtListPopup:{
    fontSize:18,
    fontWeight:'bold',
    color:'#007aff',
    lineHeight:18,
    textAlign:'center',
    ...Platform.select({
      android:{
        lineHeight:22,
      },
      ios:{
        lineHeight:22,
      }
    })
  }
});
