import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ConfirmPopupStyles from '../../styles/ConfirmPopupStyles';
import formStyles from '../../styles/FormStyles';

export default class NoPoint extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      message: 'ポイントが不足しています。ポイント購入の上、再度お試しください'
    }
    this.PopUp = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation, modal, onNavigateAction } = this.props;
      this.navigation = navigation;
      this.modal = (modal!=undefined)?modal:true;
      this.onNavigateAction = onNavigateAction;
      this.setState({loading: false});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = ()=>{
    this.PopUp?.showPopup();
  }

  hide = ()=>{
    this.PopUp?.hidePopup();
  }

  isShow = ()=>{
    return this.PopUp?.isShow();
  }

  setMessage = (message)=>{
    this.setState({message: message});
  }

  setOnNavigateAction = (navigateAction)=>{
    this.onNavigateAction = navigateAction;
  }

  _onNavigateAction = ()=>{
    if(this.onNavigateAction!=undefined) {
      this.onNavigateAction();
    }
  }

  render(){
    if(this.state.loading===true) return null;

    return (
      <Popup ref={ref=>this.PopUp=ref} modal={this.modal}>
        <View style={PortalStyle.overlay} />
        <View style={[ConfirmPopupStyles.popupContainer,{width:'85%'}]}>
          <View style={[ConfirmPopupStyles.popupTextItem,{paddingTop:30, borderBottomWidth:0}]}>
            <Text style={ConfirmPopupStyles.popupText}>{this.state.message}</Text>
          </View>
          <View style={styles.areaErrorBtn}>
            <TouchableOpacity style={formStyles.submitBtn} onPress={()=>{
              this.hide();
              this._onNavigateAction();
              //this.navigation?.navigate('Auth', {screen:'PointShoppingStack', params:{screen:'PointShopping'}});
              this.navigation?.navigate('PointShoppingStack', {screen:'PointShopping'});
            }}>
              <Text style={{color:'#ffffff',fontWeight:'bold'}}>ポイントを購入する</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalButtonWhite} onPress={()=>{ 
              this.hide();
            }}>
              <Text style={styles.textModalButtonWhite}>キャンセル</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Popup>
    );
  }
}

const styles = StyleSheet.create({
  areaErrorBtn:{
    width:'100%',
    paddingBottom:10,
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
});