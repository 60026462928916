import React,{ Component } from 'react';
import { View, Image, Text, StyleSheet, Dimensions, ImageBackground, ScrollView } from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import ListStyles from '../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class MalePrivateModeScreen extends Component { 
  _isMounted = false;
  constructor() {
    super();
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      CommonFunction.checkAccessTime();
      this.title = 'プライベートモード';
      this.props.setHeader(this.title, this.props.navigation);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
    {(insets) =>
    <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
      <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
        <ImageBackground style={[styles.image,styles.imgTop]} source={require('../assets/images/private_mode/male/banner-top.png')} resizeMode="cover">
          <View style={styles.imgTopInside}>
            <Text style={{fontSize:12, fontWeight:'bold', lineHeight:20, color:'#333', letterSpacing:1, textAlign:'center'}}>いいねしたお相手、メッセージを送ったお相手{"\n"}だけに自分のプロフィールが表示されます</Text>  
          </View>
        </ImageBackground>

        <ImageBackground style={[styles.image,styles.img01]} source={require('../assets/images/private_mode/male/img01.png')} resizeMode="cover">
          <View style={styles.img01Inside}>
            <Text style={{fontSize:20, fontWeight:'bold', lineHeight:32, color:'#333', textAlign:'center'}}>こんな不安、ありませんか？</Text>  
          </View>
        </ImageBackground>
                
        <View style={[styles.textContainer,{marginTop:60}]}>
          <Text style={[styles.txt1, {fontSize:20, textAlign:'center',}]}>これらは全て！</Text>
          <Text style={{fontWeight:'bold',color:'#1ab3b0',fontSize:30, lineHeight:55, textAlign:'center',}}>プライベートモード</Text>
          <Text style={[styles.txt1, {fontSize:20, textAlign:'center',}]}>で解決ができます</Text>
        </View>
        <View style={{marginTop:60,flexDirection:'column'}}>
          <Image style={[styles.image, styles.img02]} source={require('../assets/images/private_mode/male/img02.png')}/>
          <View style={[styles.textContainer,{marginTop:10,marginBottom:10}]}>
            <Text style={[styles.txt1, {textAlign:'center'}]}>友人・知人に気付かれることなく</Text>
            <Text style={[styles.txt1, {textAlign:'center'}]}>Pappyを使うことができる</Text>
          </View>
          <View style={[styles.textContainer,{marginTop:5,marginBottom:5}]}>
            <Text style={[styles.txt2, {textAlign:'center'}]}>プライベートモードでは、あなたのプロフィールが</Text>
            <Text style={[styles.txt2, {textAlign:'center'}]}>不特定多数のお相手に表示されなくなります</Text>
          </View>
          <View style={styles.greyBox}>
            <Text style={styles.txt3}>あなたがいいねした、またはメッセージを送った相手のみあなたのプロフィールを閲覧できます</Text>
          </View>
        </View>
        <View style={{marginTop:60,flexDirection:'column'}}>
          <Image style={[styles.image, styles.img03]} source={require('../assets/images/private_mode/male/img03.png')}/>
          <View style={[styles.textContainer,{marginTop:10,marginBottom:10}]}>
            <Text style={[styles.txt1, {textAlign:'center'}]}>お相手のプロフィールを見ても</Text>
            <Text style={[styles.txt1, {textAlign:'center'}]}>自分の足あとは残りません</Text>
          </View>
          <View style={[styles.textContainer,{marginTop:5,marginBottom:5}]}>
            <Text style={[styles.txt2, {textAlign:'center'}]}>足あとが残らないため、気兼ねなくお相手の</Text>
            <Text style={[styles.txt2, {textAlign:'center'}]}>プロフィールをいつでもチェックできます</Text>
          </View>
        </View>
        <View style={{marginTop:60,flexDirection:'column'}}>
          <Image style={[styles.image, styles.img04]} source={require('../assets/images/private_mode/male/img04.png')}/>
          <View style={[styles.textContainer,{marginTop:10,marginBottom:10}]}>
            <Text style={[styles.txt1, {textAlign:'center'}]}>あなたがいいねまたはメッセージを</Text>
            <Text style={[styles.txt1, {textAlign:'center'}]}>送ったお相手だけが、あなたの</Text>
            <Text style={[styles.txt1, {textAlign:'center'}]}>プロフィールを閲覧できます</Text>
          </View>
          <View style={[styles.textContainer,{marginTop:5,marginBottom:5}]}>
            <Text style={[styles.txt2, {textAlign:'center'}]}>あなたが興味を持ったお相手しかプロフィールの</Text>
            <Text style={[styles.txt2, {textAlign:'center'}]}>閲覧ができないため出会いに集中できます</Text>
          </View>
        </View>
        <View style={{marginTop:50,flexDirection:'column'}}>
          <Image style={[styles.image, styles.img05]} source={require('../assets/images/private_mode/male/img05.png')}/>
          <View style={[styles.textContainer,{marginTop:10,marginBottom:50}]}>
            <Text style={[styles.txt1, {textAlign:'center'}]}>今すぐご利用いただけます！{"\n"}切り替えもスイッチで簡単</Text>
          </View>
        </View>
        </ScrollView>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  image:{
    width: '100%',
    height: 150
  },
  imgTop:{
    height:windowWidth<375? 211 : 247,
  },
  imgTopInside:{
    display:'flex',
    justifyContent:'flex-end',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    paddingBottom:windowWidth<375? 12 : 20,
    height:windowWidth<375? 211 : 247,
  },
  img01:{
    height:windowWidth<375? 288 : 337,
  },
  img01Inside:{
    display:'flex',
    justifyContent:'flex-start',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    paddingTop:windowWidth<375? 23 : 29,
    height:windowWidth<375? 288 : 337,
  },
  img02:{
    height:windowWidth<375? 136 : 160,
  },
  img03:{
    height:windowWidth<375? 136 : 159,
  },
  img04:{
    height:windowWidth<375? 200 : 300,
  },
  img05:{
    height:windowWidth<375? 125 : 146,
  },
  textContainer:{
    flex:1,
    width:'100%',
    textAlign: 'center',
    justifyContent:'center',
  },
  greyBox:{
    backgroundColor: '#f4f4f4',
    width: '90%',
    marginLeft: '5%',
    padding:10,
    borderRadius:10,
  },
  txt1:{
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',    
  },
  txt2:{
    fontSize:windowWidth<375? 13 : 15,
  },
  txt3:{
    fontSize:windowWidth<375? 11 : 13,
  }
  
});
