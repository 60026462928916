
import * as React from 'react';
import { Image, View, StyleSheet,ImageBackground, Text, Platform, Dimensions } from 'react-native';
import BottomTabBadge from '../../components/common/BottomTabBadge';

const windowWidth = Dimensions.get('window').width;

export default function TabBarImageIcon(props) {
  const {image, badge, text, notnumber, focused, backBtnVis} = props;
  let iconStyle = styles.tabIcon;
  if(backBtnVis==true) {
    iconStyle = styles.tabIconIkuyo;
  }
  return (
    <View style={styles.container}>
      {text?
       <ImageBackground style={iconStyle} source={image}>
        <Text style={[styles.text,{color:focused?'#90dd95':'#979c9d'}]}>{text}</Text>
      </ImageBackground>
      :
      <Image style={iconStyle} source={image}/>
      }
      {backBtnVis?
        <Text style={styles.textIkuyo}>イククルに戻る</Text>
      :null}
      {badge > 0 && (<BottomTabBadge badge={badge} notnumber={notnumber} />)}
    </View>
  );
}

const styles = StyleSheet.create({
  container:{
    //flexDirection:'row',
    flexDirection: 'column',
    ...Platform.select({
      ios: { height:24 },
      android: { height:24 },
      default: { }
    }),
  },
  tabIcon:{
    marginTop: 5,
    width:24, 
    height:24,
    ...Platform.select({
      ios: { marginTop: 0, },
      android: { marginTop: 5, },
      default: { marginTop: 5, }
    }),
  },
  tabIconIkuyo:{
    marginTop: 0, 
    width:windowWidth<375? 50:55, 
    height:windowWidth<375? 26:29,
    alignSelf:'center',
    alignItems:'center',
    ...Platform.select({
      ios:{
        marginTop:-3,
      },
      android:{
        marginTop:0,
        height:windowWidth<375? 26:28,
      }
    })
  },
  text:{
    color:'#9aa5a7',
    width:'100%',
    height:'100%',
    justifyContent:'center',
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    display:'flex',
    ...Platform.select({
      ios: {
        paddingTop:4,
        lineHeight:20,
        textAlign:'center',
      },
      android: {
        paddingTop:4,
        lineHeight:20,
        textAlign:'center',
      },
      default: { }
    }),
  },
  textIkuyo:{
    color:'#9aa5a7',
    marginLeft: 3, 
    paddingLeft: 5, 
    fontSize:windowWidth<375? 8:10,
    fontWeight:'bold',
    width:windowWidth<375? 65:75,
    height:11,
    alignSelf:'center',
    alignItems:'center',
  }
});
