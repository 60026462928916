class AgeManager {
  _defaultAgeManager = null;
  register(_ref) {
    this._defaultAgeManager = _ref;
  }

  unregister(_ref) {
    this._defaultAgeManager = null;
  }
  
  getDefault() {
    return this._defaultAgeManager;
  }
}

export default new AgeManager();
