import React, { Fragment, Component } from 'react';
import {Dimensions, View, Text, TouchableOpacity, StyleSheet, Image, TextInput, Platform, ScrollView } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import * as RootNavigation from '../../navigation/RootNavigation';
import { Formik } from 'formik'
import PopupWrapper from '../../components/common/PopupWrapper';
import CommonFunction  from '../../utils/CommonFunction';
import MailBoxApi from "../../services/api/MailBox05Api";

const windowWidth = Dimensions.get('window').width;
const inputAccessoryViewID = "inputAccessoryView1";

export default class FavoriteNameSetting extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      rowIndex: '',
      show: false,
      item: [],
    }
    this.initialValues = {
      favoriteName: '',
    }
    this.nonFavoriteName = '名称未設定';
    this.formik = React.createRef();
    this.InputFavorite = 0;
    this.InputFavoriteName = '';
    this.selected = 0;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (param)=>{
    this.InputFavorite = param.slectNameChange;
    this.favoritnames = param.favoriteNames;
    if (this.InputFavorite == 1) {
      this.InputFavoriteName = this.favoritnames.single;
    } else if (this.InputFavorite == 2) {
      this.InputFavoriteName = this.favoritnames.double;
    } else if (this.InputFavorite == 3) {
      this.InputFavoriteName = this.favoritnames.triple;
    }
    this.initialValues.favoriteName = this.InputFavoriteName;
    if (this.InputFavoriteName =='') {
      this.InputFavoriteName = this.nonFavoriteName;
    }
    this.selected = param.selected;
    this.setState({ show: true });
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  cancelFavoriteName = async()=>{
    const params = {selected: this.selected, slectNameChange: null, updataFavoriteName: null};
    this.props.filterUpdata(params);
    this.hide();
  }

  handleSubmit = async(values)=>{
    const name = values.favoriteName;

    // const segmeter = new Intl.Segmenter('ja-JP', { granularity: 'word'});
    // const count = Array.from(segmeter.segment(name)).length

    const count = (Array.from(name).length);
    if (count >10) {
      PopupWrapper.showMessage('10文字以内で入力してください','check',()=>{
        CommonFunction.hideOverlay();
      });
      return;
    }

    // if (name.length >10) {
    //   PopupWrapper.showMessage('10文字以内で入力してください','check',()=>{
    //     CommonFunction.hideOverlay();
    //   });
    //   return;
    // }
    const apiParams = {favorite: this.InputFavorite, name: name};
    const favoriteNameResponse = await MailBoxApi.FavoriteName(apiParams);
    if(!CommonFunction.checkResponseStatus(favoriteNameResponse)) return;
    if(favoriteNameResponse?.status==1){
      const params = {selected: this.selected, slectNameChange: this.InputFavorite, updataFavoriteName: name};
      this.props.filterUpdata(params);
      this.hide();
    }
  }

  changeDispText = (textData)=>{
    if (textData.length==0) {
      textData = this.nonFavoriteName;
    }
    return textData;
  }

  render() {
    if(!this.state.show) return null;
    let w = this.InputFavorite * 30;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
          <ScrollView style={{width:'100%', height:'100%'}}>
            <View style={styles.textArea}>
              <View style={{bottom: 0, width: '100%', height: 80}}>
                <Text style={{fontSize: 20, fontWeight: 'bold', color: '#000000', textAlign: 'center', lineHeight: 60}}>
                  お気に入りマークの名称設定
                </Text>
                <Text style={{fontSize:windowWidth<375? 10 : 11, color: '#333', textAlign: 'center', lineHeight: 20}}>
                  それぞれのマークに名称を付けることができます
                </Text>
              </View>
            </View>
            <View style={styles.favorireSettingArea}>
              <View style={{flexDirection: 'row', paddingTop: 15, bottom: 0, width: w, height: 100}}>
                <Image style={[styles.checkImg, {paddingLeft: 0}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                {this.InputFavorite>=2?
                  <Image style={[styles.checkImg, {paddingLeft: 0}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                : null}
                {this.InputFavorite>=3?
                   <Image style={[styles.checkImg, {paddingLeft: 0}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                : null}
              </View>
              <View style={{flexDirection: 'column', paddingTop: 30, paddingLeft: 15, bottom: 0, width: 144}}>
                <Text style={styles.txtStar}>現在の名称</Text>
                <Text style={styles.txtInput}>{this.changeDispText(this.InputFavoriteName)}</Text>
              </View>
            </View>
            <Formik
              innerRef={ref => this.Formik=ref}
              initialValues={this.initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={values => this.handleSubmit(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
              <Fragment>
                  <View style={styles.nameInputArea}>
                    <View style={styles.favoriteNameBorder}>
                      <TextInput 
                          style={[styles.favoriteNameInputBorder, {fontSize:windowWidth<375? 14 : 16}]}
                          onChangeText={handleChange('favoriteName')}
                          value={values.favoriteName} 
                          multiline={false} 
                          placeholder="新しい名称を記入する" 
                          placeholderTextColor="#333"
                          inputAccessoryViewID={inputAccessoryViewID}
                      />
                       <Image style={styles.nameChangeBtnIcon} source={require('../../assets/images/favorite/favoriteNameSetIcon.png')} />
                    </View>
                    <Text style={{width: 254, fontSize:12, color: '#333', textAlign: 'right', alignSelf:'center', lineHeight:20}}>※最大10文字まで</Text>
                  </View>
                  <View style={styles.btnArea}>
                    <View style={{paddingTop: 0}}>
                      <TouchableOpacity 
                        style={styles.btnBlue} 
                        onPress={handleSubmit}
                      >
                        <Text style={styles.txtBtnBlue}>決定</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{paddingTop: 13}}>
                      <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                        this.cancelFavoriteName();
                      }}>
                        <Text style={styles.txtBtnCancel}>キャンセル</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
              </Fragment>
            )}          
            </Formik>
            </ScrollView>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:372, 
    width:'90%',
    alignSelf:'center', 
    borderRadius:8,
  },
  btnBlue:{
    width:'60%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignSelf:'center', 
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  btnCancel:{
    width:'40%',
    padding:4,
    borderRadius:999,
    backgroundColor:'#eef1f2',
    height:26,
    alignSelf:'center', 
  },
  txtBtnCancel:{
    fontSize:12,
    lineHeight:18,
    color:'#333',
    textAlign:'center',
  },
  textArea: {
    paddingTop: 10,
  },
  favorireSettingArea: {
    flexDirection: 'row',
    width:254,
    height:90,
    itemAlign:'center',
    alignSelf:'center',
  },
  btnAnameInputArearea: {
    width:254,
    height:85,
    itemAlign:'center',
  },
  btnArea: {
    itemAlign:'center',
    paddingTop:35,
  },
  checkImg:{
    marginTop:15,
    marginLeft:6,
    width:28,
    height:27,
  },
  favoriteNameBorder:{
    flexDirection: 'row',
    width:254, 
    height:36,
    alignSelf:'center',
    backgroundColor:"#eef1f2",
    borderColor:'#eef1f2',
    borderWidth:1,
    borderRadius:5,
  },
  favoriteNameInputBorder:{
    width:224,
    fontSize:18,
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:8,
    paddingRight:16,
    color:"#333",
    lineHeight:20,
    ...Platform.select({
      ios:{
        paddingTop:0,
        paddingBottom:0,
      },
      android:{
        paddingTop:0,
        paddingBottom:0,
      }
    })
  },
  nameChangeBtnIcon:{
    marginTop:7,
    width:18,
    height:18,
    ...Platform.select({
      web:{
        alignSelf:'right',
      }
    })
  },
  txtStar:{
    width: 190, 
    fontSize:11, 
    color: '#333', 
    textAlign: 'left', 
    ...Platform.select({
      web:{
        alignSelf:'left',
        lineHeight:11,
      }
    })
  },
  txtInput:{
    width: 190, 
    fontSize:windowWidth<375? 14 : 16, 
    color: '#333', 
    textAlign: 'left', 
    paddingTop: 4,
    lineHeight:20,
    ...Platform.select({
      web:{
        alignSelf:'left', 
      }
    })
  }
});