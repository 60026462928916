import { registerRootComponent } from 'expo';
import { Platform, AppRegistry } from 'react-native';
import messaging from '@react-native-firebase/messaging';
import App from './App';
if (Platform.OS !== 'web') {
  messaging().setBackgroundMessageHandler(async remoteMessage => {
  });
}

registerRootComponent(App);
