class SMSManager {
  _defaultSMSManager = null;
  register(_ref) {
    this._defaultSMSManager = _ref;
  }

  unregister(_ref) {
    this._defaultSMSManager = null;
  }
  
  getDefault() {
    return this._defaultSMSManager;
  }
}

export default new SMSManager();
