import React from 'react';
import { 
  View, Dimensions, Text, TouchableOpacity, Image, ScrollView, TextInput, Animated, Platform
} from 'react-native';
import { AntDesign, Ionicons, Entypo } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import { LinearGradient } from 'expo-linear-gradient';
import ActivityIndicator from '../components/common/ActivityIndicator';
import ProfileApi from '../services/api/Profile04Api';
import BottomMenu from '../components/common/BottomMenu';
import BottomMenuStyles from '../styles/BottomMenuStyles';
import UserUtil from '../utils/UserUtil';
import CalendarApi from '../services/api/Calendar11Api';
import OnlineStatus from '../components/common/OnlineStatus';
import Constants from '../constants/Constants';
import OptionData  from '../constants/option/OptionData.json';
import IineApi from '../services/api/Iine10Api';
import MailBoxApi from "../services/api/MailBox05Api";
import { Formik } from 'formik';
import PopupWrapper from "../components/common/PopupWrapper";
import Msg from '../constants/option/Msg.json';
import Moment from 'moment';
import FootmarkApi from '../services/api/Footmark06Api';
import PortalStyle from '../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import ImageViewer from 'react-native-image-zoom-viewer';
import Carousel from 'react-native-snap-carousel';
import {
  checkValue, Block16, Block16Male, Block17, Block18, Block18Male, Block19, Block20, Block21, MeetToday
} from '../components/common/UserInfo';
import UserVerify from '../utils/UserVerify';
import CommonFunction from '../utils/CommonFunction';
import Popup from '../components/common/Popup';
import styles from '../styles/UserInfoStyles';
import Recommend22Api from '../services/api/Recommend22Api';
import ProfileScreen from './ProfileScreen';
import NoPoint from '../components/common/NoPoint';
import ErrorCode from '../constants/ErrorCode';
import OverlayWrapper from '../components/common/OverlayWrapper';
import LogoNoMargin from '../components/common/LogoNoMargin';
import MessageTemplate from '../components/common/MessageTemplate';
import ExpoConstants from 'expo-constants';
import NoItemsBox from '../components/common/NoItemsBox';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const { width, height } = Dimensions.get('window');
const isWeb = Platform.OS === 'web';
const inputAccessoryViewID = "inputAccessoryView1";

export default class RecommendIineUserInfoScreen extends ProfileScreen {
  constructor(props) {
    super(props);
    this.componentName = 'RecommendIineUserInfoScreen';//this.constructor?.name;
    this.showPreviousUserInfo = false;
    this.actionEnabled = React.createRef();
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this._isMounted) {
      // search list
      this.userList = await this.getUserList();

      if(this.userList==undefined || this.userList?.length==0) {
        this.navigation?.navigate('RecommendIine');
        return;
      }

      const memberId = this.props.route?.params?.memberId;
      await this.getDataFromApi(memberId);

      // from back page
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        CommonFunction.setMainBottomTab(this.BottomTabNavigation, "none"); // hide main bottom tab

        this.actionEnabled.current = false;
        /*
        this.setState({maskUp: true},()=>{
          setTimeout(function(){
            this.scrollToTop();
            this.setState({maskUp: false});
          }.bind(this),250);
        });
        */
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.currentUser.memberId!=this.state.currentUser.memberId){
      this.setHeader(this.state.currentUser);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }

  getDataFromApi = async(memberId)=>{
    try{
      this.index = this.userList?.findIndex(
        item => memberId == item.memberId
      );

      if(this.index==-1) {
        if(this.navigation?.canGoBack()){
          this.goBack();
          return;
        }
      }

      this.setState({ currentLoading: true });

      /*
      if(Platform.OS==='web'){
        this.currentList = this.makeListData(this.userList, this.index);
      }else{
        this.currentList = this.makeListDataApp(this.userList, this.index);
      }
      */
      this.currentList = this.makeListDataApp(this.userList, this.index);
      this.currentUser = this.userList[this.index];
      this.initialIndex = this.currentList?.findIndex(
        item => this.userList[this.index].memberId === item.memberId
      );

      let profileGetListResponse = await ProfileApi.GetList(memberId);
      //check response status
      if(!CommonFunction.checkResponseStatus(profileGetListResponse)) return;
      
      if(profileGetListResponse!=undefined && profileGetListResponse?.status==1){
        let profileInfo = [];
        if(profileGetListResponse?.response?.profileList?.count>0){
          profileInfo = profileGetListResponse?.response?.profileList?.list[0];
        }

        // set appContext value
        let account = UserUtil.getAccountAppState(profileGetListResponse, this.context);
        let paymentFlag = account?.subscription?.status;
        let firstSendMessage = false;
        if(paymentFlag==0 && profileInfo.sendMessageFlg==0){ // check first message
          paymentFlag = 1;
          firstSendMessage = true;
        }

        if(account?.activated!=0){
          if(profileInfo?.noFootmarkFlg==0){
            // call footmark　api
            FootmarkApi.postFootmark(memberId);
          }
        }

        if(account?.activated!=0){
          if(profileInfo?.secretStatus==0){
            const responseGetTargetList = await CalendarApi.GetTargetList(memberId);
            const aeruList = responseGetTargetList?.response?.aeru?.list;
            if(aeruList!=undefined){
              profileInfo.aeruList = aeruList;
            
              const meetToday = aeruList.filter((element)=>{
                return element.day==Moment().format('D') && element.month==Moment().format('M') && element.year==Moment().format('YYYY');
              });
              profileInfo.meetToday = (meetToday?.length>0)?1:0;
            }
          }
        }

        let imagesList = [];
        let images = { url: profileInfo?.mainImage?.url };
        imagesList.push(images);
        if(profileInfo.subImage?.list?.length>0){
          profileInfo.subImage?.list?.map((e,i) => {
            images = { url: e.imageUrlOrg?e.imageUrlOrg:e.imageUrl };
            imagesList.push(images);
          });
        }
        
        const template = await this.getTemplateFromApiByMemberId(memberId);
        const editable = this.validateEditable({
          ageVerficationStatus: account?.ageVerficationStatus,
          verified: profileInfo?.verified,
          paymentFlag: paymentFlag
        });

        this.setState({ 
          mainIndex: this.index,
          index: this.initialIndex,
          userList: this.currentList,
          currentUser: {...this.currentUser, ...profileInfo},
          account: account,
          toggleAeruList: true,
          disableLayout: false,
          imagesList: imagesList,
          currentImageIndex: 0,
          paymentFlag: paymentFlag,
          firstSendMessage: firstSendMessage,
          template: template,
          editable: editable,
          loading: false,
          networkError: false,
          currentLoading: false
        },()=>{
          if(Platform.OS==='web'){
            if(this.swiper.snapToItem!=undefined){
              //this.swiper.snapToItem(this.initialIndex, false);
            }
          }
          /*
          this.getTemplateFromApi();
          this.setState({ editable: this.validateEditable() });
          */
        });
      }else{
        this.setState({ networkError: true });
      }
    }catch(e){
      if(__DEV__) console.log(e);
      this.setState({ loading: false, currentLoading: false });
    }finally{
      //this.setState({ loading: false });
    }
  }

  showNextUser = ()=>{
    if(this.state.currentLoading) return;

    CommonFunction?.hideOverlay();
    OverlayWrapper?.hide();
    if(this.state?.mainIndex >= (this.userList?.length-1)){
      // end, goto iine
      this.goBack();
      return;
    }

    //scroll to top
    //this.scrollToTop();
    //this.setState({scrollEnabled: true});

    this.nextIndex = this.state.index+1;
    if(this.state.userList[this.nextIndex]){
      if(Platform.OS==='web') this.scrollToTop(this.nextIndex);
      if(this.swiper?.snapToItem!=undefined){
        this.shouldComponentUpdateFlg = false;
        this.swiper.snapToItem(this.nextIndex, true);
      }
    }
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;
    
    return UserVerify.checkSMS({
      activated: this.state.account.activated, 
      navigation: this.navigation,
      redirect: false,
      account: this.state.account
    });
  }

  setIineList = (memberId)=>{
    if(memberId){
      this.iineList.push(memberId);
      let { appState, setAppState } = this.context;
      appState.iineList = this.iineList;
      setAppState(appState);
    }
  }

  skip = async()=>{
    if(this.state.currentUser?.sendGoodFlg==1){
      this.showNextUser();
    }else{
      if(this.actionEnabled.current) return;
      this.actionEnabled.current = true;
      setTimeout(() => {
        this.actionEnabled.current = false
      }, 2000)

      try{
        CommonFunction.overLay();
        const targetMemberId = this.state.currentUser?.memberId;
        if(targetMemberId){
          this.setIineList(this.state.currentUser?.memberId);
          await Recommend22Api.postSkip(targetMemberId);
        }
        this.showNextUser();
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        CommonFunction.hideOverlay();
      }
    }
  }

  reloadCurrentProfileSendGood = async(responseSendGood)=>{
    try{
      let currentUser = this.state.currentUser;
      let goodCount = parseInt(currentUser.goodCount);
      if(goodCount!=undefined && !isNaN(goodCount)){
        goodCount = goodCount+1;
      }
      currentUser.sendGoodFlg = 1;
      currentUser.goodFlg = 1;
      currentUser.goodCount = goodCount;
      currentUser.receiveMessageFlg = responseSendGood?.response?.receiveMessageFlg;
      currentUser.sendMessageFlg = responseSendGood?.response?.sendMessageFlg;
      currentUser.matchFlg = responseSendGood?.response?.matching;
      this.setState({ currentUser: currentUser });
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  sendGood = async()=>{
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    // validate SMS
    if(!this.checkSMS()) return;
    const memberId = this.state.currentUser?.memberId;
    const code = this.state.currentUser?.code;
    const parameter = {
      targetMemberId: memberId,
      messageType: 1,
      code: code
    };

    // set hide for back to list
    this.setIineList(memberId);

    try{
      CommonFunction.overLay();
      const responseSendGood = await IineApi.sendGood(parameter,0,0);
      if(responseSendGood?.status==1){ // success
        // reload data
        await this.reloadCurrentProfileSendGood(responseSendGood);
        PopupWrapper.showMessage(Constants.MsgIine,'check', this.showPopUpMsg);
      }else{
        CommonFunction.hideOverlay();
        if(JSON.stringify(responseSendGood)=='{}'){
          return;
        }

        if(CommonFunction.checkIsInvalidErrorCode(responseSendGood)){
          const errorMessage = responseSendGood?.response?.errorMessage;
          const errorGroup = responseSendGood?.response?.errorGroup;
          const errorCode = responseSendGood?.response?.errorCode;
          const code = `${errorGroup}-${errorCode}`;
          if (code==ErrorCode.GoodPostSendGood.NoMember){
            PopupWrapper.showModalMessage('お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。','', this.showNextUser);
          }else if(errorMessage){
            PopupWrapper.showModalMessage(errorMessage,'', this.showNextUser);
          }else{
            this.showNextUser();
          }
        }
      }
    }catch(e){
      CommonFunction.hideOverlay();
      if(__DEV__) console.log(e);
    }finally{
      //CommonFunction.hideOverlay();
    }
  }

  goBack = ()=>{
    this.checkBottomTab();
    if(this.navigation?.canGoBack()){
      this.navigation.goBack();
    }else{
      this.navigation?.navigate('RecommendIine');
    }
  }

  handleSubmitMessage = async(values)=>{
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    // validate SMS
    if(!this.checkSMS()) {
      this.BottomMessageRef.showPopUp(false);
      return;
    }
    // validate Age
    if(!this.checkAge()) {
      this.BottomMessageRef.showPopUp(false);
      return;
    }
    // validate Age Partner
    if(!this.checkAgePartner()) return;

    let checkMessage = CommonFunction.checkMessage(values.message);    
    if(checkMessage==""){
      this.formik?.setValues({message:''});
      this.errorPopup?.showPopup();
      return;
    }

    const targetMemberId = this.state.currentUser?.memberId;
    const code = this.state.currentUser?.code;
    let params = {
      targetMemberId: targetMemberId,
      messageType: 2,
      body: values.message,
      imageFile: null,
      code: code
    }

    try{
      OverlayWrapper.show(true);
      const sendMailResponse = await MailBoxApi.SendMail(params, 0);
      this.setIineList(targetMemberId);
      if(sendMailResponse?.status==1){ // complete
        this.BottomMessageRef.showPopUp(false);
        this.resetFormMessage();
        PopupWrapper.setOptions({timeout:500});
        PopupWrapper.showMessage(Msg.SendMessage.Success,'',this.showNextUser);
      }else{
        OverlayWrapper.hide();
        if(JSON.stringify(sendMailResponse)=='{}'){
          return;
        }

        if(CommonFunction.checkIsInvalidErrorCode(sendMailResponse)){
          const response = sendMailResponse?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
          const errorMessage = response?.errorMessage;
          if(errorCode==ErrorCode.MailBoxPostSendMail.NoPoint){
            this.NoPointRef.setMessage(errorMessage);
            this.NoPointRef.setOnNavigateAction(()=>{
              this.BottomMessageRef.hide();
              this.setState({imgCheckMsg:0, titleTemplate:''});
              this.resetFormMessage();
            });
            this.NoPointRef.show();
          }else{
            PopupWrapper.showMessage(errorMessage, 'warning', this.showNextUser);
          }
        }
      }
    }catch(e){
      OverlayWrapper.hide();
      if(__DEV__) console.log(e);
    }
  }
  
  closeAction = ()=>{
    setTimeout(() => {
      this.setIineList(this.state.currentUser?.memberId);
      this.setState({imgCheckMsg:0, titleTemplate:''});
      this.showNextUser();
    }, 250);
  }
  

  render() {
    if(this.state.networkError){
      return (<View style={styles.container}><NoItemsBox msg={Msg.NetworkError} /></View>);
    }

    if(this.state.loading) return <ActivityIndicator/>;
  
    return (
      <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[styles.container,SafeAreaStyles(insets).container]}>
        {this.state.maskUp===true && (<View style={styles.maskUp}><ActivityIndicator /></View>)}
        <NoPoint ref={ref=>{this.NoPointRef=ref}} navigation={this.navigation} />   
        <View style={HeadStyle.customHeaderTop}>
        <Carousel 
          ref={swiper => {this.swiper=swiper}}
          scrollEnabled={this.state.scrollEnabled}
          sliderWidth={this.state.currentScreenWidth}
          itemWidth={this.state.currentScreenWidth}
          layout={"default"}
          onSnapToItem = { index => this.onChangeIndex(index) }
          data={this.state.userList}
          firstItem={this.state.index}
          initialScrollIndex={this.state.index}
          initialNumToRender={3}
          lockScrollWhileSnapping={true}
          removeClippedSubviews={true}
          getItemLayout={(_, index) => ({
            length: this.state.currentScreenWidth,
            offset: (this.state.currentScreenWidth) * (index),
            index,
          })}
          renderItem={({ item, index }) => {
            if(!item || item.memberId==undefined) return <></>;
            
            if(item.memberId==this.state.currentUser.memberId){
              item = this.state.currentUser;
            }

            let heightStyle = '';
            if(checkValue(item.height) && item.height>0){
              heightStyle = item.height + 'cm';
            }
            if(checkValue(item.style)){
              heightStyle += (heightStyle!='')?' / '+item.style:item.style;
            }
            
            let goodCount = item?.goodCount;
            if(goodCount==undefined || isNaN(goodCount)){
              goodCount = '';
            }
            if(this.myProfile?.sex==Constants.MALE && this.state.account?.subscription?.status!=1){
              goodCount = '?';
            }
            if(goodCount==''){
              goodCount=0;
            }

            const onlineItem = OptionData.Master.Online.Items.filter((element)=>{
              return element.id==item.online?.id;
            });

            let border1width = 0;
            if(item.introduction || (item.aeruList?.length>0)) border1width = 1;

            let income = '';
            if(item.sex==Constants.MEN || item.sex==Constants.MALE){
              heightStyle = '';
              if(checkValue(item.income)) income = item.income;
              if(!isWeb) income = '';
            }

            return (
              <ScrollView 
                ref={ref =>this.scrollRef[index]=ref} 
                scrollToOverflowEnabled={true}
                style={styles.scrollContainer}
                contentContainerStyle={styles.scrollContentContainer}>
                  <TouchableOpacity activeOpacity={0.9} onPress={()=>{
                    if(this.state.imagesList?.length>0 && this.state.imagesList[0]?.url!=''){
                      this.setState({showPopupImg: true});
                    }
                  }}>
                  <View style={{width:'100%', height: this.mainImgHeight, justifyContent: 'center', alignItems:'stretch'}}>
                    <LinearGradient
                      colors={['rgba(0,0,0,0.18)', 'rgba(255, 255, 255, 0)']}
                      style={{position: 'absolute',left: 0,right: 0,top: 0,height: 380,zIndex: 100}}
                    />
                    <Image style={styles.myProfileImg} source={{uri : item.topMainImageUrl?item.topMainImageUrl:item.mainImageUrl}} />
                    {item.meetToday==1 && <MeetToday/>}
                    </View>
                  </TouchableOpacity>

                  {item?.subImage?.list?.length>0 &&
                  <View style={{width:'100%'}}>
                    <View style={styles.thumbList}>
                      <ScrollView horizontal={true} style={{width:'100%'}} contentContainerStyle={{paddingLeft:14}}>
                      {item.mainImageUrl&&
                        <TouchableOpacity style={styles.separator} onPress={()=> {
                          this.showImg(item.mainImageUrl);
                          this.setState({currentImageIndex: 0});
                        }}>
                          <Image resizeMode={'cover'} style={styles.thumbImg} source={{uri : item.mainImageUrl}} />
                        </TouchableOpacity>
                      }
                      {item?.subImage?.list?.map((subImage,subIndex) => {
                        return ( 
                          <TouchableOpacity key={subIndex.toString()} style={styles.separator} onPress={()=> {
                            this.showImg(subImage.imageUrlOrg);
                            this.setState({currentImageIndex: subIndex+1});
                          }}>
                            <Image resizeMode={'cover'} style={styles.thumbImg} source={{uri : subImage.imageUrl}} />
                          </TouchableOpacity>
                        )
                      })}
                      </ScrollView>
                    </View>  
                  </View>                    
                  }

                  <View style={{paddingTop:12, paddingRight: 16, paddingBottom: 20, paddingLeft: 16,width:'100%'}}>
                    <View style={[styles.section,{ alignItems:'flex-start'}]}>
                      <View style={{flexDirection:'row', alignContent:'center', alignItems:'center', marginBottom:12}}>
                        <View style={{marginRight:8, maxWidth:210}}>
                          <Text numberOfLines={1} style={{fontSize:20, fontWeight:"700", lineHeight:30,color:'#484848'}}>{item.name}</Text>
                        </View>
                        <View style={{marginRight:8, flexDirection:'row'}}>
                          <Text style={{fontSize:20, fontWeight:"700", lineHeight:30, color:'#484848'}}>{item.age}</Text>
                          <Text style={{fontSize:20, fontWeight:"700", lineHeight:30, color:'#484848', marginLeft:8}}>{item.currentPref}</Text>
                        </View>
                        {item.newFlag==1?
                        <View style={{marginLeft:7, paddingLeft:7, paddingRight:7, height:18, backgroundColor: '#ff8d63', borderRadius: 4}}>
                          <Text style={{fontSize:12, fontWeight:"700", lineHeight:18, color:'#fff', textAlign:'center'}}>NEW</Text>
                        </View>
                        :null}
                      </View>
                    </View>
                    
                    <View style={[styles.section,{borderBottomColor:'#e6e6e6', borderBottomWidth: border1width}]}>
                    {checkValue(item.tweet)?
                      <View style={{marginBottom:12}}>
                        <Text style={{fontSize:16, lineHeight:19}}>{item.tweet}</Text>
                      </View>
                    : null}
                    {checkValue(item.occupation)?
                      <View style={{}}>
                        <Text style={{fontSize:16, lineHeight:19}}>{item.occupation}</Text>
                      </View>
                    : null}
                    {checkValue(heightStyle)?
                      <View style={{}}>
                        <Text style={{fontSize:16, lineHeight:19}}>{heightStyle}</Text>
                      </View>
                    : null}
                    {checkValue(income)?
                      <View style={{}}>
                        <Text style={{fontSize:16, lineHeight:19}}>{income}</Text>
                      </View>
                    : null}
                      <View style={{alignItems:'flex-end'}}>
                        <View style={{flexDirection:'row', alignContent:'center', paddingBottom:12, paddingTop:12, justifyContent:'center'}}>
                          <View style={{justifyContent:'center'}}><AntDesign name="heart" size={15} style={{color:'#5cc8ca'}} /></View>
                          <Text style={{marginLeft:4, marginRight:8, fontSize:16}}>{goodCount}件</Text>
                          <View style={{justifyContent:'center'}}><OnlineStatus online={item.online?.id} /></View>
                          <Text style={{marginLeft:4, fontSize:16}}>{item.online?.label}</Text>
                        </View>
                      </View>
                    </View>
                  {item.introduction?
                  <View style={styles.section}>
                    <Text style={styles.h3}>自己紹介</Text>
                    <Text style={{fontSize:16}}>{item.introduction}</Text>
                  </View>
                  :null}
                  {item.aeruList?.length>0 &&
                    <View style={styles.section}>
                    {item.aeruList?.length<=3 ?
                      <>
                        <Text style={styles.h3}>デート可能日</Text>
                        {item.aeruList?.map((val,i) => {
                          return (
                            <View key={i} style={{height:this.rowHeightAeruList}}>
                              <Text style={{fontSize:16}}>{val.year}年 {val.month}月 {val.day}日 ({val.wDay})</Text>
                            </View>
                          )
                        })}
                      </>
                    :
                    <>
                      <Text style={styles.h3}>デート可能日</Text>
                      <Animated.View style={{height:this.state.heightAeruList, overflow:'hidden'}}>
                        {item.aeruList?.map((val,i) => {
                          return (
                            <View key={i} style={{height:this.rowHeightAeruList}}>
                              <Text style={{fontSize:16}}>{val.year}年 {val.month}月 {val.day}日 ({val.wDay})</Text>
                            </View>
                          )
                        })}
                      </Animated.View>
                      <TouchableOpacity style={{flexDirection:'row', alignItems:'center'}} onPress={()=>{
                          this.showAeruList();
                        }}>
                        <Text style={{justifyContent:'center'}}>もっとみる </Text>
                        <AntDesign name={this.state.toggleAeruList?"down":"up"} size={16} style={{color:'#484848', paddingLeft:5}} />
                      </TouchableOpacity>
                      </>
                      }
                    </View>
                    }
                  </View>
            
                  <View style={[styles.insideBG]}>
                  <Block21 item={item} account={this.state.account} navigation={this.navigation} NoPointRef={this.NoPointRef}/>
                  {(item.sex==Constants.MEN || item.sex==Constants.MALE)?
                  <>
                    <Block18Male item={item} />
                    <Block17 item={item} />
                    <Block16Male item={item} />
                    <Block19 item={item} />
                    <Block20 item={item} />
                  </>
                  :
                  <>
                    <Block16 item={item} />
                    <Block17 item={item} />
                    <Block18 item={item} />
                    <Block19 item={item} />
                    <Block20 item={item} />
                  </>
                  }
                  </View>
              </ScrollView>
            )}}
          />

          <View style={styles.bottomTab}>
            <View style={{padding:10}}>
              <TouchableOpacity  style={styles.skipBtn} onPress={()=>this.skip()}>
                <Image style={styles.skipImg} source={require('../assets/images/icon/icon-skip.png')}/>
                <Text style={styles.skipText}>スキップ</Text>
              </TouchableOpacity>
            </View>
            
            {this.state.currentUser?.sendGoodFlg!=1 && (
            <View style={{padding:10}}> 
              <TouchableOpacity style={styles.likeBtn} onPress={()=>{
               this.sendGood();
              }}>
                {(this.myProfile?.sex==Constants.MALE) &&
                <Image style={{position:'absolute', right:-15, top:-5, width:40, height:34}} source={require('../assets/images/icon/free-speech.png')} resizeMode="cover"/>
                }
                <Image style={styles.likeImg} source={require('../assets/images/icon/icon-heart.png')}/>
                <Text style={styles.likeText}>いいね！</Text>
              </TouchableOpacity>
            </View>
            )}
          </View>
        
          {/** popup menu */}
          <Popup ref={ref=>this.errorPopup=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
                this.errorPopup?.hidePopup();
                this.textInputMessage?.focus();
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12,}}>
              <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
                <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',textAlign:'center',}}>メッセージが入力されていません</Text>
              </View>
              <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center'}} onPress={()=>{
                this.errorPopup?.hidePopup();
                this.textInputMessage?.focus();
              }}>
                <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center'}}>OK</Text>
              </TouchableOpacity>
            </View>
          </Popup>
          <BottomMenu ref={ref=>this.BottomMenuRef=ref}>
            <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
              this.BottomMenuRef?.hide();
              this.navigation?.navigate('ChatHideSetting', {memberId: this.state.currentUser.memberId, recommendIine: 1});
            }}>
              <Text style={[BottomMenuStyles.txtListPopup, BottomMenuStyles.txtColorRed]}>非表示・ブロックの設定</Text>
            </TouchableOpacity>
            <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
              this.BottomMenuRef?.showPopUp(false);
            }}>
              <Text style={BottomMenuStyles.txtListPopup}>キャンセル</Text>
            </TouchableOpacity>
          </BottomMenu>

          {/** popup message */}
          <BottomMenu ref={ref=>this.BottomMessageRef=ref} bottom={0} style={{paddingLeft:0, paddingRight:0}} closeAction={this.closeAction}> 
            <Formik
              innerRef={ref => {
                if(ref) this.formik = ref;
              }}
              initialValues={this.initialFormMessageValues}
              onSubmit={values => this.handleSubmitMessage(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
            <View style={{width:'100%'}}>
              <View style={{width:'100%'}}>
                <LinearGradient colors={['transparent','rgba(0,0,0,0.15)','rgba(0,0,0,0.65)']} style={{flexDirection:'row', width:'100%', height:35, zIndex:0}}>               
                  <Text style={{lineHeight:35,paddingLeft:15,fontSize:14,color:'#ffffff',fontWeight:'bold',textShadowColor:'rgba(0,0,0,1)',textShadowOffset:{width:0,height:0},textShadowRadius:7}}>
                  {this.myProfile?.sex==Constants.MALE?'注目度UP！ダイレクトメッセージを送ろう（3pt）':'注目度UP！ダイレクトメッセージを送ろう'}
                  </Text>
                </LinearGradient>
                <View style={styles.blogIcClose}>
                  <TouchableOpacity onPress={ () => { 
                    this.setIineList(this.state.currentUser?.memberId);
                    this.BottomMessageRef?.hide();
                    this.setState({imgCheckMsg:0, titleTemplate:''});
                    this.showNextUser();
                  }}>
                    <Image resizeMode={'cover'} style={{width:32, height:32}} source={require('../assets/images/icon/close.png')}/>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{flex:1,paddingTop:15, paddingBottom:20, width:'100%', backgroundColor:'#ffffff'}}>
              {this.state.template?.length>0 && (
                <View style={styles.blogMsgTemplate}>
                <MessageTemplate 
                    templateItemWidth={this.templateItemWidth}
                    templateSliderWidth={this.templateSliderWidth}
                    template={this.state.template}
                    onpressTemplate={(item)=>{
                      if(this.validateAuthen()){
                        setFieldValue('message', item.text);
                    }
                    }}
                  />
                  </View>
                )}
                <View style={{paddingLeft:16, paddingRight:16}}>
                  <View style={styles.textareaBtnBottom}>
                    <TextInput 
                    ref={ref => {this.textInputMessage=ref}}
                    style={[styles.textAreaInput,{height: this.messageBox.minHeight}]} 
                    name="message" 
                    onChangeText={handleChange('message')}
                    onBlur={() => setFieldTouched('message')}
                    onFocus={()=>{ 
                      this.validateAuthen();
                    }}
                    value={values.message}
                    multiline={true} 
                    placeholder="初回メッセージを送ってみましょう" 
                    placeholderTextColor="#9aa5a7"
                    //onContentSizeChange={(e) => this.updateTextAreaSize(e.nativeEvent?.contentSize?.height)}
                    editable={this.state.editable}
                    inputAccessoryViewID={inputAccessoryViewID}
                    />
                  </View>
                  <View style={styles.btnSendMsg}>
                  {this.validateFormMessage(values) ?
                    <TouchableOpacity style={styles.btnBtnSendMsg} onPress={handleSubmit}>
                      <Text style={styles.txtBtnSendMsg}>送信する</Text>
                    </TouchableOpacity>
                    :
                    <View style={[styles.btnBtnSendMsg, {opacity:0.4}]}><Text style={styles.txtBtnSendMsg}>送信する</Text></View>
                  }
                  </View>
                </View>
              </View>
            </View>
           )}
           </Formik>
          </BottomMenu>

          {/** popup image */}
          {this.state.showPopupImg &&
            <Portal style={PortalStyle.portal}>
            <View style={PortalStyle.portalContainer}>
              <Animated.View style={[PortalStyle.overlay, { opacity: this.state.backgroundOpacity.interpolate({
                  inputRange: [0.5, 0.95],
                  outputRange: [0.4, 0.8],
                })}
              ]} 
                onStartShouldSetResponder={() => {
                this.setState({ showPopupImg: false});
              }} />
              <View style={{position:'absolute', right:15, top:ExpoConstants?.statusBarHeight+15, zIndex:1, elevation:1}}>
                <TouchableOpacity onPress={()=>{ this.setState({ showPopupImg: false}) } }>
                  <Image resizeMode={'cover'} style={{width:32, height:32}} source={require('../assets/images/icon/close.png')}/>
                </TouchableOpacity>
              </View>
              <ImageViewer imageUrls={this.state.imagesList} enableSwipeDown={true} onCancel={()=>{
                this.setState({showPopupImg: false});
              }}
              backgroundColor="transparent"
              index={this.state.currentImageIndex}
              onMove={(e)=>{
                if(e.positionY > 0){
                  this.state.backgroundOpacity.setValue(e.scale);
                }
              }} />
            </View>
          </Portal>
          }
        </View>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    )
  }
}