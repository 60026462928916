import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    //backgroundColor: '#fafafa',
    backgroundColor: '#ffffff',
    width: '100%', 
    height: '100%',
  },
  contentContainer: {
    paddingTop: 5,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  lottie:{
    width: 100,
    height: 100
  },
  ThumbnailProfile: {
    flexDirection: 'column'
  },
  profile: {
    padding: 2,
    marginLeft: 15
  },
  fontMedium: {
    fontSize: 15,
    alignSelf: 'flex-start',
    marginTop: 1,
  },
  topPanel: {
    padding: 20,
    flexDirection: 'row',
  },
  sectionHeader:{
    fontWeight: 'bold',
    backgroundColor: '#EEEEEE',
    padding: 10,
    width: '100%',
    color: '#000',
  },
  rightNum:{
    flexDirection: 'column',
    textAlign: 'right',
    width: '50%',
    paddingRight:0,
    fontSize:20
  },
  activityIndicatorContainer:{
    flex:1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  }
});
