import * as React from 'react';
import { Text, View, StyleSheet }  from 'react-native';

export default function BottomTabBadge(props) {
  let {badge, notnumber} = props;
  if(badge!=undefined && badge > 99) badge = '99+';
  
  let fontSize = 8;
    if(badge?.toString()?.length==1) fontSize = 12;
    else if(badge?.toString()?.length==2) fontSize = 10;

  if(notnumber==true){
    return (
      <View style={styles.containerDot}></View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={[styles.badge,{fontSize: fontSize}]}>{badge}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
   container:{
    position: 'absolute',
    right:-17,
    width:20, 
    height:20, 
    backgroundColor:'#ff8d63', 
    borderRadius:9999,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    textAlign:'center',
   },
   containerDot:{
    position: 'absolute',
    right:-15,
    top:2,
    width:8,
    height:8, 
    backgroundColor:'#ff8d63', 
    borderRadius:9999
   },
   badge:{
    textAlign:'center',
    width:'100%', 
    height:'100%',     
    fontSize:12,
    fontWeight:'normal',
    lineHeight:20, 
    color:'#fff',
   }
});
