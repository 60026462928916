import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import OnlineStatus from '../common/OnlineStatus';
import CommonFunction from '../../utils/CommonFunction';
import Moment from 'moment';

export default function UserRowFormat(props) {
  const { item } = props;
  let mainImageUrl = '';
  let onlineId = 0;
  //let messageDate = item?.messageDatetime?.replaceAll("/","-");
  let messageDate;
  if(item.messageDatetime!=undefined){
    let momentDate = Moment(item.messageDatetime.toString().replaceAll("/","-"));
    if(momentDate.isValid()){
      messageDate = momentDate.format("MM/DD HH:mm");
    }
  }

  // if(item.mainImageUrl!=undefined) mainImageUrl = item.mainImageUrl;
  if(item.mainImageUrl!=undefined) mainImageUrl = CommonFunction.urlConver(item.mainImageUrl);
  if(item.online!=undefined)
    onlineId = item.online?.id;

  return (
    <View style={styles.profileContainer}>
      <TouchableOpacity onPress={props.onPress}>
        <Image style={styles.myProfileThumbnail} source={{uri : mainImageUrl}} />
      </TouchableOpacity>
      <View style={styles.rightProfile}>
        <TouchableOpacity onPress={props.onPressGotoChat}>
          <View style={styles.rightRow}>
            <Text style={styles.showTime}>{messageDate}</Text>
          </View>
          <View style={{flexDirection:'row'}}>
            <View style={{alignSelf:'center'}}><OnlineStatus online={onlineId} /></View>
            <Text style={styles.profileDesc}>{item?.name} {item?.age} {item?.currentPref}</Text>
          </View>
          <Text numberOfLines={1} style={styles.msg}>{item?.message}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  profileContainer: {
    flexDirection:'row',
    flex:1,
    paddingTop:10,
    paddingRight:16,
    paddingBottom:10,
    paddingLeft:16,
    backgroundColor:'#ffffff',
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  myProfileThumbnail:{
    marginRight:12,
    width:60,
    height:60,
    borderRadius:10,
  },
  rightProfile:{
    width: '75%',
  },
  rightRow: {
    position:'relative',
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  talk: {
    justifyContent:'flex-start',
    paddingRight:8,
    paddingLeft:8,
    backgroundColor:'#23db24',
    borderRadius:60,
  },
  txtTalk: {
    color:'#fff',
    fontSize:12,
    lineHeight:19
  },
  showTime: {
    position:'absolute',
    top:0,
    right:0,
    fontSize:10,
    lineHeight:14,
    color:'#9aa5a7',
    textAlign:'right',
  },  
  online_time:{
    fontSize: 11,
    textAlign: 'left',
    paddingTop: 5,
    marginLeft: 10,
  },
  msg: {
    fontSize:12,
    fontWeight:'normal',
    color:'#9aa5a7',
    lineHeight:19
  },
  profileDesc: {
    color:'#484848',
    fontWeight:'bold',
    lineHeight:20,
    height:20

  }  
});
