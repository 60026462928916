import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
//import Constants from '../../constants/Constants';

//03_API仕様\レスポンス仕様書\04_プロフィール
export default class Profile04Api extends ApiPappy{

  //1.1.1.プロフィール情報取得
  static async GetList(targetMemberId, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/List/",
        {targetMemberId: targetMemberId},
        true
      );
      if(__DEV__) console.log('/Profile/Get/List/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.ProfileGetList));
            console.log('/Profile/Get/List/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.似た特徴のお相手リスト取得
  static async GetSuggest(targetMemberId, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/Suggest/",
        { targetMemberId: targetMemberId },
        true
      );
      if(__DEV__) console.log('/Profile/Get/Suggest/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.ProfileGetSuggest));
            console.log('/Profile/Get/Suggest/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.3. セーフティチェック取得
  static async GetSafetyCheck(targetMemberId, showError=0){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/SafetyCheck/",
        { targetMemberId: targetMemberId },
        true
      );
      if(__DEV__) console.log('/Profile/Get/SafetyCheck/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Profile/Get/SafetyCheck/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}