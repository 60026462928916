import React from 'react';
import CommonFunction from '../utils/CommonFunction';
import PopupWrapper from '../components/common/PopupWrapper';
import Payment15Api from '../services/api/Payment15Api';

const { checkUndefined } = CommonFunction;

// paidy：決済成功時
async function paidySuccessful(data){
  try{      
    const paymentPostPaidyResopnse = await Payment15Api.PaymentPostPaidy(data);
    if(!CommonFunction.checkResponseStatus(paymentPostPaidyResopnse)) return;
    if(checkUndefined(paymentPostPaidyResopnse) && paymentPostPaidyResopnse?.status==1){
      // 成功時の処理は特に行わない（ポイント更新は非同期で実施されるので更新するデータは特に無し）
      PopupWrapper.showMessage('決済が完了しました。\nポイント付与には数秒お時間を\nいただきます。','check',()=>{
      });
    }
  }catch(e){
    if(__DEV__) console.log(e);
  }finally{
  }
}

// paidy：中断時
function paidyClosed(){
  PopupWrapper.showMessage('決済操作を中断しました。','warning',()=>{
  });
}

// paidy：決済失敗時
function paidyRejected(){
  PopupWrapper.showMessage('決済に失敗しました。\n最初からやりなおしてください。','warning',()=>{
  });
}

// paidy:token送信
async function paidySubscSuccessful(data, navi){
  var navigate = navi;
  try{      
    const paymentPostPaidyTokenRespnse = await Payment15Api.PaymentPostPaidyToken(data);
    if(!CommonFunction.checkResponseStatus(paymentPostPaidyTokenRespnse)) return;
    if(checkUndefined(paymentPostPaidyTokenRespnse) && paymentPostPaidyTokenRespnse?.status==1){
      // 成功時の処理は特に行わない（サーバでのトークン処理は非同期で実施されるので更新するデータは特に無し）
      PopupWrapper.showMessage('決済処理中です。\n反映には数秒掛かりますため\n少しお時間をおいてから\n会員ステータスにて\nご確認ください。','check',()=>{   
        navigate?.navigate('Search',{screen:'SearchList'});
      });
    }
  }catch(e){
    if(__DEV__) console.log(e);
  }finally{
  }
}

export default { 
  paidySuccessful,
  paidyClosed,
  paidyRejected,
  paidySubscSuccessful
}