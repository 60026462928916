import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Animated, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { Portal } from 'react-native-paper'
import AdvanceSearchListScreen from '../../screens/AdvanceSearchListScreen';
import AdvanceSearchListSingleScreen from '../../screens/AdvanceSearchListSingleScreen';
import PortalStyle from '../../styles/PortalStyles';

export default class ListInputNew extends Component {  

  constructor(props){
    super(props);
    this.state = {
      visible: false,
      dailogSelected: [],
      dailogSelectedText: ''
    }
    this.ref = React.createRef();
    this.defaultAnimatedValue = 150;
    this.bottom = 0;
    this.AnimateBottom = new Animated.Value(this.defaultAnimatedValue);
  }

  componentDidMount(){
    let value = this.props.value;
    let value_txt = '';
    if(value){
      if(this.props.multiple){
        let selectedText = [];
        value.map((data) => {
          selectedText.push(data.value);
        });
        value_txt = selectedText.join(',');
      }else{
        value_txt = value.value;
      }
      
      this.setState({
        dailogSelected : value,
        dailogSelectedText: value_txt
      });
      
    }
  }

  setAnimation(direction) {
    if(!this.state.visible) this.setState({visible:true});

    Animated.timing(this.AnimateBottom, {
      duration: 200,
      toValue: direction=='down' ?this.defaultAnimatedValue:this.bottom,
	    useNativeDriver: Platform.OS==='web'?false:true,
    }).start(({ finished }) => {
      if(finished){
        if(direction=='down'){
          this.setState({visible: false});
        }
      }
    });
  };

  setDataSource = (item, text)=>{
    this.setState({
      dailogSelected: item,
      dailogSelectedText: text
    });
  }

  showDialog(){
    this.setAnimation('up');
  }

  hideDialog(){
    this.setAnimation('down');
  }    

  OK(){
    this.props.onChange(this.props.fieldName, this.state.dailogSelected);
    this.setAnimation('down');
  }

  Cancel(){
    // this.props.onChange(this.props.fieldName,[]);
    this.setAnimation('down');
  }

  _renderPopup(){
    if(this.state.visible){
      return (
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <Animated.View 
            style={[PortalStyle.overlay, {opacity: this.AnimateBottom.interpolate({
              inputRange: [this.bottom, this.defaultAnimatedValue],
              outputRange: [0.6,0]
            })
            }]} 
            onStartShouldSetResponder={() => {
              this.setAnimation('down');
            }} />
            <Animated.View style={[styles.animateView,
            {
              bottom: this.bottom, 
              transform: [{translateY: this.AnimateBottom}],
              justifyContent:'center',
              alignItems:'center',
              opacity: this.AnimateBottom.interpolate({
                inputRange: [this.bottom, this.defaultAnimatedValue],
                outputRange: [1,0]
              })
            }]}> 
            <View style={styles.animateViewContainer}>
              <View style={{flexDirection:'row',justifyContent:'space-between',borderBottomWidth:2,borderBottomColor:'#e6e6e6'}}>
                <TouchableOpacity style={{alignSelf:'flex-start',marginLeft:20,}} onPress={()=>{this.Cancel()}}>
                  <Text style={styles.cancel}>キャンセル</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{alignSelf:'flex-end',marginRight:20,}} onPress={async()=>{
                  if(this.ref.handleBack !=undefined){
                    await this.ref.handleBack();
                  }
                  this.OK();
                }}>
                  <Text style={styles.ok}>完了</Text>
                </TouchableOpacity>
              </View>
              {this.props.multiple?
              <AdvanceSearchListScreen values={this.state.dailogSelected} setDataSource={this.setDataSource} {...this.props} ref={ref=>this.ref=ref} />
              :
              <AdvanceSearchListSingleScreen values={this.state.dailogSelected} setDataSource={this.setDataSource} {...this.props} ref={ref=>this.ref=ref} />
              }
               
            </View>
          </Animated.View>
        </View>
      </Portal>
      )
    }
  }
    
  render() {
    let selected_value = '選択してください';
    if(this.props.title != undefined){
      selected_value = this.props.title;
    }
    if(this.state.dailogSelectedText !=''){
      selected_value = this.state.dailogSelectedText;
    }
 
    return (
      <View style={styles.inputView}>
        <TouchableOpacity 
          style={styles.inputText}
          onPress={() => this.showDialog()}
        >          
          <Text style={{color:(this.state.dailogSelectedText !='') ? '#000000':'#9e9fa0'}}>{selected_value}</Text>
          <Icon name={'caretdown'} size={12} style={styles.rightIcon} />
        </TouchableOpacity>
 
        {this._renderPopup()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    position:'absolute',
    top:0,
    left:0,
    height:'100%',
    width:'100%'
  },
  overlay:{
    height: '100%',
    width:'100%',
    backgroundColor:'#000000',
    opacity:0.5,
    position:'absolute',
    top:0,
    left:0
  },
  animateView:{
    flex:1,
    width:'100%',
    ...Platform.select({
      ios: {
        position:'absolute',
      },
      android: {
        position:'absolute',
      },
      default:{
        position:'absolute'
    }}),
    zIndex:1,
    justifyContent:'flex-start',
    alignItems:'flex-start',
    height:'50%',
    backgroundColor:'#ffffff'
  },
  animateViewContainer:{
    width:'100%',
    height:'100%'
  },
  inputView:{
    justifyContent:'center',
    width:'100%',
    height:50,
    backgroundColor:'#eef1f2',
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
  },
  inputText:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    lineHeight:20,
    color:"#000",
  },
  rightIcon:{
    position:'absolute',
    top:'50%',
    right:0,
    padding:10,
    color:'#000',
    ...Platform.select({
      ios: {
        
      },
      android: {
        
      },
      web:{
        transform:[{translateY:'-50%'}]
    }})
  },
  portal:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  ok:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:52,
    color:'#007bff',
  },
  cancel:{
    fontSize:16,
    fontWeight:'normal',
    lineHeight:52,
    color:'#007bff',
  },
});