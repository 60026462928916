import React,{Component} from 'react';
import { Image, View, TouchableOpacity, StyleSheet, Text, ImageBackground, Platform, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { Camera } from 'expo-camera';
import { AntDesign, Entypo } from '@expo/vector-icons';
import UserSetting from '../../components/common/UserSetting';
import PopupMedia from '../common/PopupMedia';
import PopupAppSetting from '../common/PopupAppSetting';
import Popup from '../common/Popup';
import PortalStyles from '../../styles/PortalStyles';
import UserUtil from '../../utils/UserUtil';
const windowWidth = Dimensions.get('window').width;
let instancesCount = 1;

export default class UploadProfileImagePicker extends Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      image: null,
      myProfile: { sex: 0 }
    };
    this.PopupMedia = React.createRef();
    this.PopupRef = React.createRef();
    this.popup = React.createRef();
    this.actionEnabled = React.createRef();
  }
  
  async componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      //this.getPermissionAsync();
      if(this.props.image!=''){
        this.setState({
          image: this.props.image
        });
      }
      instancesCount += 1;
      const { appState } = this.context;
      const myProfile = await UserUtil.getUserProfileLocal(appState);
      if(myProfile){
        this.setState({ myProfile: myProfile });
      }
      this.actionEnabled.current = false;
    }
  }
  
  componentDidUpdate(prevProps) {
    if(this._isMounted){
      this.enabeled = this.props.actionEnabled;
      if(this.props.image !== prevProps.image){
        this.setState({
          image: this.props.image
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    instancesCount = 1;
  }

  getPermissionAsync = async () => {
    if (Platform.OS==='ios') {
      if(instancesCount==1){
        const permission = await ImagePicker.requestCameraPermissionsAsync();
      }
    }
  };

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        this.actionEnabled.current = true;
        setTimeout(() => {
          this.actionEnabled.current = false
        }, 4000)
        if(this.props.actionEnableSet){
          await this.props.actionEnableSet();
        }

        const uri = result?.assets[0]?.uri;
        if(this.props.preview!=false){
          this.setState({ image: uri });
        }

        this.props.onChange(uri);
        if(this.props.handleSubmit){
          setTimeout(() => {
            this.props.handleSubmit();
          }, 500);
        }
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if(Platform.OS==='ios'){
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        this.actionEnabled.current = true;
        setTimeout(() => {
          this.actionEnabled.current = false
        }, 4000)
        if(this.props.actionEnableSet){
          await this.props.actionEnableSet();
        }

        const uri = result?.assets[0]?.uri;
        if(this.props.preview!=false){
          this.setState({ image: uri });
        }

        this.props.onChange(uri);
        if(this.props.handleSubmit){
          setTimeout(() => {
            this.props.handleSubmit();
          }, 500);
        }
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  render() {
    const popupWidth = windowWidth * 0.77
    const newHeight = popupWidth * 943 / 864
    let popupImage = require('../../assets/images/popup-profile-image-guid-male.png')
    if(this.state.myProfile?.sex!=1){
      popupImage = require('../../assets/images/popup-profile-image-guid-female.png')
    }

    if(this.props.mainImg){
      return (
        <View style={styles.myProfile}>
          {this.state.image ?
            <TouchableOpacity style={styles.myProfileImg} onPress={()=>{
              if(this.props.setSubmitField!=undefined){
                this.props.setSubmitField();
              }
              this.props.onPress();
            }}>
              {/*<Image style={styles.myProfileImg} source={{uri : this.state.image}} />*/}
              <ImageBackground imageStyle={{borderRadius:16}} style={styles.myProfileImg} source={{uri : this.state.image}}>
              {this.props.item!=undefined && this.props.item.certify!=2 &&
                <UserSetting.UnderReviewImgLayer borderRadius={16} />
              }
              </ImageBackground>
            </TouchableOpacity>
          :
          <TouchableOpacity disabled={this.enabeled} onPress={()=>{
            // プロフ画像登録促進　画像無しの場合にポップアップ表示
            if(this.props.isNoImage){
              this.popup?.showPopup();
            } else{
              if(this.props.setSubmitField!=undefined){
                this.props.setSubmitField();
              }
              if(!this.actionEnabled.current) {
                if(Platform.OS==='web'){
                  this._pickImage();
                }else{
                  this.PopupMediaRef.show(true);
                }
              }
            }
          }}>
            {/*<Image style={styles.myProfileImg} source={require('../../assets/images/noimg-male.png')} />*/}
            <View style={styles.myProfileNoImg}><Entypo name="plus" style={styles.plusIcMyProfile}/></View>
          </TouchableOpacity>
          }
          <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
          <PopupAppSetting ref={ref=>this.PopupRef=ref} />
          <Popup ref={ref=>this.popup=ref} modal={true}>
            <View style={PortalStyles.overlay} 
              onStartShouldSetResponder={() => {
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', paddingTop: 5, width:'80%', backgroundColor:'#fefefe', borderRadius: 12,}}>
              <ImageBackground resizeMode={'cover'} 
                style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
                source={popupImage}
              ></ImageBackground>
              <TouchableOpacity style={[styles.btnBlue, {marginTop:0,}]} onPress={()=>{ 
                this.popup?.hidePopup();
                if(this.props.setSubmitField!=undefined){
                  this.props.setSubmitField();
                }
                if(Platform.OS==='web'){
                  this._pickImage();
                }else{
                  this.PopupMediaRef.show(true);
                }
              }}>
              <Text style={styles.txtBtnBlue}>写真を選ぶ</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCancel} onPress={()=>this.popup?.hidePopup()}>
              <Text style={styles.txtBtnCancel}>閉じる</Text>
            </TouchableOpacity>
          </View>
      </Popup>
        </View>
      );
    }

    return (
      <>
      {this.state.image ?
        <TouchableOpacity style={styles.thumbPlusImg} onPress={()=>{
          if(this.props.setSubmitField!=undefined){
            this.props.setSubmitField();
          }
          this.props.onPress();
        }}>
          {/*<Image style={styles.plusImg} source={{ uri: this.state.image }} />*/}
          <ImageBackground imageStyle={{borderRadius:9999}} style={styles.plusImg} source={{uri : this.state.image}} resizeMode={'cover'}>
          {this.props.item!=undefined && this.props.item.certify!=2 &&
            <UserSetting.UnderReviewImgLayer borderRadius={9999} />
          }
          {/*<UserSetting.LockImgLayer />*/}
          </ImageBackground>
        </TouchableOpacity>
      :
        <TouchableOpacity style={styles.thumbPlusImg} disabled={this.enabeled} onPress={()=>{
          // プロフ画像登録促進　画像無しの場合にポップアップ表示
          if(this.props.isNoImage){
            this.popup?.showPopup();
          } else{
            if(this.props.setSubmitField!=undefined){
              this.props.setSubmitField();
            }
            if(!this.actionEnabled.current) {
              if(Platform.OS==='web'){
                this._pickImage();
              }else{
                this.PopupMediaRef.show(true);
              }
            }
          }
        }}>
          <AntDesign name="pluscircleo" style={styles.plusIc}/>
        </TouchableOpacity>
      }
      <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
      <PopupAppSetting ref={ref=>this.PopupRef=ref} />
      <Popup ref={ref=>this.popup=ref} modal={true}>
        <View style={PortalStyles.overlay} 
            onStartShouldSetResponder={() => {
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', paddingTop: 5, width:'80%', backgroundColor:'#fefefe', borderRadius: 12,}}>
            <ImageBackground resizeMode={'cover'} 
              style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
              source={popupImage}
            ></ImageBackground>
            <TouchableOpacity style={[styles.btnBlue, {marginTop:0,}]} onPress={()=>{ 
              this.popup?.hidePopup();
              if(this.props.setSubmitField!=undefined){
                this.props.setSubmitField();
              }
              if(Platform.OS==='web'){
                this._pickImage();
              }else{
                this.PopupMediaRef.show(true);
              }
            }}>
              <Text style={styles.txtBtnBlue}>写真を選ぶ</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCancel} onPress={()=>this.popup?.hidePopup()}>
              <Text style={styles.txtBtnCancel}>閉じる</Text>
            </TouchableOpacity>
          </View>
      </Popup>
      </>
    );
  }
}

const styles=StyleSheet.create({
  myProfile:{
    justifyContent:'center',
    alignItems:'center',
    marginTop:12,
    width:'100%',
    height:200,
    borderRadius:16,
  },
  myProfileImg:{
    alignItems:'center',
    width:200,
    height:200,
    //borderRadius:16,    
  },
  thumbPlusImg:{
    justifyContent:'center',
    alignItems:'center',
    margin:5,
    width:76,
    height:76,
    backgroundColor:'#e6e6e6',
    borderRadius:9999,
    borderWidth:2,
    borderColor:'#fff',
  },
  plusImg:{
    width:76,
    height:76,
    //borderRadius:9999
  },
  plusIc:{
    fontSize:24,
    lineHeight:24,
    color:'#fff',
  },
  myProfileNoImg: {
    display:'flex',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:200,
    height:200,
    backgroundColor:'#e6e6e6',
    borderWidth:4,
    borderRadius:10,
    borderColor:'#fff',
    textAlign:'center',
  }, 
  plusIcMyProfile: {
    fontSize:70,
    lineHeight:50,
    color:'#fff'
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:10,
    marginBottom:10,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    
    textAlign:'center',
  },
  btnBlue:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:30,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#ffffff',
    textAlign:'center'
  }
});