import React,{Component} from "react";
import {Text, View, StyleSheet, Platform, Dimensions} from "react-native";
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import Constants from "../constants/Constants";

export default class AdvanceSearchSliderScreen extends Component {
  _isMounted = false;
  constructor(){
    super();
    this.default = []; // default value
    this.state = {
      multiSliderValue: this.default,
      loading: true
    }
  }

  multiSliderValuesChange = (values) =>{
    let minValue = values[0];
    let maxValue = values[1];

    if(minValue < this.min || minValue > this.max) values[0] = '';
    if(maxValue > this.max || maxValue < this.min) values[1] = '';

    this.setState({ multiSliderValue: values });

    let { onChange, fieldName } = this.props;
    onChange(fieldName, values);
    let fieldNameTxt = `${fieldName}Txt`;
    let textValue = '';

    if(values.length > 0){
      if(values[0]!='' || values[1]!=''){
        if(values[0]!=''){
          textValue += values[0] + this.unit + this.postfix_min;
        }else{
          //textValue += Constants.notSetLabel;
        }
        //textValue += ' 〜 ';
        textValue += '~';
        
        if(values[1]!=''){
          textValue += values[1] + this.unit + this.postfix_max;
        }else{
          //textValue += Constants.notSetLabel;
        }
      }
    }
    onChange(fieldNameTxt, textValue);
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      let { values, min, max, unit, postfix, postfix_min, postfix_max } = this.props;
      this.unit = unit;
      this.postfix = postfix;
      this.postfix_min = postfix_min;
      this.postfix_max = postfix_max;
      this.min = min;
      this.sliderMin = min-1;
      this.sliderMax = max+1;
      this.default = [this.sliderMin, this.sliderMax];
      this.max = max;
      
      if(values!=null && values.length>0){
        if(values[0]!='') this.default[0] = values[0];
        if(values[1]!='') this.default[1] = values[1];
      }
      this.setState({multiSliderValue: this.default, loading: false});
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if(this.state.loading) return <></>;

    return (
      <View style={[styles.container, {padding:20}]}>
        
        <View style={{width:'100%',height:30}}>
          <View style={{flexDirection:'row',width:'100%'}}>
            <View style={{flex:1,justifyContent:'flex-start'}}>
            {this.state.multiSliderValue[0]>=this.min ?
              <Text style={{textAlign:'left',color:'#9aa5a7'}}>{this.state.multiSliderValue[0]} {this.unit}</Text>
              :
              <Text style={{textAlign:'left',color:'#9aa5a7'}}>未設定</Text>
            }
            </View>
            <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end'}}>
            {(this.state.multiSliderValue[1]!='' && this.state.multiSliderValue[1]<=this.max) ?
              <Text style={{textAlign:'right',color:'#9aa5a7'}}>{this.state.multiSliderValue[1]} {this.unit}
              {(this.postfix!==false && this.state.multiSliderValue[1]==this.max) && <Text>〜</Text> }
              </Text>
              :
              <Text style={{textAlign:'left',color:'#9aa5a7'}}>未設定</Text>
            }
            </View>
          </View>
        </View>
        
        <MultiSlider
          values={[this.state.multiSliderValue[0], this.state.multiSliderValue[1]]}
          onValuesChange={this.multiSliderValuesChange}
          min={this.sliderMin}
          max={this.sliderMax}
          step={1}
          allowOverlap={true}
          snapped={true}
          trackStyle={{backgroundColor:'#dddddd'}}
          selectedStyle={{backgroundColor:'#5cc8ca'}}
          markerStyle={styles.markerStyle}
          pressedMarkerStyle={{backgroundColor:'#D3D3D3'}}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    alignItems: 'center',
    width:'100%',
    height:'100%',
  },
  markerStyle: {
    ...Platform.select({
      ios: {
        
      },
      android: {
        height: 25,
        width: 25,
        backgroundColor:'#fff',
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
      },
      web: {
        height: 25,
        width: 25,
      },
    }),
  },
});
