//import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image, Dimensions } from 'react-native';
import { Badge } from 'react-native-paper';
import TabBadge from './TabBadge';

const screenWidth = Dimensions.get('window').width;

export default function MyPageIcon(props) {
  let {badge, source, topBorder, noBorder, text, blank, showBadge} = props;
  let style = styles.iconCircle;
  if(noBorder) style = '';
  if(topBorder)style = [styles.iconCircle, styles.topBorder];
  if(blank===true){
    return (
      <View style={styles.emptyGrid}></View>
    );
  }

  return (
    <TouchableOpacity style={styles.grid} onPress={props.onPress}>
      <View style={style}>
        {/*<Ionicons name={props.icon} size={26} color={'#484848'} />*/}
        {source && (<Image resizeMode={'cover'} style={styles.icon} source={source} />)}
        <Text style={styles.textIcon}>{text}</Text>
        {badge>0 && (
          showBadge==true?
          <View style={styles.badgeNum}>
            <TabBadge badge={badge}/>
          </View>
          :
          <View style={styles.badge}></View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  badge:{
    position:'absolute',
    top:15,
    right:8,
    padding:0,
    minWidth:9,
    width:9,
    height:9,
    borderRadius:999,      
    backgroundColor:'#fe8d5a',
    textAlign:'center',
  },
  badgeNum:{
    position:'absolute',
    top:10,
    right:8,
  },
  txtBadge:{
    fontSize:13,
    fontWeight:'bold',
    lineHeight:20,
    color:'#fff',
  },
  grid:{
    justifyContent:'center',
    alignContent:'center',
    alignSelf:'center',
    alignItems:'center',
    margin:screenWidth<375? 6 : 8,
    width:screenWidth<375? 80 : 94,
    height:screenWidth<375? 70 : 84,
    backgroundColor:'#fff',
    borderRadius:8,
    shadowColor: "#afc9b1",
    shadowOffset: {width: 0,height: 0,},
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 5,
  },
  emptyGrid:{
    margin:screenWidth<375? 6 : 8,
    width:screenWidth<375? 80 : 94,
    height:screenWidth<375? 70 : 84
  },
  iconCircle:{
    position:'relative',
    justifyContent:'center',
    alignItems:'center',
    paddingTop:screenWidth<=375? 0 : 15,
    width:'100%',
    height:screenWidth<=375? 84 : 110,
    borderColor:'#eef1f2',
    borderRightWidth:0.5,
    borderBottomWidth:0.5,
  },
  textIcon:{
    marginTop:10,
    fontSize:screenWidth<375? 10 : 12,
    fontWeight:'normal',
    lineHeight:screenWidth<375? 16 : 18,
    color: '#5d5d5d'
  },
  topBorder:{
    borderTopWidth:0.5
  },
  icon:{
    width:screenWidth<375? 30 : 36,
    height:screenWidth<375? 30 : 36,
  }
});
