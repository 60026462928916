import React, { Component } from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import PointShoppingScreen from '../../screens/PointShoppingScreen';
import PointShoppingScreen_ios from '../../screens/PointShoppingScreen_ios';
import PointShoppingScreen_android from '../../screens/PointShoppingScreen_android';
import PointScreen from '../../screens/PointListScreen';
import TermsScreen from '../../screens/setting/TermsScreen';
import PolicyScreen from '../../screens/setting/PolicyScreen';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import CommonFunction from '../../utils/CommonFunction';
import TransitionSpec from '../../constants/TransitionSpec';
import PaidyInfoScreen from '../../screens/PaidyInfoScreen';

const Stack = createStackNavigator();

export default class PointShoppingStack extends Component {
  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName="PointShopping" screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        {Platform.OS==='web'?
          <Stack.Screen 
          name="PointShopping" 
          children={(props)=><PointShoppingScreen {...props} />} 
          options={{headerShown: true}}/>
        :null}
        {Platform.OS==='ios'?
          <Stack.Screen 
          name="PointShopping" 
          children={(props)=><PointShoppingScreen_ios {...props} />} 
          options={{headerShown: true}}/>
        :null}
        {Platform.OS==='android'?
          <Stack.Screen 
          name="PointShopping" 
          children={(props)=><PointShoppingScreen_android {...props} />} 
          options={{headerShown: true}}/>
        :null}
        <Stack.Screen 
          name="PointList" 
          children={(props)=><PointScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Terms" 
          children={(props)=><TermsScreen {...props} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Policy" 
          children={(props)=><PolicyScreen {...props} />} 
          options={{headerShown: true}}
        />
        {Platform.OS==='web'?
          <Stack.Screen 
          name="PaidyInfo" 
          children={(props)=><PaidyInfoScreen {...props} />} 
          options={{headerShown: true}}/>
        :null}
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}