import React, { Fragment, Component } from 'react';
import { Linking, View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { Formik } from 'formik'
import styles from '../../styles/FormStyles';
import {FormTextInputWithStyle} from '../../components/form/FormInput';
import HeaderOptions from '../../constants/HeaderOptions';
import HeadStyle from '../../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import CommonFunction from '../../utils/CommonFunction';
import { AppContext } from '../../AppContext';
import Activate16Api from '../../services/api/Activate16Api';
import SMSWrapper from "../../components/common/SMSWrapper";
import LogoNoMargin from '../../components/common/LogoNoMargin';
import { AntDesign } from '@expo/vector-icons';
import Storage from '../../utils/StorageHelper2';

export default class SmsInputScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.initialValues = {
      tel: '',
      code: ''
    };

    this.state = {
      step: 1,
      action: '',
      tokenSMS: '',
      callNo: '',
      tokenCall: ''
    };

    this.Formik = React.createRef();
    this.Popup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();

      // from back page
      this.subscribeFocus = this.navigation.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        this.checkAccountState();
      });
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }

  checkAccountState = ()=>{
    const { appState } = this.context;
    const account = appState?.account;
    if(account?.activated!=0){
      if (this.navigation.canGoBack()) {
        this.navigation.goBack();
      }else{
        this.gotoMyPage();
      }
    }

    setTimeout(() => {
      if(SMSWrapper?.isShow()===true){
        SMSWrapper?.hideSMS();
      }
    }, 750);
  }

  clearLastAccess = ()=>{
    // clear lastAccess
    const { appState } = this.context;
    const memberId = appState?.userProfile?.memberId;
    if(memberId){
      const lastAccessKey = 'lastAccess'+memberId;
      Storage.removeItem(lastAccessKey);
    }
  }

  async onSubmit(values){
    switch(this.state.action){
      case 'submitStep0':
        this.formik.setValues(this.initialValues);
        this.setState({
          action: '',
          tokenSMS: '',
          step: 1
        });
        break;
      case 'submitStep1':
        try{
          CommonFunction.overLay();
          // validate tel
          let responseCheckTel = await Activate16Api.CheckTel(values?.tel);
          //check response
          if(!CommonFunction.checkResponseStatus(responseCheckTel)) return;

          if(responseCheckTel!=undefined){
            if(responseCheckTel?.status==1){
              // send sms
              let responseSendVerificationCodeSMS = await Activate16Api.SendVerificationCodeSMS(values?.tel);
              if(responseSendVerificationCodeSMS!=undefined){
                let tokenSMS = responseSendVerificationCodeSMS?.response?.token;
                if(tokenSMS){
                  // goto step2
                  this.setState({tokenSMS: tokenSMS, step: 2});
                }
              }
            }
          }
        }catch(e){
          if(__DEV__) console.log(e);
        }finally{
          CommonFunction.hideOverlay();
        }

        break;
      case 'submitStep2':
        try{
          CommonFunction.overLay();
          values.token = this.state?.tokenSMS;
          let responseVerificationCode = await Activate16Api.VerificationCode(values);
          //check response
          if(!CommonFunction.checkResponseStatus(responseVerificationCode)) return;

          if(responseVerificationCode!=undefined){
            if(responseVerificationCode?.status==1){
              // complete
              this.Popup?.showPopup();
              // clear lastAccess
              this.clearLastAccess();
            }
          }
        }catch(e){
          if(__DEV__) console.log(e);
        }finally{
          CommonFunction.hideOverlay();
        }
        break;
      case 'submitStep3':
        try{
          CommonFunction.overLay();
          // validate tel
          let responseCall = await Activate16Api.Call(values?.tel);
          //check response
          if(!CommonFunction.checkResponseStatus(responseCall)) return;

          if(responseCall!=undefined){
            if(responseCall?.status==1){
              let tokenCall = responseCall?.response?.token;
              let callNo = responseCall?.response?.telnumber;
                if(tokenCall){
                  // goto step4
                  this.setState({callNo: callNo, tokenCall: tokenCall, step: 3});
                }
            }
          }
        }catch(e){
          if(__DEV__) console.log(e);
        }finally{
          CommonFunction.hideOverlay();
        }
        // this.setState({'': '', step: 3});
        break;
      case 'submitStep4':
        try{
          CommonFunction.overLay();
          values.token = this.state?.tokenCall;
          let responseTelActivate = await Activate16Api.TelActivate(values);
          //check response
          if(!CommonFunction.checkResponseStatus(responseTelActivate)){
            return;
          } 

          if(responseTelActivate!=undefined){
            if(responseTelActivate?.status==1){
              // complete
              this.Popup?.showPopup();
              // clear lastAccess
              this.clearLastAccess();
            } 
          }
        }catch(e){
          if(__DEV__) console.log(e);
        }finally{
          CommonFunction.hideOverlay();
        }
        break;
    }
  }

  setHeader = () =>{
    this.navigation.setOptions({
      headerStyle: HeaderOptions.HeaderNoBorder,
      headerTitle: () => <View/>,
      headerLeft: () => (
        <View style={{flexDirection:'row'}}>
          <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} onPress={ () => {
            if(this.state.step ==1){
              this.navigation.goBack();
            }else if(this.state.step ==2){
              this.formik.setValues(this.initialValues);
              this.setState({
                action: '',
                tokenSMS: '',
                step: 1
              });
            }else{
              this.setState({
                step: 2
              });
            }
          }}/>
          <LogoNoMargin/>
      </View>
      ),
      headerRight: () => <View/>
    });
  }

  validateForm = (step, values) => {
    let $return = true;
    switch(step){
      case 'step1':
        if(values.tel=='') $return = false;
        break;
      case 'step2':
        if(values.code=='') $return = false;
        break;
    }

    return $return;
  }

  gotoMyPage = ()=>{
    this.navigation?.navigate('Home', {screen:'MyPageStack', params: {screen: 'MyPage'}});
  }

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={styles.ScrollContainer}>
        <Formik
          initialValues={this.initialValues}
          onSubmit={values => this.onSubmit(values) }
          innerRef={ref =>this.formik=ref}
        >
          {({ values, handleChange, errors, setFieldTouched, touched, isValid, dirty, handleSubmit }) => (
            <Fragment>
              {this.state.step ==3 &&(
                <View style={styles.bodyContent2}>
                <View style={[styles.labelView, {marginBottom:20}]}>
                  <Text style={styles.labelTextTitle}>ご登録の番号から発信して通知してください</Text>
                </View>
                <View style={{marginBottom:30,}}>
                  <Text style={{fontSize: 14, lineHeight: 20, color:'#98a3a5' }}>
                    電話番号の確認を行います。{"\n"}
                    以下の発信ボタンから発信番号通知を有効にして発信してください。
                  </Text>
                  </View>
                  <View style={{flexDirection:'row', justifyContent:'flex-start',marginBottom:0}}>
                  <Text style={{fontSize: 14, lineHeight: 20, color:'#2b8bfe' }}>
                    {values?.tel}
                  </Text>
                  <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 20, color:'#2d2d2d' }}>
                    から発信
                  </Text>
                  </View>

                <View style={styles.buttonPanelSetting}>
                  <TouchableOpacity 
                    style={[styles.blueBtn,{ width:'70%'}]} 
                    onPress={()=>{
                      Linking.openURL('tel:' + this.state.callNo);
                    }}
                  >
                    <Text style={styles.loginText}>電話番号確認をする</Text>
                  </TouchableOpacity>
                </View>
                <View style={{marginBottom:0,}}>
                  <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 20, color:'#2d2d2d', marginTop:20 }}>
                    確認が完了したら次へボタンを押して登録を完了{"\n"}
                    してください
                  </Text>
                  </View>
                <View style={styles.buttonPanelSetting}>
                  <TouchableOpacity 
                    style={[styles.submitBtn2,{width:'90%'}]} 
                    onPress={()=>{
                      this.setState({action: 'submitStep4'});
                      handleSubmit();
                    }}
                    disabled={!this.validateForm('step4',values)}
                  >
                    <Text style={styles.loginText}>次へ</Text>
                  </TouchableOpacity>
                </View>
                <View style={{flexDirection:'row', justifyContent:'center',marginTop:25, width:'100%'}}>
                  <TouchableOpacity style={[styles.menuItem,{borderBottomWidth:1, borderTopWidth: 1, width:'100%'}]}
                  onPress={()=>{
                    this.setState({action: 'submitStep0'});
                    handleSubmit();
                  }} >
                    <Text style={styles.menuItemText}>電話番号を変更・修正する</Text>
                    <AntDesign name={'right'} style={styles.rightIcon} />
                  </TouchableOpacity>
                  </View>
              </View>
              )}
              {/** Step2 */}
              {this.state.step ==2 &&(
              <View style={styles.bodyContent2}>
                <View style={[styles.labelView, {marginBottom:20,}]}>
                  <Text style={styles.labelTextTitle}>コードを入力してください</Text>
                </View>
                <View style={{marginBottom:30,}}>
                  <Text style={{fontSize: 14, lineHeight: 20, color:'#98a3a5' }}>
                    ご本人確認のためにSMS送信による認証をお願い致します。{"\n"}
                    また電話番号が公開されることはありません。
                  </Text>
                  </View>
                  <View style={{marginBottom:25}}>
                  </View>
                <FormTextInputWithStyle fieldName="code" type="code" placeholder="コードを入力してください" keyboardType="number-pad" autoComplete="off"/>
                <View style={styles.buttonPanelSetting}>
                  <TouchableOpacity 
                    style={[styles.submitBtn,{opacity:this.validateForm('step2',values)?1:0.4, width:'90%'}]} 
                    onPress={()=>{
                      this.setState({action: 'submitStep2'});
                      handleSubmit();
                    }}
                    disabled={!this.validateForm('step2',values)}
                  >
                    <Text style={styles.loginText}>認証する</Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity style={{flexDirection:'row', justifyContent:'center',marginTop:25}}>
                  <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 20, color:'#2d2d2d',textDecorationLine:'underline', marginBottom: 5 }}
                  onPress={()=>{
                    this.setState({action: 'submitStep3'});
                    handleSubmit();
                  }}>
                    SMSが届かない方はこちら
                  </Text>
                  </TouchableOpacity>
                  <View style={{flexDirection:'row', justifyContent:'center',marginTop:25, width:'100%'}}>
                  <TouchableOpacity style={[styles.menuItem,{borderBottomWidth:1, borderTopWidth: 1, width:'100%'}]} 
                  onPress={()=>{
                    this.setState({action: 'submitStep0'});
                    handleSubmit();
                  }} >
                    <Text style={styles.menuItemText}>電話番号を変更・修正する</Text>
                    <AntDesign name={'right'} style={styles.rightIcon} />
                  </TouchableOpacity>
                  </View>
              </View>
              )}
              {this.state.step ==1 &&(
              <View style={styles.bodyContent2}>
                <View style={[styles.labelView, {marginBottom:20,}]}>
                  <Text style={styles.labelTextTitle}>電話番号認証</Text>
                </View>
                <View style={{marginBottom:30,}}>
                  <Text style={{fontSize: 14, lineHeight: 20, color:'#98a3a5' }}>
                    ご本人確認のためにSMS送信による認証を{"\n"}お願い致します。{"\n"}
                    また電話番号が公開されることはありません。
                  </Text>
                  </View>
                  <View style={{marginBottom:10,}}>
                  <Text style={{fontWeight:'bold', color:'#484848'}}>電話番号</Text>
                  </View>
                <FormTextInputWithStyle fieldName="tel" type="tel" placeholder="半角数字11桁" keyboardType="number-pad" autoComplete="off"/>
                <View style={styles.buttonPanelSetting}>
                  <TouchableOpacity 
                    style={[styles.submitBtn,{opacity:this.validateForm('step1',values)?1:0.4, width:'90%'}]} 
                    onPress={()=>{
                      this.setState({action: 'submitStep1'});
                      handleSubmit();
                    }}
                    disabled={!this.validateForm('step1',values)}
                  >
                    <Text style={styles.loginText}>SMS送信</Text>
                  </TouchableOpacity>
                </View>
              </View>
              )}
            </Fragment>
          )}
        </Formik>

        <Popup ref={ref=>this.Popup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
            this.Popup?.hidePopup();
            this.gotoMyPage();
          }} 
          />
          <View style={PortalStyle.blocPopup}>
            <View style={[PortalStyle.blocPopupInner, { width:'80%',justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center'}]}>
              <View style={styles.popupInside}>
                <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
                  <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848'}}>電話番号認証ができました。{"\n"}引き続きPappyをお楽しみください</Text>
                </View>
                <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center',alignSelf:'center'}} onPress={()=>{ 
                  this.Popup?.hidePopup();
                  this.gotoMyPage();
                }}>
                  <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7', textAlign:'center'}}>OK</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Popup>
        </ScrollView>
      </View>
    );
  }
}