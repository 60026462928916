class PaymentManager {
  _defaultPaymentManager = null;
  register(_ref) {
    this._defaultPaymentManager = _ref;
  }

  unregister(_ref) {
    this._defaultPaymentManager = null;
  }
  
  getDefault() {
    return this._defaultPaymentManager;
  }
}

export default new PaymentManager();
