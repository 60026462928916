import React, { Component } from 'react';
import { StyleSheet, Text, SectionList, View, TouchableOpacity, Platform, AppState, PermissionsAndroid } from 'react-native';
import AuthHelper from '../utils/AuthHelper';
import { AppContext } from '../AppContext';
import { AntDesign } from '@expo/vector-icons';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import ConfirmPopupStyles from '../styles/ConfirmPopupStyles';
import CommonFunction from '../utils/CommonFunction';
import ApiConfig from '../constants/ApiConfig';
import ListStyles from '../styles/ListStyles';
import firebase from '@react-native-firebase/app';
import NotificationManager from 'react-native-check-notification-enable';

export default class SettingScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.actionEnabled = React.createRef();
    this.state = {
      show: false,
      notificationEnabled: 0
    };
    this.state = {
      notificationSetting: 0
    }
    this.menu_list = [
      {
        title: '名種設定', 
        data:[
          {title:'通知設定', action:'NotifInput'},
          {title:'メールアドレス設定', action: 'MailInput'},
          {title:'パスワード設定', action: 'PassInput'},
          {title:'プライベートモード設定', action: 'PrivateMode'},
          {title:'シークレットモード設定', action: 'SecretMode'},
        ]
      },{
        title: '非表示・ブロック', 
        data:[
          {title:'非表示リスト', action:'HideList'},
          {title:'ブロックリスト', action:'BlockList'},
        ]
      },{
        title: 'ヘルプ', 
        data:[
          {title:'ヘルプ', action:'Help'},
          {title:'お問い合わせ', action:'Inquiry'}
        ]
      },{
        title: 'その他', 
        data:[
          {title:'会社概要', action:'Rule'},
          {title:'利用規約', action:'Terms'},
          {title:'プライバシーポリシー', action:'Policy'},
          {title:'特定商取引法に基づく表記', action:'Law'},
          {title:'資金決済法に基づく表記', action:'Settlement'},
          {title:'ログアウト', action:'logout'}
        ]
      }
    ]
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.title = '設定';
      this.props?.setHeader(this.title);
      this.getNotificationStatus();

      // focus screen
      this.subscribeFocus = this.props.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        this.props?.setHeader(this.title);
        this.state.show = false;
        this.getNotificationStatus();
        this.actionEnabled.current = false;
      });

      if(Platform.OS!=='web'){
        // アプリがフォアグラウンドになった場合の処理
        this.subscription = AppState.addEventListener('change', () => {
          if (AppState.currentState === 'active') {
            this.state.show = false,
            this.getNotificationStatus();
          }
        });
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
    if(this.subscription)
    this.subscription.remove();
  }

  async getNotificationStatus(){
    if(Platform.OS==='ios') {
      const enabled = await firebase.messaging().hasPermission();
      if(enabled){
        this.setState({notificationEnabled: 0});
      }else{
        this.setState({notificationEnabled: 1});
      }  
    } else if (Platform.OS === 'android') {
      if(Platform.Version > 32){
        const enabled = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS);
        if(enabled){
          this.setState({notificationEnabled: 0});
        }else{
          this.setState({notificationEnabled: 1});
        }
      }else{
        const enabled = await NotificationManager.areNotificationsEnabled();
        if(enabled){
          this.setState({notificationEnabled: 0});
        }else{
          this.setState({notificationEnabled: 1});
        }
      }
    }
    this.setState({ show: true });
  }

  async _execAction(actionName) {
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)
    switch(actionName){
      case 'logout':
        this.popup?.showPopup();
        break;
      case 'MailInput': 
      case 'NotifInput':
      case 'PassInput':
      case 'BlockList':
      case 'HideList':
      case 'Identification':
      case 'PayOrderList':  
      case 'PointList':
      case 'Rule':
      case 'Policy':
      case 'Law':
      case 'Settlement':
      case 'Terms':  
      case 'PrivateMode': 
      case 'SecretMode':
        this.props.navigation?.navigate(actionName);
        break;
      case 'Help': 
        this.props.navigation?.navigate('Auth', {screen:'Help'});
        break;  
      case 'Inquiry':
        if(Platform.OS==='web'){
          const token = await CommonFunction.getEncryptAccessToken();
          window.location.href = ApiConfig.contact.url+'?token='+token;
        }else{
          this.props.navigation?.navigate('Inquiry');
        }
        break;
    }
  }

  render() {
    if(!this.state.show) return null;
    return (
      <View style={[ListStyles.container,styles.container]}>
        <SectionList
          style={styles.sectionList}
          sections={this.menu_list}
          renderItem={({ item, index, section }) => {
            let borderBottomWidth = 1;
            if(index==section?.data?.length-1) borderBottomWidth = 0;
            // let notificationSettingIcon = 0;
            // if(item.action == 'NotifInput' && this.state.notificationSetting == 1){
            //   notificationSettingIcon = 1;
            // }
            return (
            <TouchableOpacity style={[styles.menuItem,{borderBottomWidth:borderBottomWidth}]} 
              onPress={() => {this._execAction(item.action);}}>
              <Text style={styles.menuItemText}>{item.title}</Text>
              {item.title=='通知設定' && this.state.notificationEnabled==1?
               <AntDesign name="exclamationcircle" style={[styles.iconLeft, {color:'#ef9265'}]} />
              :null}
              {/* {notificationSettingIcon == 1 &&
                <View style={[styles.badge, {marginRight:5}]}>
                  <Text style={[styles.textBadge,{fontSize: 14}]}>!</Text>
                </View> 

              } */}
              <AntDesign name={'right'} style={styles.rightIcon} />
            </TouchableOpacity>
          )}}
          renderSectionHeader={({section}) => <Text style={styles.sectionHeader}>{section.title}</Text>}
          numColumns={1}
          keyExtractor={(item, index) => index.toString()+item.action}
          initialNumToRender={30}
          scrollIndicatorInsets={{ right: 1 }}
        />
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <View style={ConfirmPopupStyles.popupContainer}>
            <View style={ConfirmPopupStyles.popupTextItem}>
              <Text style={ConfirmPopupStyles.popupText}>ログアウトしますか？</Text>
            </View>
            <View style={ConfirmPopupStyles.btnContainer}>
              <TouchableOpacity style={ConfirmPopupStyles.btnItem} onPress={async()=>{
                this.popup?.hidePopup();
                await AuthHelper.logout();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>OK</Text>
              </TouchableOpacity>
              <View style={{borderLeftWidth:2, borderColor:'#d8d8dc', width:1,height:'100%'}}></View>
              <TouchableOpacity style={ConfirmPopupStyles.btnItem} onPress={()=>{
                this.popup?.hidePopup();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>キャンセル</Text>
              </TouchableOpacity>  
            </View>              
        </View>
      </Popup>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingBottom:30,
    ...Platform.select({
      ios: { 
        paddingBottom:30,
      },
      android: { 
        paddingBottom:30,
      }
    }),
    backgroundColor:'#eef0f1',
  },
  sectionList:{
    flex:1,
    width:'100%',
    backgroundColor:'#fff',
  },
  sectionHeader:{
    paddingLeft:16,
    width:'100%',
    backgroundColor:'#eef0f1',
    borderTopWidth:1,
    borderTopColor:'#e6e6e6',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
    fontSize:14,
    fontWeight:'normal',
    lineHeight:32,
    color:'#333',
  },
  menuItem:{
    flex:1, 
    flexDirection:'row',
    marginLeft:16,
    padding:10,
    paddingLeft:0,
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  menuItemText:{
    fontSize:14,
    lineHeight:27,
  },
  rightIcon:{
    position:'absolute',
    top:'50%',
    right:0,
    padding:10,
    fontSize:16,
    color:'#484848',
    ...Platform.select({
      ios: { transform:[{translateY:-10}] },
      android: { transform:[{translateY:-10}] },
      default: { transform:[{translateY:-20}] },
    })
  },
  iconLeft:{
    position:'absolute',
    top:'50%',
    right:25,
    padding:10,
    fontSize:16,
    ...Platform.select({
      ios: { transform:[{translateY:-10}] },
      android: { transform:[{translateY:-10}] },
      default: { transform:[{translateY:-20}] },
    })
  },
  badge:{
    position:'absolute',
    top:'50%',
    right:30,
    width:18,
    height:18, 
    backgroundColor:'#ff8d63', 
    borderRadius:999, 
   },
   textBadge: {
    textAlign:'center',
    color:'#fff', 
    fontSize:12,
    fontWeight:'normal',
    lineHeight:18, 
   },
});
