import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet,Image } from 'react-native';
import styles from '../../styles/FormStyles';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';

export default class ChatReportPopup extends Component {
  _isMounted = false;
  memberId= '';
  constructor(props) {
    super(props);
    
    this.state = {
      show: false,
      partnerMsg: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (navigation, hideAction)=>{
    if(navigation!=undefined)
      this.navigation = navigation;
    
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }

    this.setState({ show: true});
  }

  showPartner = (navigation, hideAction)=>{
    if(navigation!=undefined)
      this.navigation = navigation;
    
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }

    this.setState({ show: true, partnerMsg: true});
  }

  hide = ()=>{
    this.setState({ show: false, partnerMsg: false });
  }

  render() {
    if(this.state.show){
    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
            //this.hide();
          }} />
          <View style={PortalStyle.blocPopup}>
            <View style={{width:'80%'}}>
              <TouchableOpacity style={{width:32, height:32,alignSelf: 'flex-end',marginBottom:10}} onPress={()=>{
                this.hide();
                if(this.hideAction!=null && this.hideAction!=undefined){
                  this.hideAction();
                  this.hideAction = null;
                }
              }} >
                <Image resizeMode={'cover'} style={{width:32, height:32}} source={require('../../assets/images/icon/close.png')}/>
              </TouchableOpacity>
                
            </View>
          
            <View style={[PortalStyle.blocPopupInner, {paddingTop:25, paddingRight:15, paddingBottom:15, paddingLeft:15, width:'80%'}]}>
            
              <View style={styles.popupInside}>
              
                <Text style={{fontSize:20, fontWeight:'bold', lineHeight:24, color:'#484848', textAlign:'center', marginBottom:5}}>
                お相手のご利用状況により{"\n"}表示する事ができません
                </Text>
                <Text style={{fontSize:11,  lineHeight:24, color:'#484848', textAlign:'center', marginBottom:15}}>
                このお相手のプロフィールやメッセージ履歴を{"\n"}表示することができません{"\n"}通報や非表示などは以下のボタンから可能です
                </Text>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center',paddingBottom:10, width:'100%'}}>
                  <TouchableOpacity style={inlineStyles.modalButtonGray} onPress={this.props.onReportAction}>

                    <Text style={inlineStyles.textModalButtonGray}>通報する</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={inlineStyles.modalButtonGray} onPress={this.props.onBrockAction}>
                    <Text style={inlineStyles.textModalButtonGray}>非表示・ブロックする</Text>
                    {this.props.children}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}
}

const inlineStyles = StyleSheet.create({
  modalButtonGray: {
    marginTop:15,
    paddingRight:8,
    paddingLeft:8,
    paddingBottom:4,
    paddingTop: 4,
    width:'70%',
    height:34,
    backgroundColor:'#f2f2f2',
    borderRadius:999,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
    elevation: 1,
  },
  textModalButtonGray: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:26,
    color:'#484848',
    textAlign:'center',
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
});