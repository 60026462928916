import React, { Component } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import IineScreen from '../../screens/IineScreen';
import SkipIineScreen from '../../screens/iine/SkipIine';
import IineUserInfoScreen from '../../screens/IineUserInfoScreen';
import ChatRoomScreen from '../../screens/chat/ChatRoomScreen';
import ChatHideSettingScreen from '../../screens/chat/ChatHideSettingScreen';
import ReportScreen from '../../screens/ReportScreen';
import UserInfoScreen from '../../screens/UserInfoScreen';
import PointShoppingStack from './PointShoppingStack';
import UserSettingScreen from '../../screens/UserSettingScreen';
import PreviewUserInfoScreen from '../../screens/PreviewUserInfoScreen';
import UserSettingImageScreen from '../../screens/UserSettingImageScreen';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import CommonFunction from '../../utils/CommonFunction';
import TransitionSpec from '../../constants/TransitionSpec';

const Stack = createStackNavigator();

export default class IineStack extends Component {
  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName="IineList" screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        <Stack.Screen name="IineList" 
        children={(props)=><IineScreen {...props} setBadge={this.props?.setBadge} />} 
        options={{headerShown: true}}
        />
        <Stack.Screen name="SkipIineList" component={SkipIineScreen} options={{headerShown: true, title:'SkipList'}}/>
        <Stack.Screen 
          name="Profile" 
          children={(props)=><IineUserInfoScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="SameProfile" 
          children={(props)=><UserInfoScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatRoom" 
          children={(props)=><ChatRoomScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Report" 
          children={(props)=><ReportScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatHideSetting" 
          children={(props)=><ChatHideSettingScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="UserSetting" 
          children={(props)=><UserSettingScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="UserSettingImage" 
          children={(props)=><UserSettingImageScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen name="PreviewUserInfo" component={PreviewUserInfoScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}