import React,{ Component } from 'react';
import { StyleSheet, Text, View, ScrollView, Platform, TouchableOpacity } from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import ApiConfig from '../../constants/ApiConfig';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class RuleScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = '会社概要';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }  

  setHeader = () =>{
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={styles.blogInner}>
            <View>
              <Text style={styles.titleInfo}>◆会社名</Text>
              <Text style={styles.txtInfo}>プロスゲイト株式会社</Text>
            </View>
            <View>
              <Text style={styles.titleInfo}>◆代表者</Text>
              <Text style={styles.txtInfo}>佐藤裕久</Text>
            </View>
            <View>
              <Text style={styles.titleInfo}>◆事業内容</Text>
              <Text style={styles.txtInfo}>マッチングサービス「Pappy」の運営</Text>
            </View>
            <View>
              <Text style={styles.titleInfo}>◆所在地</Text>
              <Text style={styles.txtInfo}>兵庫県神戸市中央区雲井通3-2-3</Text>
            </View>
            <View>
              <Text style={styles.titleInfo}>◆お問い合わせ先</Text>
              <TouchableOpacity onPress={async()=>{
                const token = await CommonFunction.getEncryptAccessToken();
                if(Platform.OS==='web'){
                  if(token){
                    window.location.href = ApiConfig.contact.url+'?token='+token;
                  }else{
                    window.location.href = ApiConfig.contact.url;
                  }
                }else{
                  if(token){
                    this.navigation.navigate('Inquiry');
                  }else{
                    this.navigation.navigate('InquiryNonMember');
                  }
                }
              }}>
              <Text style={[styles.txtInfo,{textDecorationLine:'underline'}]}>{ApiConfig.contact.url}</Text>
              </TouchableOpacity>
            </View>

            <View>
              <Text style={styles.titleInfo}>◆許認可等</Text>
              <Text style={styles.txtInfo}>総務省 第二種電気通信事業届出番号E-15-2133{"\n"}
              インターネット異性紹介事業届出・認定済み番号{"\n"}
              63080004000{"\n"}
              商品登録第4802044号{"\n"}</Text>
            </View>

          </View>
        </ScrollView>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:15,
    paddingBottom:20,
  },
  padding5: {
    paddingTop:5,
    paddingBottom:5,
  },
  font14Margin: {
    marginTop:20,
    marginBottom:10,
  },
  font14: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848',
  },
  font12: {
    fontSize:12,
    lineHeight:17,
    color:'#444444',
  },
  titleInfo: {
    paddingBottom:8,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
    textAlign:'left',
  },
  txtInfo: {
    paddingBottom:16,
    fontSize:14,
    lineHeight:16,
    color:'#484848',
    textAlign:'left',
  }
});
