import * as Yup from 'yup'

const validateEmpty = (val) =>{
  var validate = false; 
  if(typeof val=='undefined') return validate;
  
  if(typeof val =='object' && JSON.stringify(val) != "{}"){
    validate = true;
  }else{
    if(val.length > 0 ) validate = true;
  }

  return validate;
}

const validateNickname =(val)=>{
  var validate = false;
  if(typeof val !='undefined'){
    var regEx = /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤]+$/i;
    if(regEx.test(val)){
      validate = true;
    }
  }
  return validate;
}

const validatePassword =(val)=>{
  var validate = false;
  if(typeof val !='undefined'){
    var regEx = /^[a-zA-Z0-9]+$/i;
    if(regEx.test(val)){
      validate = true;
    }
  }
  return validate;
}

const validateAge = (val) =>{
  var age = 0;
  var validate = false;
  if(typeof val !='undefined'){
    var birthDate = new Date(val);
    var ageDifMs = Date.now() - birthDate.getTime();
    var ageDate = new Date(ageDifMs);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
  }
    
  if(age >= 18){
    validate = true;
  }
  return validate;
}

const checkSamePassword = (val) => {
  var validate = false;
  if(typeof val !='undefined'){
    var same = 1;
      for(var i=0; i<val.length-1;i++){
        if(typeof val[i]!='undefined' && typeof val[i+1]!='undefined'){
          if(val[i]!=val[i+1]){
              same = 0;
          }
        }
      }
    if(!same) validate = true;
  }
  return validate;
}

const checkCountPassword = (val) => {
  var validate = false;
  if(typeof val !='undefined'){
    var count1 = 1;
		var count2 = 1;
    // case 1, check 1234
    for(var i=0; i<val.length-1;i++){
      if(typeof val[i]!='undefined' && typeof val[i+1]!='undefined'){
        var first = parseInt(val[i].charCodeAt()) + 1;
        var second = parseInt(val[i+1].charCodeAt());
        if(first!=second){
          count1 = 0;
        }
      }
    }
        //case 2, check 4321
		for(var i=0; i<val.length-1;i++){
      if(typeof val[i]!='undefined' && typeof val[i+1]!='undefined'){
				var first = parseInt(val[i].charCodeAt()) - 1;
				var second = parseInt(val[i+1].charCodeAt());
        if(first!=second){
            count2 = 0;
        }
      }
    }
  }
	if(!count1 && !count2) validate = true;
  return validate;
}

const checkConfirmPassword = (val) =>{
  var validate = false;
  if(typeof val !='undefined'){
    if(val.length===4) validate = true;
  }
  return validate;
}

const wrongConfirmPassword = (val,val2) =>{
  var validate = false;
  if(typeof val !='undefined'){
    if(val==='1234') validate = true;
  }
  return validate;
}

const validateTel = (val) =>{
  var validate = false;
  if(typeof val !='undefined'){
    var regEx = /^[0-9]+$/i;
    if(regEx.test(val) && val.length===11){
      validate = true;
    }
  }
  return validate;
}

const validateSMSCode = (val) =>{
  var validate = false;
  if(typeof val !='undefined'){
    var regEx = /^[0-9]+$/i;
    if(regEx.test(val) && val.length >= 4 && val.length <= 6) validate = true;
  }
  return validate;
}

export default {
  LoginSchema:Yup.object().shape({
    mail: Yup.string()
      .email('メールアドレスを正しく入力してください')
      .required('メールアドレスを入力してください'),
    pass: Yup.string()
      .min(4, '4～8文字で入力してください')
      .max(8, '4～8文字で入力してください')
      .required('パスワードを入力してください')
      .test('check-password','入力されたパスワードは利用できません', (val) => validatePassword(val)),
    }),
    RegisterStep1Schema: Yup.object().shape({
      name: Yup.string()
        .required('ニックネームの入力は必須です。')
        .min(2, '2～10文字で入力してください。')
        .max(10, '2～10文字で入力してください。')
        .test('check-nickname','絵文字は利用できません。', (val) => validateNickname(val)),
      currentPref:Yup.object()
        .test('len', '居住地を入力してください', (val) => validateEmpty(val)),
      sex: Yup.object()
        .test('len', '性別を入力してください', (val) => validateEmpty(val)),
      birthDate:Yup.mixed()
        .test(
            "DOB",
            "生年月日を入力してください",
            val=> validateAge(val)
          ),
      accept: Yup.mixed().test('check-accept','This field is required.',(val) => val)   
    }),
    RegisterStep2FemaleSchema: Yup.object().shape({
      dateHope:Yup.object().test('len', 'デートでしたいことを入力してください', val => validateEmpty(val)),
      meetHope:Yup.object().test('len', '出会うまでの希望を入力してください', val => validateEmpty(val))
    }),
    RegisterStep5Schema  : Yup.object().shape({
      mail: Yup.string()
        .required('メールアドレスを入力してください。')
        .email('正しいメールアドレスを入力してください')
        .max(100, '文字数オーバーです。'),
      pass: Yup.string()
        .required('パスワードを入力してください。')
        .min(4, 'パスワードは4~8文字で入力してください')
        .max(8, 'パスワードは4~8文字で入力してください')
        .test('check-password-same','入力されたパスワードは利用できません',val => checkSamePassword(val))
        .test('check-password-count','入力されたパスワードは利用できません',val => checkCountPassword(val))   
        .matches(/^[a-zA-Z0-9]+$/, "入力されたパスワードは利用できません"),  
    }),
    /*
    RegisterStep6Schema  : Yup.object().shape({
      confirmPassword: Yup.string()
        .required('パスワードを入力してください。')
        .test('check-confirmPassword', '認証コードは4桁までになります。', val => checkConfirmPassword(val))
        .test('check-wrongConfirmPassword', '認証コードが間違っています', (val,val2) => wrongConfirmPassword(val,val2))
    }),
    */
    RegisterStep6Schema  : Yup.object().shape({
      code: Yup.string()
        .required('届いた 4 ケタのコードを入力してください')
    }),
    SettingEmailSchema:Yup.object().shape({
        mail: Yup.string()
        .required('メールアドレスを入力してください。')
        .email('正しいメールアドレスを入力してください')
        .max(100, '文字数オーバーです。'),
        inputCurrentMail: Yup.string()
        .required('メールアドレスを入力してください。')
        .email('正しいメールアドレスを入力してください')
        .max(100, '文字数オーバーです。'),
    }),
    SettingEmailNotmailSchema:Yup.object().shape({
      mail: Yup.string()
      .required('メールアドレスを入力してください。')
      .email('正しいメールアドレスを入力してください')
      .max(100, '文字数オーバーです。'),
  }),
    SettingTelSchema:Yup.object().shape({

    }),
    RegistBoardSchema:Yup.object().shape({
      text: Yup.string()
        .required('This field is required.'),
    }),
    SmsInputSchema:Yup.object().shape({
      tel: Yup.string()
        //.required('This field is required.')
        .test('len', 'Invalid input', (val) => validateTel(val)),
    }),
    SmsInputSchema2:Yup.object().shape({
      code: Yup.string()
        //.required('This field is required.')
        .test('len', 'Must be exactly 4-6 characters', (val) => validateSMSCode(val)),
    }),
    SettingPassword:Yup.object().shape({
      newPassword: Yup.string()
        .required('パスワードを入力してください')
        .min(4, 'パスワードは4～８文字で入力してください。')
        .max(8, 'パスワードは4～８文字で入力してください。')
        .test('check-password-same','パスワードは全部同じ文字で入力できません',val => checkSamePassword(val))
        .test('check-password-count','パスワードは連番で入力できません',val => checkCountPassword(val))
        .matches(/^[a-zA-Z0-9]+$/, "入力されたパスワードは利用できません"),
      confirmPassword: Yup.string()
      .required('確認用パスワードが異なっています')
      .oneOf([Yup.ref('newPassword'), null], '確認用パスワードが異なっています')
    }),
    EmailVerifyCode:Yup.object().shape({
      code: Yup.string()
        //.required('This field is required.')
        .test('len', '4～6文字で入力してください', (val) => validateSMSCode(val)),
    }),
    UploadIdentificationSchema:Yup.object().shape({
      image: Yup.mixed()
      .test('required', "You need to provide a file", (value) =>{
        return value && value.length
      })
    }),
    MyTemplate:Yup.object().shape({
      body: Yup.string()
        .required('This field is required.')
    }),

  };