import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, View, ScrollView, TouchableOpacity, ImageBackground, Dimensions, Platform 
} from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import MyPage13Api from '../../services/api/MyPage13Api';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';
import * as ImagePicker from 'expo-image-picker';
import PopupAppSetting from '../../components/common/PopupAppSetting';
import RightPopup from '../../components/common/RightPopup';
import CameraUploadIdentificationScreen from './CameraUploadIdentificationScreen';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { isMobile } from 'react-device-detect';
import PopupMedia from '../../components/common/PopupMedia';

const windowWidth = Dimensions.get('window').width;

export default class IdentificationScreen extends Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      ageVerficationStatus: 0,
      image:''
    };
    this.PopupRef = React.createRef();
    this.PopupMedia = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.navigation = this.props.navigation;
      this.setHeader();
      this.getDataFromApi();
    }
  }

  getDataFromApi = async ()=>{
    try{
      const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
      //check response
      if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;

      if(checkMemberStatResponse!=undefined && checkMemberStatResponse?.status==1){
        const ageVerficationStatus = checkMemberStatResponse?.ageVerficationStatus;
        this.setState({
          ageVerficationStatus: ageVerficationStatus
        });
        setTimeout(() => {
          this.checkAgeVerification(ageVerficationStatus);
        }, 1000);
        
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false });
    }
  }

  checkAgeVerification = (ageVerficationStatus)=>{
    let errorMsg;
    if(ageVerficationStatus==1) {
      errorMsg = '申請中です。完了までしばらくお待ちください';
    }else if(ageVerficationStatus==2) {
      errorMsg = '年齢確認済です。引き続きPappyをお楽しみください';
    }
    if(errorMsg){
      this.errorPopup?.showPopup();
      this.setState({ errorMsg: errorMsg });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setHeader = () =>{
    CommonFunction.setHeaderSetting(this.navigation, '年齢確認', false);
  }

  getRightPopupRef = ()=>{
    return this.rightPopupRef;
  }

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        if(uri){          
          this.setState({ image: uri });
          const rightPopupRef = this.getRightPopupRef();
          rightPopupRef?.ShowPopUp();
        }
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if(Platform.OS==='ios'){
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        if(uri){          
          this.setState({ image: uri });
          const rightPopupRef = this.getRightPopupRef();
          rightPopupRef?.ShowPopUp();
        }
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  uploadCallback = ()=>{
    const rightPopupRef = this.getRightPopupRef();
    rightPopupRef?.ShowPopUp(false);
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 2];
      if(prevRoute?.name=='RecommendIineStack'){
        this.navigation?.pop(1);
        return;
      }
    }
    this.navigation?.navigate('Home', {screen:'MyPageStack', params: {screen: 'MyPage'}});
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            <View style={styles.ImageBackgroundContainer}>
              <ImageBackground source={require('../../assets/images/common/img-top.jpg')} resizeMode="cover" style={styles.ImageBackground}>
                <View style={styles.ImageBackgroundInside}>
                  <Text style={styles.titleImageBGTop}>メッセージをはじめるには {"\n"}「年齢確認」が必要です</Text>
                  <Text style={styles.txtImageBGTop}>※男性は有料会員になる必要があります</Text>
                </View>
              </ImageBackground>
            </View>
            <View style={styles.bloc1}>
              <View style={styles.insideBloc1}>
                <Text style={styles.textBloc1}>「Pappy」では、みなさまに安心・安全にご利用いただけるように、不適切なユーザーの排除、安全性の担保と向上の為、年齢確認を必須としております。</Text>
              </View>
            </View>
            <View style={styles.bloc3}>
              <View style={styles.bgInsideBloc3}>
                <View style={styles.insideBloc3}>
                  <Text style={styles.textTitle3}>年齢確認に必要なもの</Text>
                  <View style={styles.bgBlueInsideBloc3}><Text style={styles.textBgBlueInside3}>以下のいずれか1点</Text></View>
                </View>
                <View style={styles.rowImgBloc3}>
                  <View style={styles.rowImgInsideBloc3}>
                    <Image style={{width:76, height:67}} source={require('../../assets/images/common/icon-card1.png')} resizeMode="cover"/>
                    <View style={styles.rowTxtInsideBloc3}>
                      <Text style={styles.textRowTxtInsideBloc3}>運転免許証</Text>
                    </View>
                  </View>
                  <View style={[styles.rowImgInsideBloc3, {marginLeft:5, marginRight:5}]}>
                    <Image style={{width:101, height:67}} source={require('../../assets/images/common/icon-passport.png')}/>
                    <View style={styles.rowTxtInsideBloc3}>
                      <Text style={styles.textRowTxtInsideBloc3}>パスポート</Text>
                    </View>
                  </View>
                  <View style={styles.rowImgInsideBloc3}>
                    <Image style={{width:76, height:67}} source={require('../../assets/images/common/icon-card2.png')}/>
                    <View style={styles.rowTxtInsideBloc3}>
                      <Text style={styles.textRowTxtInsideBloc3}>健康保険証</Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.insideBloc3,{marginBottom:5}]}>
                  <Text style={[styles.textBloc3, {color:'#ff8356'}]}>その他の書類</Text>
                </View>
                <View style={styles.insideBloc3}>
                  <Text style={styles.textBloc3}>住民基本台帳カード/年金手帳/{"\n"}身体障害者手帳/在留カード/マイナンバーカード</Text>
                </View>
              </View>
              <View style={styles.insideBloc3}>
                <Text style={[styles.textTitle3, {marginBottom:20}]}>個人情報保護の徹底</Text>
                <View style={styles.insideBloc3Left}>
                  <Text style={[styles.textBloc3, {textAlign:'left'}]}>お客様の個人情報は、個人情報保護法に基づき、厳正な管理、適正な取り扱いを行います。{"\n"}
                運営事務局似て確認後、一定の期間保存した後責任をもって破棄いたします。</Text>
                </View>                
              </View>
              <View style={styles.rowImgBloc4}>
                <View style={styles.rowImgInsideBloc4}>
                  <Image style={{width:60, height:60}} source={require('../../assets/images/common/icon-1.png')}/>
                  <View style={styles.bgRowTxtInsideBloc4New}>
                    <Image style={styles.imgTxtInsideBloc4New} source={require('../../assets/images/text-1.png')}/>
                  </View>
                </View>
                <View style={styles.rowImgInsideBloc4}>
                  <Image style={{width:60, height:60}} source={require('../../assets/images/common/icon-2.png')}/>
                  <View style={styles.bgRowTxtInsideBloc4New}>
                    <Image style={styles.imgTxtInsideBloc4New} source={require('../../assets/images/text-2.png')}/>
                  </View>
                </View>
                <View style={styles.rowImgInsideBloc4}>
                  <Image style={{width:60, height:60}} source={require('../../assets/images/common/icon-3.png')}/>
                  <View style={styles.bgRowTxtInsideBloc4New}>
                    <Image style={styles.imgTxtInsideBloc4New} source={require('../../assets/images/text-3.png')}/>
                  </View>
                </View>
              </View>
              <View style={styles.bloc5}>
                <View style={styles.titleBloc5}>
                  <Text style={styles.textTitleBloc5}>必要となる情報</Text>
                </View>
                <View>
                  <Image style={styles.imgCardInfo} source={require('../../assets/images/common/card-info.png')} resizeMode="cover"/>
                </View>
                <View style={{marginTop:10, marginBottom:10, padding:12, textAlign:'left'}}>
                  <Text style={styles.textBloc5}>名前・顔写真・住所等の個人情報は必要ありません。{"\n"}上記サンプルのように、必須項目以外は隠して頂いて問題ありません。</Text>
                </View>
              </View>

              <View style={styles.btnArea}>
                {this.state.ageVerficationStatus==0?
                <TouchableOpacity style={styles.btnBlue} onPress={()=>{      
                  if(Platform.OS==='web'){
                    this._pickImage();
                  }else{
                    //this._pickCamera();
                    this.PopupMediaRef.show(true);
                  }
                }}>
                  <Text style={styles.txtBtnBlue}>
                    証明書を選択する
                  </Text>
                </TouchableOpacity>
                :
                <View style={[styles.btnBlue,{opacity:0.4}]}>
                  <Text style={styles.txtBtnBlue}>
                    証明書を選択する
                  </Text>
                </View>
                }
              </View>
            </View>
        </View>
      </ScrollView>
        
      <Popup ref={ref=>this.errorPopup=ref} modal={true}>
        <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
            this.errorPopup?.hidePopup();
        }} />
        <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
          <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
            <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',textAlign:'center'}}>{this.state.errorMsg}</Text>
          </View>
          <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center'}} onPress={()=>{
            this.errorPopup?.hidePopup();
            }}>
            <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7', textAlign:'center'}}>OK</Text>
          </TouchableOpacity>
        </View>
      </Popup>

      <PopupAppSetting ref={ref=>this.PopupRef=ref} />
      <RightPopup 
        ref={ref=>this.rightPopupRef=ref} 
        component={<CameraUploadIdentificationScreen {...this.props} image={this.state.image} getRightPopupRef={this.getRightPopupRef} callback={this.uploadCallback} />}>
      </RightPopup>
      <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:0
  },
  ImageBackgroundContainer:{
    width:'100%',
    height:180,
  },
  ImageBackground:{
    flex: 1, 
    flexDirection:'column', 
    justifyContent:'center', 
    width:'100%',
    height:180,
  },
  ImageBackgroundInside: {
    width:'100%',
    textAlign:'center',
  },
  titleImageBGTop: {
    marginBottom:10,
    fontSize:18,
    fontWeight:'bold',
    lineHeight:29,
    color:'#ffffff',
    textShadowColor:'rgba(0,0,0,0.80)',
    textShadowOffset:{width: 1,height:1},
    textShadowRadius:5,
    textAlign:'center',
  },
  txtImageBGTop: {
    fontSize:10,
    lineHeight:16,
    fontWeight:'normal',
    color:'#ffffff',
    textShadowColor:'rgba(0,0,0,0.80)',
    textShadowOffset:{width: 1,height:1},
    textShadowRadius:5,
    textAlign:'center',
  },
  bloc1: {
    padding:20,
  },
  insideBloc1: {
    paddingTop:20,
    paddingBottom:20,
    paddingRight:15,
    paddingLeft:15,
    backgroundColor:'#ffffff',
    textAlign:'center',
  },
  textBloc1: {
    fontSize:12,
    fontWeight:'normal',
    lineHeight:21,
    color:'#555555',
    textAlign:'center',
  },
  bloc2: {
    marginBottom:20,
    paddingTop:windowWidth<375? 15 : 35,    
    paddingBottom:windowWidth<375? 15 : 35,    
    paddingRight:windowWidth<375? 10 : 35,    
    paddingLeft:windowWidth<375? 10 : 35,
    backgroundColor:'#ffffff',
  },
  insideBloc2: {
    textAlign:'center',
  },
  titleBloc2: {
    marginBottom:20,
    fontSize:20,
    fontWeight:'bold',
    lineHeight:30,
    color:'#494949',
  },
  textBloc2: {
    fontSize:12,
    fontWeight:'normal',
    lineHeight:19,
    color:'#555555',
  },
  bgInsideBloc2: {
    marginTop:25,
    marginBottom:10,
    padding:18,
    backgroundColor:'#eef1f2',
    borderRadius:10,
    textAlign:'center',
  },
  textDateBloc2: {
    marginTop:10,
    fontSize:20,
    fontWeight:'bold',
    lineHeight:20,
    color:'#494949',
  },
  bloc3: {
    paddingTop:45,
    paddingBottom:45,
    paddingRight:windowWidth<375? 10 : 35,
    paddingLeft:windowWidth<375? 10 : 35,
    backgroundColor:'#ffffff',
  },
  bgInsideBloc3: {
    marginBottom:30,
    paddingTop:20,
    paddingBottom:20,
    paddingRight:15,
    paddingLeft:15,
    backgroundColor:'#eef1f2',
    borderRadius:10,
    textAlign:'center',
  },
  insideBloc3: {
    textAlign:'center',
  },
  textTitle3: {
    fontSize:20,
    fontWeight:'bold',
    lineHeight:30,
    color:'#494949',
    textAlign:'center',
  },
  bgBlueInsideBloc3: {
    marginTop:20,
    marginBottom:20,
    marginRight:'auto',
    marginLeft:'auto',
    width:'60%',
    height:30,
    backgroundColor:'#61bdd8',
    borderRadius:5,
    textAlign:'center',
  },
  textBgBlueInside3: {
    fontSize:15,
    fontWeight:'bold',
    lineHeight:30,
    color:'#fff',
    textAlign:'center',
  },
  rowImgBloc3: {
    marginBottom:20,
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  rowImgInsideBloc3: {
    //flex:'auto',
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  rowTxtInsideBloc3: {
    marginTop:10,
    width:'100%'
  },
  textRowTxtInsideBloc3: {
    fontSize:13,
    fontWeight:'bold',
    lineHeight:18,
    color:'#494949',
    width:'100%',
    textAlign:'center'
  },
  insideBloc3Left: {
    marginBottom:10,
    textAlign:'left',
  },
  textBloc3: {
    fontSize:12,
    fontWeight:'normal',
    lineHeight:19,
    color:'#494949',
    textAlign:'center'
  },
  rowImgBloc4: {
    marginTop:10,
    marginBottom:60,
    flex:1,
    flexDirection:'row',
  },
  rowImgInsideBloc4: {
    flex:1,
    textAlign:'center',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    paddingLeft:windowWidth<375? 5 : 5,
    paddingRight:windowWidth<375? 5 : 5,
  },
  bgRowTxtInsideBloc4: {
    flexGrow:1,
    marginTop:10,
    paddingRight:5,
    paddingLeft:5,
    width:'95%',
    minHeight:55,
    backgroundColor:'#eef1f2',
    textAlign:'center',
    justifyContent:'center',
  },
  smallTextRowTxtInsideBloc4: {
    fontSize: windowWidth<360? 6 : 6,
    fontWeight:'normal',
    lineHeight:9,
    color:'#555',
  },
  bgRowTxtInsideBloc4New: {
    flexGrow:1,
    marginTop:10,
    padding:windowWidth<375? 3 : 3,
    minHeight:55,
    backgroundColor:'#eef1f2',
    textAlign:'center',
    justifyContent:'center',
  },
  imgTxtInsideBloc4New: {
    width:windowWidth<375? 89 : 89,
    height:windowWidth<375? 27 : 27,  
  },
  bloc5: {
    marginBottom:50,
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:10,
    borderColor:'#eef1f2',
    borderWidth:3,
  },
  titleBloc5: {
    paddingTop:30,
    paddingBottom:30,
    textAlign:'center',
  },
  textTitleBloc5: {
    fontSize:20,
    fontWeight:'bold',
    lineHeight:30,
    color:'#494949',
    textAlign:'center',
  },
  textBloc5: {
    fontSize:11,
    fontWeight:'normal',
    lineHeight:18,
    color:'#494949',    
  },
  imgCardInfo: {
    width:windowWidth<375? 294 : 299,
    height:windowWidth<375? 164 : 167,
  },  
  btnArea: {
    paddingRight:15,
    paddingLeft:15,
  },
  btnBlue: {
    height:48,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    shadowColor:"#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
    textAlign:'center',
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
});
