import React, { PureComponent } from 'react';
import { Animated, View, StyleSheet,Easing, Platform, Image }  from 'react-native';
import { EvilIcons, Feather } from '@expo/vector-icons';

const loadingIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABMCAMAAAAfi8onAAAAflBMVEUAAABoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGgZsXMbAAAAKXRSTlMAMFEDCxXg+u7mgiH3HBHDmEhDPijYvrWuiFmdknHx1GZeNfnPqHlsxyHOk/8AAAIkSURBVFjD7VjZloIwDC2LILsCbrjhuPb/f3CI4AkFhTjkwTPH+0TT9JYmTZtGUBA7vivYYEkpUzY2WUD/DDZz1M9mBkSy/CjXfWy6LxcGiW1cjM262ULQiclsltnJNpFkNhdU3S42zS4Ee4PmBNhevtfBNgfBVNCQgfLyNZuO/RREaBZkU//96JHZNIl2QTbFrisaE/rs+pwt8IuWYwo6RrBLxuFTtjO0DuIdbGHI5BlbAo2beAvGrBhja2024wSNn56lpQ3BFAbN22w5fK+bo1UH57aMGvMtaxTwPx4G6E49Y8KNrR4Bzn1GRck7QrhW3+e5XtsdW8Um1zHIEoGYScBuW59hBSKcAGNqVtdK9vIOrW4lv5TNpmq4RqKBqxqgHtjj4X5EcK7EixRl7iUUTeibGOdz8ScaSE5lj72ibvPMkmigJox8XPY6B4MSy9FjkWjblqvvuP30ht6k4oq010rpvFLSe8icahlZt55ulc7o1jpIgO/2mti8gKM2L/sx9JekAzNYO4tRj87FiWLxxafCSDUV5h/HhbB1HNmAnZH2kdUa58K93oJFYVvJNgJxY2TzRLzjWWmVTZgakxdG4otPxYDTku0k579l+G9A/ttZzRzEgMyBJathzrjYs0H+TJU/iz4Mz/AZXx/p0JcR/6uN/0XJ/9rlf4nzVwn4Kxj81RX+yg9/VYq/YpbbnNU8EQaUSuN/qKm2sMdDiAGJdaTF5S8flJQa9sQ7KwAAAABJRU5ErkJggg==';

export default class ActivityIndicator extends PureComponent {
  _isMounted = false;
  constructor(props){
    super(props);
    this.spinValue = new Animated.Value(0);
    this.size = this.props?.size;
    if(!this.size){
      this.size = Platform.OS=='web'?28:32;
    }
    this.style = this.props.style;
    this.color = this.props.color?this.props.color:'rgba(0,0,0,0.8)';

    this.spinRotate = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg']
    });

    this.useIcon = false;
    if(this.props?.useIcon===true) this.useIcon = true;
  }
  
  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      this.spin();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  spin = () => {
    if(this._isMounted){
      this.spinValue.setValue(0);
      Animated.timing(
        this.spinValue,
        {
          toValue: 1,
          duration: 2500,
          easing: Easing.linear,
          useNativeDriver: true
        }
      ).start(() => this.spin());
    }
  }

  render(){
    return (
      <View style={[styles.container,this.style]}>
        <Animated.View style={{transform: [{rotate: this.spinRotate}]}}>
        {/** <EvilIcons name="spinner" size={this.size} color={this.color} />*/}
        {this.useIcon===true?
        <Feather name="loader" size={this.size} color={this.color} />
        :
        <Image source={{ uri: loadingIcon }} resizeMode={'cover'} style={{width:this.size,height:this.size}} />
        }
        </Animated.View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%'
  }
});