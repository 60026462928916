import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import HeadStyles from '../../styles/HeadStyles';
import LogoNoMargin from './LogoNoMargin';
import Logo from './Logo';

import { Entypo } from '@expo/vector-icons';
import CommonFunction from '../../utils/CommonFunction';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {    
		// Update state so the next render will show the fallback UI.    
		return { hasError: true };  
	}

	componentDidCatch(error, errorInfo) {    
		// You can also log the error to an error reporting service  
	}

	setHeader(){
		this.props?.navigation?.setOptions({
			headerShown: true,
			tabBarStyle: { display: 'flex' },
			header: () => (
				<View style={HeadStyles.customHeader}>
					<View style={[HeadStyles.touchIcBack,{flexDirection:'row',left:16}]}>
						<TouchableOpacity onPress={() => {
						if(this.props?.navigation?.canGoBack()){
							this.props.navigation?.goBack();
						}else{
							this.props.navigation?.replace('NonAuth', { screen:'Index'});
						}}}>
							<Entypo name={'chevron-thin-left'} style={HeadStyles.icBack2}/>
						</TouchableOpacity>
						<LogoNoMargin/>
					</View>
					<View style={{flex:1, alignItems:'stretch'}}><Text style={[HeadStyles.headerTitlePage]}>エラー</Text></View>
				</View>
			)
		});
	}

	render() {
		if (this.state.hasError) {
			this.setHeader();
			return (
				<View style={styles.container}>
					{Platform.OS === 'web' && CommonFunction.isAndroidUAOnly() ?
					<View style={[styles.msgBox, {width:'85%', alignSelf:'center',justifyContent:'center'}]}>
						<Text style={styles.msgTxt}>
						pappyでエラーが発生しました。{"\n"}
						推奨ブラウザのchromeブラウザのご利用で改善する場合がございます。{"\n"}{"\n"}
						chromeブラウザをご利用いただいた上で、{"\n"}
						時間を置いても改善しない場合、{"\n"}
						お手数ですがサポートへお問い合わせください。
						</Text>
					</View>
					:
					<View style={styles.msgBox}>
						<Text style={styles.msgTxt}>
						pappyでエラーが発生しました。{"\n"}
						時間を置いても改善しない場合、お手数ですがサポート{"\n"}
						へお問い合わせください。
						</Text>
					</View>
				}
			</View>
			);    
		}
		
		return this.props.children; 
	}
}

const styles = StyleSheet.create({
	container: {
		flex:1,
		backgroundColor:'#ffffff',
		...Platform.select({
			ios: {
				paddingTop: 70
			},
			android: {
				paddingTop: 70
			},
			default:{
				paddingTop:44
			}
		}),
		paddingBottom:25,
	},
	msgBox: {
		paddingTop:20,
		alignItems:'center', 
		justifyContent:'center', 
		alignContent:'center', 
		width:'100%'
	},
	msgTxt:{
		textAlign:'center', 
		fontSize:13, 
		lineHeight:18, 
		color:'#9aa5a7'
	}
});