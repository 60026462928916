import React,{ useState } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Platform } from 'react-native';
import Constants from '../../constants/Constants';
import OnlineStatus from '../common/OnlineStatus';

const isWeb = Platform.OS === 'web';

export default function SuggestUserColumnFormat(props) {
  let {column, name, img, age, area, tweet, income, online, sex, memberId, onPressIine, goodFlag, introduction } = props;
  const [goodFlg, setGoodFlg] = useState(goodFlag);
  let thumbnailHeight = 130;
 
  if(column===Constants.numColumnsMale) thumbnailHeight = 200;
  if(Constants.NOTSETTEXT.indexOf(area)!=-1) area = '';

  tweet = (tweet!='')?tweet:introduction;
  if(sex!=undefined && sex==Constants.MEN){
    if(Constants.NOTSETTEXT.indexOf(income)==-1) {
      if(isWeb) tweet = income;
    }
  }

  return (
    <TouchableOpacity style={styles.ThumbnailProfile} onPress={props.onPress}>
      <View>
        <View>
          <Image source={{uri : img}} resizeMode={'cover'} style= {[styles.ThumbnailProfileImg,{ height:thumbnailHeight}]} />
          {/** 
          {sex==Constants.MEN &&
          (goodFlg==0 ?
            <TouchableOpacity style={styles.favorite} onPress={async()=>{
              if(onPressIine!=undefined){
                const returnGoodFlg = await onPressIine(memberId);
                if(returnGoodFlg!='notactivated'){
                  setGoodFlg(returnGoodFlg);
                }
              }
            }}>
            <Image style={styles.favoriteIcon} source={require('../../assets/images/favorite.png')}/>
          </TouchableOpacity>
          :
          <View style={[styles.chat]}>
            <Image style={styles.chat_icon} source={require('../../assets/images/ic-chat.png')}/>
          </View>
          )}
          */}
        </View>

        <View style={styles.profile}>
          <View style={styles.profileItem}>
            <View style={styles.descItem}>
              <OnlineStatus online={online} />
              <Text style={styles.fontMedium}>{age} {area}</Text>
            </View>
          </View>
          <Text numberOfLines={1} style={styles.txtDescProfile}>{tweet}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  ThumbnailProfile: {
    flexDirection: 'column',
    padding:0,
  },
  profile: {
    paddingRight: 5,
    paddingBottom: 5,
    marginLeft: 3,
    marginTop: 8,
  },
  fontMedium: {
    fontSize: 14,
    alignSelf: 'flex-start',
    marginTop: 1,
    fontWeight:"600",
    color: '#484848',
    letterSpacing:-.2,
  },
  favorite:{
    backgroundColor:'#eef1f2', 
    position:'absolute', 
    right:-4, 
    bottom:-4, 
    width:36, 
    height:36, 
    borderRadius: 18, 
    borderWidth:1,
    borderColor: '#eef1f2',
    alignContent:'center', 
    justifyContent:'center'
  },
  favoriteIcon:{
    width:16,
    height:16,
    alignSelf:'center'
  },
  ThumbnailProfileImg:{
    width: '100%', 
    height: 130, 
    borderRadius: 10
  },
  profileItem: {
    flex:1,
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'stretch',
  },
  descItem: {
    flex:3,
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  txtDescProfile: {
    paddingLeft:12,
    fontSize:12,
    lineHeight:18,
    color:'#484848',
    letterSpacing:-.2,
  },
  chat:{
    backgroundColor:'#ff8d63', 
    position:'absolute', 
    right:-4, 
    bottom:-4, 
    width:36, 
    height:36, 
    borderRadius: 18, 
    borderWidth:1,
    borderColor: '#ff8d63',
    alignContent:'center', 
    justifyContent:'center'
  },
  chat_icon:{
    width:20,
    height:20,
    alignSelf:'center'
  },
});
