import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, FlatList, View, ScrollView, TouchableOpacity, ImageBackground, Dimensions, Alert
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import ActivityIndicator from '../components/common/ActivityIndicator';
import Payment15Api from '../services/api/Payment15Api';
import { AppContext } from '../AppContext';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import ConfirmPopupStyle from '../styles/ConfirmPopupStyles';
import SMSWrapper from "../components/common/SMSWrapper";
import AgeWrapper from "../components/common/AgeWrapper";
import ListStyles from '../styles/ListStyles';
import ErrorCode from '../constants/ErrorCode';
import PopupWrapper from '../components/common/PopupWrapper';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';
import PurchaseFunctionIos from '../utils/PurchaseFunction_ios';
import Payment15Api_ios from '../services/api/Payment15Api_ios';
import { isSystemPayment } from '../modules/purchase';
import { getProductDetail } from '../modules/purchase';
import HeadStyles from '../styles/HeadStyles';
import LogoNoMargin from '../components/common/LogoNoMargin';
import TitleSetting from '../components/common/TitleSetting';
import { Entypo } from '@expo/vector-icons';
import { addGetProductItemListener } from '../modules/purchase';
import { addCompPaymentEventListener } from '../modules/purchase';
import { addPurchaseErrorEventListener } from '../modules/purchase';
import { addGetRestoreItemEventListener } from '../modules/purchase';
import { removeGetProductItemListener } from '../modules/purchase';
import { removeCompPaymentEventListener } from '../modules/purchase';
import { removePurchaseErrorEventListener } from '../modules/purchase';
import { removeGetRestoreItemEventListener } from '../modules/purchase';
import ApiConfig from '../constants/ApiConfig';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PurchaseScreen_ios extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      data: []
    };
    this.paymentFlg = false;
    // setting previous route without bottom tab
    this.prevRoute = ['ChatRoom'];
    this.popup = React.createRef();
    this.loadingPopup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {

      // イベント受信時のハンドラー登録
      addGetProductItemListener(this.handleGetProductEventSubsc);
      addCompPaymentEventListener(this.handleCompPaymentEventSubsc);
      addPurchaseErrorEventListener(this.handlePurchaseErrorEventSubsc);
      addGetRestoreItemEventListener(this.handleGetRestoreItemEventSubsc);

      this.navigation = this.props.navigation;
      this.setHeader();
      // 購入可能なプロダクトIDを取得する
      this.getPlatformProductData();
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.hideBottomTab(this.navigation);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
    this.checkBottomTab();
    // イベント受信解除
    removeGetProductItemListener();
    removeCompPaymentEventListener();
    removePurchaseErrorEventListener();
    removeGetRestoreItemEventListener();
  }  

  checkBottomTab=()=>{
    let showBottomTab = true;
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
    }
    if(showBottomTab) {
      CommonFunction.showBottomTab(this.navigation);
    }
  }

  //　購入アイテム一覧を表示するため、購入可能なプロダクトIDを要求
  getPlatformProductData = async ()=>{
    if(!this.state.isRefreshing){
      this.setState({ loading: true });
    }
    // 購入可能かチェック(端末の課金有効設定）
    if(isSystemPayment()) {
      // 販売可能なプロダクトIDをAppleに問い合わせ
      let envFlg = false;
      if (ApiConfig.mode==='development') {
        envFlg = true;
      }
      await PurchaseFunctionIos.checkSubscProductIds(envFlg);
    } else {
      PopupWrapper.showMessage('購入が許可されていません。端末の課金許可設定を確認してください','warning',()=>{
        CommonFunction.hideOverlay();
      });
    }
  }

  // 画面ヘッダーの設定
  setHeader = () =>{
    this.title = "有料会員登録";
    this.navigation.setOptions({
      header: () => (
        <View style={HeadStyles.customHeader}>
          <View style={[HeadStyles.touchIcBack, {flexDirection:'row',left:16}]}>
            <TouchableOpacity onPress={async()=> {
              if(this.paymentFlg===false){
                this.navigation.goBack();
              }
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyles.icBack2}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch'}}><TitleSetting title={this.title} numberOfLines={1} /></View>
        </View>
      )
    });
  }
  
  // 表示アイテムの設定
  renderItem = ({item, index}) => {
    let bannerStyle = styles.itemBlogRatePlan;
    let env = false;
    if (ApiConfig.mode==='development') {
      env = true;
    }
    let banner  = getProductDetail(item.productId, env);
    // console.log("環境",ApiConfig.mode);
    if(index == 0) {
      bannerStyle = styles.itemBlogRatePlanOther;    
    }
    const key = Date.now();
    
    if(banner){
      return (
        <View style={{flex:1, width:'100%', justifyContent:'center',alignSelf:'center',alignItems:'center',alignContent:'center'}}>
          <TouchableOpacity style={{width:'100%'}} onPress={async()=>{
            this.handleOnPress(item);
          }}>
            <Image style={bannerStyle} source={{uri:banner+"?"+key}}/>
          </TouchableOpacity>  
        </View>        
      );
    }
  }

  noItemDisplay = () => {
    return <></>;
  }

  // プロダクトIDの取得完了
  handleGetProductEventSubsc = (eventData) => {
    this.setState({loading: false, isRefreshing: false});
    let pdata = JSON.parse(eventData.productItems); 
    console.log("pdata",pdata);
    this.compGetProductIdForAppleStore(pdata);
  }

  // Appleストアの購入処理完了
  handleCompPaymentEventSubsc = async(eventData) => {
    let transactionId = eventData.transactionId;
    console.log("transactionId",transactionId);
    await this.subscriptionAppStoreAction(transactionId);
  }

  // 課金操作でのエラー発生
  handlePurchaseErrorEventSubsc = (eventData) => {
    this.paymentFlg = false;
    let message = eventData.message;
    this.loadingPopup.hidePopup();
    console.log("message",message);
    PopupWrapper.showMessage(message,'warning',()=>{
      CommonFunction.hideOverlay();
    });
  }
  
  // リストア；課金情報がありでリストアAPIを実施
  handleGetRestoreItemEventSubsc = (eventData) => {
    let transactionId = eventData.transactionId;
    this.restoreAppStoreAction(transactionId);
  }

  // 取得したプロダクトIDの一覧から購入アイテム一覧を表示する
  compGetProductIdForAppleStore = (pdata) => {
    this.setState({
      loading: false,
      isRefreshing: false,
      data: pdata,
    });
  }

  // 購入アイテム選択時
  handleOnPress = async(item)=>{
    if(this.paymentFlg==true) {
      return;
    }
    // 継続課金が購入可能かCheckSubscriptionStatを実施
    const checkSubscriptionStatResopnse = await Payment15Api.CheckSubscriptionStat(0);
    if(!CommonFunction.checkResponseStatus(checkSubscriptionStatResopnse)) return;

    if(checkSubscriptionStatResopnse?.status==1){
      // アプリのサブスク購入処理(初回or再購入)
      await this.paymentAction(item)
    }else{
      // 継続課金不可のエラーハンドリング（SMS認証と年齢認証のエラー）
      if(CommonFunction.checkIsInvalidErrorCode(checkSubscriptionStatResopnse)){
        if(checkSubscriptionStatResopnse.response!=undefined){
          const res = checkSubscriptionStatResopnse.response;
          const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
          const errorMessage = res?.errorMessage;
          if(errorCode==ErrorCode.CheckSubscriptionStat.Activated){
            SMSWrapper.showSMS(this.navigation);
          }else if(errorCode==ErrorCode.CheckSubscriptionStat.AgeVerfication){
            AgeWrapper.showAge(this.navigation);
          }else{
            if(errorMessage) PopupWrapper.showMessage(errorMessage, 'warning');
          }
        }
      }
    }
  }

  // プラン購入ボタンタップ時の処理
  paymentAction = async (item)=>{
    this.paymentFlg = true;
    this.loadingPopup.showPopup();
    // 購入可能かチェック(端末の課金有効設定）
    if(isSystemPayment()) {
    } else {
      PopupWrapper.showMessage('購入が許可されていません。端末の課金許可設定を確認してください','warning',()=>{
        CommonFunction.hideOverlay();
      });
      this.paymentFlg = false;
      this.loadingPopup.hidePopup();
      return;
    }
    let subsc = PurchaseFunctionIos.subscPurchaseAction(item.productId);    
  }

  // サブスク登録APIを送信する
  subscriptionAppStoreAction = async (transactionId)=>{
    try{
      const params = {transactionId: transactionId, errorFlg: 0};
      const response = await Payment15Api_ios.SubscriptionAppStore(params);
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(checkUndefined(response) && response?.status==1){
        // サブスク登録成功
        Alert.alert('', 'プラン購入が完了しました', [
          {
            text: 'OK',
          },
        ]);
      } else {
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.paymentFlg = false;
      this.loadingPopup.hidePopup();
    }
  }

  // iOSのリストア処理を実施する
  handleOnAppleReStore = async()=>{
    if(this.paymentFlg==true) {
      return;
    }
    this.paymentFlg = true;
    this.loadingPopup.showPopup();
    PurchaseFunctionIos.restoreTransactionAction();
  }

  // リストアAPIを送信する
  restoreAppStoreAction = async (transactionId)=>{
    try{
      const params = {transactionId: transactionId, errorFlg: 0};
      const response = await Payment15Api_ios.RestoreAppStore(params);
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(checkUndefined(response) && response?.status==1){
        // リストア成功
        Alert.alert('', 'リストア処理が完了しました', [
          {
            text: 'OK',
          },
        ]);
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.paymentFlg = false;
      this.loadingPopup.hidePopup();
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    // リストアボタン
    const ButtonPaidMember = ()=>{
      return(
        <View style={{}}>
          <TouchableOpacity style={styles.btnBtnSendMsg} onPress={()=>this.handleOnAppleReStore()}>
            <Text style={styles.txtBtnSendMsg}>リストアする</Text>
          </TouchableOpacity>
          <Text style={styles.txtComment}>決済の内容がアプリに反映されない場合にリストアをお試しください。</Text>  
        </View>
      );
    }

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} 
        ref={scroller => { this.scroller = scroller; }} 
        scrollToOverflowEnabled={true}
        scrollIndicatorInsets={{ right: 1 }}>
          <View style={styles.titlePage}>
              <View style={styles.titlePageTxt}>
                <Text style={styles.txtTitlePageBig}>有料会員登録</Text><Text style={styles.txtTitlePage}>をすると•••</Text>
                </View>
            </View>
          <View style={styles.blogInner}>
            <ImageBackground source={require('../assets/images/purchase/img01.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>何人でもメッセージの{"\n"}やりとりが無制限で{"\n"}できるように！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img02.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>お相手の女性の{"\n"}人気度が{"\n"}一目でわかる！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img03.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>有料会員登録と{"\n"}同時にポイントが{"\n"}もらえる！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img04.png')} resizeMode="stretch" style={styles.imgBG3BannerLast}>
              <View style={styles.imgBG3BannerInsideLast}>
                <Text style={styles.txtBG3BannerLast}><Text style={styles.txtBG3BannerLast2}>6</Text><Text style={styles.txtBG3BannerLast2}>ヶ月以上</Text><Text style={styles.txtBG3BannerLast3}>の</Text>{"\n"}プランがおすすめ</Text>
              </View>
            </ImageBackground>
            <View style={styles.blogRatePlan}>
              <Text style={styles.txtBlogRatePlan}>料金プランを選択してください</Text>
            </View>
            <View style={styles.blogRatePlanInside}> 
              <FlatList
                scrollEnabled={false} 
                contentContainerStyle={{ flexGrow: 1 }}
                data={this.state.data?.list}
                renderItem={this.renderItem}
                ListEmptyComponent={this.noItemDisplay}
                keyExtractor={(item,index) => item.productId}
                inverted={true}
              />
            </View>
            <View style={styles.blogInside1}>
              <Text style={styles.txtBlogInside1Top}>\ 会員登録するとこんなメリットが！/</Text>
              <Text style={styles.txtBlogInside1Bottom}>有料会員ができること</Text>
            </View>
            <View style={styles.blogImgTitle01}>
              <Text style={styles.txtBlogInside1Title}>メッセージし放題！</Text>
            </View>
            <View style={styles.blogDesc01}>
              <Text style={styles.txtBlogDesc}><Text style={styles.txtOrange}>いつでも何人とでも</Text>女性との{"\n"}メッセージのやりとりが可能です。</Text>
            </View>
            <View style={styles.blogChat}>
              <Image style={styles.imgChat01} source={require('../assets/images/purchase/chat01.png')} resizeMode="contain"/>
              <Image style={styles.imgChat02} source={require('../assets/images/purchase/chat02.png')} resizeMode="contain"/>
            </View>
            <View style={styles.blogPlusMonthlyGifts}>
              <Image style={styles.imgBlogPlusMonthlyGifts} source={require('../assets/images/purchase/img09.png')} resizeMode="contain"/>
              <View style={styles.blogPlusMonthlyGiftsInside}>
                <Text style={styles.txtBlogPlusMonthlyGiftsInside}>毎月有料会員の方に50ポイントを{"\n"}プレゼント致します。</Text>
              </View>              
            </View>
            <View style={styles.blogRatePlan}>
              <Text style={styles.txtBlogRatePlan}>料金プランを選択してください</Text>
            </View>
            <View style={[styles.blogRatePlanInside, {paddingBottom:10}]}> 
              <FlatList
                scrollEnabled={false}
                contentContainerStyle={{ flexGrow: 1 }}
                data={this.state.data?.list}
                renderItem={this.renderItem}
                ListEmptyComponent={this.noItemDisplay}
                keyExtractor={(item,index) => item.productId}
                inverted={true}
              />
            </View>
            <View style={styles.blogNote}>
              <View style={styles.blogNoteTitle}><Text style={styles.txtBlogNoteTitle}>有料会員の注意事項</Text></View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>お申し込みを起算日とし、12か月後（12ヶ月プラン）・6か月後（6ヶ月プラン）・3か月後（3ヶ月プラン）・1か月後（1ヶ月プラン）に自動更新されます</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>自動更新は期限が切れる24時間以内に自動的に課金される仕組みです</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>自動更新をご希望ではない場合は期限が切れる24時間前までに解約を完了させてください</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>次回更新日は、
                  <TouchableOpacity onPress={()=>{
                    if(this.paymentFlg==false){
                      this.navigation.navigate('MemberStatus')
                    }}}>
                    <Text style={[styles.txtBlue, styles.txtUnderline]}>会員ステータス</Text>
                  </TouchableOpacity>
                  ページでご確認いただけます</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>更新日前に解約のお相手続きをされた場合、残りの期間については月割り・日割りでの返金はいたしません</Text>
                </View>
              </View>
            </View>
            <TouchableOpacity style={styles.blogFooter} onPress={()=>{
              if(this.paymentFlg==false){
                this.navigation.navigate('Terms')
              }}}>
              <Text style={[styles.txtSkyBlue, styles.txtUnderline, {textAlign:'center',}]}>利用規約</Text>
            </TouchableOpacity>
          </View>
          <ButtonPaidMember/>
        </ScrollView>
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {}} />
          <View style={ConfirmPopupStyle.popupContainer}>
            <View style={ConfirmPopupStyle.popupTextItem}>
              <Text style={ConfirmPopupStyle.popupText}>有料会員登録にはメールアドレスの登録が必要です。</Text>
            </View>
            <View style={ConfirmPopupStyle.btnContainer}>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderRight]} onPress={()=>{
                this.popup?.hidePopup();
                this.navigation.navigate('SettingStack',{screen:'MailInput'});
              }}>
                <Text style={ConfirmPopupStyle.btnText}>OK</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderLeft]} onPress={()=>{
                this.popup?.hidePopup();
              }}>
                <Text style={ConfirmPopupStyle.btnText}>今はしない</Text>
              </TouchableOpacity>  
            </View>              
        </View>
      </Popup>
      <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
        <View style={PortalStyle.overlay} />
        <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
      </Popup>
    </View>
  }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  titlePage:{
    paddingTop:15,
    paddingBottom:15,
    alignSelf:'center', 
  },
  titlePageTxt:{
    flexDirection:'row',
    textAlign:'center',
  },
  txtTitlePageBig:{
    fontSize:windowWidth<375? 23:27,
    fontWeight:'bold',
    lineHeight:38,
    color:'#5A70B6',
    letterSpacing:1,
  },
  txtTitlePage:{
    fontSize:windowWidth<375? 18:22,
    fontWeight:'bold',
    paddingTop:8,
    lineHeight:30,
    color:'#5A70B6',
    letterSpacing:1,
  },
  imgBG3Banner:{
    width:'100%',
    height:windowWidth<375? 138:161,
  },
  imgBG3BannerInside:{
    paddingTop:windowWidth<375? 65:75,
    paddingLeft:windowWidth<375? 52:55,
    paddingRight:windowWidth<375? 138:150,
    textAlign:'center',
  },
  txtBG3Banner:{
    fontSize:windowWidth<375? 12:15,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 19:23,
    color:'#555555',
    letterSpacing:1,
    textAlign:'center',
  },
  imgBG3BannerLast:{    
    marginTop:26,
    width:'100%',
    height:windowWidth<375? 121:141,
  },
  imgBG3BannerInsideLast:{
    paddingTop:windowWidth<375? 52:60,
    paddingLeft:20,
  },
  txtBG3BannerLast:{
    fontSize:windowWidth<375? 16:19,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#65bd39',
  },
  txtBG3BannerLast2:{
    fontSize:windowWidth<375? 23:27,
  },
  txtBG3BannerLast3:{
    fontSize:windowWidth<375? 20:23,
  },
  blogRatePlan: {
    marginTop:22,
    marginBottom:12,
    textAlign:'center',
  },
  txtBlogRatePlan: {
    fontSize:windowWidth<375? 16:20,
    fontWeight:'bold',
    lineHeight:22,
    color:'#555',
    textAlign:'center'
  },
  blogRatePlanInside:{
    backgroundColor:'#f0f5f6',
    paddingTop:30,
  },
  itemBlogRatePlanOther:{
    width:'100%',
    height:windowWidth<375? 68:80,
  },
  itemBlogRatePlan:{
    marginLeft:5,
    marginRight:5,
    width:'100%',
    height:windowWidth<375? 128:148,
  },
  blogInside1:{
    paddingTop:30,
    paddingBottom:15,
    paddingLeft:15,
    paddingRight:15,
    textAlign:'center',
  },
  txtBlogInside1Top:{
    fontSize:windowWidth<375? 12:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#445F8E',
    textAlign:'center',
  },
  txtBlogInside1Bottom:{
    fontSize:windowWidth<375? 23:27,
    fontWeight:'bold',
    lineHeight:38,
    color:'#5A70B6',
    letterSpacing:1,
    textAlign:'center',
  },
  blogImgTitle01:{
    marginBottom:10,
    textAlign:'center',
    backgroundColor:'#6FC5E5',
  },
  txtBlogInside1Title:{
    fontSize:windowWidth<375? 23:27,
    paddingTop:10,
    paddingBottom:10,
    fontWeight:'bold',
    lineHeight:38,
    color:'#FFFFFF',
    letterSpacing:1,
    textAlign:'center',
  },
  blogDesc01:{
    padding:15,
    textAlign:'center',
  },
  txtBlogDesc:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#555',
    textAlign:'center',
  },
  blogChat:{
    marginTop:20,
    marginBottom:42,
  },
  imgChat01:{
    width:'100%',
    height:windowWidth<375? 247:290,
  },
  imgChat02:{
    width:'100%',
    height:windowWidth<375? 220:258,
  },
  blogPlusMonthlyGifts:{
    marginTop:30,
    marginBottom:30,
    paddingTop:30,
    paddingBottom:25,
    backgroundColor:'#fffce1',
  },
  imgBlogPlusMonthlyGifts:{
    width:'100%',
    height:windowWidth<375? 136:160,
  },
  blogPlusMonthlyGiftsInside:{
    paddingTop:16,
    paddingLeft:16,
    paddingRight:16,
    textAlign:'center',
  },
  txtBlogPlusMonthlyGiftsInside:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#9a5f16',
    textAlign:'center',
  },
  blogNote:{
    paddingTop:30,
    paddingBottom:30,
    paddingLeft:16,
    paddingRight:16,
  },
  blogNoteTitle:{
    marginBottom:10,
  },
  txtBlogNoteTitle:{
    fontSize:windowWidth<375? 11:13,
    fontWeight:'bold',
    lineHeight:16,
    color:'#555',
  },
  blogNoteInside:{
    flexDirection:'row',
  },
  blogNoteInsideLeft:{
    flexDirection:'column',
    width:12,
  },
  blogNoteInsideRight:{
    flex:1,
    flexDirection:'column',
    paddingLeft:3,
  },
  txtBlogNote:{
    fontSize:windowWidth<375? 10:12,
    fontWeight:'normal',
    lineHeight:windowWidth<375? 17:20,
    color:'#555',
  },
  blogFooter:{
    paddingBottom:35,
    textAlign:'center',
  },
  txtOrange:{
    color:'#F19B38',
  },
  txtBlue:{
    color:'#004eae',
  },
  txtSkyBlue:{
    color:'#61bbd7',
  },
  txtUnderline:{
    textDecorationLine:'underline',
  },
  btnBtnSendMsg:{
    marginTop:10,
    marginBottom:12,
    marginLeft:'auto',
    marginRight:'auto',
    padding:15,
    width:'90%',
    height:50,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnSendMsg:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:20,
    color:'#fff',
    textAlign:'center',
  },
  txtComment: {
    fontSize:windowWidth<375? 10:11,
    fontWeight:'normal',
    lineHeight:16,
    color:'#9aa5a7',
    textAlign:'center',
  }
});

