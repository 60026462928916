import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import WebUtil from '../../utils/WebUtil';
import StorageHelper from '../../utils/StorageHelper2';
import AppUtil from '../../utils/AppUtil';

export default class Leave19Api extends ApiPappy{

  //1.1.1退会チェックAPI /Leave/Get/LeaveCheck/
  static async LeaveGetLeaveCheck(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Leave/Get/LeaveCheck/",
        {},
        true
      );
      if(__DEV__) console.log('/Leave/Get/LeaveCheck/',response);
  
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.ProfileGetMyProfile));
            console.log('/Leave/Get/LeaveCheck/', responseField?.join(','));
          }
          
          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }
  
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1.退会API 
  static async LeaveDeleteAccount(reasonCategory, reasonDetail, opinionImprovement, opinionFunction, opinionOther){
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Leave/Delete/Account/",
      { 
        reasonCategory: reasonCategory,
        reasonDetail: reasonDetail,
        opinionImprovement: opinionImprovement,
        opinionFunction: opinionFunction,
        opinionOther: opinionOther,
        web_uuid: webUUID,
        adid: adid
      },
      true
      );
      if(__DEV__) console.log('/Leave/Delete/Account/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;

          const removeKeys = ['origin'];
          StorageHelper?.removeItems(removeKeys);

          if(__DEV__) {
          // check response field
          const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
          console.log('/Leave/Delete/Account/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
  
}