import React, { useState } from 'react';
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Platform, Image, ScrollView } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import PopupWrapper from '../../components/common/PopupWrapper';
import ProfileSettingApi from '../../services/api/ProfileSetting12Api';
import Msg from '../../constants/option/Msg.json';
import CommonFunction from '../../utils/CommonFunction';
import OverlayWrapper from './OverlayWrapper';

const inputAccessoryViewID = "inputAccessoryView1";

const DisplayLineText = (props) => {
  let { values } = props;
  let text = values;

  if(Array.isArray(values)){
    let list = [];
    values.forEach((val,index)=>{
      list.push(val.value);
    });
    text = list.join(', ');
  }else if(typeof values === 'object'){
    if("id" in values) {
      if(values.id==0){
        text = '';
      }else{
        if("value" in values) 
          text = values.value;
      }
    }
  }

  if(text){
    return (
      <View style={styles.infoList}>
        <View style={styles.infoLabel}>
          <Text style={[styles.label,{color:'#5cc8ca'}]} numberOfLines={1}>{text}</Text>
        </View>
        <View style={styles.infoText}>
          <AntDesign name={'caretdown'} style={styles.downIcon} />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.infoList}>
      <View style={styles.infoLabel}>
        <Text style={[styles.label,{color:'#9aa5a7'}]}>未選択</Text>
      </View>
      <View style={styles.infoText}>
        <AntDesign name={'caretdown'} style={styles.downIcon} />
      </View>
    </View>
  );
}

const DisplayLineTextBg = (props) => {
  let { values } = props;
  let text = values;

  if(Array.isArray(values)){
    let list = [];
    values.forEach((val,index)=>{
      list.push(val.value);
    });
    text = list.join(', ');
  }else if(typeof values === 'object'){
    if("id" in values) {
      if(values.id==0){
        text = '';
      }else{
        if("value" in values) 
          text = values.value;
      }
    }
  }

  let placeHolder = '未選択';
  if(props.placeholder!=undefined){
    placeHolder = props.placeholder;
  }
  if(text){
    return (
      <View style={[styles.infoList, {backgroundColor:'#eef1f2', borderRadius:5,}]}>
        <View style={styles.infoLabel}>
          <Text style={[styles.label,{color:'#484848'}]} numberOfLines={1}>{text}</Text>
        </View>
        <View style={styles.infoText}>
          <AntDesign name={'caretdown'} style={styles.downIcon} />
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.infoList, {backgroundColor:'#eef1f2', borderRadius:5,}]}>
      <View style={styles.infoLabel}>
        <Text style={[styles.label,{color:'#9aa5a7'}]}>{placeHolder}</Text>
      </View>
      <View style={styles.infoText}>
        <AntDesign name={'caretdown'} style={styles.downIcon} />
      </View>
    </View>
  );
}

const CustomSelected = (props) => {
  let { values } = props;
  let text = values;

  if(Array.isArray(values)){
    let list = [];
    values.forEach((val,index)=>{
      list.push(val.value);
    });
    text = list.join(', ');
  }else if(typeof values === 'object'){
    if("id" in values) {
      if(values.id==0){
        text = '';
      }else{
        if("value" in values) 
          text = values.value;
      }
    }
  }
  
  if(text){
    text = text?.replace(/\\n/g,'\n');
    return (
      <View style={styles.infoList}>
        <View style={styles.infoLabel}>
          <Text style={[styles.label,{color:'#5cc8ca'}]}>{text}</Text>
        </View>
        <View style={styles.infoText}>
          <AntDesign name={'caretdown'} style={styles.downIcon} />
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.infoList, {backgroundColor:'#eef1f2', borderBottomColor:'#6ec1da', borderBottomWidth:2, borderTopLeftRadius:5, borderTopRightRadius:5,}]}>
      <View style={styles.infoLabel}>
        <Text style={[styles.label,{color:'#9aa5a7'}]}>選択してください</Text>
      </View>
      <View style={styles.infoText}>
        <AntDesign name={'caretdown'} style={styles.downIcon} />
      </View>
    </View>
  );
}

const DisplayLineTextRightIcon = (props) => {
  let { values, placeholder, numberOfLines } = props;
  if(numberOfLines==undefined) numberOfLines = 1;
  let text = values;
  //if(values!=undefined && values.value!=undefined) text = values.value;
  if(Array.isArray(values)){
    let list = [];
    values.forEach((val,index)=>{
      list.push(val.value);
    });
    text = list.join(', ');
  }else if(typeof values === 'object'){
    if("id" in values) {
      if(values.id==0){
        text = '';
      }else{
        if("value" in values) 
          text = values.value;
      }
    }
  }

  if(text){
    return (
      <View style={styles.infoList}>
        <View style={styles.infoLabel}>
          <Text style={[styles.label,{width:'90%', paddingRight:10}]}>{text}</Text>
        </View>
        <View style={styles.infoText}>
          <AntDesign name={'right'} style={styles.rightIcon} />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.infoList}>
      <View style={styles.infoLabel}>
        <Text style={[styles.label,{color:'#9aa5a7'}]}>{placeholder!=undefined ?placeholder:'未選択'}</Text>
      </View>
      <View style={styles.infoText}>
        <AntDesign name={'right'} style={styles.rightIcon} />
      </View>
    </View>
  );
}

const DisplayText = (props) => {
  let { values, icon, checkZero, unit } = props;
  let text = values;
  if(Array.isArray(values)){
    let list = [];
    values.forEach((val,index)=>{
      if(val.id!=0){
        list.push(val.value);
      }
    });
    text = list.join(', ');
  }else if(typeof values === 'object'){
    if("id" in values) {
      if(values.id==0){
        text = '';
      }else{
        if("value" in values) 
          text = values.value;
      }
    }
  }

  if(unit==undefined) unit = '';

  if(icon==undefined) icon = 'caretdown';
  if(checkZero==true && text==0){
    text = '';
  }

  if(text){
    return (
      <View style={styles.infoInput}>
        <View style={styles.infoText}>
          <View style={styles.infoTextContainer}>
            <View style={styles.infoTextInner}>
              <Text style={styles.txtSelect} numberOfLines={1}>{text}{unit}</Text>
              <AntDesign name={icon} style={icon=='caretdown'?styles.downIcon:styles.rightIcon} />
            </View>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.infoInput}>
      <View style={styles.infoText}>
        <View style={styles.infoTextContainer}>
          <View style={styles.infoTextInner}>
            <Text style={[styles.txtSelect,{color:'#9aa5a7'}]}>未選択</Text>
            <AntDesign name={icon} style={icon=='caretdown'?styles.downIcon:styles.rightIcon} /></View>
        </View>
      </View>  
    </View>    
  );
}

const WordOfDayInput = (props)=>{
  let { fieldName, onChange, onBlur, values, placeholder, maxInput, getRef, callback } = props;
  if(maxInput==undefined) maxInput = 0;
  if(values==undefined) values = '';

  const [text, onChangeText] = useState(values);

  const handleSubmit = async () => {
    try{
      OverlayWrapper.show(true);
      // call Api
      const responseProfilePutTweet = await ProfileSettingApi.ProfilePutTweet(text);
      const success = responseProfilePutTweet?.status;
      if(responseProfilePutTweet!=undefined && success==1){
        onChange(fieldName,text);
        PopupWrapper.showMessage(Msg.Update.Success,'check',()=>{
          OverlayWrapper.hide();
          if(getRef!=undefined){
            const parentRef = getRef(fieldName);
            if(parentRef) parentRef?.ShowPopUp(false);
          }
        });
        /*
        if(getRef!=undefined){
          setTimeout(()=>{
            PopupWrapper.hideMessage();
            const parentRef = getRef(fieldName);
            if(parentRef) parentRef.ShowPopUp(false);
          }, 1000);
        }
        */
        // callback function
        if(callback!=undefined){
          callback();
        }
      }else{ // error
        OverlayWrapper.hide();
      }
    }catch(e){
      OverlayWrapper.hide();
      if(__DEV__) console.log(e);
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollContainer}>
      <View style={[styles.BodyContent, {paddingTop:30, paddingRight:10, paddingBottom:5,}]}>
        <View style={{justifyContent:'flex-end',alignContent:'flex-end', alignItems:'flex-end'}}>
          {maxInput > 0 &&
          <Text style={{color:'#98a3a5'}}>※{maxInput}文字まで</Text>
          }
        </View>
      </View>
      <View style={styles.textView}>
        <TextInput 
          style={styles.textInput}
          value={text}
          //onChangeText={text=>{onChange(fieldName,text)}}
          onChangeText={onChangeText}
          //onBlur={() => onBlur(fieldName)}
          placeholder={placeholder}
          placeholderTextColor="#9aa5a7"
          inputAccessoryViewID={inputAccessoryViewID}
        />
        </View>
        </ScrollView>
        <View style={{padding:8, width:'80%', position:'absolute', justifyContent:"center", alignItems: 'center', alignSelf:'center', ...Platform.select({ios: { bottom:60 },android: { bottom:45 },default: { bottom:20 }}),}}>
          <TouchableOpacity 
            style={[styles.btnExsample, {opacity:(text!=values) ?1:0.4, width:'90%' , justifyContent:"center", alignItems: 'center'}]} 
            disabled={!(text!=values)} 
            onPress={()=>{ 
              handleSubmit();
          }}>
            <Text style={styles.txtBtnExsample}>保存する</Text>
          </TouchableOpacity>
        </View>
    </View>
  );
}

const SelfIntroInput = (props)=>{
  let { fieldName, onChange, onBlur, values, placeholder, navigation, maxInput, getRef, callback } = props;
  if(maxInput==undefined) maxInput = 0;
  if(values==undefined) values = '';

  const [text, onChangeText] = useState(values);

  const handleSubmit = async () => {
    try{
      OverlayWrapper.show(true);
      // call Api
      const responseProfilePutIntro =  await ProfileSettingApi.ProfilePutIntroduction(text);
      const success = responseProfilePutIntro?.status;
      if(responseProfilePutIntro!=undefined && success==1){
        onChange(fieldName,text);
        PopupWrapper.showMessage(Msg.Update.Success,'check',()=>{
          OverlayWrapper.hide();
          if(getRef!=undefined){
            const parentRef = getRef(fieldName);
            if(parentRef) parentRef.ShowPopUp(false);
          }
        });
        /*
        if(getRef!=undefined){
          setTimeout(()=>{
            PopupWrapper.hideMessage()
            const parentRef = getRef(fieldName);
            if(parentRef) parentRef.ShowPopUp(false);
          }, 1000);
        }
        */
        // callback function
        if(callback!=undefined){
          callback();
        }
      }else{ // error
        OverlayWrapper.hide();
      }
    }catch(e){
      OverlayWrapper.hide();
      if(__DEV__) console.log(e);
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollContainer}>
      <View style={styles.BodyContent}>
        <Text style={{fontSize:12, lineHeight:19, letterSpacing:-.5,}}>
        趣味や休日の過ごし方、性格や仕事の内容など
        あなたの個性をアピールできると、マッチング率が
        大幅アップします。
        </Text>
      </View>
      <View style={{flexDirection:'row', marginTop:10, marginBottom:8, paddingRight:16, paddingLeft:16, width:'100%', height:20, }}>
          <Text style={{flex:1, fontSize:14, fontWeight:'bold', color:'#484848', textAlign:'left'}}>自己紹介文</Text>
          <Text style={{flex:1, fontSize:13, fontWeight:'bold', color:'#9aa5a7', textAlign:'right'}}>※{maxInput}文字まで</Text>
      </View>
      <View style={[styles.textAreaView, {marginBottom:32}]}>
        <View style={styles.viewTextAreaInput}>
          <TextInput 
            style={[styles.textAreaInput, {marginLeft:0, height:180,}]}
            value={text}
            //onChangeText={text=>{onChange(fieldName,text)}}
            //onBlur={() => onBlur(fieldName)}
            onChangeText={onChangeText}
            placeholder={placeholder}
            multiline
            numberOfLines={8}
            placeholderTextColor="#9aa5a7"
            inputAccessoryViewID={inputAccessoryViewID}
          />
        </View>
      </View>
      </ScrollView>
      <View style={{padding:8, width:'80%', position:'absolute', justifyContent:"center", alignItems: 'center', alignSelf: 'center', ...Platform.select({ios: { bottom:60 },android: { bottom:45 },default: { bottom:20 }}),}}>
        <TouchableOpacity 
          style={[styles.btnExsample, {opacity:(text!=values) ?1:0.4, justifyContent:"center", alignItems: 'center', width:'90%'}]} 
          disabled={!(text!=values)} 
          onPress={()=>{ 
            handleSubmit();
        }}>
          <Text style={styles.txtBtnExsample}>保存する</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const NicknameInput = (props)=>{
  let { fieldName, onChange, onBlur, values, placeholder, maxInput, getRef, callback } = props;
  if(maxInput==undefined) maxInput = 0;
  if(values==undefined) values = '';

  const [text, onChangeText] = useState(values);

  const handleSubmit = async () => {
    try{
      OverlayWrapper.show(true);
      if(text.length<2 || text.length>10){
        PopupWrapper.showMessage('2～10文字で入力してください。','warning',()=>{
          OverlayWrapper.hide();
        });
      }else if(!validateNickname(text)){
        PopupWrapper.showMessage('絵文字は利用できません。','warning',()=>{
          OverlayWrapper.hide();
        });
      }else{
      // call Api
      const responseProfilePutNickname = await ProfileSettingApi.ProfilePutNickname(text);
      const success = responseProfilePutNickname?.status;
      if(success==1){
        onChange(fieldName,text);
        PopupWrapper.showMessage(Msg.Update.Success,'check',()=>{
          OverlayWrapper.hide();
          if(getRef!=undefined){
            const parentRef = getRef(fieldName);
            if(parentRef) parentRef.ShowPopUp(false);
          }
        });
        
        /*
        if(getRef!=undefined){
          setTimeout(()=>{
            PopupWrapper.hideMessage();
            const parentRef = getRef(fieldName);
            if(parentRef) parentRef.ShowPopUp(false);
          }, 1000);
        }
        */
        // callback function
        if(callback!=undefined){
          callback();
        }
      }else{
        OverlayWrapper.hide();
        //CommonFunction.checkErrorCode(responseProfilePutNickname);
      }
      }
    }catch(e){
      OverlayWrapper.hide();
      if(__DEV__) console.log(e);
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollContainer}>
      <View style={[styles.BodyContent, {paddingTop:30, paddingRight:10, paddingBottom:5,}]}>
        <View style={{justifyContent:'flex-end', alignContent:'flex-end', alignItems:'flex-end'}}>
          {maxInput > 0 &&
          <Text style={{color:'#98a3a5'}}>※{maxInput}文字まで</Text>
          }
        </View>
      </View>
      <View style={styles.textView}>
        <TextInput 
          style={styles.textInput}
          value={text}
          //onChangeText={text=>{onChange(fieldName,text)}}
          onChangeText={onChangeText}
          //onBlur={() => onBlur(fieldName)}
          placeholder={placeholder}
          placeholderTextColor="#9aa5a7"
          inputAccessoryViewID={inputAccessoryViewID}
        />
        </View>
        </ScrollView>
        <View style={{padding:8, width:'80%', position:'absolute', justifyContent:"center", alignItems: 'center', alignSelf:'center', ...Platform.select({ios: { bottom:60 },android: { bottom:45 },default: { bottom:20 }}),}}>
        {text==''?
          <View style={styles.btnDisable}>
            <Text style={styles.txtBtnDisable}>保存する</Text>
          </View>
          :
          <TouchableOpacity 
            style={[styles.btnExsample, {opacity:(text!=values) ?1:0.4, width:'90%'}]} 
            disabled={!(text!=values)} 
            onPress={()=>{ 
              handleSubmit();
          }}>
            <Text style={styles.txtBtnExsample}>保存する</Text>
          </TouchableOpacity>
        }
        </View>
    </View>
  );
}

const TextBoxInput = (props)=>{
  let { fieldName, onChange, onBlur, values, placeholder } = props;
  
  return (
    <View style={styles.container}>
      <View style={styles.BodyContent}>
      <View style={styles.textView}>
          <TextInput 
            style={styles.textInput}
            value={values}
            onChangeText={text=>{onChange(fieldName,text)}}
            onBlur={() => onBlur(fieldName)}
            placeholder={placeholder}
            placeholderTextColor="#9aa5a7"
            inputAccessoryViewID={inputAccessoryViewID}
          />
        </View>
      </View>
    </View>
  );
}

const TextAreaInput = (props)=>{
  let { fieldName, onChange, onBlur, values, placeholder } = props;
  
  return (
    <View style={styles.container}>
      <View style={[styles.BodyContent, {padding:0,}]}>
        <View style={styles.textAreaView}>
          <View style={styles.viewTextAreaInput}>
            <TextInput 
              style={[styles.textAreaInput, {height:125,}]}
              value={values}
              onChangeText={text=>{onChange(fieldName,text)}}
              onBlur={() => onBlur(fieldName)}
              placeholder={placeholder}
              multiline
              numberOfLines={5}
              inputAccessoryViewID={inputAccessoryViewID}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const UnderReviewImgLayer = (props)=>{
  let borderRadius = null;
  if(props.borderRadius!=undefined){
    borderRadius = props.borderRadius
  }

  return (
    <View style={[styles.unerReviewImageContainer, borderRadius?{borderRadius:borderRadius}:null]}>
      <Text style={styles.unerReviewImageTxt}>審査中</Text>
    </View>
  );
}

const LockImgLayer = (props)=>{
  return (
    <View style={{flex:1,justifyContent:'center', alignItems:'center'}}>
      <Image resizeMode={'cover'} style={{width:32, height:41}} source={require('../../assets/images/icon/lock.png')}/>
    </View>
  );
}

const validateNickname =(val)=>{
  var validate = false;
  if(typeof val !='undefined'){
    var regEx = /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤]+$/i;
    if(regEx.test(val)){
      validate = true;
    }
  }
  return validate;
}

export default { 
  DisplayLineText, 
  DisplayLineTextBg, 
  DisplayLineTextRightIcon, 
  DisplayText, 
  WordOfDayInput, 
  SelfIntroInput, 
  NicknameInput,
  TextBoxInput, 
  TextAreaInput, 
  UnderReviewImgLayer,
  CustomSelected,
  LockImgLayer 
}

const styles = StyleSheet.create({ 
  container: {
    flex: 1,
    backgroundColor:'#eef1f2',
    alignItems: 'center',
    width:'100%',
    height:'100%',
  },
  scrollContainer: {
    width:'100%',
    height:'100%'
  },
  BodyContent:{
    padding:15,
    width:'100%',
  },
  textView:{
    justifyContent:"center",
    width:"100%",
    height:50,
    backgroundColor:"#ffffff",
  },
  textInput:{
    marginLeft:8,
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    backgroundColor:'#ffffff',
    color:"#000",
    marginBottom:1,
    ...Platform.select({
      web:{
        lineHeight:20,
      }
    })
  },
  infoList:{
    flexDirection:'row', 
    alignSelf:'stretch',
    backgroundColor:'#fff',
    justifyContent:'center',
    position:'relative'
  },
  infoLabel:{
    flex:1,  
    alignSelf:'stretch', 
    alignItems:'flex-start',
    justifyContent:'center'
  },
  label:{
    marginTop:10,
    marginRight:8,
    marginBottom:10,
    marginLeft:16,
    fontSize:12,
    fontWeight:'bold',
    lineHeight:20,
    color:'#9aa5a7',
  },
  infoInput:{
    ...Platform.select({
      ios: { width:130, },
      android: { width:130, },
      //default: { width:85, },
    }),
  },     
  infoText:{
    justifyContent:'center',
    alignSelf:'stretch',
    alignItems:'flex-end',
    ...Platform.select({
      //ios: { width:130, },
      //android: { width:130, },
      //default: { width:85, },
    }),
  },
  txtSelect:{
    marginRight:30,
    fontSize:16,
    fontWeight:'normal',
    lineHeight:22,
    color:'#5cc8ca',
    justifyContent:'center',
    textAlign:'right',
    ...Platform.select({
      ios: { width:100, },
      android: { width:100, },
      default: { /*width:100,*/ maxWidth:150 },
    }),
  },
  downIcon:{
    position:'absolute',
    top:'50%',
    right:10,
    marginTop:-8,
    padding:0,
    fontSize:12,
    color:'#070706',    
  },
  infoTextContainer:{
    flexDirection:'row',
  },
  infoTextInner:{
    justifyContent:'center',
  },
  sectionList:{
    width:'100%',
    backgroundColor:'#fff',
  },
  menuItem:{
    flex:1, 
    flexDirection:'row',
    marginLeft:16,
    padding:10,
    paddingLeft:0,
    backgroundColor:'#ffffff',
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  menuItemText:{
    fontSize:14,
    lineHeight:32,
    color:'#484848',    
  },
  rightIcon:{
    position:'absolute',
    //top:'50%',
    right:0,
    padding:10,
    fontSize:16,
    //color:'#e6e6e6',
    color:'#999999',
    //transform:[{translateY:-20}], 
  },
  rightIconInside:{
    position:'absolute',
    top:'50%',
    right:0,
    padding:10,
    fontSize:16,
    color:'#e6e6e6',
    ...Platform.select({
      ios: { transform:[{translateY:-10}], },
      android: { transform:[{translateY:-10}], },
      default: { transform:[{translateY:-20}], },
    }),
  },
  textAreaView:{
    justifyContent:"center",
    width:"100%",
    backgroundColor:"#ffffff",
    /*borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,*/
    ...Platform.select({
      ios: { paddingTop:20, },
      android: { },
      default: {  },
    }),
  },
  viewTextAreaInput: {
    paddingRight:8,
    paddingLeft:16,
    width:'100%',
    backgroundColor:'#ffffff',
  },
  textAreaInput:{
    padding:16,
    paddingLeft:0,
    width:'100%',
    ...Platform.select({
      ios: { },
      android: { textAlignVertical:'top', },
      default: { },
    }),
  },
  btnExsample:{
    height:56,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
    textAlign:'center',
  },
  txtBtnExsample:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff'
  },
  btnDisable:{
    height:56,
    backgroundColor:'#d0d6d8',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#bfc5c7",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
    textAlign:'center',
  },
  txtBtnDisable:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff'
  },
  unerReviewImageContainer:{
    width:'100%', 
    height:'100%',
    position:'absolute',
    left:0,
    top:0, 
    opacity:0.5, 
    backgroundColor:'#ffffff', 
    justifyContent:'center',
    //borderRadius:16,
  },
  unerReviewImageTxt:{
    textAlign:'center', 
    fontSize:16, 
    fontWeight:'bold', 
    color:'#000000'
  }
});
