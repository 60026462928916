import React, { Component } from 'react';
import { Animated, View, StyleSheet, Dimensions, TouchableOpacity, Text, Platform }  from 'react-native';
import { Portal } from 'react-native-paper';
import AdvanceSearchListScreen from '../../screens/AdvanceSearchListScreen';
import AdvanceSearchListSingleScreen from '../../screens/AdvanceSearchListSingleScreen';
import { Entypo } from '@expo/vector-icons';
import HeadStyle from '../../styles/HeadStyles';
import LogoNoMargin from './LogoNoMargin';
import ExpoConstants from 'expo-constants';
import HeaderOptions from '../../constants/HeaderOptions';

const screenWidth = Dimensions.get('window').width;

export default class RightSlidePopup extends Component {
  _isMounted = false;

  constructor(){
    super();
    //this.screenWidth = Dimensions.get('window').width;
    this.state = {
      LeftPosition: new Animated.Value(screenWidth),
      visible: false
    }
    this.ref = React.createRef();
  }
  
  componentDidMount(){
    this._isMounted = true;
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  ShowPopUp = (show) => {
    if(show){ 
      this.setState({visible:true});
    }

    Animated.timing(this.state.LeftPosition, {
      //duration: 350,
      duration: 0,
      toValue: show ? 0 : screenWidth,
      useNativeDriver: false
    }).start(({ finished }) => {
      if(finished){
        if(!show){
          this.setState({visible: false});
        }
      }
    });
  }

  render(){
    if(this.state.visible){
      let font = 16;
      if(this.props.title.indexOf('（複数選択可能）')!=-1){
        font = 15;
        if (screenWidth<=375) {
          font = 12;
          if (screenWidth<=340) {
            font = 10;
          }
        }
      } 
      return (
        <View style={styles.touch}>
          <Portal>
            <Animated.View style={[styles.container,{
              left:this.state.LeftPosition
            }]}>
              <View style={styles.header}>
                <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
                  <Text style={[HeadStyle.headerTitlePage, {fontSize:font}]}>{this.props.title}</Text>
                </View>
                <View style={{position:'absolute',justifyContent:'center',alignItems:'center',flexDirection:'row',left:16,zIndex:1,elevation:1, height:'100%'}}>
                  <TouchableOpacity onPress={async()=>{
                    if(this.ref.handleBack !=undefined){
                      await this.ref.handleBack();
                    }
                    this.ShowPopUp(false);
                  }}>
                    <Entypo name={'chevron-thin-left'} style={[HeadStyle.icBack,{paddingLeft:0}]} />
                  </TouchableOpacity>
                  <LogoNoMargin/>
                </View>
              </View>
              {this.props.component!=null?
                this.props.component
                : 
                (this.props.single===true?
                  <AdvanceSearchListSingleScreen {...this.props} ref={ref=>this.ref=ref} />  
                :
                  <AdvanceSearchListScreen {...this.props} ref={ref=>this.ref=ref} />  
                )
              }
            </Animated.View>
          </Portal>
        {this.props.showChild===true && this.props.children }
        </View>
      );
    }
    
    if(this.props?.isImage && Platform.OS != 'web'){
      return(
        <View>
          <TouchableOpacity onPress={() => {this.ShowPopUp(true)}}  style={styles.touch}>
            {this.props.children}
          </TouchableOpacity>
        </View>
      );
    }else{
      return (
        <TouchableOpacity onPress={() => {this.ShowPopUp(true)}} style={styles.touch}>
          {this.props.children}
        </TouchableOpacity>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width:'100%',
    height:'100%',
    ...Platform.select({
      ios:{
        marginTop: ExpoConstants?.statusBarHeight
      }
    })
  },
  touch:{
    flex:1,
    width:'100%',
    alignItems:'flex-end',
    justifyContent:'center',
    alignContent:'center',
    alignSelf:'center',
  },
  header:{
    flexDirection:'row',
    width:'100%',
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#b2b2b2',
    shadowColor: 'transparent',
    elevation: 0,
    zIndex:0,
    height: HeaderOptions.headerTopMenuHeight, 
    ...Platform.select({
      ios: {
        marginTop:0,
      },
      android: { 
        marginTop:0, 
      },
      default: {
      
      }
    })
  }
});