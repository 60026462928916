import React,{Component} from 'react';
import { Image, View, TouchableOpacity, StyleSheet, Platform} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { Camera } from 'expo-camera';
import PopupMedia from '../common/PopupMedia';
import PopupAppSetting from '../common/PopupAppSetting';

export default class UploadChatImagePicker extends Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      image: null 
    };
    this.PopupMedia = React.createRef();
    this.PopupRef = React.createRef();
  }
  
  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      //this.getPermissionAsync();
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }  

  getPermissionAsync = async () => {
    if (Platform.OS==='ios') {
      const permission = await ImagePicker.requestCameraPermissionsAsync();
    }
  };

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.props.onChange(uri);
        if(this.props.handleSubmit){
          setTimeout(() => {
            this.props.handleSubmit();
          }, 500);
        }
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if (Platform.OS==='ios') {
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.props.onChange(uri);
        if(this.props.handleSubmit){
          setTimeout(() => {
            this.props.handleSubmit();
          }, 500);
        }
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  render() {
    return (
      <>
      <TouchableOpacity onPress={()=>{
        if(this.props.onPress()){
          if(this.props.setSubmitField!=undefined){
            this.props.setSubmitField();
          }

          if(Platform.OS==='web'){
            this._pickImage();
          }else{
            this.PopupMediaRef.show(true);
          }
        }
      }}>
        <Image style={styles.icBtnBottom} source={require('../../assets/images/icon/ic-camera-gray.png')}/>
      </TouchableOpacity>
      <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
      <PopupAppSetting ref={ref=>this.PopupRef=ref} />
      </>
    );
  }
}

const styles=StyleSheet.create({
  icBtnBottom:{
    width:24,
    height:24
  }
});