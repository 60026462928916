import React,{ Component } from 'react';
import { StyleSheet, View, Animated, Platform, Keyboard } from 'react-native';
import { Portal } from 'react-native-paper';
import PortalStyle from '../../styles/PortalStyles';

export default class BottomMenu extends Component{
  constructor() {
    super();
    this.defaultAnimatedValue = 200;
    this.state = {
      show: false
    };
    this.showBottom = new Animated.Value(this.defaultAnimatedValue);
    this.bottom = 20;
    this.backgroundLayer = true;
  }

  componentDidMount(){
    if(this.props?.bottom!=undefined) this.bottom =this.props.bottom;
    if(this.props?.backgroundLayer!=undefined) this.backgroundLayer =this.props.backgroundLayer;
    this.bottomNegative = this.bottom*-1;
  }

  componentWillUnmount = ()=>{
  }

  showPopUp = (show) => {
    if(show){
      this.setState({show: true});
      this.addKeyboardListener();
    }

    Animated.timing(this.showBottom, {
      duration: 300,
      toValue: show ? this.bottomNegative : this.defaultAnimatedValue,
      useNativeDriver: Platform.OS==='web'?false:true
    }).start(({ finished }) => {
      if(finished){
        if(!show) {
          this.setState({show: false});
          this.removeKeyboardListener();
        }
      }
    });
  }

  hide = ()=>{
    this.setState({show: false});
    this.showBottom?.setValue(this.defaultAnimatedValue);
    this.removeKeyboardListener();
  }
  
  show = ()=>{
    this.setState({show: true});
    this.showBottom?.setValue(this.bottom);
    this.addKeyboardListener();
  }

  setBottom = (bottom)=>{
    this.showBottom?.setValue(bottom);
  }

  isShow = ()=>{
    return this.state?.show;
  }

  addKeyboardListener = ()=>{
    if(Platform.OS==='ios'){
      this.showSubscription = Keyboard.addListener('keyboardDidShow', (e) => {
          this.showBottom.setValue(e?.endCoordinates?.height*-1);
      });
    }
  }

  removeKeyboardListener = ()=>{
    if(Platform.OS==='ios'){
      this.showSubscription?.remove();
    }
  }

  render (){
    if(this.state.show){
      return (
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            {this.backgroundLayer!=false &&
            <Animated.View style={[PortalStyle.overlay,{opacity: this.showBottom.interpolate({
                inputRange: [this.bottom, this.defaultAnimatedValue],
                outputRange: [0.5,0]
              })
            }]} onStartShouldSetResponder={() => {
              this.showPopUp(false);
              if(this.props?.closeAction) {
                this.props?.closeAction();
              }
            }}/>
            }
            <Animated.View style={[styles.bottomPopupSlider,{
              paddingRight:10,
              paddingLeft:10,
              bottom:this.bottom,
              transform: [{translateY: this.showBottom}],
              justifyContent:'center',
              alignItems:'center',
              opacity: this.showBottom.interpolate({
                inputRange: [this.bottom, this.defaultAnimatedValue],
                outputRange: [1,0]
              })
            },this.props.style]}>
              {this.props.children}
            </Animated.View>
          </View>
        </Portal>
      );
    }else{
      return null;
    }
  }
}

const styles = StyleSheet.create({
  bottomPopupSlider:{
    flex:1,
    width:'100%',         
    position:'absolute',
  }
});
