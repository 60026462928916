import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Dimensions, ScrollView, Platform } from 'react-native';
import Line from '../common/Line'
import Icon from 'react-native-vector-icons/AntDesign';

import { Dialog, Portal } from 'react-native-paper';

export default class DialogListInput extends Component {  

    constructor(props){
        super(props);
        this.state = {
          visible: false,
          dailogSelected: [],
          dailogSelectedText: '',
        }
      }

      componentDidMount(){
        if(this.props.value.length > 0){
          let selectList = JSON.parse(this.props.value);
          let selectTxt = '';
          if(!Array.isArray(selectList)){
            selectList = [];
            selectList.push(this.props.value);
          }
          if(Array.isArray(selectList)){
            selectList.map((s, i) => {
              selectTxt += this.findValueForId(s);
              if(i<selectList.length-1){
                selectTxt += ", ";
              }
            });

            this.setState({
              dailogSelected: selectList,
              dailogSelectedText: selectTxt
            });
          }
        }
      }

      showDialog(){
        this.setState({
          visible: true
        })
      }

      hideDialog(){
        this.setState({
          visible: false
        })
      }

      findValueForId(id){
        let txt = '';
        this.props.items.map( (s, i) =>  {
          if(s.id==id) txt=s.value;
        });
        /*
        if(id==0){
          txt = '';
        }*/
        return txt;
      }

      onSelect(id) {
        let txt = '';
        let sel_list = this.state.dailogSelected;
        let multiple = this.props.multiple;
        
        if(this.props.singleSelectId==id){
          if(sel_list.includes(id)){
            sel_list=[];
          }else{
            sel_list=[id];
            txt = this.findValueForId(id);
          }
          this.setState({
            dailogSelected: sel_list,
            dailogSelectedText: txt
          });
          return;
        }else{
          if(sel_list.includes(this.props.singleSelectId)){
            return;
          }
        }        

        if(multiple){
          if(sel_list.includes(id)){
            const index = sel_list.indexOf(id);
            if (index > -1) {
              sel_list.splice(index, 1);
            }
          }else{
            sel_list.push(id);
          }          
          sel_list.map( (s, i) =>  {
            txt += this.findValueForId(s);
            if(i<sel_list.length-1){
              txt += ", ";
            }
          });
        }else{
          sel_list=[id];
          txt = this.findValueForId(id);
        }
        this.setState({
          dailogSelected: sel_list,
          dailogSelectedText: txt
        });
      }

      OK(){
        let val = this.state.dailogSelected;
        if(val.length > 1){
          val = JSON.stringify(val);
        }else{
          val = val[0];
        }
        this.props.onChange(val);  
        this.hideDialog();
      }

      Cancel(){
        this.setState({
          visible: false,
          dailogSelected: [],
          dailogSelectedText: '',
        });
        this.hideDialog();
      }

      _renderPopup(){
        if (this.state.visible) {

          let title = '選択してください';
          let text_ok = 'OK';
          let text_cancel = 'Cancel';
          let item_style = styles.list_select;
          let item_not_sel_style = styles.list_select;
          let label_style = styles.label;
          let label_selected_style = styles.label_selected;
          let scrollStyle = styles.scrollStyle;
          if(this.props.item_style == 'button'){
            scrollStyle = styles.scrollStyleCol;
          }
          if(this.props.title != undefined){
            title = this.props.title;
          }
          if(this.props.text_ok != undefined){
            text_ok = this.props.text_ok;
          }
          if(this.props.text_cancel != undefined){
            text_cancel = this.props.text_cancel;
          }
          if(this.props.item_style == 'button'){
            item_style = styles.button_select;
            item_not_sel_style = styles.button_not_select;
            label_style = styles.button_label;
            label_selected_style = styles.button_label_selected;
          }

          const pickerItems = this.props.items.map( (s, i) =>  {
            let item_css = item_not_sel_style;
            if(this.state.dailogSelected.includes(s.id)){
              item_css = item_style;
            }
            return (
              <TouchableOpacity 
                key={s.id}
                onPress={this.onSelect.bind(this, s.id)}
                style={item_css}
                >
                  {
                    this.state.dailogSelected.includes(s.id) ?
                    <Text style={label_selected_style}>{s.value}</Text>
                      : <Text style={label_style}>{s.value}</Text>
                  }
                  {
                    (this.state.dailogSelected.includes(s.id) && this.props.item_style != 'button') ?
                    <Icon name={'check'} size={16} style={styles.rightIcon_selected} />
                      : null
                  }
              </TouchableOpacity>
            )
          });
          const dialogHeight = this.props.height;
          const dialogWidth = this.props.width;
          const dialogMarginLeft = ((100-this.props.width.replace('%',''))/2)+'%';
          const contentHeight = this.props.height-80;
          
          //console.log('dialogWidth',dialogWidth);
          return (
            <Portal style={styles.portal}>
              <Dialog 
                visible={this.state.visible} 
                style={[styles.dialog,{height:dialogHeight, width:dialogWidth, marginLeft:dialogMarginLeft}]} 
                onDismiss={() => this.hideDialog()}>
                  <View style={styles.header}><Text style={styles.txtHeader}>{title}</Text></View>
                  <Line />
                  <View style={[styles.content,{height:contentHeight}]}>
                    <ScrollView>
                      <View style={scrollStyle}>
                        {pickerItems}
                      </View>
                    </ScrollView>                    
                  </View>
                  <View style={styles.footer}>
                    {/*<TouchableOpacity style={styles.button} onPress={() => this.Cancel()}>
                        <Text style={styles.cancel}>{text_cancel}</Text>
                      </TouchableOpacity>
                    */}
                      <TouchableOpacity style={this.state.dailogSelected.length>0?styles.okBtn:styles.disableOkBtn} onPress={() => this.OK()} disabled={this.state.dailogSelected.length>0?false:true}>
                        <Text style={styles.txtOkBtn}>{text_ok}</Text>
                      </TouchableOpacity>
                  </View>
              </Dialog>
            </Portal>
          );
        } else {
            return null;
        }
      }
    
      render() {
          let selected_value = '選択してください';
          if(this.props.title != undefined){
            selected_value = this.props.title;
          }
          if(this.state.dailogSelectedText !=''){
            selected_value = this.state.dailogSelectedText;
          }
        return (
          <View style={styles.inputView}>
              <TouchableOpacity 
                style={styles.inputText}
                onPress={() => this.showDialog()}
              >
                <Text>{selected_value}</Text>
                <Icon name={'caretdown'} size={12} style={styles.rightIcon} />
              </TouchableOpacity>
                                        
              {this._renderPopup()}
            </View>
        );
      }
    
}

const styles = StyleSheet.create({
  inputView:{
    justifyContent:'center',
    width:'100%',
    minHeight:50,
    backgroundColor:'#eef1f2',
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
  },
  inputText:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    lineHeight:20,
    color:"#000",
  },
  rightIcon:{
    position:'absolute',
    top:'50%',
    right:0,
    padding:10,
    color:'#000',
    ...Platform.select({
      ios: {
        
      },
      android: {
        
      },
      web:{
        transform:[{translateY:'-50%'}]
    }}),
  },
  portal:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    zIndex: 100,
    justifyContent: 'center',
    alignContent: 'center',    
    height: 450,
    maxHeight: 700,
    width: '66%',
    marginLeft: '17%',
    backgroundColor: '#FFF',
    borderColor: '#CCC',
    borderRadius: 15,
  },

  header:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding:10,
  },
  txtHeader:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
  },
  content:{
    justifyContent: 'center',
    alignContent: 'center',
    height: 360,
    /*overlfow: 'scroll'*/
  },

  footer:{
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'row',
  },
  button:{
    //borderColor: '#CCC',
    //borderTopWidth: 1,
    //borderRightWidth: 1,
    width:'50%',
    height: 44,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  ok:{
    fontWeight: "600",
    fontSize: 16,
    color: '#488aff',
    alignItems: 'center'
  },
  cancel:{
    fontWeight: "500",
    fontSize: 16,
    color: '#488aff',
    alignItems: 'center'
  },

  list_select:{
    height: 20,
    padding: 4,
    margin: 6,
    flex: 1,
    flexDirection: 'row',
    marginLeft: 3,
  },
  label:{
    color: '#484848',   
  },
  label_selected:{
    color: '#488aff',   
  },
  scrollStyle:{

  },
  scrollStyleCol:{
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap:'wrap',
    flex:1
  },

  button_not_select:{
    justifyContent:'flex-start',
    alignItems:'center',
    marginTop:5,
    marginBottom:5,
    marginLeft:7,
    marginRight:7,
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:10,
    paddingRight:10,
    height:32,
    backgroundColor:'#FFF',
    borderColor:'#9aa5a7', 
    borderWidth:2,
    borderRadius:999, 
  },
  button_select:{
    justifyContent:'flex-start',
    alignItems:'center',
    marginTop:5,
    marginBottom:5,
    marginLeft:7,
    marginRight:7,
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:10,
    paddingRight:10,
    height:32,
    backgroundColor:'#5cc8ca',
    borderColor:'#5cc8ca', 
    borderWidth:2,
    borderRadius:999,
  },
  button_label:{
    alignItems:'center',
    color:'#9aa5a7',
  },
  button_label_selected:{
    alignItems:'center',
    color:'#FFF',
  },

  rightIcon_selected:{
    position: 'absolute',
    right:0,
    paddingRight: 10,
    color: '#488aff',
  },
  okBtn:{
    alignItems:'center',
    justifyContent:'center',
    marginTop:10,
    marginBottom:10,
    width:'80%',
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    shadowColor:'#10a4c3',
    shadowOffset: {
      width:0,
      height:2,
    },
    shadowOpacity:1,
    shadowRadius:1,
    elevation:1,
  },
  txtOkBtn:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:18,
    color:'#fff',
  },
  disableOkBtn:{
    alignItems:'center',
    justifyContent:'center',
    marginTop:10,
    marginBottom:10,
    width:'80%',
    height:42,
    backgroundColor:"#5cc8ca",
    borderRadius:999,
    shadowColor:"#10a4c3",
    shadowOffset: {
      width:2,
      height:2,
    },
    shadowOpacity:1,
    shadowRadius:1,
    elevation:1,
    opacity:0.4,
  }
});