import React,{Component} from 'react';
import { View, StyleSheet } from 'react-native';
import ActivityIndicator from '../components/common/ActivityIndicator';
import Storage from '../utils/StorageHelper2';
//import Constants from '../constants/Constants';
import { AppContext } from '../AppContext';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';
import CommonStyles from '../styles/CommonStyles';

export default class AuthLoadingScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor() {
    super();
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      this.checkAccessToken();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  checkAccessToken = async () => {
    if(__DEV__) console.log('AuthLoading');
    
    const { navigation } = this.props
    ProfileSettingApi.setCheckAccessFlg(false);
    const userProfile = await ProfileSettingApi.ProfileGetCheck(this.context);

    if(userProfile?.status==1){
      /*
      const { appState, setAppState } = this.context;
      appState.userProfile = userProfile;
      setAppState(appState);

      const myProfile = {
        memberId: userProfile.memberId,
        sex: userProfile.sex,
        name: userProfile.name
      }
      Storage.storeObjectData('userProfile', myProfile);
      */

      navigation?.navigate('Auth', {screen:'Home'});
      /*
      if(userProfile.sex!=undefined && userProfile.sex==Constants.MALE){
        this.props.navigation.navigate('Auth', {screen:'RecommendIine'});
      }else{
        this.props.navigation.navigate('Auth', {screen:'Home'});
      }
      */
    }else{
      navigation?.navigate('NonAuth', {screen:'Index'});
    }
  }

  render() {
    return (
      <View style={CommonStyles.activityIndicatorContainer}>
        <ActivityIndicator />
      </View>
    );
  }
}