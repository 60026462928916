import * as React from 'react';
import { Text, View, StyleSheet }  from 'react-native';

export default function TabBadge(props) {
  let badge = props.badge;
  if(badge!=undefined && badge>0){
    if(badge>99) badge = '99+';
    
    let fontSize = 8;
    if(badge?.toString()?.length==1) fontSize = 12;
    else if(badge?.toString()?.length==2) fontSize = 10;

    return (
      <View style={styles.container}>
        <Text style={[styles.badge,{fontSize: fontSize}]}>{badge}</Text>
      </View>
    );
  }else{
    return null;
  }
}

const styles = StyleSheet.create({
   container:{
    width:18,
    height:18,
    backgroundColor:'#ff8d63', 
    borderRadius:9999,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    textAlign:'center',
   },
   badge:{
    textAlign:'center',
    width:'100%',
    height:'100%',
    fontSize:12,
    fontWeight:'normal',
    lineHeight:18,
    color:'#fff',   
   }
});
