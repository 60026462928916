import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import Storage from '../../utils/StorageHelper2';
import WebUtil from '../../utils/WebUtil';
import AppUtil from '../../utils/AppUtil';

//03_API仕様\レスポンス仕様書\02_ログイン
export default class Login02Api extends ApiPappy{

  //1.3.1.メールアドレスログイン
  static async LoginByMail(values){
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Login/Put/LoginByMail/",
        { 
          mail: values.mail,
          pass: values.pass,
          web_uuid: webUUID,
          adid: adid
        },
        false
      );
      if(__DEV__) console.log('/Login/Put/LoginByMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Login/Put/LoginByMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status==1){
            const accessToken = data.accessToken;
            if(accessToken!=undefined){
              Storage.storeData('accessToken', accessToken);
            }
          }else{
            //error
            if(data.response.errorGroup=="02"&&data.response.errorCode=="00006"){
              AuthHelper.showError("何らかの理由で利用が停止されております。"+"\n"+"サポートまでお問い合わせください。");
            }else{
              CommonFunction.checkErrorCode(data, false, true, null);
            }            
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.6.新規登録フロー専用ログインAPI
  static async LoginByFirstLoginKey(firstLoginKey){
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Login/Put/LoginByFirstLoginKey/",
        { 
          firstLoginKey: firstLoginKey,
          web_uuid: webUUID,
          adid: adid
        },
        true
      );
      if(__DEV__) console.log('/Login/Put/LoginByFirstLoginKey/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Login/Put/LoginByFirstLoginKey/', responseField?.join(','));
          }

          $return = data;
          if(data?.status==1){
            const accessToken = data.accessToken;
            if(accessToken!=undefined){
              Storage.storeData('accessToken', accessToken);
            }
          }else{
            // error
            CommonFunction.checkErrorCode(data, false);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.7.イクヨ連携用ログインAPI
  static async LoginByIkuyo(key){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Login/Put/LoginByIkuyo/",
        { 
          key: key
        },
        true
      );
      if(__DEV__) console.log('/Login/Put/LoginByIkuyo/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Login/Put/LoginByIkuyo/', responseField?.join(','));
          }

          $return = data;
          if(data?.status==1){
            const accessToken = data.accessToken;
            if(accessToken!=undefined){
              Storage.storeData('accessToken', accessToken);
            }
          }else{
            // error
            //CommonFunction.checkErrorCode(data, false);
          }
        }
      }else {
        //AuthHelper.showNetworkError();
      }

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}