import React,{Component} from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import * as Linking from 'expo-linking';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ConfirmPopupStyles from '../../styles/ConfirmPopupStyles';

export default class PopupAppSetting extends Component{
  constructor(props){
    super(props);
    this.popup = React.createRef();
  }

  show = (show=true)=>{
    if(show){
      this.popup?.showPopup();
    }else{
      this.popup?.hidePopup();
    }
  }

  render() {
    return (
      <Popup ref={ref=>this.popup=ref} modal={true}>
        <View style={PortalStyle.overlay} />
          <View style={ConfirmPopupStyles.popupContainer}>
            <View style={ConfirmPopupStyles.popupTextItem}>
              <Text style={ConfirmPopupStyles.popupText}>カメラの使用を許可しますか。</Text>
            </View>
            <View style={ConfirmPopupStyles.btnContainer}>
              <TouchableOpacity style={ConfirmPopupStyles.btnItem} onPress={async()=>{
                this.popup?.hidePopup();
                Linking.openSettings();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>OK</Text>
              </TouchableOpacity>
              <View style={{borderLeftWidth:2, borderColor:'#d8d8dc', width:1,height:'100%'}}></View>
              <TouchableOpacity style={ConfirmPopupStyles.btnItem} onPress={()=>{
                this.popup?.hidePopup();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>キャンセル</Text>
              </TouchableOpacity>  
            </View>              
        </View>
      </Popup>
    );
  }
}
