import { StyleSheet, Platform, StatusBar } from 'react-native';
import HeaderOptions from '../constants/HeaderOptions';
import ExpoConstants from 'expo-constants';

export default StyleSheet.create({
  icBackLogin:{
    paddingLeft: 16,
    fontSize:18,
    color:'#484848',
  },
  icBack:{
    paddingLeft:16,
    fontSize:20,
    color:'#484848',
  },
  icBack2:{
    fontSize:20,
    color:'#484848',
  },
  touchIcBack:{
    width:30,
    alignItems:'center',
    position:'absolute', 
    left:10, 
    zIndex:1, 
    elevation:1, 
  },
  headerTitlePage:{
    ...Platform.select({
      ios: {
        fontSize:16,
      },
      android: {
        fontSize:16,
      },
      web:{
        fontSize:16,
    }}),
    fontWeight:'normal',
    color:'#484848',
    textAlign:'center',
  },
  btnCloseRight:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    marginRight:7,
    textAlign:'center',
  },
  icCloseRight:{
    fontSize:32,
    lineHeight:24,
    color:'#484848',
  },
  txtCloseRight:{
    fontSize:12,
    marginTop:-2,
    //lineHeight:11,
    color:'#484848',
  },
  txtReset:{
    paddingRight:20,
    fontSize:15,
    fontWeight:'normal',
    color:'#484848',
  },
  icClose:{
    paddingLeft:16,
    fontSize:18,
    color:'#484848',
  },
  headerSubTitlePage:{
    fontSize:20,
    fontWeight:"400",
    color:'#484848',
    textAlign:'center',
  },
  customHeader:{
    height: HeaderOptions.headerTopMenuHeight,
    ...Platform.select({
      web: {
        position:'fixed',
      },
      android: {

      },
      ios: {
        marginTop:ExpoConstants?.statusBarHeight || 0,
      }
    }),
    flexDirection:'row',
    width: '100%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: '#b2b2b2',
    shadowColor: 'transparent',
    elevation: 1,
    zIndex: 1
  },
  customHeaderNoBorder:{
    height: HeaderOptions.headerTopMenuHeight,
    ...Platform.select({
      web: {
        position:'fixed',
      },
      android: {
        
      },
      ios: {
        marginTop:ExpoConstants?.statusBarHeight || 0,
      }
    }),
    flexDirection:'row',
    width: '100%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    backgroundColor: '#ffffff',
    shadowColor: 'transparent',
    elevation: 1,
    zIndex: 1
  },
  customHeader2:{
    height: HeaderOptions.headerTopMenuHeight,
    flexDirection:'row',
    width: '100%',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: '#b2b2b2',
    //borderBottomColor: '#e3e3e5',
    shadowColor: 'transparent',
    elevation: 1,
    zIndex: 1
  },
  commonTitle:{
    fontWeight:'normal',
    fontSize:20
  },
  customHeaderTop:{
    ...Platform.select({
      ios: {
        
      },
      android: {
        
      },
      web:{
        marginTop: HeaderOptions.headerTopMenuHeight
    }}),
  },
  txtBold:{
    fontWeight:'bold',
  },
  txt14:{
    fontSize:14,
  },
  appHeaderTop:{
    ...Platform.select({
      ios: {
        marginTop:ExpoConstants?.statusBarHeight || 0,
      },
      android: {
        
      }})
  },
});
