import { StyleSheet, Platform } from 'react-native';

export default (insets) => StyleSheet.create({
  container: {
    paddingBottom: insets?.bottom||0,
  },
  containerMargin: {
    marginBottom: insets?.bottom||0,
  },
  containerPopup: {
    ...Platform.select({
      ios:{
        marginTop: insets?.top||0,
        marginBottom: insets?.bottom||0,
      },
      android:{
        marginTop: insets?.top||0,
        marginBottom: insets?.bottom||0,
      }
    })
  }
});
