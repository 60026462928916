import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import Constants from '../../constants/Constants';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import CommonFunction from '../../utils/CommonFunction';
import UserUtil from '../../utils/UserUtil';
import Storage from '../../utils/StorageHelper2';

/**
 * API: 03_API仕様\レスポンス仕様書\12_プロフィール設定\プロフィール設定機能_レスポンス仕様.xlsx
 * Design: 02_画面仕様\05_プロフィールを変更\01_プロフィールを編集
 * Document: 02_画面仕様/05_プロフ編集.xlsx
 */

export default class ProfileSetting12Api extends ApiPappy{
  //1.1.1.プロフィール情報取得
  static async ProfileGetCheck(stateContext=null, checkErrorCode=true){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/Check/",
        {},
        true
      );
      if(__DEV__) console.log('/Profile/Get/Check/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          $return = data;

          if(data?.status==1){
            const responseField = CommonFunction.checkResponseField(data.response?.profileInfo, ResponseConstants.ProfileGetCheck);
            $return.userProfile = responseField.response;
            if(__DEV__){
              console.log('Profile/Get/Check: ', responseField.notFound.join(','));
            }

            // Store data & set AppState Value
            const myProfile = {
              memberId: $return.userProfile.memberId,
              sex: $return.userProfile.sex,
              name: $return.userProfile.name
            }
            Storage.storeObjectData('userProfile', myProfile);
            if(stateContext){
              UserUtil.setAppStateUserProfile(stateContext, $return.userProfile);
              UserUtil.setAccountAppState(data, stateContext);
            }
          }else{
            // error
            if(checkErrorCode==true) CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.4.今日のひとこと登録
  static async ProfilePutTweet(value){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Put/Tweet/",
        {tweet: value},
        true
      );
      if(__DEV__) console.log('/Profile/Put/Tweet/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Put/Tweet/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.3.自己紹介登録
  static async ProfilePutIntroduction(value){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Put/Introduction/",
        {introduction: value},
        true
      );
      if(__DEV__) console.log('/Profile/Put/Introduction/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Put/Introduction/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  static async ProfilePutNickname(values){
    try{
      let postData = {
        name: values
      };

      // check undefined value
      Object.keys(postData)?.map((key,index)=>{
        if(postData[key]==undefined){
          postData[key] = '';
        }
      });

      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Put/Profile/",
        postData,
        true
      );
      if(__DEV__) console.log('/Profile/Put/Profile/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Put/Profile/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.プロフィール設定
   static async ProfilePutProfile(values){
    try{
      let postData = {
        name: values.name, 
        height: values.height, 
        currentPref: values.currentPref, 
        birthCity: values.birthCity, 
        dateHope: values.dateHope, 
        meetHope: values.meetHope, 
        style: values.style, 
        occupation: values.occupation,
        education : values.education,
        alcohol: values.alcohol,
        cigarette: values.cigarette,
        holiday: values.holiday
      };

      if(values.sex==Constants.FEMALE){
        postData.vibe = values.vibe;
        postData.personality = values.personality;
      }else{
        postData.income = values.income;
        postData.assets = values.assets;
      }

      // check undefined value
      Object.keys(postData)?.map((key,index)=>{
        if(postData[key]==undefined){
          postData[key] = '';
        }
      });

      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Put/Profile/",
        postData,
        true
      );
      if(__DEV__) console.log('/Profile/Put/Profile/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Put/Profile/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
             // error
             CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.プロフィール画像登録
  static async ProfilePostPicture(value){
    const encodeData = true;
    const encodeExcludeVar = ['profileImage'];
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Post/Picture/",
        {profileImage: value},
        true,
        encodeData,
        encodeExcludeVar
      );
      if(__DEV__) console.log('/Profile/Post/Picture/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Post/Picture/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1.プロフィール画像削除
  static async ProfileDeletePicture(value){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Delete/Picture/",
        {pictureId: value},
        true
      );
      if(__DEV__) console.log('/Profile/Delete/Picture/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Delete/Picture/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.2.メインプロフィール画像設定
  static async ProfilePutMainPicture(value){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Put/MainPicture/",
        {pictureId: value},
        true
      );
      if(__DEV__) console.log('/Profile/Put/MainPicture/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Put/MainPicture/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  static async ProfilePutImagePrivateStatus(value, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Put/ImagePrivateStatus/",
        {pictureId: value},
        true
      );
      if(__DEV__) console.log('/Profile/Put/ImagePrivateStatus/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Profile/Put/ImagePrivateStatus/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}