import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, View, ScrollView, Platform, Alert,
  ImageBackground, Dimensions, SafeAreaView, TouchableOpacity, FlatList 
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import MyPage13Api from '../services/api/MyPage13Api';
import Payment15Api from '../services/api/Payment15Api_android';
import ActivityIndicator from '../components/common/ActivityIndicator';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import { Entypo } from '@expo/vector-icons';
import apiConfig from '../constants/ApiConfig';
import ListStyles from '../styles/ListStyles';
import UserVerify from '../utils/UserVerify';
import { RefreshControl } from 'react-native-web-refresh-control';
import { queryPurchasesInApp, getDeviceReceipt, removeDeviceReceipt, getProductIds, addEventListener, launchBillingFlow, setMode, setProfileId } from '../modules/purchase';
import PopupWrapper from '../components/common/PopupWrapper';
import Storage from '../utils/StorageHelper2';
import ErrorCode from '../constants/ErrorCode';
import formStyles from '../styles/FormStyles';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import ConfirmPopupStyle from '../styles/ConfirmPopupStyles';
import base64 from 'react-native-base64';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PointShoppingScreen_android extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      data: [],
      point: 0,
      account: UserUtil.getDefaultAccountAppState(),
      alreadyCallApi: false,
    };
    this.prevRoute = ['IinePlan','Profile','SameProfile','ChatRoom'];
    this.loadingPopup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.parentNavigation = this.navigation?.getParent();
      
      this.setHeader();
      if(this.subscription ==undefined){
        this.subscription = addEventListener(async({ errorMessage: errorMessage }) => {
          if(errorMessage){
            this.loadingPopup.hidePopup();
            PopupWrapper.showMessage(errorMessage, 'warning',()=>{
              CommonFunction.hideOverlay();
            });
          }else{
            
            const deviceReceipt = getDeviceReceipt().split('##')
            const storageReceipt = await Storage.getObjectData('android_point_receipt')
            if(deviceReceipt && deviceReceipt.length > 1){
              const purchaseState = deviceReceipt[2] === 'true';
              const receiptData = {receipt: deviceReceipt[0], signature: deviceReceipt[1], purchaseState: purchaseState} 
              if(!purchaseState){
                this.loadingPopup.hidePopup();
                PopupWrapper.showMessage('コンビニ支払いを選択された方は、お支払い後ポイントが付与されます。', 'warning',()=>{
                  CommonFunction.hideOverlay();
                });
              }
            }
            
            this.purchasePointGooglePlay()
          }
        });

      }

      this.getStoreDetails();
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        this.getDataFromApi();
        
        // hide bottom tab
        CommonFunction.hideBottomTab(this.parentNavigation);
      });
    
      const { appState } = this.context;
      if(appState.account){
        this.setState({ account: appState.account});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
    if(this.subscription !=undefined){
      this.subscription.remove();
    }
    const routes = this.parentNavigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)==-1) CommonFunction.showBottomTab(this.parentNavigation);
    }
  }

  setHeader(){
    this.title = 'ポイント購入';
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;
    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.navigation
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  renderPackageItem = ({item, index}) => {
    let banner;
    switch(item){
      case 'and.pappy.in.app.purchase01':
        banner = apiConfig.pappy_image_url + '/image/in_app_purchase/and/in_app_purchase01.png';//require('../assets/images/point/point10.png');
        break;
      case 'and.pappy.in.app.purchase02':
        banner = apiConfig.pappy_image_url + '/image/in_app_purchase/and/in_app_purchase02.png';//require('../assets/images/point/point30.png');
        break;
      case 'and.pappy.in.app.purchase03':
        banner = apiConfig.pappy_image_url + '/image/in_app_purchase/and/in_app_purchase03.png';//require('../assets/images/point/point55.png');
        break;
      case 'and.pappy.in.app.purchase04':
        banner = apiConfig.pappy_image_url + '/image/in_app_purchase/and/in_app_purchase04.png';//require('../assets/images/point/point110.png');
        break;
      case 'and.pappy.in.app.purchase05':
        banner = apiConfig.pappy_image_url + '/image/in_app_purchase/and/in_app_purchase05.png';//require('../assets/images/point/point550.png');
        break;
      default:
        break;
    }

    if(banner){
      return (
        <View style={{flex:1, width:'100%', justifyContent:'center',alignSelf:'center',alignItems:'center',alignContent:'center'}}>
          <TouchableOpacity style={{width:'100%'}} onPress={
            async()=>{
                this.handleOnPress(item);
          }
        }>
            <Image style={styles.itemBlogRatePlan} source={{uri:banner}} resizeMode="contain"/>
          </TouchableOpacity>  
        </View>        
      );
    }
  }
  
  noItemDisplay = () => {
    return <></>;
  }

  getStoreDetails = async ()=>{
    setMode(apiConfig.mode)
    const myProfile = await UserUtil.getUserProfileLocal(this.context);
    if(myProfile.memberId!=undefined){
      setProfileId(base64.encode(myProfile.memberId))
    }
    this.productDetailsList = await getProductIds()
    this.setState({  data: this.productDetailsList })
    this.setState({ loading: false, isRefreshing: false});
  }

  purchasePointGooglePlay = async () =>{
    const receiptData = getDeviceReceipt()
    const receiptList = receiptData.split('##')
    if(!receiptData || !(receiptList[2] === 'true') || !(receiptList[4] === 'true')) {
      return;
    }
    try{   
      const loading = await Storage.getObjectData('loading_android_purchase')
      if(loading) return;
      await Storage.storeObjectData('loading_android_purchase', true);   

      const responsePurchasePointGooglePlay = await Payment15Api.purchasePointGooglePlay(receiptList[0], receiptList[1] );

      if(!CommonFunction.checkResponseStatus(responsePurchasePointGooglePlay)) return;

      if(checkUndefined(responsePurchasePointGooglePlay) && responsePurchasePointGooglePlay?.status==1){
        removeDeviceReceipt()
        // PopupWrapper.showMessage('ポイントを購入しました', 'check');
        Alert.alert('', 'ポイントを購入しました', [
          {
            text: 'OK',
          },
        ]);
        const point = responsePurchasePointGooglePlay?.point;
        this.setState({
          point: point
        });
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(responsePurchasePointGooglePlay)){
          const response = responsePurchasePointGooglePlay?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
          if(errorCode==ErrorCode.PurchasePointGooglePlay.incorrectReceipt || errorCode==ErrorCode.PurchasePointGooglePlay.overlappedReceipt){
            removeDeviceReceipt()
          }
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.loadingPopup.hidePopup();
      await Storage.storeObjectData('loading_android_purchase', false);
    }
  }

  getDataFromApi = async ()=>{
    if(!this.state.isRefreshing && !this.state.alreadyCallApi){
      this.setState({ loading: true });
    }

    try{
      const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
      if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;
      
      let point = 0;
      if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
        point = checkMemberStatResponse?.point;
      }

      this.setState({
        point: point,
        alreadyCallApi: true
      });
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false, isRefreshing: false});
    }

  }

  onRefresh = ()=>{
    this.setState({isRefreshing: true}, ()=>{
      this.getDataFromApi();
    });
  }

  createInputElement = (type='hidden',name,value)=>{
    let element = document.createElement('input');
    element.setAttribute("type", type);
    element.setAttribute("name", name);
    element.setAttribute("value", value);
    return element;
  }

  onItemBay = (productId)=>{
    launchBillingFlow(productId)
  }

  handleOnPress = async (item)=>{
    const myProfile = await UserUtil.getUserProfileLocal(this.context);
    if(myProfile.memberId!=undefined){
      isCheck = await CommonFunction.checkAccessTime()
      if(!isCheck){
        return;
      }
    }
    if(this.isProcessing)  return;
    this.isProcessing = true;
    const storageReceipt = getDeviceReceipt()
    if(storageReceipt){
      PopupWrapper.showMessage('前回ポイント購入時の処理が完了していません。\nポイント付与の処理を正常に行うため、電波の良いところでアプリをご利用ください。', 'warning',()=>{
        CommonFunction.hideOverlay();
      });
    } else{
      this.loadingPopup.showPopup();
      this.onItemBay(item)
    }
    setTimeout(() => {
      this.isProcessing = false;
    }, 1000);  
  }
  
  getItemLayout = (data, index) => {
    const length = windowWidth<375? 114:134; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
      <SafeAreaView style={ListStyles.container}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
          />
        }>
          <ImageBackground source={require('../assets/images/point/bg-top.png')} resizeMode="cover" style={styles.bgTopImg}>
          </ImageBackground>
          <View style={styles.blogPadding}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center', padding:10, width:'100%', backgroundColor:'#f1f1f1', borderRadius:8}}>
              <View style={{alignSelf: 'flex-start', paddingLeft: 5}}>
                <Text style={{fontSize:13, lineHeight:18,}}>現在のポイント</Text>
              </View>
              <View style={{alignSelf: 'flex-end', paddingRight: 5}}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                  <View style={{marginRight:5, width:18, height:18, backgroundColor:'#f8d549',borderRadius:999,textAlign:'center'}}>
                    <Text style={{fontSize:14, fontWeight:'bold', color:'#ffffff', lineHeight:18,textAlign:'center'}}>P</Text>
                  </View>
                  <Text style={{fontSize:14, lineHeight:18}}>{this.state.point}</Text>  
                </View>                
              </View>
            </View>
            <View style={{width:'100%',alignItems:'flex-end', marginTop: 7}}>
              <TouchableOpacity style={{flexDirection:'row', alignItems:'center'}} onPress={()=>{
                this.navigation.navigate('PointList');
              }}>
                <Text style={{fontSize:11, fontWeight:'bold', lineHeight:18, color:'#333333'}}>ポイント履歴を確認する</Text>
                <Entypo name="chevron-right" style={{fontSize:11, lineHeight:18, color: '#4b4b4b'}}/>
              </TouchableOpacity>
            </View>
          </View>
          {this.state.data?.length > 0 && (
          <>
          <View style={styles.blogPointPlan}>
            <Text style={styles.txtBlogPointPlan}>ご希望のポイントを選んでください</Text>
          </View>
          <View style={styles.blogRatePlanInside}> 
            <FlatList
              scrollEnabled={false}
              contentContainerStyle={{ flexGrow: 1 }}
              data={this.state.data}
              renderItem={this.renderPackageItem}
              ListEmptyComponent={this.noItemDisplay}
              keyExtractor={(item,index) => item}
              inverted={true}
              getItemLayout={this.getItemLayout}
            />
          </View>
          </>
          )}

          <View style={[styles.blogTitle, styles.bgGray]}>
            <Text style={styles.txtTitle}>ポイントを使って出来ること</Text>
          </View>

          <View style={styles.blogInner}>
            <View style={styles.blogInside}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その1</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>1ポイント＝1いいね！{"\n"}に交換できる</Text>
              </View>              
              <View style={styles.blogImg}>
                <Image style={styles.img01} source={require('../assets/images/point/point-img01.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>手持ちのいいね数が足りない時は{"\n"} <Text style={styles.txtGreen}>1ポイント＝1いいねに交換</Text>できます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>お相手とマッチングすればやりとりが可能になるので{"\n"}いいねを送って積極的にアタックをしましょう</Text>
              </View>            
            </View>
            <View style={styles.blogInside}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その2</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>マッチングしていないお相手に{"\n"}ダイレクトメッセージを送る</Text>
              </View>              
              <View style={styles.blogImg}>
                <Image style={styles.img02} source={require('../assets/images/point/point-img02.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>1いいね＋3ポイントを消費すれば{"\n"}マッチングしていないお相手に{"\n"}<Text style={styles.txtGreen}>ダイレクトメッセージ</Text>を送れます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>お相手に直接メッセージを送る事ができるので{"\n"}いいねだけよりも効果的にアプローチが可能になります</Text>
              </View>
            </View>
            <View style={[styles.blogInside, {marginBottom:40,}]}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その3</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>セーフティチェックで{"\n"}お相手の利用状況が確認できる</Text>
              </View> 
              <View style={styles.blogImg}>
                <Image style={styles.img03} source={require('../assets/images/point/point-img03.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>お相手と<Text style={styles.txtGreen}>安心してやり取りする</Text>ための{"\n"}気になる情報を確認することができます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>直近1ヶ月間のお相手のご利用状況が分かるので{"\n"}安心してやりとりをしたい方にオススメです</Text>
              </View>
            </View>
            <View style={[styles.blogTitle, {marginTop: 0, marginBottom: 20,}]}>
              <Text style={styles.txtTitle}>その他のポイント入手方法</Text>
            </View>
            <View style={[styles.blogInside, {marginBottom:30}]}>
              <View style={[styles.blogImg, {paddingTop: 20}]}>
                <Image style={styles.img04} source={require('../assets/images/point/point-img04.png')} resizeMode="contain"/>
              </View>
              <View style={[styles.blogPadding2, {paddingTop: 10, paddingBottom: 20,}]}>
                <Text style={[styles.txt18, {textAlign:'center'}]}>有料会員の方には{"\n"}<Text style={styles.txtOrange}>毎月50ポイントプレゼント</Text>致します</Text>
              </View>
            </View>            
            <View style={{}}>
              <Text style={styles.headNotePlan}>ポイントでできること</Text>
              <View style={styles.notePlan}>
                <Text style={styles.txtNotePlan}>※価格はすべて税込みです{"\n"}
                ※保有できるポイントは最大10,000ポイントです{"\n"}
                ※ご購入いただいたポイントは返金できません{"\n"}
                ※ご購入いただいたポイントの有効期限は購入日を含め180日です</Text>
              </View>
              <TouchableOpacity onPress={()=>{
                this.navigation.navigate('Terms')
              }}>
                <Text style={styles.aLink}>利用規約</Text>
              </TouchableOpacity>
               <TouchableOpacity onPress={()=>{
                this.navigation.navigate('Policy')
              }}>
                <Text style={styles.aLink}>プライバシーポリシー</Text>
              </TouchableOpacity>
              <View style={[formStyles.buttonPanelSetting]}>
              <TouchableOpacity style={formStyles.submitBtn} onPress={async()=>{
                  this.loadingPopup.showPopup();
                  var result = await queryPurchasesInApp()
                  if(!result.errorMessage){
                    this.purchasePointGooglePlay();
                  } else{
                    this.loadingPopup.hidePopup();
                    PopupWrapper.showMessage(result.errorMessage, 'warning',()=>{
                      CommonFunction.hideOverlay();
                    });
                  }
                }
              }>
                <Text style={formStyles.loginText}>リストアする</Text>
                
              </TouchableOpacity>
              
            </View>
            <Text style={[{marginTop:10, fontSize:10.5, lineHeight:18,textAlign:'center',color: '#98a3a5'}]}>決済の内容がアプリに反映されない場合にリストアをお試しください。</Text>
            </View>
            
          </View>
          
        </ScrollView>
        <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
        </Popup>
    </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  blogInner:{
    flex:1,
    padding:windowWidth<375? 15 : 22,
    backgroundColor:'#f2f2f2',
  },
  imgBGOrange1: {
    marginBottom:15,
    width:windowWidth<375? 320 : 375,
    height:windowWidth<375? 70 : 82,
    textAlign:'center',
  },
  imgBGOrange2: {
    width:windowWidth<375? 273 : 320,
    height:windowWidth<375? 68 : 80,
    textAlign:'center',
  },
  blogTitle: {
    marginTop: 35,
    width:'100%',
  },
  bgBlue: {
    backgroundColor:'#f6fcff',
  },
  bgGray: {
    paddingTop: 50,
    paddingBottom: 35,
    backgroundColor: '#f2f2f2',
  },
  bgOrange: {
    backgroundColor:'#fcf5f2',
  },
  txtTitle: {
    fontSize:windowWidth<375? 19 : 22,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
    textAlign:'center',
  },
  blogInside: {
    position:'relative',
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    marginBottom:55,
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:8,
    shadowColor: "#afc9b1",
    shadowOffset: {width: 0,height: 0,},
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 5,
  },
  txtImgBGOrange: {
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 30,
    color:'#fff',
  },
  txtImgBGOrange2: {
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 30,
    color:'#333333',
    textAlign:'center',
  },
  blogImg:{
    marginBottom:10,
    width:'100%',
  },
  paddingT10:{
    paddingTop:10,
  },
  paddingT15:{
    paddingTop:15,
  },
  paddingB25:{
    paddingBottom:25,
  },
  img01:{
    width:'100%',
    height:windowWidth<375? 115 : 140,
  },
  img02:{
    width:'100%',
    height:windowWidth<375? 53 : 62,
  },
  img03:{
    width:'100%',
    height:windowWidth<375? 89 : 104,
  },
  img04:{
    width:'100%',
    height:windowWidth<375? 62 : 73,
  },
  txt18:{
    fontSize:windowWidth<375? 15 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 27 : 31,
    color:'#484848',
  },
  txt16:{
    fontSize:windowWidth<375? 13 : 16,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 25 : 29,
    color:'#484848',
  },
  txtBlue:{
    color:'#61bdd8',
  },
  txtGreen:{
    color:'#5cc8ca',
  },
  txtOrange:{
    color:'#fe895b',
  },
  blogPadding: {
    paddingLeft:windowWidth<375? 20 : 26,
    paddingRight:windowWidth<375? 20 : 26,
    width:'100%',
    textAlign:'center',
  },
  blogPadding2: {
    width:'100%',
    textAlign:'center',
  },
  blogPointPlan: {
    marginTop:30,
    marginBottom:12,
  },
  txtBlogPointPlan: {
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center',
    lineHeight:22,
    letterSpacing:-.4
  },
  blogRatePlanInside:{
    backgroundColor:'#fff',
    paddingTop:10,
  },
  itemBlogRatePlan:{
    //marginLeft:5,
    //marginRight:5,
    width:'100%',
    height:windowWidth<375? 128:148,
  },
  bgTopImg: {
    marginBottom:18,
    width:'100%',
    height:windowWidth<375? 91:107,
  },
  headNotePlan: {
    fontSize:11,
    fontWeight:'bold',
    lineHeight:20,
    color: '#333'
  },
  notePlan: {
    marginBottom:10,
  },
  txtNotePlan: {
    fontSize:10,
    fontWeight:'normal',
    lineHeight:16,
    color: '#333'
  },
  aLink: {
    marginTop:10,
    fontSize:11,
    fontWeight:'bold',
    lineHeight:18,
    color: '#333'
  },
  bgPastNum:{
    position:'absolute',
    top: -13,
    left: 'auto',
    right: 'auto',
    width: 110,
    height:windowWidth<375? 26 : 27,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
  },
  txtBGPastNum:{
    fontSize:windowWidth<375? 12 : 13,
    fontWeight: '700',
    lineHeight:windowWidth<375? 26 : 27,
    color: '#fff',
    textAlign: 'center',
  },
});