import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import WebUtil from '../../utils/WebUtil';
//import Constants from '../../constants/Constants';

/**
 * 02_プラットフォーム共通APIレスポンス仕様
 * 05_テレコム用APIレスポンス仕様
 */
export default class Payment15Api extends ApiPappy{
  //1.1.1購入チェックAPI (02_プラットフォーム共通APIレスポンス仕様)
  static async CheckSubscriptionStat(showError=1,trial){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Get/CheckSubscriptionStat/",
        {trial: trial},
        true
      );
      if(__DEV__) console.log('/Payment/Get/CheckSubscriptionStat/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Payment/Get/CheckSubscriptionStat/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.1.テレコム遷移パラメータ取得取得 (05_テレコム用APIレスポンス仕様)
  static async SubscriptionOrderUrlTelecom(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Get/SubscriptionOrderUrlTelecom/",
        {},
        true
      );
      if(__DEV__) console.log('/Payment/Get/SubscriptionOrderUrlTelecom/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.SubscriptionOrderUrlTelecom));
            console.log('/Payment/Get/SubscriptionOrderUrlTelecom/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.3テレコムサブスク解約API
  static async PaymentPutSubscriptionDiscard(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Put/SubscriptionDiscard/",
        {},
        true
      );
      if(__DEV__) console.log('/Payment/Put/SubscriptionDiscard/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Payment/Put/SubscriptionDiscard/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.1.テレコム遷移パラメータ取得取得
  static async OrderUrlTelecom(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Get/OrderUrlTelecom/",
        {},
        true
      );
      if(__DEV__) console.log('/Payment/Get/OrderUrlTelecom/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.OrderUrlTelecom));
            console.log('/Payment/Get/OrderUrlTelecom/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

    //1.1.2.クレジットカード認証遷移パラメータ取得取得 (05_テレコム用APIレスポンス仕様)
    static async AuthCreditCardUrlTelecom(values){
      try{
        let webUUID = await WebUtil.getWebUUID();
        const response = await this.post(
          ApiConfig.pappy_api_url+"/Payment/Get/AuthCreditCardUrlTelecom/",
          {
            web_uuid: webUUID
          },
          true
        );
        if(__DEV__) console.log('/Payment/Get/AuthCreditCardUrlTelecom/',response);
  
        let $return = {};
        if(response!=undefined){
          if(response?.status==200){
            const data = response?.data;
            if(__DEV__) {
              // check response field
              const responseField = CommonFunction.checkAllResponseField(data, 
                ResponseConstants.CommonResponseField.concat(ResponseConstants.AuthCreditCardUrlTelecom));
              console.log('/Payment/Get/AuthCreditCardUrlTelecom/', responseField?.join(','));
            }
  
            $return = data;
            if(data?.status!=1){
              // error
              CommonFunction.checkErrorCode(data);
            }
          }
        }else AuthHelper.showNetworkError();
  
        return $return;
      }catch(e){
        if(__DEV__) console.log(e);
      }
    }
    //1.1.2.都度決済クレジットカード認証遷移パラメータ取得取得 (05_テレコム用APIレスポンス仕様)
    static async SubscriptionUrlTelecom(values){
      try{
        const response = await this.post(
          ApiConfig.pappy_api_url+"/Payment/Get/SubscriptionUrlTelecom/",
          {},
          true
        );
        if(__DEV__) console.log('/Payment/Get/SubscriptionUrlTelecom/',response);
  
        let $return = {};
        if(response!=undefined){
          if(response?.status==200){
            const data = response?.data;
            if(__DEV__) {
              // check response field
              const responseField = CommonFunction.checkAllResponseField(data, 
                ResponseConstants.CommonResponseField.concat(ResponseConstants.SubscriptionUrlTelecom));
              console.log('/Payment/Get/SubscriptionUrlTelecom/', responseField?.join(','));
            }
  
            $return = data;
            if(data?.status!=1){
              // error
              CommonFunction.checkErrorCode(data);
            }
          }
        }else AuthHelper.showNetworkError();
  
        return $return;
      }catch(e){
        if(__DEV__) console.log(e);
      }
    }

  // テレコムとpaidyのポイント購入データ取得　Payment/Get/Order
  static async PaymentGetOrder(){
    try{
      let webUUID = await WebUtil.getWebUUID();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Get/Order/",
        {
          web_uuid: webUUID
        },
        true
      );
      if(__DEV__) console.log('/Payment/Get/Order/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.PaymentGetOrder));
            console.log('/Payment/Get/Order/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
  
  // Paidyオーソリ結果受信API（決済IDをPappyサーバに送信：ポイント付与は非同期で行われる）　　Payment/Post/Paidy
  static async PaymentPostPaidy(values){
    // 金額と決済IDを設定する
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Post/Paidy/",
        {
          paymentId: values.id,
          amount: values.amount
        },
        true
      );
      if(__DEV__) console.log('/Payment/Post/Paidy/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Payment/Post/Paidy/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // テレコムとPaidyのサブスク用のデータ取得API
  static async SubscriptionUrl(values){
    try{
      let webUUID = await WebUtil.getWebUUID();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Get/SubscriptionUrl/",
        {
          web_uuid: webUUID
        },
        true
      );
      if(__DEV__) console.log('/Payment/Get/SubscriptionUrl/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.SubscriptionUrl));
            console.log('/Payment/Get/SubscriptionUrl/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // paidyで発行したtokenをPappyサーバに送信
  static async PaymentPostPaidyToken(values){
    // tokenの登録
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Post/PaidyToken/",
        {
          tokenId: values.id,
        },
        true
      );
      if(__DEV__) console.log('/Payment/Post/PaidyToken/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Payment/Post/PaidyToken/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // ペイディサブスク解約API
  static async PaymentPutPaidyDiscard(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Put/PaidyDiscard/",
        {},
        true
      );
      if(__DEV__) console.log('/Payment/Put/PaidyDiscard/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Payment/Put/PaidyDiscard/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

}