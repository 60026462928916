import React,{Component} from 'react';
import { Dimensions, Text, View, Platform } from 'react-native';
import OtherIine from './iine/OtherIine';
import MyIine from './iine/MyIine';
import IineHeaderRight from '../components/common/IineHeaderRight';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import TabbarOptions from '../constants/TabbarOptions';
import HeaderOptions from '../constants/HeaderOptions';
import HeadStyle from '../styles/HeadStyles';
import TabBadge from '../components/common/TabBadge';
import { AppContext } from '../AppContext';
import CommonFunction from '../utils/CommonFunction';
import Logo from '../components/common/Logo';

const Tab = createMaterialTopTabNavigator();
const windowWidth = Dimensions.get('window').width;

export default class IineScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      swipeEnabled: false,
      badge: { unreadCount: 0 }
    };
    this.tab = { 
      num: 2, 
      width: 90 
    };
    this.left = ((windowWidth/this.tab.num)-this.tab.width)/2;
    this.title = 'Iine'; // web title page
    this.tabBarItemWidth = windowWidth/this.tab.num;
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      const { navigation } = this.props;
      this.navigation = navigation;
      this.setHeader();

      this._unsubscribe = this.props.navigation?.addListener('focus', () => {
        // show bottom tab
        CommonFunction.showBottomTab(this.navigation);
      });
      
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }

  setTopBadge = (badge)=>{
    this.setState({badge: badge});
  }

  getOtherTabRef = ()=>{
    return this.tabOtherRef;
  }

  getMyTabRef = ()=>{
    return this.tabMyRef;
  }

  parentState = (state=null)=>{
    if(state){
      this.setState({parentState: state});
    }else{
      return this.state.parentState;
    }
  }

  setHeader(){
    this.navigation.setOptions({
      headerStyle: [HeaderOptions.HeaderIine,HeaderOptions.HeaderIosHeight],
      headerTitleStyle: HeaderOptions.HeaderIineTitle,
      headerTitleAlign:'center',
      headerTitle: () => (
        <Text style={[HeadStyle.headerTitlePage,{fontSize:16, fontWeight:'normal'}]}>いいね</Text>
      ),
      headerRight: ()=> <IineHeaderRight onPress={()=>{
        const tabRef = this.getOtherTabRef();
        // let checkSMS = true;
        // if(tabRef){
        //   checkSMS = tabRef?.checkSMS();
        //   if(!checkSMS) return;
        // }

        this.navigation.navigate('SkipIineList');
      }} />,
      headerLeft:()=> <Logo/>
    });
  }

  render() {
    return (
      <Tab.Navigator
        screenOptions={{
          //title: this.title,
          lazy: true,
          swipeEnabled: false,
          tabBarLabelStyle: TabbarOptions.labelStyle,
          tabBarStyle: TabbarOptions.style2,
          tabBarItemStyle:{ width: this.tabBarItemWidth },
          tabBarIndicatorStyle: [TabbarOptions.indicatorStyle, {width: this.tab.width, left: this.left}],
          tabBarActiveTintColor: TabbarOptions.activeTintColor,
          tabBarInactiveTintColor: TabbarOptions.inactiveTintColor,
          animationEnabled: false
      }}>
        <Tab.Screen name="Other" 
          children={()=><OtherIine ref={ref=>this.tabOtherRef=ref} setBadge={this.props?.setBadge} setTopBadge={this.setTopBadge} {...this.props} parentState={this.parentState} otherTabRef={this.getMyTabRef} />} 
          options={{ tabBarLabel: 'お相手から',tabBarBadge:()=>
            <View style={{position:'absolute', top:4, right:37}}>
              <TabBadge badge={this.state.badge?.unreadCount}/>
            </View>
          }} 
        />
        <Tab.Screen name="My" 
          children={()=><MyIine ref={ref=>this.tabMyRef=ref} {...this.props} parentState={this.parentState} otherTabRef={this.getOtherTabRef} />} 
          options={{ tabBarLabel: '自分から' }} 
          listeners={{
            tabPress: (e) => {
              const tabRef = this.getOtherTabRef();
              let checkSMS = true;
              if(tabRef){
                checkSMS = tabRef?.checkSMS();
                if(!checkSMS) e.preventDefault();
              }
            }
          }}
        />
      </Tab.Navigator>
    );
  }
}
