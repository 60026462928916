import React from 'react'
import BackHandler from './BackHandler';
import { Platform } from 'react-native';

const listScreenNoActivity = ['Terms'];

/** This helper will call onBackPress when we press the hardware back button */
const useBackKey = (onBackPress?: () => void) => {
  React.useEffect(() => {
    if (!onBackPress) return

    if(Platform.OS==='web'){
      let flag = true;
      const initialUrl = window.location.href;
      listScreenNoActivity.forEach((element) => {
        if(initialUrl.indexOf(element)!=-1) { flag = false; };
      });
      if(!flag) return;
    }

    const onBack = () => {
      onBackPress()
      return true
    }

    BackHandler.startEvent();
    BackHandler.addEventListener('hardwareBackPress', onBack);
    return () => { 
      BackHandler.stopEvent(); 
      BackHandler.removeEventListener('hardwareBackPress', onBack); 
    }
  }, [onBackPress]);

}

export default useBackKey