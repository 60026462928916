import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, Dimensions } from 'react-native';
import styles from '../../styles/FormStyles';
import PortalStyle from '../../styles/PortalStyles';
import SMSManager from "./SMSManager";
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import Constants from '../../constants/Constants';
import StorageHelper2 from '../../utils/StorageHelper2';

const windowWidth = Dimensions.get('window').width;

export default class SMSverify extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      sex: Constants.FEMALE
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      SMSManager.register(this);
      const { navigation } = this.props;
      this.navigation = navigation;
      let sex = await this.getSex();
      this.setState({ sex: sex});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    SMSManager.unregister(this);
  }
  
  getSex = async()=>{
    const { appState } = this.context;
    let userProfile = appState?.userProfile;
    if(!userProfile || userProfile==undefined){
      userProfile = await StorageHelper2.getObjectData('userProfile');
    }
    return userProfile?.sex;
  }

  show = async(navigation, redirect=true)=>{
    if(navigation!=undefined)
      this.navigation = navigation;

    if(redirect!=undefined){
      this.redirect = redirect;
    }else{
      this.redirect = true;
    }

    let sex = await this.getSex();
    this.setState({ show: true, sex: sex});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  isShow = ()=>{
    return this.state.show;
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
            //this.hide();
          }} />
          <View style={PortalStyle.blocPopup}>
            <View style={[PortalStyle.blocPopupInner, {paddingTop:25, paddingRight:15, paddingBottom:15, paddingLeft:15, width:'80%',}]}>
              <View style={styles.popupInside}>
                <View style={{flexDirection:'column', justifyContent:'center', alignItems:'flex-start', marginBottom:10, width:'100%',}}>
                  <Text style={{fontSize:windowWidth<375? 11:14, fontWeight:'bold', lineHeight:windowWidth<375? 20:24, color:'#484848'}}>ご利用には電話番号認証が必要です。{"\n"}ご本人確認のためにSMS送信による認証を{"\n"}お願い致します。</Text>
                  <Text style={{fontSize:windowWidth<375? 10:13, fontWeight:'normal', lineHeight:windowWidth<375? 20:24, color:'#484848'}}>※電話番号は第三者に一切公開されません。</Text>
                </View>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', padding: 8, width:'100%', backgroundColor:'#f5f2ee', borderRadius: 5,}}>
                  <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', marginTop:2, marginBottom:10, padding:5, width:'100%', backgroundColor:'#e3dbd0', borderRadius:5,}}>
                    <Text style={{fontSize:windowWidth<375? 11:14, fontWeight:'bold', lineHeight:windowWidth<375? 18:20, color:'#484848'}}>電話番号認証を終えると出来ること</Text>
                  </View>
                  {this.state?.sex==Constants.MALE ?
                  <View style={inlineStyles.itemCheck}>
                    <Image style={inlineStyles.icCheck} source={require('../../assets/images/check.png')}/>
                    <Text style={inlineStyles.txtCheck}>30いいね＆20ptを貰える</Text>
                  </View>
                  :null}
                  <View style={inlineStyles.itemCheck}>
                    <Image style={inlineStyles.icCheck} source={require('../../assets/images/check.png')}/>
                    <Text style={inlineStyles.txtCheck}>気になるお相手に「いいね！」を送れる</Text>
                  </View>
                  <View style={[inlineStyles.itemCheck, {marginBottom:5,}]}>
                    <Image style={inlineStyles.icCheck} source={require('../../assets/images/check.png')}/>
                    <Text style={inlineStyles.txtCheck}>お相手の通報履歴を確認できる</Text>
                  </View>
                </View>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', marginBottom:windowWidth<375? 5:8, width:'100%',}}>
                  <Image style={{width:windowWidth<375? 20:27, height:windowWidth<375? 9:12}} source={require('../../assets/images/arrow-bubble.png')}/>
                </View>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', marginBottom:5, width:'100%',}}>
                  <Text style={{fontSize:windowWidth<375? 10:13, fontWeight:'normal', lineHeight:windowWidth<375? 20:24, color:'#484848'}}>＼ かんたん10秒で認証完了 ／</Text>
                  </View>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', width:'100%',}}>
                  <TouchableOpacity style={inlineStyles.modalButtonBlue} onPress={()=>{ 
                    this.hide();
                    this.navigation.navigate('Auth',{ screen:'SmsInput'});
                  }}>
                    <Text style={inlineStyles.textModalButtonBlue}>電話番号認証をする</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={inlineStyles.modalButtonWhite} onPress={()=>{ 
                    this.hide();
                    /*
                    if(this.redirect!=false){
                      this.navigation.navigate('Home',{ screen:'MyPageStack', params:{ screen:'MyPage' }});
                    }
                    */
                  }}>
                    {/*<Text style={inlineStyles.textModalButtonWhite}>今はしない</Text>*/}
                    <Text style={inlineStyles.textModalButtonWhite}>閉じる</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const inlineStyles = StyleSheet.create({
  modalButtonBlue: {
    padding:8,
    width:'70%',
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  textModalButtonBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
  itemCheck:{
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    width:'100%',
  },
  icCheck:{
    marginTop:windowWidth<375? 4:5,
    marginLeft:2,
    marginRight:5,
    width:windowWidth<375? 9:10,
    height:windowWidth<375? 9:10,
  },
  txtCheck:{
    fontSize:windowWidth<375? 10:13,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 18:20,
    color:'#484848',
  },
});