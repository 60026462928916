import React,{ Fragment, Component } from 'react';
import { 
  StyleSheet, Text, View, TouchableOpacity, Platform  
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import DatePicker from 'rmc-date-picker/lib/DatePicker';
import PopPicker from 'rmc-date-picker/lib/Popup';
import PopupStyles from 'rmc-picker/lib/PopupStyles';
import RightSlidePopup from '../../components/common/RightSlidePopup';
import ChildRightSlidePopup from '../../components/common/DisplayText';
import { Formik } from 'formik';
import OptionData  from '../../constants/option/OptionData.json';
import AdvanceSearchSliderScreen from '../../screens/AdvanceSearchSliderScreen';
import CommonFunction from '../../utils/CommonFunction';
import HeadStyle from '../../styles/HeadStyles';
import Moment from 'moment';
import Constants from '../../constants/Constants';
import { AppContext } from '../../AppContext';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import UserUtil from '../../utils/UserUtil';
import Storage from '../../utils/StorageHelper2';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import OverlayWrapper from '../../components/common/OverlayWrapper';
import LogoNoMargin from '../../components/common/LogoNoMargin';
import ExpoConstants from 'expo-constants';
import HeaderOptions from '../../constants/HeaderOptions';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class MeetSearchScreenPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props){
    super(props);

    this.minDate = new Date();
    this.maxDate = new Date();
    //this.maxDate.setMonth(this.minDate.getMonth()+1);
    this.maxDate.setDate(this.minDate.getDate()+29); 
    this.date_select=[];
    this.date_select[0]=this.minDate.getFullYear();
    this.date_select[1]=this.minDate.getMonth();
    this.date_select[2]=this.minDate.getDate();
    this.formik = React.createRef();
    this.Area = {};
    this.initialValues = {
      meetDate: this.minDate,
      adds: [],
      age: [],
      dateHope: []
    }

    this.state = {
      loading: true,
      searchInfo: this.initialValues,
      selectDate: null,
    }
  }
  
  async componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      // popup reference
      if(this.props.getRightPopupRef!=undefined){
        let refname = this.props?.refname;
        if(refname==undefined) refname = 'MeetSearchScreenPopup';
        this.rightPopupRef = this.props.getRightPopupRef(refname);
      }

      // remove Area id 0
      this.Area = OptionData.Master.Area.Items.filter(function(item){
        return item.id > 0;
      });

      const { appState } = this.context;
      this.myProfile = await UserUtil.getUserProfileLocal(appState);
      if(this.myProfile?.currentPref!=undefined){
        const currentPref = this.myProfile.currentPref;
        this.initialValues.adds = [{id: currentPref.id, value: currentPref.label}];
        this.initialValues.addsTxt = currentPref.label;
      }

      let searchInfo = await Storage.getObjectData('searchMeet');
      if(searchInfo!=undefined) {
        if(searchInfo.meetDate!=undefined) {
          searchInfo.meetDate = new Date(searchInfo.meetDate);
          if(Moment(Moment(searchInfo.meetDate).format('YYYY-MM-DD')).isBefore(Moment(this.minDate).format("YYYY-MM-DD"))){
            searchInfo.meetDate = this.minDate;
          }
        }
      }else {
        searchInfo = this.initialValues;
      }

      this.setState({ loading: false, searchInfo: searchInfo });
    }
  }

  onDatePickerChange = (date) => {
    if(this.date_select[1]!=date[1]){
      let minDateOfMonth = new Date(date[0],date[1],1);
      let minDateTxt = Moment(this.minDate).format('YYYY-MM-DD');
      let currentDateTxt = Moment(minDateOfMonth).format('YYYY-MM-DD');
      if(Moment(minDateTxt).isAfter(currentDateTxt)){
        minDateOfMonth=this.minDate;
      }
      this.date_select=date;
      this.setState({ selectDate: minDateOfMonth });
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  displayDate = (date) => {
    if(date==undefined) date = this.state.searchInfo?.meetDate;

    const year = Moment(date)?.format("YYYY");
    const month = Moment(date)?.format("MM");
    const day = Moment(date)?.format("DD");
    return `${year}年 ${month}月 ${day}日`;
  }

  handleSubmit = async (values) => {
    const checkAccessTime = await CommonFunction.checkAccessTime();
    if(!checkAccessTime) return;

    try{
      if(Platform.OS==='web') OverlayWrapper.show(true);
      
      if(values.adds?.length < 1){
        this.popup2?.showPopup();
      }else{
        Storage.storeObjectData('searchMeet', values);
        this.rightPopupRef?.ShowPopUp(false);
        if(this.props.callback!=undefined){
          this.props.callback();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      if(Platform.OS==='web') OverlayWrapper.hide();
    }
  }
  
  render(){
    if(this.state.loading) {
      if(Platform.OS==='web') return <ActivityIndicator/>;
      else return <></>
    }
    
    const datePicker = (
      <DatePicker
        rootNativeProps={{'data-xx': 'yy'}}
        minDate={this.minDate}
        maxDate={this.maxDate}
        defaultDate={this.state.searchInfo?.meetDate}
        date={this.state.selectDate}
        mode={'date'}
        formatMonth={ (month, date) => {return (month+1).toString().padStart(2, '0')+'月';}}
        formatDay={ (day, date) => {return day.toString().padStart(2, '0')+'日';}}
        onValueChange={this.onDatePickerChange}
        itemStyle={{
          ...Platform.select({
            android:{
              fontSize:18,
              height:40,
              fontWeight: 'normal'
            },
            ios:{
              fontSize: 18
            }
          })
        }}
      />
    );

    const insets = this.context?.appState?.insets;

    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={[styles.headerLeft,{flexDirection:'row',alignItems:'center'}]}>
            <TouchableOpacity onPress={()=>{
              this.rightPopupRef?.ShowPopUp(false);
            }}>
              <AntDesign name={'close'} style={[HeadStyle.icBack, {paddingLeft:14, paddingRight:4}]} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
            <Text style={HeadStyle.headerTitlePage}>絞り込み</Text>
          </View>
          <View style={styles.headerRight}>
            <TouchableOpacity onPress={()=>{
              this.formik?.setValues(this.initialValues);
            }}>
              <Text style={HeadStyle.txtReset}>クリア</Text>
            </TouchableOpacity>
          </View>
        </View>

        <Formik
          initialValues={this.state.searchInfo}
          onSubmit={values => this.handleSubmit(values) }
          innerRef={p => (this.formik = p)}
        >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
        <Fragment>
          <View style={styles.section}>
            <View style={styles.sectionWrapper}>
              <View style={styles.sectionInside}>
                <View style={styles.formLabel}>
                  <Text style={styles.label}>日付</Text>
                </View>
                <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end', alignItems:"center",alignContent:'center'}}>
                  <PopPicker
                    datePicker={datePicker}
                    styles={PopupStyles}
                    style={{fontFamily:'Segoe UI,roboto,sans-serif', fontSize:16}}
                    transitionName="rmc-picker-popup-slide-fade"
                    maskTransitionName="rmc-picker-popup-fade"
                    title="会いたい日を選択"
                    date={this.state.selectDate==null ? this.state.searchInfo?.meetDate : this.state.selectDate}
                    okText="完了"
                    dismissText="キャンセル"
                    onChange={(date)=>{
                      setFieldValue('meetDate', date);
                    }}>
                    {Platform.OS==='android' || Platform.OS==='ios'?
                    <TouchableOpacity>
                      <Text style={styles.selectText}>{this.displayDate(values.meetDate)}</Text> 
                    </TouchableOpacity>
                    :
                    <Text style={styles.selectText}>{this.displayDate(values.meetDate)}</Text>
                  }
                  </PopPicker>
                </View>
              </View>
              <View style={styles.sectionInside}>
                <View style={styles.formLabel}>
                  <Text style={styles.label}>会いたい地域（複数選択可能）</Text>
                </View>
                <RightSlidePopup fieldName={'adds'} title={'会いたい地域（複数選択可能）'} items={this.Area} onChange={setFieldValue} values={values.adds} mustselect={true} maxselect={5}>
                  <ChildRightSlidePopup values={values.addsTxt} />
                </RightSlidePopup>
              </View>
              <View style={styles.sectionInside}>
                <View style={styles.formLabel}>
                  <Text style={styles.label}>年齢</Text>
                </View>                  
                <RightSlidePopup title={'年齢'} component={
                  <AdvanceSearchSliderScreen 
                    fieldName={'age'} 
                    onChange={setFieldValue} 
                    values={values.age} 
                    min={this.myProfile?.sex==Constants.FEMALE?OptionData.Master.Age2.FeMale.min:OptionData.Master.Age2.Male.min} 
                    max={this.myProfile?.sex==Constants.FEMALE?OptionData.Master.Age2.FeMale.max:OptionData.Master.Age2.Male.max} 
                    unit={OptionData.Master.Age2.unit} 
                    postfix_min={'以上'} 
                    postfix_max={'以下'} 
                  />
                }>
                  <ChildRightSlidePopup values={values?.ageTxt} />
                </RightSlidePopup>
              </View>
              <View style={styles.sectionInsideNoBorder}>
                <View style={styles.formLabel}>
                  <Text style={styles.label}>デートでしたいこと</Text>
                </View>
                <RightSlidePopup fieldName={'dateHope'} title={'デートでしたいこと'} items={OptionData.Master.DateHope.Items} onChange={setFieldValue} values={values.dateHope}>
                  <ChildRightSlidePopup values={values?.dateHopeTxt} />
                </RightSlidePopup>
              </View>
            </View>
          </View>

          <View style={[styles.footer,{bottom: insets?.bottom}]}>
            <View style={styles.btnSubmit}>
              <TouchableOpacity onPress={handleSubmit} style={styles.btnBlue}>
                <Text style={styles.txtBtnBlue}>この条件で検索する</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Popup ref={ref=>this.popup2=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              //this.popup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'65%', backgroundColor:'#fefefe', borderRadius: 12,}}>
            <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>地域は1つ以上選択してください</Text>
            <TouchableOpacity onPress={()=>this.popup2?.hidePopup()} style={[styles.btnGray, {marginTop:20,}]}>
              <Text style={styles.txtBtnGray}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

        </Fragment>
      )}          
      </Formik>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    backgroundColor:'#eef0f1',
    ...Platform.select({
      ios:{
        marginTop: ExpoConstants?.statusBarHeight
      }
    })
  },
  section:{
    borderTopColor:'#eef0f1',
    borderTopWidth:20,
  },
  sectionWrapper:{
    backgroundColor:'#fff',
    borderTopWidth:1,
    borderTopColor:'#e6e6e6',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  sectionInside:{
    flexDirection:'row',
    alignSelf:'stretch',
    marginLeft:16,
    paddingRight:8,
    minHeight:44,
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  sectionInsideNoBorder:{
    flexDirection:'row', 
    alignSelf:'stretch',
    marginLeft:16,
    paddingRight:8,
    minHeight:44,
    backgroundColor:'#fff',
  },
  formLabel:{
    alignItems:'flex-start'
  },
  label:{
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    fontSize: 14,
    lineHeight: 19,
    color:'#484848',
  },
  selectText:{
    color:'#5cc8ca',
    fontSize:14,
    lineHeight:19,
  },
  footer:{
    position:'absolute',
    backgroundColor: '#eef0f1',
    height:60,
    bottom:0,
    width:'100%',
    justifyContent:'center',
  },
  btnSubmit:{
    lineHeight:50,
    height:50,
    paddingTop:0,
    paddingLeft:18,
    paddingRight:18,
  },
  btnBlue: {
    backgroundColor:'#5cc8ca'
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  header:{
    flexDirection:'row',
    borderBottomWidth:1,
    backgroundColor:'#ffffff',
    borderBottomColor:'#b2b2b2',
    shadowColor: 'transparent',
    width:'100%',
    height: HeaderOptions.headerTopMenuHeight,
    ...Platform.select({
      ios: {
        marginTop:0,
       },
      android: { 
        marginTop:0,
      },
      default: {
      }
    })
  },
  headerCenter:{
    flex:1, 
    alignItems:'stretch',
    justifyContent:'center',
    height:'100%', 
  },
  headerLeft:{
    position:'absolute', 
    justifyContent:'center', 
    height:'100%', 
    left:0, 
    top:0, 
    zIndex:1,
    elevation:1
  },
  headerRight:{
    position:'absolute', 
    justifyContent:'center', 
    height:'100%', 
    right:0, 
    top:0, 
    zIndex:1,
    elevation:1
  },
  btnGray:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:30,
    backgroundColor:'#ebebeb',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnGray:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#666666',
    textAlign:'center'
  }
});