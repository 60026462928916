import React, { Component } from 'react';
import {Dimensions, View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import * as RootNavigation from '../../navigation/RootNavigation';
import CommonFunction from '../../utils/CommonFunction';
import MailBoxApi from "../../services/api/MailBox05Api";
import PopupWrapper from '../../components/common/PopupWrapper';

const windowWidth = Dimensions.get('window').width;

export default class ChatImageDelete extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
    this.inputData = [];
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (params)=>{
    this.inputData = params.inputItem;
    const messageId = this.inputData.messageId;
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  messageImageDelete= async()=>{
    if (this.inputData!=undefined) {
      if (this.inputData.messageType == 4) {
        const params = {messageId: this.inputData.messageId};
        const MailBoxDeleteMessageResponse = await MailBoxApi.MailBoxDeleteMessage(params);
        if(!CommonFunction.checkResponseStatus(MailBoxDeleteMessageResponse)) return;
        if(MailBoxDeleteMessageResponse?.status==1){
          PopupWrapper.showMessage('削除しました','check',()=>{
            CommonFunction.hideOverlay();
          });
          const params = {deleteItem: this.inputData};
          this.props.chatListUpdata(params);
        }
      }
    }
    this.hide();
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
            <View style={styles.textArea}>
              <View style={{paddingTop: 24, bottom: 0, width: '100%'}}>
                <Text style={{fontSize: 20, fontWeight: 'bold', color: '#333', textAlign: 'center', lineHeight: 26}}>
                  送信したメッセージを{"\n"}
                  削除しますか？
                </Text>
                <Text style={{paddingTop: 15, fontSize:windowWidth<375? 11 : 12, color: '#333', textAlign: 'center', lineHeight: 20}}>
                  メッセージを削除すると{"\n"}
                  お互いの画面で表示されなくなります。{"\n"}
                  ※削除の取り消しは出来ません。
                </Text>
              </View>
            </View>
            <View style={styles.btnArea}>
              <View style={{paddingTop: 0, alignSelf: 'center', itemAlign:'center'}}>
                <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                  this.messageImageDelete();
                }}>
                  <Text style={styles.txtBtnBlue}>削除する</Text>
                </TouchableOpacity>
              </View>
              <View style={{paddingTop: 20, alignSelf: 'center', itemAlign:'center'}}>
                <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                  this.hide();
                }}>
                  <Text style={styles.txtBtnCancel}>キャンセル</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:294, 
    width:'90%',
    alignSelf:'center', 
    borderRadius:8
  },
  textArea: {
    height:165,
    width:'100%',
    itemAlign:'center',
  },
  btnArea: {
    paddingTop:10,
    width:'100%',
    itemAlign:'center',
  },
  btnBlue:{
    width:222,
    height:38,
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignSelf:'center', 
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  btnCancel:{
    width:208,
    height:32,
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
    backgroundColor:'#eef1f2',
    alignSelf:'center', 
  },
  txtBtnCancel:{
    fontSize:15,
    fontWeight:'bold',
    lineHeight:26,
    color:'#333',
    textAlign:'center',
  },
});