import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, View, ScrollView, 
  ImageBackground, Dimensions, SafeAreaView, TouchableOpacity, FlatList, Alert 
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import MyPage13Api from '../services/api/MyPage13Api';
import ActivityIndicator from '../components/common/ActivityIndicator';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import { Entypo } from '@expo/vector-icons';
import ListStyles from '../styles/ListStyles';
import UserVerify from '../utils/UserVerify';
import { RefreshControl } from 'react-native-web-refresh-control';
import PurchaseFunctionIos from '../utils/PurchaseFunction_ios';
import Payment15Api_ios from '../services/api/Payment15Api_ios';
import { isSystemPayment } from '../modules/purchase';
import { getProductDetail } from '../modules/purchase';
import ErrorCode from '../constants/ErrorCode';
import PopupWrapper from '../components/common/PopupWrapper';
import HeadStyles from '../styles/HeadStyles';
import LogoNoMargin from '../components/common/LogoNoMargin';
import TitleSetting from '../components/common/TitleSetting';
import { addGetProductItemListener } from '../modules/purchase';
import { addCompPaymentEventListener } from '../modules/purchase';
import { addPurchaseErrorEventListener } from '../modules/purchase';
import { removeGetProductItemListener } from '../modules/purchase';
import { removeCompPaymentEventListener } from '../modules/purchase';
import { removePurchaseErrorEventListener } from '../modules/purchase';
import ApiConfig from '../constants/ApiConfig';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PointShoppingScreen_ios extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      data: [],
      point: 0,
      account: UserUtil.getDefaultAccountAppState(),
      alreadyCallApi: false,
    };
    this.prevRoute = ['IinePlan','Profile','SameProfile','ChatRoom'];
    this.paymentFlg = false;
    this.loadingPopup = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      // イベント受信時のハンドラー登録
      addGetProductItemListener(this.handleGetProductEvent);
      addCompPaymentEventListener(this.handleCompPaymentEvent);
      addPurchaseErrorEventListener(this.handlePurchaseErrorEvent);

      this.navigation = this.props?.navigation;
      this.parentNavigation = this.navigation?.getParent();
      
      this.setHeader();

      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        this.getDataFromApi();        
        CommonFunction.hideBottomTab(this.parentNavigation);
      });
      const { appState } = this.context;
      if(appState.account){
        this.setState({ account: appState.account});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
    const routes = this.parentNavigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)==-1) CommonFunction.showBottomTab(this.parentNavigation);
    }
    // イベント受信解除
    removeGetProductItemListener();
    removeCompPaymentEventListener();
    removePurchaseErrorEventListener();
  }

  setHeader(){
    this.title = "ポイント購入";
    this.navigation.setOptions({
      header: () => (
        <View style={HeadStyles.customHeader}>
          <View style={[HeadStyles.touchIcBack, {flexDirection:'row',left:16}]}>
            <TouchableOpacity onPress={async()=> {
              if(this.paymentFlg===false){
                this.navigation.goBack();
              }
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyles.icBack2}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch'}}><TitleSetting title={this.title} numberOfLines={1} /></View>
        </View>
      )
    });
   }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.navigation
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  renderPackageItem = ({item, index}) => {
    let env = false;
    if (ApiConfig.mode==='development') {
      env = true;
    }
    let banner  = getProductDetail(item.productId, env);
    // console.log("環境",ApiConfig.mode);
    const key = Date.now();

    if(banner){
      return (
        <View style={{flex:1, width:'100%', justifyContent:'center',alignSelf:'center',alignItems:'center',alignContent:'center'}}>
          <TouchableOpacity style={{width:'100%'}} onPress={async()=>{
            const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
            if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;
      
            if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
              const account = UserUtil.getAccountAppState(checkMemberStatResponse, this.context);
              this.setState({ account: account }, ()=>{
                if(!this.checkSMS()) return;
                if(!this.checkAge()) return;
                this.handleOnPress(item);
              });
            }
          }}>
            <Image style={styles.itemBlogRatePlan} source={{uri:banner+"?"+key}}/>
          </TouchableOpacity>  
        </View>        
      );
    }
  }
  
  noItemDisplay = () => {
    return <></>;
  }

  // プロダクトIDの取得完了
  handleGetProductEvent = (eventData) => {
    let pdata = JSON.parse(eventData.productItems); 
    console.log("pdata",pdata);
    this.compGetProductIdForAppleStore(pdata);
  }

  // Appleストアの購入処理完了
  handleCompPaymentEvent = async(eventData) => {
    this.paymentFlg = true;
    let transactionId = eventData.transactionId;
    console.log("transactionId",transactionId);
    await PurchaseFunctionIos.savePointTransaction(transactionId);
    await this.PurchasePointAction();
  }

  // 課金操作でのエラー発生
  handlePurchaseErrorEvent = (eventData) => {
    this.paymentFlg = false;
    let message = eventData.message;
    this.loadingPopup.hidePopup();
    console.log("message",message);
    PopupWrapper.showMessage(message,'warning',()=>{
      CommonFunction.hideOverlay();
    });
  }

  // 画面遷移直後のデータ取得（自身のポイント取得のためCheckMemberStatを実施）
  getDataFromApi = async()=>{
    // 自身の所持ポイントを取得
    if(!this.state.isRefreshing && !this.state.alreadyCallApi){
      this.setState({ loading: true });
    }

    try{
      const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
      if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;
      
      let point = 0;
      if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
        point = checkMemberStatResponse?.point;
      }
      this.setState({
        point: point,
        alreadyCallApi: true
      });
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false, isRefreshing: false});
    }

    // ポイント付与チェックを実施
    await this.checkPaymentPurchasePoint();

    // ポイント購入可能なプロダクトIDを取得する
    this.getPlatformProductData();
  }

  CheckMemberStat = async()=>{
    try{
      const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
      if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;
      
      let point = 0;
      if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
        point = checkMemberStatResponse?.point;
      }
      this.setState({
        point: point,
      });
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false, isRefreshing: false});
    }
  }

  //　購入アイテム一覧を表示するため、購入可能なプロダクトIDを要求
  getPlatformProductData = async ()=>{
        // 購入可能かチェック(端末の課金有効設定）
    if(isSystemPayment()) {
      // 販売可能なプロダクトIDをAppleに問い合わせ
      await PurchaseFunctionIos.checkPointProductIds();
    } else {
      PopupWrapper.showMessage('購入が許可されていません。端末の課金許可設定を確認してください','warning',()=>{
        CommonFunction.hideOverlay();
      });
    }
  }

  // 取得したプロダクトIDの一覧から購入アイテム一覧を表示する
   compGetProductIdForAppleStore = (pdata) => {
    this.setState({
      data: pdata,
    });
  }

  // プラン購入ボタンタップ時の処理
  paymentAction = async (item)=>{
    this.paymentFlg = true;
    this.loadingPopup.showPopup();
    // 購入可能かチェック(端末の課金有効設定）
    if(isSystemPayment()) {
    } else {
      PopupWrapper.showMessage('購入が許可されていません。端末の課金許可設定を確認してください','warning',()=>{
        CommonFunction.hideOverlay();
      });
      this.paymentFlg = false;
      this.loadingPopup.hidePopup();
      return;
    }

    let getTransaction = await PurchaseFunctionIos.getPointTransaction();
    if (getTransaction!=undefined) {
      PopupWrapper.showMessage('前回ポイント購入時の処理が完了していません。\nポイント付与の処理を正常に行うため、電波の良いところでアプリをご利用ください。','warning',()=>{
        CommonFunction.hideOverlay();
      });
      this.paymentFlg = false;
      this.loadingPopup.hidePopup();
      return;
    } else {
      console.log("トランザクションID無し");
    }
    this.loadingPopup.showPopup();
    PurchaseFunctionIos.pointPurchaseAction(item.productId);    
  }

  // ポイント付与APIを送信する
  PurchasePointAction = async ()=>{
    await this.checkPaymentPurchasePoint();
  }

  // 保存しているトランザクションIDがある時はポイント付与APIを実施
  checkPaymentPurchasePoint = async ()=>{
    let getTransaction = await PurchaseFunctionIos.getPointTransaction();
    if (getTransaction!=undefined) {
      console.log("getTransaction",getTransaction);
      console.log("トランザクションID有り:ポイント付与のAPIを実行する");
      this.paymentPurchasePointIos();    
    }
  }

  // 購入が成功したtransactionIdをPappyサーバに送信
  paymentPurchasePointIos = async ()=>{
    if(!this.state.isRefreshing){
      this.setState({ loading: true });
    }
    
    try{
      let saveTransactionId = await PurchaseFunctionIos.getPointTransaction();
      const params = {transactionId: saveTransactionId, errorFlg: 0};
      const response = await Payment15Api_ios.PurchasePointAppStore(params);
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(checkUndefined(response) && response?.status==1){
        let point = 0;
        point = response?.point;
        // ポイント購入成功、トランザクションIDを消去する
        PurchaseFunctionIos.deletePointTransaction();
        Alert.alert('', 'ポイントを購入しました', [
          {
            text: 'OK',
          },
        ]);
        this.setState({
          point: point,
        });
      } else {
        PopupWrapper.showMessage('ポイント購入が失敗しました。','warning',()=>{
          CommonFunction.hideOverlay();
        });
        const errorCode = `${response?.response?.errorGroup}-${response?.response?.errorCode}`;
        if(errorCode==ErrorCode.PurchasePointAppStore.Duplication){
          PurchaseFunctionIos.deletePointTransaction();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false, isRefreshing: false});
      this.paymentFlg = false;
      this.loadingPopup.hidePopup();
    }
  }

  onRefresh = ()=>{
    this.setState({isRefreshing: true}, ()=>{
      this.getDataFromApi();
    });
  }

  createInputElement = (type='hidden',name,value)=>{
    let element = document.createElement('input');
    element.setAttribute("type", type);
    element.setAttribute("name", name);
    element.setAttribute("value", value);
    return element;
  }

  handleOnPress = async (item)=>{
    if(this.paymentFlg==true) {
      return
    }
    await this.paymentAction(item)
  }
  
  getItemLayout = (data, index) => {
    const length = windowWidth<375? 114:134; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
      <SafeAreaView style={ListStyles.container}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
          />
        }>
          <ImageBackground source={require('../assets/images/point/bg-top.png')} resizeMode="cover" style={styles.bgTopImg}>
          </ImageBackground>
          <View style={styles.blogPadding}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center', padding:10, width:'100%', backgroundColor:'#f1f1f1', borderRadius:8}}>
              <View style={{alignSelf: 'flex-start', paddingLeft: 5}}>
                <Text style={{fontSize:13, lineHeight:18,}}>現在のポイント</Text>
              </View>
              <View style={{alignSelf: 'flex-end', paddingRight: 5}}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                  <View style={{marginRight:5, width:18, height:18, backgroundColor:'#f8d549',borderRadius:999,textAlign:'center'}}>
                    <Text style={{fontSize:14, fontWeight:'bold', color:'#ffffff', lineHeight:18,textAlign:'center'}}>P</Text>
                  </View>
                  <Text style={{fontSize:14, lineHeight:18}}>{this.state.point}</Text>  
                </View>                
              </View>
            </View>
            <View style={{width:'100%',alignItems:'flex-end', marginTop: 7}}>
              <TouchableOpacity style={{flexDirection:'row', alignItems:'center'}} onPress={()=>{
                this.navigation.navigate('PointList');
              }}>
                <Text style={{fontSize:11, fontWeight:'bold', lineHeight:18, color:'#333333'}}>ポイント履歴を確認する</Text>
                <Entypo name="chevron-right" style={{fontSize:11, lineHeight:18, color: '#4b4b4b'}}/>
              </TouchableOpacity>
            </View>
          </View>
          {this.state.data?.list?.length > 0 && (
          <>
          <View style={styles.blogPointPlan}>
            <Text style={styles.txtBlogPointPlan}>ご希望のポイントを選んでください</Text>
          </View>
          <View style={styles.blogRatePlanInside}> 
            <FlatList
              scrollEnabled={false}
              contentContainerStyle={{ flexGrow: 1 }}
              data={this.state.data?.list}
              renderItem={this.renderPackageItem}
              ListEmptyComponent={this.noItemDisplay}
              keyExtractor={item => item.productId}
              inverted={true}// 順番反転
              getItemLayout={this.getItemLayout}
            />
          </View>
          </>
          )}

          <View style={[styles.blogTitle, styles.bgGray]}>
            <Text style={styles.txtTitle}>ポイントを使って出来ること</Text>
          </View>

          <View style={styles.blogInner}>
            <View style={styles.blogInside}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その1</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>1ポイント＝1いいね！{"\n"}に交換できる</Text>
              </View>              
              <View style={styles.blogImg}>
                <Image style={styles.img01} source={require('../assets/images/point/point-img01.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>手持ちのいいね数が足りない時は{"\n"} <Text style={styles.txtGreen}>1ポイント＝1いいねに交換</Text>できます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>お相手とマッチングすればやりとりが可能になるので{"\n"}いいねを送って積極的にアタックをしましょう</Text>
              </View>
            </View>
            <View style={styles.blogInside}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その2</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>マッチングしていないお相手に{"\n"}ダイレクトメッセージを送る</Text>
              </View>              
              <View style={styles.blogImg}>
                <Image style={styles.img02} source={require('../assets/images/point/point-img02.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>1いいね＋3ポイントを消費すれば{"\n"}マッチングしていないお相手に{"\n"}<Text style={styles.txtGreen}>ダイレクトメッセージ</Text>を送れます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>お相手に直接メッセージを送る事ができるので{"\n"}いいねだけよりも効果的にアプローチが可能になります</Text>
              </View>
            </View>
            <View style={[styles.blogInside, {marginBottom:40,}]}>
              <View style={styles.bgPastNum}>
                <Text style={styles.txtBGPastNum}>その3</Text>
              </View>
              <View style={{padding: 20, textAlign:'center',}}>
                <Text style={styles.txtImgBGOrange2}>セーフティチェックで{"\n"}お相手の利用状況が確認できる</Text>
              </View> 
              <View style={styles.blogImg}>
                <Image style={styles.img03} source={require('../assets/images/point/point-img03.png')} resizeMode="contain"/>
              </View>
              <View style={styles.blogPadding2}>
                <Text style={[styles.txt16, {textAlign:'center'}]}>お相手と<Text style={styles.txtGreen}>安心してやり取りする</Text>ための{"\n"}気になる情報を確認することができます</Text>
              </View>
              <View style={{padding:windowWidth<375? 12 : 15, paddingTop: 10, textAlign:'center',}}>
                <Text style={{fontSize:windowWidth<375? 11 : 12, lineHeight:windowWidth<375? 22 : 23, textAlign:'center'}}>直近1ヶ月間のお相手のご利用状況が分かるので{"\n"}安心してやりとりをしたい方にオススメです</Text>
              </View>
            </View>
            <View style={[styles.blogTitle, {marginTop: 0, marginBottom: 20,}]}>
              <Text style={styles.txtTitle}>その他のポイント入手方法</Text>
            </View>
            <View style={[styles.blogInside, {marginBottom:30}]}>
              <View style={[styles.blogImg, {paddingTop: 20}]}>
                <Image style={styles.img04} source={require('../assets/images/point/point-img04.png')} resizeMode="contain"/>
              </View>
              <View style={[styles.blogPadding2, {paddingTop: 10, paddingBottom: 20,}]}>
                <Text style={[styles.txt18, {textAlign:'center'}]}>有料会員の方には{"\n"}<Text style={styles.txtOrange}>毎月50ポイントプレゼント</Text>致します</Text>
              </View>
            </View>            
            <View style={{}}>
              <Text style={styles.headNotePlan}>ポイントでできること</Text>
              <View style={styles.notePlan}>
                <Text style={styles.txtNotePlan}>※価格はすべて税込みです{"\n"}
                ※保有できるポイントは最大10,000ポイントです{"\n"}
                ※ご購入いただいたポイントは返金できません</Text>
              </View>
              <TouchableOpacity onPress={()=>{
                if(this.paymentFlg==false){
                  this.navigation.navigate('Terms')                
                }
              }}>
                <Text style={styles.aLink}>利用規約</Text>
              </TouchableOpacity>
               <TouchableOpacity onPress={()=>{
                if(this.paymentFlg==false){
                  this.navigation.navigate('Policy')
                }
              }}>
                <Text style={styles.aLink}>プライバシーポリシー</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
        </Popup>
    </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  blogInner:{
    flex:1,
    padding:windowWidth<375? 15 : 22,
    backgroundColor:'#f2f2f2',
  },
  blogTitle: {
    marginTop: 35,
    width:'100%',
  },
  bgGray: {
    paddingTop: 50,
    paddingBottom: 35,
    backgroundColor: '#f2f2f2',
  },
  txtTitle: {
    fontSize:windowWidth<375? 19 : 22,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
    textAlign:'center',
  },
  blogInside: {
    position:'relative',
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    marginBottom:55,
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:8,
    shadowColor: "#afc9b1",
    shadowOffset: {width: 0,height: 0,},
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 5,
  },
  txtImgBGOrange2: {
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 30,
    color:'#333333',
    textAlign:'center',
  },
  blogImg:{
    marginBottom:10,
    width:'100%',
  },
  img01:{
    width:'100%',
    height:windowWidth<375? 115 : 140,
  },
  img02:{
    width:'100%',
    height:windowWidth<375? 53 : 62,
  },
  img03:{
    width:'100%',
    height:windowWidth<375? 89 : 104,
  },
  img04:{
    width:'100%',
    height:windowWidth<375? 62 : 73,
  },
  txt18:{
    fontSize:windowWidth<375? 15 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 27 : 31,
    color:'#484848',
  },
  txt16:{
    fontSize:windowWidth<375? 13 : 16,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 25 : 29,
    color:'#484848',
  },
  txtGreen:{
    color:'#5cc8ca',
  },
  txtOrange:{
    color:'#fe895b',
  },
  blogPadding: {
    paddingLeft:windowWidth<375? 20 : 26,
    paddingRight:windowWidth<375? 20 : 26,
    width:'100%',
    textAlign:'center',
  },
  blogPadding2: {
    width:'100%',
    textAlign:'center',
  },
  blogPointPlan: {
    marginTop:30,
    marginBottom:12,
  },
  txtBlogPointPlan: {
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center',
    lineHeight:22,
    letterSpacing:-.4
  },
  blogRatePlanInside:{
    backgroundColor:'#fff',
    paddingTop:10,
  },
  itemBlogRatePlan:{
    //marginLeft:5,
    //marginRight:5,
    width:'100%',
    height:windowWidth<375? 128:148,
  },
  bgTopImg: {
    marginBottom:18,
    width:'100%',
    height:windowWidth<375? 91:107,
  },
  headNotePlan: {
    fontSize:11,
    fontWeight:'bold',
    lineHeight:20,
    color: '#333'
  },
  notePlan: {
    marginBottom:10,
  },
  txtNotePlan: {
    fontSize:10,
    fontWeight:'normal',
    lineHeight:16,
    color: '#333'
  },
  aLink: {
    marginTop:10,
    fontSize:11,
    fontWeight:'bold',
    lineHeight:18,
    color: '#333'
  },
  bgPastNum:{
    position:'absolute',
    top: -13,
    left: 'auto',
    right: 'auto',
    width: 110,
    height:windowWidth<375? 26 : 27,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
  },
  txtBGPastNum:{
    fontSize:windowWidth<375? 12 : 13,
    fontWeight: '700',
    lineHeight:windowWidth<375? 26 : 27,
    color: '#fff',
    textAlign: 'center',
  },
});