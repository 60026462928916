import { StyleSheet, Platform, Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#ffffff',
      alignItems: 'center',
      width:'100%',
      height:'100%',
    },
    ScrollContainer: {
      width:'100%',
      height:'100%',
    },
    HeadFormSection:{
      alignItems: 'flex-start',
      width:'100%',
      paddingTop: windowWidth>375?10:5,
      paddingRight:24,
      //paddingBottom:25,
      paddingBottom:15,
      paddingLeft:24,
      ...Platform.select({
        ios:{
          paddingTop: windowWidth>375?10:0,
        },
        android:{
          paddingTop: windowWidth>375?10:0,
        }
      })
    },
    txtHeadForm:{
      marginBottom:10,
      fontSize:25,
      fontWeight:'bold',
      lineHeight:32,
      color:'#484848'
    },
    txtHeadForm2:{
      marginRight:-15,
      marginBottom:10,
      fontSize:22,
      fontWeight:'bold',
      lineHeight:32,
      color:'#484848'
    },
    IinetxtHeadForm:{
      //marginRight:-15,
      //marginBottom:10,
      fontSize:20,
      fontWeight:'bold',
      //lineHeight:32,
      color:'#484848'
    },
    descHeadForm:{
      fontSize:14,
      fontWeight:'normal',
      lineHeight:20,
      color:'#9aa5a7',
    },
    BodyFormSection:{
      paddingLeft:32,
      paddingRight:32,
      width:'100%',
    },
    BodyContent:{
      padding:15,
      width:'100%',
    },
    bodyContent2:{
      padding:30,
      width:'100%',
    },
    formGroup:{
      marginBottom:windowWidth>375?15:5,
    },
    labelView:{
      marginTop:10,
      marginBottom:10,
    },
    labelText:{
      fontSize:14,
      fontWeight:'bold',
      color:"#484848",
      lineHeight: 20,
    },
    inputView:{
      justifyContent:"center",
      width:"100%",
      height:50,
      backgroundColor:"#eef1f2",
      borderBottomColor:'#5cc8ca',
      borderBottomWidth:1,
      borderTopLeftRadius:5,
      borderTopRightRadius:5,
    },
    inputText:{
      paddingTop:15,
      paddingBottom:15,
      paddingLeft:16,
      paddingRight:16,
      fontSize:16,
      lineHeight:20,
      color:"#000",
    },
    errorText:{
      alignItems:'flex-start',
      marginBottom:10,
      width:'80%',
      fontSize: 14, 
      color:'red',
    },
    icPassword:{
      justifyContent:'center',
      alignContent:'flex-end',
      marginLeft:10,
      marginRight:10,
    },
    eyeIcPass:{
      fontSize:24,
      color:'#484848',
    },
    forgot:{
      fontSize:14,
      color:'#484848',
    },
    forgot_link:{
      fontSize:14,
      fontWeight:'bold',
      color:"#484848",
      textDecorationLine:'underline',
    },
    buttonPanel:{
      //flex:1,
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      marginTop:20,
      width:"100%",
    },    
    loginBtn:{
      width:"80%",
      marginTop:10,
      marginRight:15,
      marginBottom:10,
      marginLeft:15,
      backgroundColor:"#5cc8ca",
      borderRadius:25,
      height:50,
      alignItems:"center",
      justifyContent:"center",
      shadowColor: "#10a4c3",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 1,
      shadowRadius: 1,
      elevation: 1,
    },
    loginText:{
      color:"white",
      fontWeight:"bold",
      fontSize: 16
    },
    descLabelView:{
      fontSize:14,
      fontWeight:'normal',
      lineHeight:20,
      color:'#9aa5a7',
    },    
    commentLabelView:{
      fontSize:14,
      fontWeight:'normal',
      lineHeight:20,
      color:'#484848',
    },
    txtLink:{
      //marginLeft:10,
      marginLeft:0,
      lineHeight:20,
      color:'#5cc8ca',
      textDecorationLine:'underline',
    },
    txtnoLink:{
      lineHeight:20,
      color:'#484848',
      textDecorationLine:'none',
    },
    h2:{
      fontSize: 20,
      fontWeight: 'bold'
    },
    inputTextSetting:{
      width:"100%",
      height:50,
      marginBottom:10,
      justifyContent:"center",
      borderBottomColor: '#f00',
    },
    buttonPanelSetting:{
      width:"100%",
      marginTop:20,
      flexDirection:'row', 
      justifyContent: 'center',
      ...Platform.select({
        ios: { },
        android: { },
        default: { flex:1, },
      }),
    },       
    submitBtn:{
      width:"80%",
      margin: 0,
      backgroundColor:"#5cc8ca",
      borderRadius:25,
      height:46,
      alignItems:"center",
      justifyContent:"center",
      shadowColor: "#10a4c3",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 1,
      shadowRadius: 1,
      elevation: 1,
    },
    buttonDisabled:{
      backgroundColor:"#ade4f0",
      shadowColor: "#ffffff",
    },
    picker:{
      width:"100%",
      backgroundColor:"#EEEEEE",
      borderColor:"#EEEEEE",
      borderRadius:10,
      marginBottom:5,
      justifyContent:"center",
      height:50,
    },
    disabledBtn:{
      width:"80%",
      marginTop:10,
      marginRight:15,
      marginBottom:10,
      marginLeft:15,
      backgroundColor:"#5cc8ca",
      borderRadius:25,
      height:50,
      alignItems:"center",
      justifyContent:"center",
      shadowColor: "#10a4c3",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 1,
      shadowRadius: 1,
      elevation: 1,
      opacity:0.4,
    },
    disabledText:{
      color:"white",
      fontWeight:"bold",
      fontSize: 16
    },
    skipLink:{
      marginRight:15,
      //marginLeft:30,
    },
    skipLinkTxt:{
      fontSize:16,
      fontWeight:'normal',
      color:'#5cc8ca',
    },
    icArrowRight:{
      fontSize:18,
      color:'#8d8d8d',
    },
    txtBodyContent:{
      fontSize:12,
      fontWeight:'normal',
      color:"#484848"
    },
    bubbleBG:{
      marginTop:50,
      width:311,
      height:68,
    },
    bubbleBGInside:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'baseline',
      padding:10
    },
    txtBubble:{
      fontSize:14,
      fontWeight:'normal',
      lineHeight:30,
      color:'#ff8a60',
    },
    imgRow:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
    },
    imgRowItem:{
      margin:2,
      width:116,
      height:116,
      borderRadius:10,
    },
    overlay:{
      height: '100%',
      width:'100%',
      backgroundColor:'#000000',
      opacity:0.5,
      position:'absolute',
      top:0,
      left:0
    },
    portal:{
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    popupContainer:{
      height:'100%',
      width:'100%',
      justifyContent:'center',
    },
    popupInner:{
      justifyContent:'center',
      alignItems:'center',
      alignSelf:'center',
      width:'80%',
      backgroundColor:'#fff',
      borderRadius:12,
    },
    popupHeader:{
      padding:15,
      width:'100%',
      borderTopLeftRadius:12,
      borderTopRightRadius:12,
    },
    headerRow1:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'baseline',
    },
    txtHeaderPopupRow1:{
      fontSize:16,
      fontWeight:'bold',
      lineHeight:26,
    },
    txtHeaderPopupRow2:{
      fontSize:windowWidth<375? 17 : 19,
      fontWeight:'bold',
      lineHeight:26,
    },
    txtHeaderPopupRow3:{
      fontSize:33,
      fontWeight:'bold',
      lineHeight:26,
      ...Platform.select({
        ios:{
          lineHeight:32,
        },
        android:{
          lineHeight:32,
        }
      })
    },
    txtHeaderPopupRow4:{
      fontSize:windowWidth<375? 14 : 16,
      fontWeight:'normal',
      lineHeight:26,
    },
    headerRow2:{
      flexDirection:'row',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      alignSelf:'center',
      marginBottom:15,
      width:'100%',
    },
    bubblePopup:{
      flex:1,
      padding:5,
      width:'65%',      
      height:32,
      backgroundColor:'#fff',
      borderRadius:8,
    },
    txtBubblePopup:{
      fontSize:9,
      fontWeight:'normal',
      lineHeight:22,
    },
    bubbleTriangle:{
      height:12,
    },
    imgTriangle:{
      width:12,
      height:12,
    },
    bubbleImg:{
      marginLeft:5,
    },
    imgPopup1:{
      width:45,
      height:45,
    },
    headerRow3:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
    },
    txtDescPopup1:{
      fontSize:13,
      fontWeight:'normal',
      lineHeight:24,
      textAlign:'center'
    },
    blocPopupInner:{
      padding:15,
      width:'100%',
      alignItems:'center'
    },
    txtDescPopup2:{
      fontSize:11,
      fontWeight:'bold',
      lineHeight:24,
      textAlign:'center'
    },
    imgPopup2Inner:{
      flexDirection:'row',
      marginTop:5,
    },
    imgPopupShadow:{
      margin:8,
      width:50,
      height:50,
      borderRadius:9999,
      shadowColor:"#000000",
      shadowOffset: {
        width:0,
        height:0,
      },
      shadowOpacity:0.75,
      shadowRadius:3,
      elevation:1,
    },
    imgPopup2:{
      width:50,
      height:50,
    },
    btnLink1:{
      marginTop:15,
      marginBottom:15,
      padding:10,
      width:'80%',
      height:42,
      backgroundColor:'#5cc8ca',
      borderRadius:999,
    },
    txtBtnLink1:{
      fontSize:14,
      fontWeight:'normal',
      lineHeight:22,
      color:'#fff',
      textAlign:'center',
    },
    icTxtBtnLink1:{
      fontSize:20,
      color:'#fff',
    },
    btnLink2:{
      marginBottom:25,
      textAlign:'center',
    },
    txtBtnLink2:{
      fontSize:11,
      fontWeight:'bold',
      lineHeight:20,
      color:'#666',
    },
    bgBlue:{
      backgroundColor:'#f6f7fa',
    },    
    bgPink:{
      backgroundColor:'#fff7fb',
    },
    txtBlue:{
      color:'#1e5295',
    },
    txtPink:{
      color:'#e6437c',
    },

    btnGray:{
      paddingLeft:8,
      paddingRight:8,
      width:'70%',
      height:30,
      backgroundColor:'#ebebeb',
      borderRadius:999,
      alignItems:"center",
      justifyContent:"center",
    },
    txtBtnGray:{
      fontSize:14,
      fontWeight:'bold',
      lineHeight:30,
      color:'#666666',
      textAlign:'center'
    },
    blogCheckbox:{
      flexDirection:'row',
      justifyContent:'center',
    },
    txtCheckbox:{
      /*fontSize:14,*/
      fontSize:windowWidth<375 ? 12 : 14,
      fontWeight:'bold',
      lineHeight:24,
      color:'#666',
      textShadowColor: 'rgba(255, 255, 255, 0.95)',
      textShadowOffset: {width: 1, height: 1},
      textShadowRadius: 10,
    },
    blueBtn:{
      width:"80%",
      margin: 0,
      backgroundColor:"#6ea3ec",
      borderRadius:25,
      height:46,
      alignItems:"center",
      justifyContent:"center",
      shadowColor: "#6ea3ec",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 1,
      shadowRadius: 1,
      elevation: 1,
    },
    submitBtn2:{
      width:"80%",
      margin: 0,
      backgroundColor:"#5cc8ca",
      borderRadius:25,
      height:46,
      alignItems:"center",
      justifyContent:"center",
    },
    menuItem:{
      flex:1, 
      flexDirection:'row',
      padding:10,
      paddingLeft:0,
      borderBottomWidth:1,
      borderBottomColor:'#e6e6e6',
      borderTopColor:'#e6e6e6',
    },
    menuItemText:{
      fontSize:12,
      lineHeight:27,
    },
    rightIcon:{
      position:'absolute',
      top:'50%',
      right:0,
      padding:10,
      fontSize:16,
      color:'#484848',
      ...Platform.select({
        ios: { transform:[{translateY:-10}] },
        android: { transform:[{translateY:-10}] },
        default: { transform:[{translateY:-20}] },
      })
    },
    labelTextTitle:{
      fontSize:24,
      fontWeight:'bold',
      color:"#484848",
      ...Platform.select({
        ios:{
          lineHeight:36
        },
        android:{
          lineHeight:36
        },
        default:{
          lineHeight: 20
        }
      })
    },
  });