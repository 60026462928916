import React,{ useEffect, useState, useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Platform } from 'react-native';
import TabBarImageIcon from '../components/common/TabBarImageIcon';
import SearchStack from './stack/SearchStack';
import MeetStack from './stack/MeetStack';
import IineStack from './stack/IineStack';
import ChatStack from './stack/ChatStack';
import MyPageStack from './stack/MyPageStack';
import CommonFunction from '../utils/CommonFunction';
import StorageHelper from '../utils/StorageHelper2';
import HeaderOptions from '../constants/HeaderOptions';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AppContext } from '../AppContext';

const BottomTab = createBottomTabNavigator();

const getLocalStorageValue = async(key) => {
  let item;
  if(Platform.OS==='web'){
    item = localStorage.getItem(key);
  }else{
    item = await StorageHelper.getData(key);
  }
  return item;
}

export default function BottomTabNavigator({ navigation, route }) {
  const [badge, setBadge] = useState({
    good: 0,
    notification: 0,
    footmark: 0,
    message: 0,
    contact: 0
  });
  const currentDate = new Date().getDate();
  const [ikuyoStatus, setIkuyoStatus] = useState(() =>
    getLocalStorageValue('ikuyoStatus')
  );
  const [origin, setOrigin] = useState(() =>
    getLocalStorageValue('origin')
  );
  let backBtnVis = false;
  if(Platform.OS==='web'){
    if (Number(ikuyoStatus)==1) {
      if (origin=='ios') {
        const agent = window.navigator.userAgent.toLowerCase()
        if (agent.indexOf("edg") != -1) {
        } else if (agent.indexOf("edge") != -1) {
        } else if (agent.indexOf("firefox") != -1) {
        } else if (agent.indexOf("fxios") != -1) {
        } else {
          if ((agent.indexOf("chrome") != -1) || (agent.indexOf("safari") != -1)) {
            backBtnVis = true;
          }
        }
      } else {
        backBtnVis = true;
      }
    }
  }
  
  const insets = useSafeAreaInsets();
  
  const {appState, setAppState} = useContext(AppContext);
  useEffect(() => {
    appState.insets = insets;
    setAppState(appState);
  },[]);

  return (
    <BottomTab.Navigator 
      initialRouteName="Search"
      screenOptions={{
        tabBarActiveTintColor: "#90dd95",
        tabBarInactiveTintColor: "#9aa5a7",
        tabBarActiveBackgroundColor: "#FFFFFF",
        tabBarInactiveBackgroundColor: "#FFFFFF",
        tabBarLabelStyle: {
          fontSize: 10,
          fontWeight: 'bold',
          lineHeight: 18,
        },
        tabBarStyle: [{...Platform.select({
          ios: {},
          android: {},
          default: { 
            height: HeaderOptions.bottomTabHeight,
            position:'absolute',
            bottom:0
          }
        }),
        }]
      }}
      backBehavior={"history"}
    >
    {backBtnVis==true?
        <BottomTab.Screen
        name="Ikukuru"
        children={(props)=><SearchStack setBadge={setBadge} {...props} />}
        options={{
          headerShown: false,
          title: '',
          unmountOnBlur:false,
          tabBarIcon: () => 
            <TabBarImageIcon image={require('../assets/images/search2222.png')} backBtnVis={backBtnVis} focused={false} />
        }}
        listeners={{
          tabPress: () => {
            CommonFunction.backToIkuyo();
            return;
          }
        }}
        />
      : <></> }
      <BottomTab.Screen
        name="Search"
        children={(props)=><SearchStack setBadge={setBadge} setIkuyoStatus={setIkuyoStatus} {...props} />}
        options={{
          headerShown: false,
          title: '探す',
          unmountOnBlur:false,
          tabBarIcon: ({focused}) => 
            <TabBarImageIcon image={focused?require('../assets/images/search-active.png'):require('../assets/images/search.png')} />
        }}
        listeners={{
          focus: () => {
            CommonFunction.setAccessTime();
          },
          tabPress: (e) => {
            e.preventDefault();
            navigation.navigate('Home', {screen:'Search', params:{ screen:'SearchList'}});
          }
        }}
      />
      <BottomTab.Screen
        name="Meet"
        children={(props)=><MeetStack setBadge={setBadge} {...props} />}
        options={{
          headerShown: false,
          title: '日付から探す',
          unmountOnBlur:true,
          tabBarIcon: ({focused}) => 
            <TabBarImageIcon image={focused?require('../assets/images/calendar-active.png'):require('../assets/images/calendar.png')} text={currentDate} focused={focused}/>
        }}
        listeners={{
          focus: () => {
            //CommonFunction.checkAccessTime();
          },
          tabPress: (e) => {
            //e.preventDefault();
            //navigation.navigate('Home', {screen:'Meet', params:{ screen:'MeetList'}});
            navigation.navigate('Meet', {screen:'MeetList'});
          }
        }}
      />
      <BottomTab.Screen
        name="Iine"
        children={(props)=><IineStack setBadge={setBadge} {...props} />}
        options={{
          headerShown: false,
          title: 'いいね',
          unmountOnBlur:true,
          tabBarIcon: ({focused}) => 
            <TabBarImageIcon image={focused?require('../assets/images/iine-active.png'):require('../assets/images/iine.png')} 
            badge={badge?.good} />
        }}
        listeners={{
          focus: () => {
            //CommonFunction.checkAccessTime();
          },
          tabPress: (e) => {
            e.preventDefault();
            navigation.navigate('Home', {screen:'Iine', params:{ screen:'IineList'}});
          }
        }}
      />
      <BottomTab.Screen
        name="Chat"
        children={(props)=><ChatStack setBadge={setBadge} {...props} />}
        options={{
          headerShown: false,
          title: 'メッセージ',
          unmountOnBlur:true,
          tabBarIcon: ({focused}) => 
            <TabBarImageIcon image={focused?require('../assets/images/chat-active.png'):require('../assets/images/chat.png')} 
            badge={badge?.message} />
        }}
        listeners={{
          focus: () => {
            //CommonFunction.checkAccessTime();
          },
          tabPress: (e) => {
            e.preventDefault();
            navigation.navigate('Home', {screen:'Chat', params:{ screen:'ChatList'}});
          }
        }}
      />
      <BottomTab.Screen
        name="MyPageStack"
        children={(props)=><MyPageStack setBadge={setBadge} setIkuyoStatus={setIkuyoStatus} {...props} />}
        options={{
          headerShown: false,
          title: 'マイページ',
          unmountOnBlur:true,
          tabBarIcon: ({focused}) => {
            let notification = 0;
            if(badge?.notification>0 || badge?.contact>0 || badge?.footmark>0) notification = 1;
            return ( 
              <TabBarImageIcon image={focused?require('../assets/images/user-active.png'):require('../assets/images/user.png')} 
              badge={notification} notnumber={true} />
            );
          }
        }}
        listeners={{
          tabPress: (e) => {
            e.preventDefault();
            navigation.navigate('Home', {screen:'MyPageStack', params:{ screen:'MyPage'}});
          },
          focus: () => {
            let routeName = '';
            const currentRoutes = navigation?.getState()?.routes[0]?.state;
            if(currentRoutes){
              const index = currentRoutes?.index;
              if(index!=-1){
                routeName = currentRoutes.routes[index]?.state?.routes[0]?.name;
              }
            }
            if(routeName!='Withdraw' && routeName!='SubscriptionComplete' &&  routeName!='PointPurchaseComplete'){
              //CommonFunction.checkAccessTime();
            }
          }
        }}
      />
    </BottomTab.Navigator>
  );
}